import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCategoryList } from '../../actions/categoryActions';
import { getProductList, getProductById } from '../../actions/productActions';
import { RESET_PRODUCT_LIST } from '../../constants/productConstants';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { API } from '../../config';

const useStyles = makeStyles({
  media: {
    padding: 12,
    // height: 130,
    // width: 150,
    // margin: 'auto',
    // marginTop: 12,
    // height: auto;
  },
  titleProduct: {
    fontSize: '1.3rem',
  },
});

const ProductCard = ({
  productPhotoName = 'photo_Testowa dieta_5f4e04e36c6d0f305167f0d2.png',
  name = 'Dieta',
  selectProduct,
  productId,
}) => {
  // const dispatch = useDispatch();

  const classes = useStyles();
  return (
    <Card>
      <CardActionArea onClick={() => selectProduct(productId)}>
        <CardMedia
          component='img'
          // height='140'
          className={classes.media}
          image={`/diets/${productPhotoName}`}
          // image={`${API}/uploads/diets/${productPhotoName}`}
          // image={`${API}/uploads/${productPhotoName}`}
          title='Dieta testowa'
        ></CardMedia>
        <CardContent>
          <Typography
            align='center'
            gutterBottom
            variant='h5'
            className={classes.titleProduct}
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions></CardActions> */}
    </Card>
  );
};

export default function ProductSelection({ startDietConfig }) {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);
  const { products } = useSelector((state) => state.productList);

  useEffect(() => {
    dispatch({ type: RESET_PRODUCT_LIST });
    dispatch(getCategoryList());
  }, [dispatch]);

  const handleClickCategory = (id) => {
    dispatch(
      getProductList({
        filter: `category=${id}`,
        select: `name, photo`,
        sort: 'displayOrder',
      })
    );
  };

  const handleClickProduct = (id) => {
    // if (product.category.name === 'Diety') {
    dispatch(getProductById(id));
    startDietConfig();
    // }
  };

  return (
    <div style={{ textAlign: 'center', margin: 20 }}>
      {categories && (
        <ButtonGroup size='large' color='primary'>
          {/* Na chwile obecną są tylko diety do wyboru */}

          {categories.map((c) => {
            if (c.name === 'Diety') {
              return (
                <Button key={c._id} onClick={() => handleClickCategory(c._id)}>
                  {c.name}
                </Button>
              );
            } else return null;
          })}
        </ButtonGroup>
      )}

      <div style={{ marginTop: '12px' }}>
        <Grid container spacing={2}>
          {products &&
            products.map((p) => (
              <Grid key={p._id} item xs={12} sm={6} md={2}>
                <ProductCard
                  name={p.name}
                  productPhotoName={p.photo}
                  productId={p._id}
                  selectProduct={handleClickProduct}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
}
