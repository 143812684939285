import * as React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';
import { Link } from 'react-router-dom';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NavLinks from './NavLinks';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
interface Props {
  children: React.ReactNode;
  history: any;
  auth: any;
  logout: any;
}

function NavBar({
  children,
  history,
  auth: { isAuthenticated, user },
  logout,
}: Props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            Fit CRM
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            {isAuthenticated && user ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='subtitle2'>{`Hej, ${user.fName}`}</Typography>
                {/* // <div onClick={() => signout(() => history.push('/'))}> */}
                <IconButton onClick={logout} color='secondary' size='large'>
                  <ExitToAppIcon />
                </IconButton>
              </div>
            ) : (
              <Link to='/login'>
                <IconButton color='secondary' size='large'>
                  <LockSharpIcon />
                </IconButton>
              </Link>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <NavLinks user={user} />
        {/* // <List>
        //   {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
        //     <ListItem key={text} disablePadding sx={{ display: 'block' }}>
        //       <ListItemButton
        //         sx={{
        //           minHeight: 48,
        //           justifyContent: open ? 'initial' : 'center',
        //           px: 2.5,
        //         }}
        //       >
        //         <ListItemIcon
        //           sx={{
        //             minWidth: 0,
        //             mr: open ? 3 : 'auto',
        //             justifyContent: 'center',
        //           }}
        //         >
        //           {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
        //         </ListItemIcon>
        //         <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        //       </ListItemButton>
        //     </ListItem>
        //   ))}
        // </List> 
        // <Divider />
        // <List>
        //   {['All mail', 'Trash', 'Spam'].map((text, index) => (
        //     <ListItem key={text} disablePadding sx={{ display: 'block' }}>
        //       <ListItemButton
        //         sx={{
        //           minHeight: 48,
        //           justifyContent: open ? 'initial' : 'center',
        //           px: 2.5,
        //         }}
        //       >
        //         <ListItemIcon
        //           sx={{
        //             minWidth: 0,
        //             mr: open ? 3 : 'auto',
        //             justifyContent: 'center',
        //           }}
        //         >
        //           {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
        //         </ListItemIcon>
        //         <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        //       </ListItemButton>
        //     </ListItem>
        //   ))}
        // </List> */}
      </Drawer>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(NavBar);
