import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

// Import your language translation files
import translation from 'zod-i18n-map/locales/pl/zod.json';

i18next.init({
  lng: 'pl',
  resources: {
    pl: {
      zod: translation,
    },
  },
});
z.setErrorMap(zodI18nMap);

export const servingStyleEnum = [
  'Najlepsze na zimno',
  'Warto podgrzać',
  'Na zimno i na ciepło',
] as const;
export const mealTypeEnum = [
  'Śniadanie',
  'Drugie Śniadanie',
  'Obiad',
  'Podwieczorek',
  'Kolacja',
  'Koktajl',
  'Shot',
] as const;

// pl(z); // Apply Polish translations to zod

export const mealOverviewSchema = z.object({
  //   id: z.string(),
  name: z.string().trim().min(3),
  nameLabel: z.string().min(3).trim(),
  nameWWW: z.string().min(3).trim(),
  servingStyle: z.enum(servingStyleEnum),
  mealType: z.enum(mealTypeEnum),
  comments: z.string().trim(), //.optional(),
});

export type MealOverview = z.infer<typeof mealOverviewSchema>;
export type MealOverViewWithId = MealOverview & { _id: string };
