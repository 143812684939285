import React from 'react';

import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

const UserContacts = ({ contacts, contactOption, addContact, editContact }) => {
  // const dispatch = useDispatch();
  // const { contacts } = useSelector((state) => state.userDetails.user);
  const {
    contactChannel,
    contactStatus,
    contactTypes,
    problemSource,
  } = contactOption;
  const contactsFiltered = contacts.filter(
    (c) => c.createdBy?.fName !== 'System'
  );

  const contactChannelOptions = contactChannel?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );
  const contactStatusOptions = contactStatus?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );
  const contactTypesOptions = contactTypes?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );

  const problemSourceOptions = problemSource?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );

  const structure = [
    {
      title: 'Data',
      field: 'contactDate',
      // width: '15%',
      render: (row) => format(new Date(row.contactDate), 'd.M.y'),
      editable: 'never',
      defaultSort: 'desc',
    },
    {
      title: 'Typ',
      field: 'contactType',
      lookup: contactTypesOptions,
    },
    {
      title: 'Kanał',
      field: 'contactChannel',
      lookup: contactChannelOptions,
    },
    {
      title: 'Status',
      field: 'contactStatus',
      lookup: contactStatusOptions,
    },
    {
      title: 'Odpowiedzialność',
      field: 'problemSource',
      lookup: problemSourceOptions,
    },
    {
      title: 'Opis',
      field: 'contactDescription',
      width: '70%',
      // editable: 'always',
    },
    {
      title: 'Wprowadzone',
      render: (rowData) => rowData?.createdBy?.fName,
      editable: 'never',
    },
  ];
  return (
    <div style={{ margin: 16 }}>
      {contacts && (
        <MaterialTable
          title='Historia kontaktów'
          data={contactsFiltered}
          // data={contacts}
          columns={structure}
          options={{
            sorting: true,
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                // dispatch(addContact(newData));
                addContact(newData);
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                editContact(newData, oldData);
                // dispatch(editContact(newData, oldData));
                resolve();
              }),
          }}
        />
      )}
    </div>
  );
};

UserContacts.propTypes = {
  contacts: PropTypes.array,
  contactOptions: PropTypes.object,
  editContact: PropTypes.func,
  addContact: PropTypes.func,
};

export default UserContacts;
