import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
  },
  columnA: {
    marginRight: theme.spacing(2),
    width: '50%',
  },
  rowCol: {
    minHeight: 35,
  },
  edit: {
    marginLeft: 'auto',
  },
}));

const valueTypeConversion = (value, type) => {
  let convertedValue = value;
  switch (type) {
    case 'date':
      convertedValue = new Date(value).toLocaleDateString();
      break;
    case 'numberFloat':
      convertedValue = parseFloat(value).toFixed(1);
      break;
    case 'percent':
      convertedValue = (parseFloat(value) * 100).toFixed(0);
      break;
  }

  return convertedValue;
};

const InfoBox = ({
  title = '',
  structure = [],
  data = null,
  onUpdate = undefined,
  options = { isEditable: true },
}) => {
  const classes = useStyles();
  const [isEdited, setIsEdited] = useState(false);
  const [values, setValues] = useState({ ...data });
  const [errors, setErrors] = useState(false);

  const editHandler = () => {
    setIsEdited((state) => !state);
    setValues({ ...data });
  };
  const cancelHandler = () => {
    setValues({});
    setIsEdited((state) => !state);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (typeof onUpdate === 'function') {
      if (!errors) {
        onUpdate(values);
        setValues({});
        setIsEdited((state) => !state);
      }
    }
  };

  const valueChangeHandler = (e) => {
    //validate for errors
    const field = structure.find((s) => s.key === e.target.name);
    if (field.validate && field.validate(e.target.value)) {
      setErrors(true);
    } else setErrors(false);

    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <div className={classes.content}>
          <div className={classes.columnA}>
            {structure.map(
              (s) =>
                // <div style={{ display: 'inline-block' }}>

                s.value || isEdited ? (
                  <Typography
                    key={s.key || s.name}
                    variant='body1'
                    className={classes.rowCol}
                  >
                    <strong>{`${s.name}:`}</strong>
                  </Typography>
                ) : null
              // </div>
            )}
          </div>
          <div className={classes.columnB}>
            {structure.map((s) => {
              if (values && isEdited && s.isEditField) {
                return (
                  // <div>
                  <TextField
                    key={s.key}
                    error={s.validate && s.validate(values[s.key])}
                    multiline={s.type === 'textArea' ? true : false}
                    fullWidth
                    type={s.type === 'number' && 'number'}
                    name={s.key}
                    value={values[s.key]}
                    className={classes.rowCol}
                    onChange={valueChangeHandler}
                    select={s.type === 'select'}
                  >
                    {s.type === 'select' &&
                      s.selectOptions.map((sO) => (
                        <MenuItem key={sO.value} value={sO.value}>
                          {sO.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  // </div>
                );
              } else if (s.value !== undefined) {
                return (
                  <Typography
                    key={s.key || s.name}
                    variant='body1'
                    className={classes.rowCol}
                  >
                    {valueTypeConversion(s.value, s.type)}
                  </Typography>
                );
              }
            })}
          </div>
        </div>
      </CardContent>
      {options.isEditable ? (
        <CardActions disableSpacing>
          {isEdited ? (
            <div className={classes.edit}>
              {!errors && (
                <IconButton onClick={submitHandler} size="large">
                  <CheckIcon />
                </IconButton>
              )}
              <IconButton className={classes.edit} onClick={cancelHandler} size="large">
                <CancelIcon />
              </IconButton>
            </div>
          ) : (
            <IconButton className={classes.edit} onClick={editHandler} size="large">
              <EditIcon />
            </IconButton>
          )}
        </CardActions>
      ) : null}
    </Card>
  );
};

export default InfoBox;
