import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { red, green, yellow } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  avatar: {
    // color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  chip: {
    margin: theme.spacing(1),
    // color: green[500],
    // color: theme.palette.getContrastText(red[500]),
  },
}));
const statusArr = [
  { avatar: 'Planowany', name: 'Planowany', color: 'green' },
  { avatar: 'Pł', name: 'Płatność', color: 'yellow' },
  //   { avatar: 'Z', name: 'Zawieszony', color: 'red' },
];

const StatusChip = ({ deliveryDays, handleClick, currentStatus }) => {
  const classes = useStyles();
  if (!deliveryDays) return null;
  return (
    <div>
      {statusArr.map((status) => {
        const count = deliveryDays.filter(
          (d) => d.status === status.name
        ).length;
        const isClicked = status.name === currentStatus ? true : false;
        return (
          <Chip
            // avatar={
            //   <Avatar className={classes.avatar}>
            //     {status.avatar}
            //     {/* {count} */}
            //   </Avatar>
            // }
            key={status.name}
            label={`${status.name}: ${count}`}
            color='primary'
            className={classes.chip}
            // color={status.color}
            variant={isClicked ? 'default' : 'outlined'}
            // disabled={!isClicked}
            onClick={() => handleClick(status.name)}
          />
        );
      })}
    </div>
  );
};

export default StatusChip;
