import React, { useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { RecipeReport } from '../../../../FitAPI/src/types/reports/RecipeReport';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import Loader from '../layout/Loader';
import SummaryInfo from './SummaryInfo';
import { SummaryData } from '../../../../FitAPI/src/types/reports/summaryReport';

type Props = {
  query: UseQueryResult<RecipeReport[], unknown>;
  summaryData: SummaryData | undefined;
};

const RecipesReport = (props: Props) => {
  const { query, summaryData } = props;
  const { isLoading, isError, data } = query;

  const ref = useRef(null);
  const printIngredientsHandler = useReactToPrint({
    content: () => ref.current,
  });

  const theme = useTheme();

  return (
    <div>
      <Box textAlign='right'>
        <Button onClick={printIngredientsHandler}>Drukuj</Button>
      </Box>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Typography variant='h5' sx={{ color: theme.palette.secondary.main }}>
          Błąd - Sprawdź czy jest zdefiniowane menu na ten okres
        </Typography>
      ) : (
        <div ref={ref}>
          <div>
            <Typography variant='h5' align='center'>
              Raport na kuchnię
            </Typography>
            {summaryData && (
              <SummaryInfo summaryData={summaryData} type='productionReport' />
            )}

            {data.map((row) => (
              <React.Fragment key={row.recipeId}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{`${row.recipeName} (${
                      row.mealLabel
                    }) -  ${row.totalWeight.toLocaleString()}g ${
                      row.portionQty
                        ? '| ' +
                          row.portionQty?.toFixed(1) +
                          'szt. x ' +
                          row.portionWeightPrepared?.toFixed(0) +
                          'g.' +
                          '/' +
                          row.portionWeightRaw?.toFixed(0) +
                          'g.'
                        : ''
                    }`}</Typography>
                    {/* <Typography variant='body1'>
                      Waga całkowita:<strong>{`${row.totalWeight}g`}</strong>
                    </Typography> */}
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Typography variant='body1'>
                      <strong>Lista składników:</strong>
                    </Typography> */}
                    <div style={{ marginLeft: 0 }}>
                      {row.ingredientsList.map((ingr) => (
                        <Grid key={ingr._id} container>
                          <Grid item xs={2}>
                            <Typography
                              align='right'
                              variant='body2'
                              style={{ paddingRight: 6 }}
                            >
                              <strong>{`${ingr.weight.toFixed(0)}g.`}</strong>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography key={ingr._id} variant='body2'>
                              {ingr.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                      {/* Suma składników */}
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography
                            align='right'
                            variant='body2'
                            style={{ paddingRight: 6 }}
                          >
                            <strong>
                              <em>
                                {row.totalIngr?.toFixed(0).toLocaleString()}g.
                              </em>
                            </strong>
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <Typography variant='body1'>
                            Suma składników
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      Diety: {row.diets.toString()}
                      <br />
                      Tacka:<strong>{` ${row.mealName}`}</strong>
                    </Typography>
                    {row.instructions && (
                      <>
                        <Typography variant='body2'>Opis dania</Typography>
                        <Typography variant='body2'>
                          {row.instructions}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <hr />
                <div className='page-break' />
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipesReport;
