import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { CART_REMOVE_ITEM } from '../../constants/cartConstants';
import { format } from 'date-fns';
import { API } from '../../config';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },

  // TODO: fix formating of the image
  media: {
    height: 175,
    width: '60%',
    margin: 'auto',
    // height: auto;
  },
});

const ProductCard = ({ cartItem, removeProduct, idx }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={`/diets/${cartItem.product.photo}`}
          // image={`${API}/uploads/${cartItem.product.photo}`}
          // image="/static/images/cards/contemplative-reptile.jpg"
          title='Dieta testowa'
        />
        <CardContent>
          <Typography align='center' gutterBottom variant='h5' component='h2'>
            {`${cartItem.name} ${cartItem.calories} kcal`}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            <Grid container>
              <Grid item xs={6}>
                Początek:
              </Grid>
              <Grid item xs={6}>
                {format(cartItem.deliveryDays[0], 'd.MM.yyyy')}
                {/* {format(cartItem.deliveryDays[0].deliveredAt, 'd.MM.yyyy')} */}
              </Grid>
              <Grid item xs={6}>
                Koniec:
              </Grid>
              <Grid item xs={6}>
                {format(
                  cartItem.deliveryDays[cartItem.deliveryDays.length - 1],
                  'd.MM.yyyy'
                )}
                {/* {format(
                  cartItem.deliveryDays[cartItem.deliveryDays.length - 1]
                    .deliveredAt,
                  'd.MM.yyyy'
                )} */}
              </Grid>
              <Grid item xs={6}>
                Liczba dni:
              </Grid>
              <Grid item xs={6}>
                {cartItem.nrDays}
              </Grid>
              <Grid item xs={6}>
                Cena:
              </Grid>
              <Grid item xs={6}>
                {`${cartItem.price} zł`}
              </Grid>
            </Grid>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size='small'
          color='primary'
          onClick={(idx) => dispatch({ type: CART_REMOVE_ITEM, payload: idx })}
          // onClick={(idx) => handleRemove(idx)}
        >
          Usuń
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
