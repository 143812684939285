//Dialog box to accept changes from the user
import React, { useState } from 'react';

import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getProducts } from '../../../utils/api/productApi';
import { getMealName } from '../../../utils/api/menu.meal.api';
import { getMealVariants } from '../../../utils/api/optionApi';
import {
  ProductType,
  ProductCaloriesType,
  MealVariantType,
} from '../../../types/productType';
import SaveIcon from '@mui/icons-material/Save';
import { addMealOrder } from '../../../utils/api/orderMealApi';
import { useSnackbar } from 'notistack';

interface DayMenuProps {
  orderId: string;
  orderItemId: string;
  dayId: string;
  dayAt: string;
  handleClose: () => void;
  // open: boolean;
  // handleCancel: () => void;
  // handleAccept: () => void;
  // title: string;
  // description: string;
}

// interface ProductType {
//   name: string;
// }

export default function DayMenuAdd(props: DayMenuProps) {
  // const { open, handleAccept, handleCancel, title, description } = props;
  const { orderId, orderItemId, dayId, dayAt, handleClose } = props;

  // const queryClient = useQueryClient();
  const [dietName, setDietName] = useState('');
  const [caloriesAvailable, setCaloriesAvailable] = useState([]);
  const [size, setSize] = useState('');
  const [calories, setCalories] = useState(0);
  // const [mealsAvailable, setMealsAvailable] = useState([]);
  const [mealVariant, setMealVariant] = useState('');
  const [meal, setMeal] = useState('');
  const [mealCount, setMealCount] = useState(0);

  const productQuery = useQuery(['products'], () => getProducts({}));
  const optionQuery = useQuery(['getMealVariants', mealVariant, calories], () =>
    getMealVariants(mealVariant, calories)
  );

  const mealNameQuery = useQuery(
    ['getMealName', dayAt, dietName, meal, size, mealCount],
    () => getMealName(dayAt.split('T')[0], dietName, meal, size, mealCount)
  );
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const dietNames = productQuery.data?.products
    .filter((p: ProductType) => p.active)
    .sort((a: ProductType, b: ProductType) => a.displayOrder - b.displayOrder)
    .map((p: ProductType) => p.name);

  const isFlexDiet: boolean =
    productQuery.data?.products.find(
      (diet: ProductType) => diet.name === dietName
    )?.isFlex || false;

  const handleDietNameChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.value as string;
    setDietName(name);
    const currentCalories = productQuery.data.products
      .find((diet: ProductType) => diet.name === name)
      .calories.map((c: ProductCaloriesType) => c.variant);
    setCaloriesAvailable(currentCalories);
    setCalories(currentCalories[0]);

    const variant = productQuery.data.products.find(
      (diet: ProductType) => diet.name === name
    ).mealOptionsVariant;

    setMealVariant(variant);
  };

  const handleCaloriesChange = (event: SelectChangeEvent<number>) =>
    setCalories(event.target.value as number);

  const handleSizeChange = (event: SelectChangeEvent<string>) =>
    setSize(event.target.value as string);

  const handleMealChange = (event: SelectChangeEvent<string>) =>
    setMeal(event.target.value as string);

  const handleMealCountChange = (event: SelectChangeEvent<number>) =>
    setMealCount(event.target.value as number);

  const mutation = useMutation(
    () =>
      addMealOrder(
        orderId,
        orderItemId,
        dayId,
        dietName,
        calories,
        meal,
        mealCount
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getMeals']);
        enqueueSnackbar(`Dodano: ${mealNameQuery.data.mealNameWWW}`, {
          variant: 'success',
        });
      },
    }
  );

  const handleSave = () => {
    mutation.mutate();
    handleClose();
  };

  return (
    <>
      {productQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id='dietName-label'>Dieta</InputLabel>
          <Select
            labelId='dietName-label'
            id='dietName'
            value={dietName}
            label='Age'
            onChange={handleDietNameChange}
          >
            {dietNames.map((diet: string) => (
              <MenuItem value={diet}>{diet}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {dietName !== '' && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap={2}
          // sx={{
          //   '&>*': { mb: 3 },
          // }}
        >
          <FormControl fullWidth>
            <InputLabel id='size-label'>Rozmiar</InputLabel>
            <Select
              labelId='size-label'
              id='size'
              value={size}
              label='Posiłek'
              onChange={handleSizeChange}
            >
              {['S', 'M', 'L', 'XL'].map((variant) => (
                <MenuItem value={variant}>{variant}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id='calories-label'>Dieta</InputLabel>
            <Select
              labelId='calories-label'
              id='calories'
              value={calories}
              label='Posiłek'
              onChange={handleCaloriesChange}
            >
              {caloriesAvailable.map((variant: number) => (
                <MenuItem value={variant}>{variant}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {optionQuery.data ? (
            <>
              <FormControl fullWidth>
                <InputLabel id='meals-label'>Typ posiłku</InputLabel>
                <Select
                  labelId='meals-label'
                  id='meals'
                  value={meal}
                  label='kcal'
                  onChange={handleMealChange}
                >
                  {optionQuery.data.map((variant: MealVariantType) => (
                    <MenuItem value={variant.mealType}>
                      {variant.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isFlexDiet && (
                <FormControl fullWidth>
                  <InputLabel id='meal-count'>Posiłek</InputLabel>
                  <Select
                    labelId='meal-count'
                    id='meal-count'
                    value={mealCount}
                    label='Posiłek'
                    onChange={handleMealCountChange}
                  >
                    {Array.from({ length: 6 }, (_, index) => index).map(
                      (index) => (
                        <MenuItem value={index}>{index}</MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </>
          ) : null}
          {/* {mealNameQuery.data ? mealNameQuery.data.mealNameWWW : 'brak'} */}
          <Box marginY={3}>
            {mealNameQuery.isFetching ? (
              <CircularProgress />
            ) : (
              mealNameQuery.data && (
                <>
                  <Typography color='textSecondary'>
                    {mealNameQuery.data?.mealNameWWW}
                  </Typography>
                  <Box marginTop={0}>
                    <Typography color='textSecondary' variant='body2'>
                      {mealNameQuery.data?.servingStyle}
                    </Typography>
                    <Typography color='textSecondary' variant='caption'>
                      {`${parseInt(mealNameQuery.data?.kcal).toFixed(
                        0
                      )} kcal | B:${parseInt(
                        mealNameQuery.data?.protein
                      ).toFixed(1)} | W:${parseInt(
                        mealNameQuery.data?.carbs
                      ).toFixed(1)} | T:${parseInt(
                        mealNameQuery.data?.fat
                      ).toFixed(1)}`}
                    </Typography>
                  </Box>
                </>
              )
            )}
          </Box>
          <Box
            display='flex'
            justifyContent='start'
            marginBottom={3}
            marginTop={0}
          >
            <IconButton onClick={handleSave} size='large'>
              <SaveIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
}

export {};
