import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// import { Redirect } from 'react-router-dom';

import { createUser } from '../actions/userActions';
import { CLEAR_USER } from '../constants/userConstants';
import Layout from '../components/layout/Layout';

import { makeStyles } from '@mui/styles';
import { MyTextInput } from '../helpers/CustomFields';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';

const initialValues = {
  fName: '',
  sName: '',
  gender: '',
  // address: '',
  password: '123456',
  phone: '',
  email: '',
  foodExclusions: [],
  otherFoodExclusions: '',
  comments: '',
};
const validationSchema = Yup.object({
  fName: Yup.string().required('Wymagane'),
  sName: Yup.string().required('Wymagane'),
  // gender: Yup.string().required('Wymagane'),
  phone: Yup.string()
    .required('Wymagane')
    .matches(
      // /^(?:\(?\?)?(?:[-\.\(\)\s]*(\d)){9}\)?$/,
      /^(?:\(?\?)?(?:[-.()\s]*(\d)){9}\)?$/,
      'Sprawdź czy telefon jest poprawny'
    ),
  email: Yup.string()
    .email('Sprawdź czy email jest poprawny')
    .required('Wymagane'),
});

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
  },
}));

const NewUserScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userDetails = useSelector((state) => state.userDetails);
  const classes = useStyles();
  const [foodList] = useState([]);

  // useEffect(() => {
  //   // fetchFoodList();
  // }, []);

  useEffect(() => {
    dispatch({ type: CLEAR_USER });
  }, [dispatch]);

  const handleSubmit = async (values) => {
    const newUserId = await dispatch(createUser(values));
    navigate(`/users/${newUserId}`);
  };

  return (
    <Layout title={`Dodaj nowego uzytkownika`}>
      {/* {user ? (
        <Redirect to={`/users/${user._id}`} />
      ) : ( */}
      {/* // ) : loading ? ( // <Loader /> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form>
          <Container component='main' maxWidth='xs'>
            <MyTextInput
              variant='outlined'
              margin='normal'
              fullWidth
              label='Imię*'
              name='fName'
            />
            <MyTextInput
              variant='outlined'
              margin='normal'
              label='Nazwisko*'
              name='sName'
            />
            <MyTextInput
              variant='outlined'
              margin='normal'
              label='Email*'
              name='email'
            />
            <MyTextInput
              variant='outlined'
              margin='normal'
              label='Telefon'
              name='phone'
            />
            <Box mt={1}>
              {/* <MyRadioInput
                row
                legend='Płeć*'
                name='gender'
                radioOptions={genderList}
              /> */}
            </Box>

            {/* Wyjątki dla klienta	*/}
            <Autocomplete
              selectOnFocus
              multiple
              id='foodExclusions'
              name='foodExclusions'
              options={foodList}
              // onChange={(e, value) =>
              //   formik.setFieldValue('foodExclusions', value)
              // }
              getOptionLabel={(food) => food.name}
              renderInput={(params) => (
                <TextField
                  variant='outlined'
                  margin='normal'
                  label='Wyjątki ze składników'
                  name='foodExclusions'
                  {...params}
                />
              )}
              //   value={formik.values.foodExclusions}
            />
            <MyTextInput
              variant='outlined'
              margin='normal'
              label='Wyjątki - inne'
              name='otherFoodExclusions'
              multiline
              rows={2}
            />
            <MyTextInput
              variant='outlined'
              margin='normal'
              multiline
              rows={4}
              label='Uwagi'
              name='comments'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              // onClick={handleSubmit}
            >
              Dodaj
            </Button>
          </Container>
        </Form>
      </Formik>
      {/* )} */}
    </Layout>
  );
};

export default NewUserScreen;
