//function to calculate nutrition information for 100 g based on recipe propotions and ingredients nutrition information
// calculation for recipe screen
export const calculateNutrition100 = (ingrRecipeArr, weightRecipe) => {
  const nutritionRecipe = ingrRecipeArr.reduce(
    (nutr, x) => ({
      kcal: nutr.kcal + (x.ingredient.kcal * x.weight) / 100,
      protein: nutr.protein + (x.ingredient.protein * x.weight) / 100,
      carbs: nutr.carbs + (x.ingredient.carbs * x.weight) / 100,
      fat: nutr.fat + (x.ingredient.fat * x.weight) / 100,
      sodium: nutr.sodium + ((x.ingredient.sodium || 0) * x.weight) / 100,
      carbFiber:
        nutr.carbFiber + ((x.ingredient.carbFiber || 0) * x.weight) / 100,
    }),
    {
      kcal: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      sodium: 0,
      carbFiber: 0,
    }
  );

  const { kcal, protein, carbs, fat, sodium, carbFiber } = nutritionRecipe;
  return {
    kcal: (kcal * 100) / weightRecipe,
    protein: (protein * 100) / weightRecipe,
    carbs: (carbs * 100) / weightRecipe,
    fat: (fat * 100) / weightRecipe,
    sodium: (sodium * 100) / weightRecipe,
    carbFiber: (carbFiber * 100) / weightRecipe,
  };
};

//function to calculate nutrition information for portion based on the size of the portion
export const calculateNutritionPortion = (portionWeightRaw, nutrition100) => {
  if (!portionWeightRaw) return null;
  return {
    kcal: (portionWeightRaw * nutrition100.kcal) / 100,
    protein: (portionWeightRaw * nutrition100.protein) / 100,
    carbs: (portionWeightRaw * nutrition100.carbs) / 100,
    fat: (portionWeightRaw * nutrition100.fat) / 100,
    sodium: (portionWeightRaw * nutrition100.sodium) / 100,
    carbFiber: (portionWeightRaw * nutrition100.carbFiber) / 100,
  };
};
