//Dialog box to accept changes from the user
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
  open: boolean;
  handleCancel: () => void;
  handleAccept: () => void;
  title: string;
  description: string;
}

export default function AlertDialog(props: AlertDialogProps) {
  const { open, handleAccept, handleCancel, title, description } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            Zaniechaj
          </Button>
          <Button onClick={handleAccept} color='primary' autoFocus>
            Kontunuj
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
