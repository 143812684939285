import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Document, Page, pdfjs } from 'react-pdf';
import { getPurchaseInvoicePdf } from '../../utils/api/purchase.invoice.api';
import { Box, IconButton, Typography } from '@mui/material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
  invoiceId: string | undefined;
};

const InvoicePDF = (props: Props) => {
  const { invoiceId } = props;
  const { data: pdfUrl } = useQuery({
    queryKey: ['purchaseInvoicePdf', invoiceId],
    queryFn: () => getPurchaseInvoicePdf(invoiceId),
  });
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <section>
      {pdfUrl && (
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Box sx={{ display: 'flex' }}>
            <Page pageNumber={pageNumber} scale={1.5} />
          </Box>
        </Document>
      )}
      <Box
        mt={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          type='button'
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          <ArrowBackIos />
        </IconButton>
        <Typography variant='body1'>
          {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
        </Typography>

        {numPages && (
          <IconButton
            type='button'
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <ArrowForwardIos />
          </IconButton>
        )}
      </Box>
    </section>
  );
};

export default InvoicePDF;
