import {
  GET_DELIVERY_DAYS_REQUEST,
  GET_DELIVERY_DAYS_SUCCESS,
  GET_DELIVERY_DAYS_FAIL,
  DELIVERY_DAYS_UPDATE_MEAL,
  DELIVERY_DAYS_UPDATE_FLEX_MEAL,
  DELIVERY_DAYS_UPDATE_MEAL_NAME,
} from '../constants/productionReportConstants';
import produce from 'immer';
// import { OrderMealsDetails } from '../../../FitAPI/src/utils/order/getOrderMealsDetails';

const initialState = {
  deliveryDays: [],
  loading: true,
  error: null,
};

export default function productionReportReducers(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DELIVERY_DAYS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_DELIVERY_DAYS_SUCCESS:
      return {
        ...state,
        deliveryDays: payload,
        loading: false,
      };

    case GET_DELIVERY_DAYS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DELIVERY_DAYS_UPDATE_MEAL:
      return produce(state, (draftState) => {
        const { dayId, mealId, updatedValue, dietMenus } = action.payload;
        const { deliveryDays } = draftState;
        const dayIdx = deliveryDays.findIndex((d) => d.dayId === dayId);
        const mealIdx = deliveryDays[dayIdx].meals.findIndex(
          (m) => m._id === mealId
        );

        let updatedMeal = {
          ...deliveryDays[dayIdx].meals[mealIdx],
          ...updatedValue,
        };

        if (Object.keys(updatedValue).includes('diet')) {
          const selectedDiet = Object.values(updatedValue)[0];
          const menuDiet = dietMenus.find(
            (dietMenu) => dietMenu.dietType === selectedDiet
          );

          const mealDiet = menuDiet.mealMatchMatrix.find(
            (meal) => meal.mealType.name === updatedMeal.mealType
          );
          //when no flex diet is selected change to first (and only) meal for diet
          if (!menuDiet.isFlex) updatedMeal.mealCount = 0;
          updatedMeal.mealName = mealDiet.meals[updatedMeal.mealCount].name;
          const mealNutrtions = mealDiet.meals[
            updatedMeal.mealCount
          ].nutritions.find((n) => n.size === updatedMeal.size);
          updatedMeal.kcal = mealNutrtions.kcal;
          updatedMeal.protein = mealNutrtions.protein;
          updatedMeal.carbs = mealNutrtions.carbs;
          updatedMeal.fat = mealNutrtions.fat;
        }

        deliveryDays[dayIdx].meals[mealIdx] = {
          ...updatedMeal,
        };

        //Check if meals are different then standard  meals and set exception for the day
        const defaultMenuDiet = dietMenus.find(
          (dietMenu) => dietMenu.dietType === deliveryDays[dayIdx].diet
        );
        const defaultMeal = defaultMenuDiet.mealMatchMatrix.find(
          (meal) => meal.mealType.name === updatedMeal.mealType
        );
        if (
          updatedMeal.mealName !==
            defaultMeal.meals[updatedMeal.mealCount].name ||
          updatedMeal.diet !== deliveryDays[dayIdx].diet
        ) {
          deliveryDays[dayIdx].isMealException = true;
        } else {
          deliveryDays[dayIdx].isMealException = false;
        }
      });

    case DELIVERY_DAYS_UPDATE_FLEX_MEAL:
      return produce(state, (draftState) => {
        const { dayId, mealId, dietMenus, mealCount } = action.payload;
        const { deliveryDays } = draftState;

        const dayIdx = deliveryDays.findIndex((d) => d.dayId === dayId);
        const mealIdx = deliveryDays[dayIdx].meals.findIndex(
          (m) => m._id === mealId
        );
        const currentMeal = deliveryDays[dayIdx].meals[mealIdx];

        // Find specific meal in diet menu
        const menuDiet = dietMenus.find(
          (dietMenu) => dietMenu.dietType === currentMeal.diet
        );
        const mealDietArr = menuDiet.mealMatchMatrix.find(
          (meal) => meal.mealType.name === currentMeal.mealType
        );
        const selectedMeal = mealDietArr.meals[mealCount];
        const selectedMealNutritions = selectedMeal.nutritions.find(
          (n) => n.size === currentMeal.size
        );

        const updatedMeal = {
          ...currentMeal,
          mealCount: mealCount,
          mealName: selectedMeal.name,
          mealNameLabel: selectedMeal.nameLabel,
          mealNameWWW: selectedMeal.nameWWW,
          servingStyle: selectedMeal.servingStyle,
          kcal: selectedMealNutritions.kcal,
          protein: selectedMealNutritions.protein,
          fat: selectedMealNutritions.fat,
          carbs: selectedMealNutritions.carbs,
          currentMeal: selectedMeal.currentMeal,
          trayType: selectedMeal.trayTypes.find(
            (t) => t.size === currentMeal.size
          ).tray,
          tagsCustomer: [...selectedMeal.tagsCustomer],
          tagsProduction: [...selectedMeal.tagsProduction],
        };

        deliveryDays[dayIdx].meals[mealIdx] = { ...updatedMeal };
      });

    // update name on label and www for the user
    case DELIVERY_DAYS_UPDATE_MEAL_NAME:
      return produce(state, (draftState) => {
        let dayIdx;
        let mealIdx;
        draftState.deliveryDays.forEach((dD, i) => {
          const localMealIdx = dD.meals.findIndex(
            (m) => m._id === action.payload._id
          );
          if (localMealIdx > -1) {
            mealIdx = localMealIdx;
            dayIdx = i;
            return;
          }
        });
        draftState.deliveryDays[dayIdx].meals[mealIdx] = { ...action.payload };
      });
    default:
      return state;
  }
}
