import { format } from 'date-fns';
import { PurchaseInvoice } from '../../types/purchaseInvoiceType';
import api from '../api';

export const addPurchaseInvoiceScan = async (file: File) => {
  const formData = new FormData();
  formData.append('invoiceScan', file);
  const response = await api.post(`/purchase-invoices`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data.data;
};

export const getPurchaseInvoices = async () => {
  const response = await api.get(`/purchase-invoices`);
  return response.data.data;
};

export const getPurchaseInvoice = async (id: string | undefined) => {
  if (!id) throw new Error('No purchase invoice id provided');
  const response = await api.get(`/purchase-invoices/${id}`);
  return response.data.data;
};

export const getPurchaseInvoicePdf = async (id: string | undefined) => {
  if (!id) throw new Error('No purchase invoice id provided');
  const response = await api.get(`/purchase-invoices/${id}/pdf`, {
    responseType: 'blob',
  });
  const blob = new Blob([response.data], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
  //   return response.data;
};

export const updatePurchaseInvoice = async ({
  id,
  data,
}: {
  id: string | undefined;
  data: Partial<PurchaseInvoice>;
}) => {
  if (!id) throw new Error('No purchase invoice id provided');
  const convertedDateInvoice = {
    ...data,
    invoiceDate: data.invoiceDate
      ? format(data.invoiceDate, 'yyyy-MM-dd')
      : undefined,
    dueDate: data.dueDate ? format(data.dueDate, 'yyyy-MM-dd') : undefined,
    paidAt: data.paidAt ? format(data.paidAt, 'yyyy-MM-dd') : undefined,
  };

  const response = await api.put(
    `/purchase-invoices/${id}`,
    convertedDateInvoice
  );
  return response.data.data;
};

export const updateMultipleInvoices = async ({
  invoiceIds,
  fieldsToUpdate,
}: {
  invoiceIds: string[];
  fieldsToUpdate: Partial<PurchaseInvoice>;
}) => {
  // const updatedInvoices = invoices.map((invoice) => ({
  //   ...invoice,
  //   invoiceDate: formatDate(invoice.invoiceDate),
  //   dueDate: formatDate(invoice.dueDate),
  //   paidAt: formatDate(invoice.paidAt),
  // }));

  // const paidInvoices = invoices.map((invoice) => ({
  //   ...invoice,
  //   isPaid: true,
  //   paidAt: format(new Date(), 'yyyy-MM-dd'),
  // }));

  const response = await api.put(`/purchase-invoices`, {
    invoiceIds,
    fieldsToUpdate,
    // paidInvoices,
  });
  return response.data.data;
};

export const deletePurchaseInvoice = async (id: string | undefined) => {
  if (!id) throw new Error('No purchase invoice id provided');
  const response = await api.delete(`/purchase-invoices/${id}`);
  return response.data.data;
};

export const getPaymentFile = async ({
  invoices,
}: {
  invoices: string;
}): Promise<Blob> => {
  const response = await api.get(`/purchase-invoices/payment-file`, {
    params: { invoices },
    responseType: 'blob',
  });
  const blob = new Blob([response.data], { type: 'text/csv' });
  return blob;
};
