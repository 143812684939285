export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';

// export const CART_START_DIET_CONFIG = 'CART_START_DIET_CONFIG';
export const CART_DIET_UPDATE_CALORIES = 'CART_DIET_UPDATE_CALORIES';
// export const CART_DIET_UPDATE_DURATION = 'CART_DIET_UPDATE_DURATION';

export const CART_DIET_ADD_QTY = 'CART_DIET_ADD_QTY';
export const CART_DIET_SUBSTRACT_QTY = 'CART_DIET_SUBSTRACT_QTY';

export const CART_DIET_ADD_DAY = 'CART_DIET_ADD_DAY';
export const CART_DIET_SUBSTRACT_DAY = 'CART_DIET_SUBSTRACT_DAY';
export const CART_DIET_UPDATE_NR_DAYS = 'CART_DIET_UPDATE_NR_DAYS';
export const CART_DIET_UPDATE_NR_DELIVERIES = 'CART_DIET_UPDATE_NR_DELIVERIES';

// export const CART_DIET_TRIAL_TOGGLE = 'CART_DIET_TRIAL_TOGGLE';
export const CART_DIET_TRIAL_ON = 'CART_DIET_TRIAL_ON';
export const CART_DIET_TRIAL_OFF = 'CART_DIET_TRIAL_OFF';

export const CART_DIET_WEEKEND_UPDATE = 'CART_DIET_WEEKEND_UPDATE';
export const CART_DIET_DELIVERY_DAYS_UPDATE = 'CART_DIET_DELIVERY_DAYS_UPDATE';

export const CART_DIET_DAILY_DISCOUNT_UPDATE =
  'CART_DIET_DAILY_DISCOUNT_UPDATE';
export const CART_DIET_PRICE_UPDATE = 'CART_DIET_PRICE_UPDATE';

export const CART_RESET = 'CART_RESET';
export const CART_CURRENT_CART_ITEM_RESET = 'CART_CURRENT_CART_ITEM_RESET';

//operations on complete order

export const CART_OPTIONS_REQUEST = 'CART_OPTIONS_REQUEST';
export const CART_OPTIONS_SUCCESS = 'CART_OPTIONS_SUCCESS';
export const CART_OPTIONS_FAIL = 'CART_OPTIONS_FAIL';

export const CART_ORDER_PRICE_UPDATE = 'CART_ORDER_PRICE_UPDATE';
export const CART_ORDER_UPDATE_VALUES = 'CART_ORDER_UPDATE_VALUES';
export const CART_UPDATE_PAYMENT_METHOD = 'CART_UPDATE_PAYMENT_METHOD';
export const CART_UPDATE_ORDER_SOURCE = 'CART_UPDATE_ORDER_SOURCE';
export const CART_UPDATE_ISPAID = 'CART_UPDATE_ISPAID';
export const CART_UPDATE_PAIDAT = 'CART_UPDATE_PAIDAT';
export const CART_UPDATE_IS_EMAIL_SKIPPED = 'CART_UPDATE_IS_EMAIL_SKIPPED';

export const CART_SET_ADDRESS = 'CART_SET_ADDRESS';
