import React, { useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { MealReport } from '../../../../FitAPI/src/types/reports/MealReport';

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { useReactToPrint } from 'react-to-print';
import Loader from '../layout/Loader';
import SummaryInfo from './SummaryInfo';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { SummaryData } from '../../../../FitAPI/src/types/reports/summaryReport';
import { MoreHoriz } from '@mui/icons-material';

type Props = {
  query: UseQueryResult<MealReport[], unknown>;
  summaryData?: SummaryData;
};

const MealsReport = (props: Props) => {
  const { query, summaryData } = props;
  const { isLoading, isError, data } = query;

  const ref = useRef(null);
  const printIngredientsHandler = useReactToPrint({
    content: () => ref.current,
  });

  const theme = useTheme();

  return (
    <div>
      <Box textAlign='right'>
        <Button onClick={printIngredientsHandler}>Drukuj</Button>
      </Box>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Typography variant='h5' sx={{ color: theme.palette.secondary.main }}>
          Błąd - Sprawdź czy jest zdefiniowane menu na ten okres
        </Typography>
      ) : (
        <div ref={ref}>
          <div>
            <Typography align='center' variant='h5'>
              Raport tacek
            </Typography>
            {summaryData && (
              <SummaryInfo summaryData={summaryData} type='productionReport' />
            )}
            {data.map((row) => (
              <React.Fragment key={row._id}>
                <div //style={{ marginBottom: 16, marginTop: 16 }}
                >
                  {/* {row.isImage && <PhotoCameraIcon />} */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      marginTop: 3,
                    }}
                  >
                    <Typography variant='h5'>
                      {`${row.code}.${row.name} (${row.mealLabel})`}{' '}
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: 2,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {row.isImage && <PhotoCameraIcon />}
                      {/* Link to corresponding meal page */}
                      <IconButton
                        onClick={() => window.open(`/meals/${row._id}`)}
                      >
                        <MoreHoriz />
                      </IconButton>
                    </Box>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TableContainer
                      // component={Paper}
                      // ref={traysRef}
                      >
                        <Table
                          // className={classes.table}
                          padding='checkbox'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                              // style={{ width: '50%' }}
                              >
                                Nazwa
                              </TableCell>
                              {row.sizeCount.map((sC) => (
                                <TableCell
                                  key={sC.size}
                                  align='right'
                                  // style={{ paddingRight: 12 }}
                                >
                                  {`${sC.size}(${sC.count})`}
                                </TableCell>
                              ))}

                              <TableCell align='right'>{row.count}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* Wszystkie receptury  */}
                            {row.recipes.map((r) => (
                              <TableRow key={r._id}>
                                <TableCell align='left'>{`(R) ${r.name}`}</TableCell>
                                {r.sizes.map((s) => (
                                  <TableCell key={s.size} align='right'>
                                    {`${s.weight} ${
                                      s.portionQty
                                        ? ' (' + s.portionQty.toFixed(1) + ')'
                                        : ''
                                    }`}
                                  </TableCell>
                                ))}
                                <TableCell align='right'>
                                  {`${r.totalWeight?.toLocaleString()} ${
                                    r.totalPortionQty
                                      ? ' (' +
                                        r.totalPortionQty.toFixed(1) +
                                        ')'
                                      : ''
                                  }`}
                                </TableCell>
                              </TableRow>
                            ))}
                            {/* Wszystkie składniki  */}
                            {row.ingredients.map((i) => (
                              <TableRow key={i._id}>
                                <TableCell align='left'>{`(S) ${i.name}`}</TableCell>
                                {i.sizes.map((s) => (
                                  <TableCell key={s.size} align='right'>
                                    {s.weight}
                                  </TableCell>
                                ))}
                                <TableCell align='right'>
                                  {i.totalWeight.toLocaleString()}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        {`${row.diets.toString()}`}
                      </Typography>
                      <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                      <Trays trays={row.trayTypes} />
                      <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                      <Typography variant='body2'>
                        {`${row.isImage ? 'Zdjęcie' : ''} `}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider />
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Summary for each report to be included in print version

// type MealReportTrayTypes = Pick<MealReport, 'trayTypes'>;
// Infer the type of the shape { trayType: string; count: number; sizes: string[]; }
type MealReportTrayTypes = Pick<
  MealReport['trayTypes'][number],
  'trayType' | 'count' | 'sizes'
>;

interface MealReportTrayProps {
  trays: MealReportTrayTypes[];
}
const Trays = (props: MealReportTrayProps) => {
  const { trays } = props;

  return (
    <>
      {trays.map((t) => (
        <Grid container key={t.trayType}>
          <Grid item xs={6}>{`${t.trayType}:`}</Grid>
          <Grid item>
            <strong>{`${t.count} (${t.sizes.toString()})`}</strong>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default MealsReport;
