import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myDate } from '../../helpers/helpers';
import { Link } from 'react-router-dom';

import { getOrdersByUserId } from '../../actions/orderActions';

import { makeStyles } from '@mui/styles';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import Loader from '../layout/Loader';
import Alert from '@mui/material/Alert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { green } from '@mui/material/colors';
import format from 'date-fns/format';
import max from 'date-fns/max';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2),
  },
  card: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const UserOrders = ({ id }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { orders, loading, error } = useSelector((state) => state.userOrders);

  useEffect(() => {
    dispatch(getOrdersByUserId(id));
  }, [dispatch, id]);
  return (
    <Card elevation={3} className={classes.card}>
      <div className={classes.title}>
        <Typography variant='h6'>Historia zamówień</Typography>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Alert severity='error'>
          {error.msg} - {error.status}
        </Alert>
      ) : (
        <div className='overflow-auto'>
          <Table className='product-table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell className='px-24' colSpan={4}>
                  Numer
                </TableCell>
                <TableCell className='px-24' colSpan={4}>
                  Numer W
                </TableCell>
                <TableCell className='px-0' colSpan={2}>
                  Data zamówienia
                </TableCell>
                <TableCell className='px-0' colSpan={2}>
                  L.dni
                </TableCell>
                <TableCell className='px-0' colSpan={2}>
                  Koniec
                </TableCell>
                <TableCell className='px-0' colSpan={2}>
                  Produkty
                </TableCell>
                <TableCell className='px-0' colSpan={1}>
                  Cena
                </TableCell>
                <TableCell className='px-0' colSpan={1}>
                  Płatność
                </TableCell>
                <TableCell className='px-0' colSpan={1}>
                  Aktywne
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    className='px-24 capitalize'
                    colSpan={4}
                    align='left'
                  >
                    <Link to={`/orders/${item._id}`}>{item.no}</Link>
                  </TableCell>
                  <TableCell colSpan={4}>
                    {item.idWooCommerce ? item.idWooCommerce : ''}
                  </TableCell>
                  <TableCell
                    className='px-0 capitalize'
                    align='left'
                    colSpan={2}
                  >
                    {myDate(item.createdAt, 't')}
                  </TableCell>
                  <TableCell colSpan={2}>
                    {item.orderItems.map((i) => i.nrDays).join(' | ')}
                  </TableCell>
                  <TableCell colSpan={2}>
                    {format(
                      max(item.orderItems.map((i) => new Date(i.endAt))),
                      'dd.MM.yy'
                    )}
                  </TableCell>
                  <TableCell className='px-0' align='left' colSpan={2}>
                    {item.orderItems.map(
                      (p, i) =>
                        `${p.name} ${p.calories} ${
                          item.orderItems.length - 1 === i ? '' : '|'
                        }`
                    )}
                  </TableCell>
                  <TableCell className='px-0' colSpan={1}>
                    {item.totalPrice}
                  </TableCell>
                  <TableCell className='px-0' colSpan={1}>
                    {item.paymentMethod}
                  </TableCell>
                  <TableCell className='px-0' colSpan={1}>
                    {item.isActive && (
                      <FiberManualRecordIcon
                        fontSize='small'
                        style={{ color: green[500] }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Card>
  );
};

export default UserOrders;
