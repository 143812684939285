import api from '../utils/api';
import { setAlert } from './alert';
import produce from 'immer';

import {
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_FAIL,
  GET_ORDERS_BY_USER_REQUEST,
  GET_ORDERS_BY_USER_SUCCESS,
  GET_ORDERS_BY_USER_FAIL,
  ORDER_ERROR,
  // GET_ORDERS,
  CLEAR_ORDER,
  GET_ORDER,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_START_REQUEST,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_MEAL_UPDATE_REQUEST,
  ORDER_MEAL_UPDATE_SUCCESS,
  ORDER_MEAL_UPDATE_FAIL,
  ORDER_DAY_UPDATE_REQUEST,
  ORDER_DAY_UPDATE_SUCCESS,
  ORDER_DAY_UPDATE_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
} from '../constants/orderConstants';

// Get user by ID
export const getOrdersByUserId = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDERS_BY_USER_REQUEST });
    const res = await api.get(`/orders?user=${userId}`);
    dispatch({
      type: GET_ORDERS_BY_USER_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: GET_ORDERS_BY_USER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//aktualnie nie wykorzystywane
// // Get all orders
// export const getOrders = () => async (dispatch) => {
//   dispatch({ type: CLEAR_ORDER });
//   try {
//     dispatch({ type: GET_ORDER_LIST_REQUEST });
//     const res = await api.get('/orders?limit=10000');
//     dispatch({
//       type: GET_ORDER_LIST_SUCCESS,
//       payload: res.data.data,
//     });
//   } catch (err) {
//     dispatch({
//       type: GET_ORDER_LIST_FAIL,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };

// Get order by ID
export const getOrderById = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_START_REQUEST });
    const res = await api.get(`/orders/${orderId}`);
    dispatch({
      type: GET_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert(err.response.statusText, 'error'));
    dispatch({
      type: ORDER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Create order
export const createOrder = (newOrder) => async (dispatch, getState) => {
  //we dont need currentCartItem
  const {
    userDetails: { user },
    cart: { currentCartItem: undefined, ...cart },
  } = getState();

  try {
    dispatch({ type: ORDER_CREATE_REQUEST });
    const { data } = await api.post(`users/${user._id}/orders/`, cart);
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.data });
    dispatch(setAlert('Zamówienie zapisane', 'success'));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_CREATE_FAIL, payload: message });
    dispatch(setAlert(message, 'error'));
  }
};

//Update order
export const updateOrder = (updatedOrder) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_UPDATE_REQUEST });
    const { data } = await api.put(`/orders/${updatedOrder._id}`, updatedOrder);
    dispatch({ type: ORDER_UPDATE_SUCCESS, payload: data.data });
    dispatch(setAlert('Zamówienie zmienione', 'success'));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_UPDATE_FAIL });
    dispatch(setAlert(message, 'error'));
  }
};

export const updateDay =
  (orderId, orderItemId, dayId, newDay) => async (dispatch) => {
    try {
      dispatch({ type: ORDER_DAY_UPDATE_REQUEST });
      const { data } = await api.put(
        `/orders/${orderId}/order-items/${orderItemId}/delivery-days/${dayId}`,
        { newDay }
      );
      dispatch({ type: ORDER_DAY_UPDATE_SUCCESS, payload: data.data });
      dispatch(setAlert('Posiłki zmienione', 'success'));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_DAY_UPDATE_FAIL });
      dispatch(setAlert(message, 'error'));
    }
  };
// export const updateDay = (productId, updatedDay) => (dispatch, getState) => {
//   const {
//     order: { order },
//   } = getState();

//   const updatedOrder = produce(order, (draftOrder) => {
//     const orderIdx = order.orderItems.findIndex((p) => p._id === productId);
//     const dayIdx = order.orderItems[orderIdx].deliveryDays.findIndex(
//       (d) => d._id === updatedDay._id
//     );
//     draftOrder.orderItems[orderIdx].deliveryDays[dayIdx] = {
//       ...updatedDay,
//     };
//   });

//   dispatch(updateOrder(updatedOrder));
// };

export const updateDayMeals =
  (orderId, orderItemId, dayId, newMealsDelivered, isMealException) =>
  async (dispatch) => {
    try {
      dispatch({ type: ORDER_MEAL_UPDATE_REQUEST });
      const { data } = await api.put(
        `/orders/${orderId}/order-items/${orderItemId}/delivery-days/${dayId}/meals`,
        { newMealsDelivered, isMealException }
      );
      dispatch({ type: ORDER_MEAL_UPDATE_SUCCESS, payload: data.data });
      dispatch(setAlert('Posiłki zmienione', 'success'));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_MEAL_UPDATE_FAIL });
      dispatch(setAlert(message, 'error'));
    }
  };

export const updatePayment =
  (orderId, newPayment) => async (dispatch, getState) => {
    const {
      order: { order },
    } = getState();

    try {
      dispatch({ type: ORDER_PAY_REQUEST });
      const { data } = await api.post(`/orders/${orderId}/pay`, {
        ...newPayment,
      });
      dispatch({ type: ORDER_PAY_SUCCESS, payload: data.data });
      dispatch(setAlert('Płatność zmieniona', 'success'));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ORDER_PAY_FAIL });
      dispatch(setAlert(message, 'error'));
    }

    // const newOrder = {
    //   ...order,
    //   ...newPayment,
    // };
    // dispatch(updateOrder(newOrder));
  };
// export const updatePayment = (newPayment) => (dispatch, getState) => {
//   const {
//     order: { order },
//   } = getState();

//   const newOrder = {
//     ...order,
//     ...newPayment,
//   };
//   dispatch(updateOrder(newOrder));
// };
