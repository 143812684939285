import produce from 'immer';

import {
  GET_RECIPE_DETAILS_REQUEST,
  GET_RECIPE_DETAILS_SUCCESS,
  GET_RECIPE_DETAILS_FAIL,
  RECIPE_DETAILS_SAVE_REQUEST,
  RECIPE_DETAILS_SAVE_SUCCESS,
  RECIPE_DETAILS_SAVE_FAIL,
  RECIPE_CREATE_REQUEST,
  RECIPE_CREATE_SUCCESS,
  RECIPE_CREATE_FAIL,
  RECIPE_CLONE_REQUEST,
  RECIPE_CLONE_SUCCESS,
  RECIPE_CLONE_FAIL,
  RECIPE_DETAILS_UPDATE_INGREDIENT,
} from '../constants/recipeConstants';

export const recipeDetailsReducer = (
  state = { recipe: {}, loading: false, error: null },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECIPE_DETAILS_REQUEST:
    case RECIPE_CREATE_REQUEST:
    case RECIPE_DETAILS_SAVE_REQUEST:
    case RECIPE_CLONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECIPE_DETAILS_SUCCESS:
    case RECIPE_CREATE_SUCCESS:
    case RECIPE_DETAILS_SAVE_SUCCESS:
    case RECIPE_CLONE_SUCCESS:
      return {
        ...state,
        recipe: payload,
        loading: false,
        error: null,
      };
    case GET_RECIPE_DETAILS_FAIL:
    case RECIPE_CREATE_FAIL:
    case RECIPE_DETAILS_SAVE_FAIL:
    case RECIPE_CLONE_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    // case RECIPE_DETAILS_UPDATE_INGREDIENT:
    //   return produce(state, (draftState) => {
    //     console.log(payload);
    //     draftState.recipe.ingredients = [...payload];
    //     // const { index, updatedIngredient } = payload;
    //     // const { ingredients } = state.recipe;
    //     // console.log(updatedIngredient);
    //     // console.log(state.recipe.ingredients[index]);
    //     // draftState.recipe.ingredients[index].ingredient =
    //     //   updatedIngredient.ingredient;
    //     // draftState.recipe.ingredients[index].weight = updatedIngredient.weight;
    //     // draftState.recipe.ingredients[index].tableData.editing = undefined;
    //     // // draftState.recipe.ingredients[index] = updatedIngredient;
    //   });

    // case RECIPE_DETAILS_CLEAR_ERROR:
    //   return {
    //     ...state,
    //     error: null,
    //   };

    //   case CLEAR_RECIPE:
    //     return {
    //       // ...state,
    //       recipe: null,
    //     };

    default:
      return state;
  }
};
