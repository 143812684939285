import {
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAIL,
} from '../constants/categoryConstants';

export const categoryReducer = (state = { categories: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categories: payload,
        loading: false,
        error: null,
      };
    case GET_CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
