import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import WarningIcon from '@mui/icons-material/WarningRounded';
import { yellow } from '@mui/material/colors';
import { DayWithMeals } from '../../../../FitAPI/src/utils/order/getOrderMealsDetails';
import { ProductType } from '../../../../FitAPI/src/models/ProductModel';
import { DietMenuSchemaPopulated } from '../../../../FitAPI/src/models/MenuModel';
import { useAppDispatch } from '../../hooks';
import MealRow from './MealRow';
import { UseMutationResult } from '@tanstack/react-query';
import { UpdateMealProps } from '../../utils/api/orderMealApi';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  subHeaderRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

interface DayStatusProps {
  status: string;
}
const DayStatus = ({ status }: DayStatusProps) => {
  let backgroundColor;
  let textColor = 'white';

  switch (status) {
    case 'Planowany':
      backgroundColor = 'green';
      break;
    case 'Dostarczony':
      backgroundColor = 'grey';
      break;
    case 'Zawieszony':
      backgroundColor = 'orange';
      textColor = 'black';
      break;
    case 'Płatność':
      backgroundColor = 'yellow';
      textColor = 'black';
      break;
    case 'Anulowany':
      backgroundColor = 'red';
      break;
    default:
      backgroundColor = 'blue';
      break;
  }

  const useDayStatusStyles = makeStyles({
    box: {
      backgroundColor: backgroundColor,
      color: textColor,
      textAlign: 'center',
      // padding: '2,6,6,4',
      minWidth: 100,
      borderRadius: 12,
    },
  });
  const classes = useDayStatusStyles();

  return (
    <div className={classes.box}>
      <Typography variant='overline'>{status}</Typography>
    </div>
  );
};

interface Props {
  row: DayWithMeals;
  products: ProductType[];
  dietMenus: DietMenuSchemaPopulated[];
  mealMutation: UseMutationResult<void, unknown, UpdateMealProps, unknown>;
  isDayRefetching: boolean;
}

export default function Row(props: Props) {
  const { row, products, dietMenus, mealMutation } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          <Link to={`/orders/${row._id}`}> {row.no} </Link>
        </TableCell>
        <TableCell align='left'>{row.fName}</TableCell>
        <TableCell align='left'>{row.sName}</TableCell>
        <TableCell align='left'>{`${row.diet}-${row.calories}`}</TableCell>
        <TableCell align='left'>{row.otherFoodExclusions}</TableCell>
        <TableCell align='left'>
          <DayStatus status={row.status} />
        </TableCell>
        {/* <TableCell align='left'>{row.status}</TableCell> */}
        {/* <TableCell align='left'>
          <PaymentStatus status={row.isPaid} />
        </TableCell> */}
        <TableCell align='left'>
          {row.isMealException ? (
            <WarningIcon style={{ color: yellow[900] }} color='secondary' />
          ) : (
            ''
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <div className={classes.subHeaderRow}>
                <Typography variant='h6' gutterBottom component='div'>
                  Posiłki
                </Typography>
              </div>
              <Table size='small' aria-label='meals'>
                <TableHead>
                  <TableRow>
                    <TableCell>Posiłek</TableCell>
                    <TableCell>Dieta</TableCell>
                    {/* <TableCell align='left'>Nr</TableCell> */}
                    <TableCell align='left'>Nazwa dania</TableCell>
                    <TableCell align='left'>Rozmiar</TableCell>
                    <TableCell align='left'>kcal</TableCell>
                    <TableCell align='left'>B.</TableCell>
                    <TableCell align='left'>W.</TableCell>
                    <TableCell align='left'>T.</TableCell>
                    <TableCell align='left'></TableCell>
                    <TableCell align='left'></TableCell>
                  </TableRow>
                </TableHead>
                <>
                  {row.meals.map((meal) => (
                    <MealRow
                      key={meal?._id}
                      meal={meal}
                      products={products}
                      dietMenus={dietMenus}
                      orderId={row.orderId}
                      orderItemId={row.orderItemId}
                      dayId={row.dayId}
                      mealMutation={mealMutation}
                      isDayRefetching={props.isDayRefetching}
                    />
                  ))}
                </>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
