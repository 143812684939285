import { Grid, Typography } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import calculateDeviation from '../calculateDeviation';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  errorNone: {
    padding: theme.spacing(1),
  },
  errorSmall: {
    padding: theme.spacing(1),
    background: theme.palette.warning.main,
    // color: 'black',
  },
  errorLarge: {
    padding: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: 'white',
  },
}));

const TrayNutritionSummary = ({ nutritions }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Typography variant='caption'>
            <strong>Kalorie</strong>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant='caption' className={classes.errorNone}>
            <strong>Kc.</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='caption'>
            <strong>B.[g]</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='caption'>
            <strong>W.[g]</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='caption'>
            <strong>T.[g]</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='caption'>
            <strong>Bł.[g]</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='caption'>
            <strong>S.[mg]</strong>
          </Typography>
        </Grid>
      </Grid>
      {/* Nutr. for a full diet grouped by calories */}
      {nutritions.map((nutr) => (
        <Grid container spacing={2} key={nutr._id}>
          <Grid item xs={1}>
            <Typography variant='caption'>{nutr.calories}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              variant='caption'
              className={clsx(
                calculateDeviation(nutr.kcal, nutr.calories) === 'small' &&
                  classes.errorSmall,
                calculateDeviation(nutr.kcal, nutr.calories) === 'large' &&
                  classes.errorLarge,
                calculateDeviation(nutr.kcal, nutr.calories) === 'none' &&
                  classes.errorNone
              )}
            >
              {nutr.kcal.toFixed(0)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'>
              {`${nutr.protein.toFixed(1)}
                | ${(((nutr.protein * 4) / nutr.kcal) * 100).toFixed(0)}%`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'>
              {`${nutr.carbs?.toFixed(1)}
                | ${(((nutr.carbs * 4) / nutr.kcal) * 100).toFixed(0)}%`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'>
              {`${nutr.fat.toFixed(1)}
                | ${(((nutr.fat * 9) / nutr.kcal) * 100).toFixed(0)}%`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'>
              {`${nutr.carbFiber?.toFixed(1) || ''}
                | ${(((nutr?.carbFiber || 0 * 2) / nutr.kcal) * 100).toFixed(
                  0
                )}%`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='caption'>
              {nutr.sodium?.toFixed(1) || ''}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default TrayNutritionSummary;
