import { Button, Typography } from '@mui/material';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  MenuType,
  MenuTypePopulated,
} from '../../../FitAPI/src/models/MenuModel';
import Layout from '../components/layout/Layout';
import Loader from '../components/layout/Loader';
import {
  addMealToMenu,
  deleteMeal,
  getMenu,
  updateMeal,
  updateMenuProductionAt,
} from '../utils/api/menu.meal.api';
import format from 'date-fns/format';
import { pl } from 'date-fns/locale';
import SingleDietModule from '../components/menu/SingleDietModule';
import { Meal } from '../../../FitAPI/src/models/MealModel';
import { getAllMeals } from '../utils/api/mealApi';
import ProductionDate from '../components/menu/ProductionDate';
import { WithId } from '../types';
import CopyDay from '../components/menu/CopyDay';
import MenuSummary from '../components/menu/MenuSummary';
import { updateMenu } from '../utils/api/menu.api';
import { useSnackbar } from 'notistack';

function MenuScreen() {
  const { menuId } = useParams();
  const [isCopyDayOpen, setIsCopyDayOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  if (!menuId) throw new Error('No menu Id');

  // get menu
  const { data: menu, isLoading } = useQuery<MenuTypePopulated>(
    ['getMenu', menuId],
    () => getMenu(menuId)
  );

  //get all meal names to use in autocomplete field
  const { data: allMealNames } = useQuery<WithId<Meal>[]>({
    queryKey: ['getAllMeals'],
    queryFn: getAllMeals,
  });

  const afterUpdate = (data: MenuType): void => {
    queryClient.setQueryData(['getMenu', menuId], data);
    enqueueSnackbar('Zapisano', { variant: 'success' });
  };

  // mutation to update menu overview
  const updateMenuMutation = useMutation(updateMenu, {
    onSuccess: afterUpdate,
  });

  //mutation to update meal
  const updateMealMutation = useMutation(updateMeal, {
    onSuccess: afterUpdate,
  });

  const handleUpdateMenu = (menu: Partial<MenuType>) => {
    updateMenuMutation.mutate({ _id: menuId, ...menu });
  };

  // mutatation to delete dietmenu
  const deleteMealMutation = useMutation(deleteMeal, {
    onSuccess: afterUpdate,
  });

  const addMealMutation = useMutation(addMealToMenu, {
    onSuccess: afterUpdate,
  });

  const productionAtMutation = useMutation(updateMenuProductionAt, {
    onSuccess: afterUpdate,
  });

  const handleProductionAtChange = (date: Date | null) => {
    if (date) {
      productionAtMutation.mutate({
        menuId,
        productionAt: format(date, 'yyyy-MM-dd'),
      });
    }
  };

  const handleCloseCopyDay = () => {
    setIsCopyDayOpen(false);
  };
  const handleOpenCopyDay = () => {
    setIsCopyDayOpen(true);
  };

  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : menu ? (
        <div>
          <div>
            <Typography variant='h5'>
              Menu na dzień{' '}
              {format(new Date(menu?.menuAt), 'dd.MM.yy (eeee)', {
                locale: pl,
              })}
            </Typography>
            <ProductionDate
              productionAt={menu?.productionAt}
              handleDateChange={handleProductionAtChange}
              isUpdating={productionAtMutation.isLoading}
            />
            <MenuSummary
              season={menu.season}
              weekNr={menu.weekNr}
              version={menu.version}
              dayOfWeek={menu.dayOfWeek}
              handleUpdate={handleUpdateMenu}
              isLoading={updateMenuMutation.isLoading}
            />
            <Button onClick={handleOpenCopyDay}>Kopiuj dzień</Button>
            <CopyDay
              handleClose={handleCloseCopyDay}
              isOpen={isCopyDayOpen}
              menuAt={menu.menuAt}
              currentMenuId={menu._id}
            />
            {menu.dietMenus.map((dietMenu) => (
              <SingleDietModule
                key={dietMenu._id}
                menuId={menu._id}
                dietMenu={dietMenu}
                deleteMealMutation={deleteMealMutation}
                addMealMutation={addMealMutation}
                allMealNames={allMealNames}
                updateMealMutation={updateMealMutation}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </Layout>
  );
}

export default MenuScreen;
