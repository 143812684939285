import { Controller, useForm } from 'react-hook-form';
import {
  IconButton,
  MenuItem,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Meal } from '../../../../FitAPI/src/models/MealModel';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  MealOverview,
  mealOverviewSchema,
  mealTypeEnum,
  servingStyleEnum,
} from '../../types/mealType';
import SaveIcon from '@mui/icons-material/Save';
import { WithId } from '../../types';

type Props = {
  meal: WithId<Meal>;
  // meal: MealOverViewWithId;
  handleUpdate: (mealId: string, meal: Partial<Meal>) => void;
  // handleUpdate: (mealId: string, meal: MealOverview) => void;
  isLoading: boolean;
};

const Overview = (props: Props) => {
  const { meal } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MealOverview>({
    resolver: zodResolver(mealOverviewSchema),
  });

  const onSubmit = (data: MealOverview) => {
    props.handleUpdate(meal._id, data);
  };

  if (props.isLoading)
    return <Skeleton variant='rectangular' width={'100%'} height={500} />;
  return (
    <Paper sx={{ p: 3 }}>
      <Box
        // component='form'
        sx={{
          '& .MuiTextField-root': {
            m: 1,
          },
        }}
      >
        <div style={{ width: '100%' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='name'
              control={control}
              defaultValue={meal.name}
              render={({ field }) => (
                <TextField
                  required
                  label='Nazwa'
                  variant='standard'
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  inputRef={field.ref}
                  fullWidth
                  multiline
                  {...field}
                />
              )}
            />
            <Controller
              name='nameLabel'
              control={control}
              defaultValue={meal.nameLabel}
              render={({ field }) => (
                <TextField
                  label='Etykieta'
                  variant='standard'
                  error={Boolean(errors.name)}
                  helperText={errors.nameLabel?.message}
                  inputRef={field.ref}
                  fullWidth
                  multiline
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name='nameWWW'
              control={control}
              defaultValue={meal.nameWWW}
              render={({ field }) => (
                <TextField
                  required
                  label='WWW'
                  variant='standard'
                  error={Boolean(errors.name)}
                  helperText={errors.nameWWW?.message}
                  inputRef={field.ref}
                  fullWidth
                  multiline
                  {...field}
                />
              )}
            />

            <Controller
              name='servingStyle'
              control={control}
              defaultValue={meal.servingStyle}
              render={({ field }) => (
                <TextField
                  required
                  select
                  label='Sposób podania'
                  variant='standard'
                  error={Boolean(errors.name)}
                  helperText={errors.servingStyle?.message}
                  inputRef={field.ref}
                  fullWidth
                  multiline
                  {...field}
                >
                  {servingStyleEnum.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name='mealType'
              control={control}
              defaultValue={meal.mealType}
              render={({ field }) => (
                <TextField
                  required
                  select
                  label='Typ posiłku'
                  variant='standard'
                  error={Boolean(errors.name)}
                  helperText={errors.mealType?.message}
                  inputRef={field.ref}
                  fullWidth
                  multiline
                  {...field}
                >
                  {mealTypeEnum.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name='comments'
              control={control}
              defaultValue={meal.comments}
              render={({ field }) => (
                <TextField
                  label='Opis'
                  variant='standard'
                  error={Boolean(errors.comments)}
                  helperText={errors.comments?.message}
                  inputRef={field.ref}
                  fullWidth
                  multiline
                  {...field}
                />
              )}
            />
            {/* Add icon button to submit */}
            {/* <input type='submit' value='submit' /> */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label='save' type='submit'>
                <SaveIcon />
              </IconButton>
            </Box>
          </form>
        </div>
      </Box>
    </Paper>
  );
};

export default Overview;
