import React from 'react';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import MyRow from '../../../helpers/MyRow';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Address as AddressType } from '../../../../../FitAPI/src/models/AddressSchema';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  address: AddressType;
}

const Address = ({ isVisible, handleClose, address }: Props) => {
  console.log('address', address);
  const {
    street,
    buildingNr,
    aptNr,
    voivodeship,
    city,
    zipCode,
    driver,
    zone,
    deliveryPrice,
    comments,
    fromTime,
    toTime,
    // coordinates,
    // formattedAddress,
  } = address;
  // const MyMap = () => {
  //   // const { google } = mapProps;
  //   const containerStyle = {
  //     width: '100%',
  //     height: 400,
  //     position: 'relative',
  //   };
  //   // Adres coordinates
  //   const loc = { lat: coordinates[1], lng: coordinates[0] };
  //   const initMap = (mapProps, map) => {
  //     // const src =
  //     //   'https://www.google.com/maps/d/u/1/kml?mid=1gHSeZJSuXWXzyQWE0bXWnQF0gjrjvH6L';
  //   };

  //   return (
  //     <Map
  //       google={google}
  //       zoom={15}
  //       containerStyle={containerStyle}
  //       onReady={(mapProps, map) => initMap(mapProps, map)}
  //       initialCenter={loc}
  //     >
  //       <Marker title={formattedAddress} name='Adres dostawy' position={loc} />
  //     </Map>
  //   );
  // };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      // fullWidth
      // maxWidth='md'
    >
      <DialogTitle> Adres</DialogTitle>

      <DialogContent>
        {/* <Grid container>
          <Grid item xs={12}> */}
        <MyRow label='Województwo' data={voivodeship} />
        <MyRow label='Miejscowość' data={city} />
        <MyRow label='Ulica' data={street} />
        <MyRow label='Nr domu' data={buildingNr} />
        <MyRow label='Nr mieszkania' data={aptNr} />
        <MyRow label='Kod pocztowy' data={zipCode} />
        <MyRow label='Kierowca' data={driver} />
        <MyRow label='Strefa' data={zone} />
        <MyRow label='Koszt dostawy' data={deliveryPrice} />
        <MyRow label='Uwagi' data={comments} />
        {fromTime && <MyRow label='Godz.od' data={fromTime} />}
        {toTime && <MyRow label='Godz.do' data={toTime} />}
        {/* </Grid> */}
        {/* <Grid item xs={6}>
            {coordinates && <MyMap />}
          </Grid> */}
        {/* </Grid> */}

        <DialogActions>
          <Button color='primary' onClick={handleClose}>
            Zamknij
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export default Address;
// export default GoogleApiWrapper({
//   apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
//   language: 'pl',
//   componentRestrictions: { country: 'pl' },
// })(Address);
