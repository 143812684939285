import React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { randomId } from '@mui/x-data-grid-generator';
import { GridRowModes, GridRowsProp } from '@mui/x-data-grid';
import { GridRowModesModel } from '@mui/x-data-grid';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const _id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        _id,
        ingredient: { name: undefined },
        sizes: [
          { size: 'S', weight: undefined },
          { size: 'M', weight: undefined },
          { size: 'L', weight: undefined },
          { size: 'XL', weight: undefined },
        ],
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
        Dodaj składnik
      </Button>
    </GridToolbarContainer>
  );
}

export default EditToolbar;
