import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import Layout from '../components/layout/Layout';
import { getRecipeById, updateRecipe } from '../actions/recipeActions';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';
import InfoBox from '../components/layout/InfoBox';
import AutoCompleteField from '../components/layout/AutoCompleteField';
import {
  Container,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
} from '@mui/material';
import {
  calculateNutrition100,
  calculateNutritionPortion,
} from '../utils/calculateNutrition';
import Portion from '../components/recipe/Portion';
import NutrtionBox from '../components/recipe/NutrtionBox';
import Link from '@mui/material/Link';
import { Link as RouterLink, useParams } from 'react-router-dom';

// const RecipeScreen = ({ match }) => {
const RecipeScreen = () => {
  const dispatch = useDispatch();
  const recipeState = useSelector((state) => state.recipeDetails);
  const { recipeId } = useParams();
  // const { recipeId } = match.params;
  const { recipe, loading, error } = recipeState;
  const [updatedRecipe, setUpdatedRecipe] = useState();
  const [isRecipeChanged, setIsRecipeChanged] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  let newIngr;

  useEffect(() => {
    dispatch(getRecipeById(recipeId));
  }, [dispatch, recipeId]);

  useEffect(() => {
    const recipeCopy = cloneDeep(recipe);
    setUpdatedRecipe(recipeCopy);
  }, [recipe]);

  useEffect(() => {
    // if (updateRecipe && needsUpdate) {
    if (updatedRecipe && needsUpdate) {
      const sumIngr = updatedRecipe.ingredients.reduce(
        (acc, x) => acc + parseFloat(x.weight),
        0
      );
      const loss = parseFloat(updatedRecipe.weight) / parseFloat(sumIngr);

      const nutrition100 = calculateNutrition100(
        updatedRecipe.ingredients,
        updatedRecipe.weight
      );

      const newPortionWeightRaw =
        updatedRecipe.portionWeightPrepared / updatedRecipe.loss;

      const nutritionPortion = calculateNutritionPortion(
        updatedRecipe.portionWeightPrepared,
        // newPortionWeightRaw,
        // updatedRecipe.portionWeightRaw,
        nutrition100
      );

      const newRecipe = {
        ...updatedRecipe,
        nutrition100,
        nutritionPortion,
        sumIngr: sumIngr,
        loss: loss,
        portionWeightRaw: newPortionWeightRaw,
        // portionWeightRaw:
        //   updatedRecipe.portionWeightPrepared / updatedRecipe.loss,
        // portionWeightPrepared: updatedRecipe.portionWeightRaw * loss,
      };
      setUpdatedRecipe(newRecipe);
      setNeedsUpdate(false);
    }
  }, [needsUpdate, updatedRecipe]);

  const structure = [
    {
      title: 'Nazwa',
      field: 'ingredient.name',
      editable: 'always',
      editComponent: (props) => {
        const dataSource = 'ingredients';
        return (
          <div style={{ width: 350 }}>
            <AutoCompleteField
              currentValue={props.rowData.ingredient}
              onAutoCompleteChange={(e, value) => {
                if (!value) return null;
                newIngr = value;
                props.onChange(value._id);
              }}
              url={`/${dataSource}?select=name&pageSize=10000`}
              getOptionLabel={(option) => `${option.name} - ${option.kcal}kcal`}
              dataSource={dataSource}
            />
          </div>
        );
      },
      validate: (rowData) => rowData.ingredient?.name.length > 3,
      render: (rowData) => (
        <RouterLink
          to={`/ingredients?q=${rowData.ingredient.name}`}
          target='_blank'
        >
          {rowData.ingredient.name}
        </RouterLink>
      ),
    },
    {
      title: 'Waga [g]',
      field: 'weight',
      editable: 'always',
      type: 'numeric',
      validate: (rowData) => rowData.weight > 0,
    },
    {
      title: 'Waga na 100g [g]',
      field: 'weight100',
      render: (rowData) => parseFloat(rowData.weight100).toFixed(0),
      editable: 'never',
      type: 'numeric',
    },
    {
      title: 'kcal',
      field: 'ingredient.kcal',
      type: 'numeric',
      render: (rowData) => parseFloat(rowData.ingredient.kcal).toFixed(0),
      editable: 'never',
    },
    {
      title: 'Białko',
      field: 'ingredient.protein',
      render: (rowData) => parseFloat(rowData.ingredient.protein).toFixed(1),
      editable: 'never',
    },
    {
      title: 'Węgle',
      field: 'ingredient.carbs',
      render: (rowData) => parseFloat(rowData.ingredient.carbs).toFixed(1),
      editable: 'never',
    },
    {
      title: 'Tłuszcze',
      field: 'ingredient.fat',
      render: (rowData) => parseFloat(rowData.ingredient.fat).toFixed(1),
      editable: 'never',
    },
    {
      title: 'Błonnik',
      field: 'ingredient.carbFiber',
      render: (rowData) => parseFloat(rowData.ingredient.carbFiber).toFixed(1),
      editable: 'never',
    },
    {
      title: 'Sód',
      field: 'ingredient.sodium',
      render: (rowData) => parseFloat(rowData.ingredient.sodium).toFixed(1),
      editable: 'never',
    },
  ];

  const handleIsPortionToggle = (isPortion) => {
    const newRecipe = {
      ...updatedRecipe,
      isPortion: !isPortion,
    };
    setUpdatedRecipe(newRecipe);
    setIsRecipeChanged(true);
  };

  const handlePortionSize = (e) => {
    const portionSize = parseInt(e.target.value);
    if (!Number.isInteger(portionSize)) return;
    const newRecipe = {
      ...updatedRecipe,
      portionWeightPrepared: portionSize,
    };
    setUpdatedRecipe(newRecipe);
    setIsRecipeChanged(true);
    setNeedsUpdate(true);
  };
  const saveHandler = () => {
    dispatch(updateRecipe(updatedRecipe));
    setIsRecipeChanged(false);
  };

  const cancelHandler = () => {
    setUpdatedRecipe(recipe);
    setIsRecipeChanged(false);
  };

  return (
    <>
      <Helmet>
        <title>{`R | ${updatedRecipe?.name || ''}`}</title>
      </Helmet>
      <Layout>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message message={error.msg} />
        ) : (
          updatedRecipe && (
            <Container id='Recipe'>
              <Breadcrumbs>
                <Link
                  color='inherit'
                  to='/recipes'
                  component={RouterLink}
                  underline='hover'
                >
                  Receptury
                </Link>
                <Typography color='textPrimary'>
                  {updatedRecipe.name}
                </Typography>
              </Breadcrumbs>

              <Typography
                style={{ marginBottom: 12, marginTop: 12 }}
                variant='h5'
                align='center'
              >
                {updatedRecipe.name}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InfoBox
                    title='Receptura'
                    structure={[
                      {
                        name: 'Nazwa',
                        key: 'name',
                        value: `${updatedRecipe.name}`,
                        isEditField: true,
                        validate: (value) => value.length < 5,
                      },
                      {
                        name: 'Suma składników [g]',
                        // key: sumIngr,
                        value: `${updatedRecipe.sumIngr}`,
                      },
                      {
                        name: 'Waga dania [g]',
                        key: 'weight',
                        value: `${updatedRecipe.weight}`,
                        isEditField: true,
                        validate: (value) => value <= 0,
                        type: 'number',
                      },

                      {
                        name: 'Porcja (gotowa) [g]',
                        value: updateRecipe.portionWeightPrepared,
                        // validate: (value) => value <= 0,
                        // isEditField: true,
                      },

                      {
                        name: 'WOT [%]',
                        value: `${updatedRecipe.loss}`,
                        type: 'percent',
                      },

                      // {
                      //   name: 'Wykorzystane',
                      //   value: updatedRecipe.usedAt,
                      //   type: 'date',
                      // },
                      {
                        name: 'Opis',
                        key: 'comments',
                        value: updatedRecipe.comments,
                        isEditField: true,
                        type: 'textArea',
                      },
                    ]}
                    data={updatedRecipe}
                    onUpdate={(newData) => {
                      setUpdatedRecipe({ ...newData });
                      setIsRecipeChanged(true);
                      setNeedsUpdate(true);
                      // dispatch(updatRecipe(newData));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NutrtionBox
                    title='Wartości odż. na 100g'
                    nutrition={{
                      kcal: updatedRecipe.nutrition100?.kcal,
                      protein: updatedRecipe.nutrition100?.protein,
                      carbs: updatedRecipe.nutrition100?.carbs,
                      fat: updatedRecipe.nutrition100?.fat,
                      sodium: updatedRecipe.nutrition100?.sodium,
                      carbFiber: updatedRecipe.nutrition100?.carbFiber,
                    }}
                  />
                </Grid>

                {/* to avoid switch to be initialized in uncontrolled state */}
                <Grid item xs={12}>
                  {typeof updatedRecipe.isPortion != 'undefined' && (
                    <Portion
                      isPortion={updatedRecipe.isPortion}
                      handleToggle={handleIsPortionToggle}
                      portionWeightPrepared={
                        updatedRecipe.portionWeightPrepared
                      }
                      handlePortionSize={handlePortionSize}
                      portionWeightRaw={updatedRecipe.portionWeightRaw}
                      nutrition={updatedRecipe.nutritionPortion}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <MaterialTable
                    title='Składniki'
                    data={updatedRecipe.ingredients}
                    columns={structure}
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [5, 10, 15],
                      sorting: true,
                      search: false,
                      doubleHorizontalScroll: true,
                      paging: false,
                      maxBodyHeight: 600,
                    }}
                    editable={{
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          const newIngredient = {
                            ingredient: { ...newIngr },
                            weight: newData.weight,
                          };
                          const newReceipe = { ...updatedRecipe };
                          newReceipe.ingredients.push({ ...newIngredient });
                          setUpdatedRecipe(newReceipe);
                          setNeedsUpdate(true);
                          setIsRecipeChanged(true);
                          resolve();
                        }),
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          const index = oldData.tableData.id;
                          const ingrUpdate = [...updatedRecipe.ingredients];
                          ingrUpdate[index] = {
                            ...newData,
                          };
                          if (newIngr)
                            ingrUpdate[index].ingredient = { ...newIngr };
                          let newRecipe = {
                            ...updatedRecipe,
                            ingredients: ingrUpdate,
                          };
                          setUpdatedRecipe(newRecipe);
                          setNeedsUpdate(true);
                          setIsRecipeChanged(true);
                          resolve();
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          const newReceipe = { ...updatedRecipe };
                          const index = oldData.tableData.id;
                          newReceipe.ingredients.splice(index, 1);
                          setUpdatedRecipe(newReceipe);
                          setIsRecipeChanged(true);
                          setNeedsUpdate(true);
                          resolve();
                        }),
                    }}
                  />
                </Grid>
              </Grid>
              {isRecipeChanged && (
                <div style={{ marginTop: 12, float: 'right' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={saveHandler}
                  >
                    Zapisz
                  </Button>
                  <Button
                    style={{ marginLeft: 12 }}
                    variant='contained'
                    color='secondary'
                    onClick={cancelHandler}
                  >
                    Odrzuć
                  </Button>
                </div>
              )}
            </Container>
          )
        )}
      </Layout>
    </>
  );
};

export default RecipeScreen;
