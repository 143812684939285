import React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ExtensionIcon from '@mui/icons-material/Extension';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
  faCarrot,
  faHamburger,
  faUtensils,
  faCogs,
  faCartShopping,
} from '@fortawesome/free-solid-svg-icons';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Home } from '@mui/icons-material';

interface Props {
  user: any;
}

function NavLinks({ user }: Props) {
  return (
    <div>
      <List>
        {user.role === 'admin' && (
          <ListItemLink icon={<Home />} primary='Główna' to='/home' />
        )}
        <ListItemLink icon={<PeopleIcon />} primary='Klienci' to='/users' />
        <ListItemLink
          icon={<ShoppingCartIcon />}
          primary='Zamówienia'
          to='/orders'
        />
        <ListItemLink
          icon={<ExtensionIcon />}
          primary='Dopasowania'
          to='/meal-selection'
        />
        <ListItemLink
          icon={<WarningIcon />}
          primary='Kończący'
          to='/users-orders-ending'
        />
        <ListItemLink
          icon={<NotificationImportantIcon />}
          primary='Kontakty'
          to='/users-contacts'
        />
      </List>

      <List>
        <Divider />
        <ListItemLink
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: 3 }}
              icon={faCarrot as IconProp}
              size='lg'
            />
          }
          primary='Składniki'
          to='/ingredients'
        />
        <ListItemLink
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: 3 }}
              icon={faHamburger as IconProp}
              size='lg'
            />
          }
          primary='Receptury'
          to='/recipes'
        />
        <ListItemLink
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: 3 }}
              icon={faUtensils as IconProp}
              size='lg'
            />
          }
          primary='Tacki'
          to='/meals'
        />
        <ListItemLink
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: 3 }}
              icon={faCogs as IconProp}
              size='lg'
            />
          }
          primary='Produkcja dzienna'
          to='/menus'
        />
        <ListItemLink
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: 3 }}
              icon={faCartShopping as IconProp}
              size='lg'
            />
          }
          primary='Zakupy'
          to='/purchases'
        />
        <ListItemLink
          icon={<MonetizationOnIcon />}
          primary='Koszty'
          to='/purchase-invoices'
        />
        <Divider />
        <ListItemLink
          icon={<DescriptionIcon />}
          primary='Raporty'
          to={`/reports-manager`}
        />
        {user.role === 'admin' && (
          <ListItemLink
            icon={<AssessmentIcon />}
            primary='Raporty admin'
            to={`/reports-admin`}
          />
        )}
        <ListItemLink
          icon={<DescriptionIcon />}
          primary='Raporty produkcja'
          to={`/reports-production`}
        />
        <Divider />
        <ListItemLink
          icon={<SportsKabaddiIcon />}
          primary='Konkurencja'
          to='/webscrap'
        />
      </List>
    </div>
  );
}

interface ListItemLinkProps {
  icon: React.ReactElement;
  primary: string;
  to: string;
}
function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, any>((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <ListItem button component={CustomLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

export default NavLinks;
