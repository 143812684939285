import React from 'react';
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { blue, grey } from '@mui/material/colors';
import { format } from 'date-fns';
import { PurchaseInvoiceTypeList } from '../../../../fitAPI/src/api/purchaseInvoice/supplierList';
import { useQuery } from '@tanstack/react-query';
import { getPurchaseInvoiceReport } from '../../utils/api/reportApi';
import { ReportPeriod } from '../../types/reportType';
import { PurchaseInvoiceQueryParams } from '../../../../FitAPI/src/types/reports/ReportQueryParams';

type Props = {
  dayFrom: Date | null;
  dayTo: Date | null;
  period: ReportPeriod;
};

const PurchaseInvoiceChart = (props: Props) => {
  const { dayFrom, dayTo, period } = props;
  const [costType, setCostType] =
    React.useState<PurchaseInvoiceQueryParams['costType']>('totalNetCost');
  const [type, setType] = React.useState<'all' | PurchaseInvoiceTypeList>(
    'all'
  );
  const [isPaid, setIsPaid] = React.useState<'all' | 'true' | 'false'>('all');

  const { data, isLoading } = useQuery({
    queryKey: [
      'getPurchaseInvoiceReportData',
      dayFrom,
      dayTo,
      costType,
      period,
      type,
      isPaid,
    ],
    queryFn: () =>
      getPurchaseInvoiceReport({
        dateFrom: dayFrom ? format(dayFrom, 'yyyy-MM-dd') : '',
        dateTo: dayTo ? format(dayTo, 'yyyy-MM-dd') : '',
        costType,
        period,
        type,
        isPaid,
      }),
  });
  const handleCostTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newCostType: PurchaseInvoiceQueryParams['costType']
  ) => {
    setCostType(newCostType);
  };
  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: 'all' | PurchaseInvoiceTypeList
  ) => {
    setType(newType);
  };

  const handleisPaidChange = (
    event: React.MouseEvent<HTMLElement>,
    newPaidStatus: 'all' | 'true' | 'false'
  ) => {
    setIsPaid(newPaidStatus);
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <section>
      <Typography variant='h5' mt={4} mb={1}>
        Koszty
      </Typography>
      <Box my={3}>
        <ToggleButtonGroup
          sx={{ ml: 2 }}
          value={costType}
          exclusive
          onChange={handleCostTypeChange}
          aria-label='cost type'
        >
          <ToggleButton value='totalNetCost' aria-label='total'>
            Koszt całkowity
          </ToggleButton>
          <ToggleButton value='netCostPerUnit' aria-label='food'>
            Na zestaw
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          sx={{ ml: 2 }}
          value={type}
          exclusive
          onChange={handleTypeChange}
          aria-label='text alignment'
        >
          <ToggleButton value='all' aria-label='all'>
            Wszystko
          </ToggleButton>
          <ToggleButton value='Spożywka' aria-label='food'>
            Spożywka
          </ToggleButton>
          <ToggleButton value='Transport' aria-label='logistics'>
            Transport
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          sx={{ ml: 2 }}
          value={isPaid}
          exclusive
          onChange={handleisPaidChange}
          aria-label='paid status'
        >
          <ToggleButton value='all' aria-label='all'>
            Wszystko
          </ToggleButton>
          <ToggleButton value='true' aria-label='paid'>
            Opłacone
          </ToggleButton>
          <ToggleButton value='false' aria-label='unpaid'>
            Nieopłacone
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {data ? (
        <ResponsiveContainer width='100%' height={400}>
          <BarChart
            width={150}
            height={40}
            data={data?.data} //.map((i) => ({ name: i._id }))}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='_id'
              tickFormatter={(val) => format(new Date(val), 'd.MM')}
            />
            <YAxis yAxisId='left' orientation='left' stroke={grey[600]} />
            {/* <YAxis yAxisId='right' orientation='right' stroke='blue' /> */}
            <Tooltip />
            <Bar
              yAxisId='left'
              dataKey='totalNetAmount'
              fill={blue[400]}
              label
              // label={(props) => console.log(props)}
            />
            {/* <Bar yAxisId='right' dataKey='count' fill='#8884d8' label /> */}
          </BarChart>
        </ResponsiveContainer>
      ) : null}
    </section>
  );
};

export default PurchaseInvoiceChart;
