import React from 'react';

import { Button, Box, Typography } from '@mui/material';
import { DriverReportFilters } from '../../../../../FitAPI/src/types/reports/ReportQueryParams';
import { Download, MailOutline } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import format from 'date-fns/format';
import { EmailHistory } from '../../../types/emailHistory';
import SummaryInfo from '../SummaryInfo';
import { SummaryData } from '../../../../../FitAPI/src/types/reports/summaryReport';
import ConfirmDownloadDialog from './ConfirmDownloadDialog';

type Props = {
  handleDownloadDriverExcel: (filter: DriverReportFilters) => void;
  handleDownloadLabelDrivers: () => void;
  handleSendDriverReport: () => void;
  emailHistory: EmailHistory[] | undefined;
  isDownloading: boolean;
  summaryData: SummaryData | undefined;
};

const ReportDownloads = (props: Props) => {
  const {
    handleDownloadDriverExcel,
    handleDownloadLabelDrivers,
    handleSendDriverReport,
    emailHistory,
    isDownloading,
    summaryData,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <div>
      <Box my={8}>
        <Typography variant='h5' component='h2' align='center'>
          Dostawy
        </Typography>
        {summaryData && (
          <SummaryInfo
            summaryData={summaryData}
            type='driverReport' // dateFrom={dateFrom} dateTo={dateTo} boxQty={boxQty}
          />
        )}
      </Box>
      <Box mb={2}>
        <Button
          variant='contained'
          disabled={isDownloading}
          onClick={handleDownloadLabelDrivers}
          startIcon={<Download />}
        >
          Pobierz etykiety
        </Button>
      </Box>
      <Box mb={2} gap={2} sx={{ display: 'flex' }}>
        <Button
          variant='contained'
          disabled={isDownloading}
          onClick={() => handleDownloadDriverExcel('WAW')}
          startIcon={<Download />}
        >
          Pobierz raport z kurierami WAW
        </Button>
        <Button
          variant='contained'
          disabled={isDownloading}
          onClick={() => handleDownloadDriverExcel('OTHER')}
          startIcon={<Download />}
        >
          Pobierz raport z kurierami Miasta
        </Button>
      </Box>
      <Box mt={4}>
        <Button
          variant='contained'
          disabled={isDownloading}
          // onClick={handleSendDriverReport}
          onClick={() => setIsDialogOpen(true)}
          startIcon={<MailOutline />}
        >
          Wyślij listy adresowe
        </Button>
      </Box>

      <Box mt={4} mb={2}>
        <Typography variant='h6'>Historia wysyłki maila</Typography>
        {emailHistory && (
          <DataGrid
            rows={emailHistory}
            columns={[
              {
                field: 'dateAt',
                headerName: 'Data',
                width: 100,
                valueFormatter: (params) =>
                  format(new Date(params.value as string), 'dd.MM HH:mm'),
              },
              {
                field: 'description',
                headerName: 'Opis',
                width: 600,
              },
            ]}
            getRowId={(row) => row._id}
          />
        )}
      </Box>
      {summaryData && (
        <ConfirmDownloadDialog
          onConfirm={handleSendDriverReport}
          isOpen={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          summaryData={summaryData}
        />
      )}
    </div>
  );
};

export default ReportDownloads;
