// used to get all the days for a given order item

import api from '../api';

//get all users with their orders for a given delivery date (delivery days)
// used in meal-selection screen

export async function getDays(date: Date) {
  if (!date) throw new Error('Date is required');
  const data = await api.get(
    `/orders/delivery-days/${date.toISOString().slice(0, 10)}`
  );
  return data.data.data;
}
