// export const GET_ORDERS = 'GET_ORDERS';

export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_FAIL = 'GET_ORDER_LIST_FAIL';

export const GET_ORDER = 'GET_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const GET_ORDERS_BY_CUSTOMER = 'GET_ORDERS_BY_CUSTOMER';
export const ORDER_ERROR = 'ORDER_ERROR';
// export const SET_CURRENT_DIET = 'SET_CURRENT_DIET';

export const GET_ORDERS_BY_USER_REQUEST = 'GET_ORDERS_BY_USER_REQUEST';
export const GET_ORDERS_BY_USER_SUCCESS = 'GET_ORDERS_BY_USER_SUCCESS';
export const GET_ORDERS_BY_USER_FAIL = 'GET_ORDERS_BY_USER_FAIL';

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL';
export const ORDER_START_REQUEST = 'ORDER_START_REQUEST';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

export const ORDER_DAY_UPDATE_REQUEST = 'ORDER_DAY_UPDATE_REQUEST';
export const ORDER_DAY_UPDATE_SUCCESS = 'ORDER_DAY_UPDATE_SUCCESS';
export const ORDER_DAY_UPDATE_FAIL = 'ORDER_DAY_UPDATE_FAIL';

export const ORDER_MEAL_UPDATE_REQUEST = 'ORDER_MEAL_UPDATE_REQUEST';
export const ORDER_MEAL_UPDATE_SUCCESS = 'ORDER_MEAL_UPDATE_SUCCESS';
export const ORDER_MEAL_UPDATE_FAIL = 'ORDER_MEAL_UPDATE_FAIL';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
