import { MealImage } from '../../../../FitAPI/src/api/mealImage/image.model';
import api from '../api';

//Get all meal images
interface MealImagesVariables {
  mealId: string | undefined;
}

interface MealImageParams extends MealImagesVariables {
  imageId: string;
}
interface AddImagesVariables extends MealImagesVariables, MealImage {
  file: File;
}

export async function getMealImages({ mealId }: MealImagesVariables) {
  if (!mealId) throw new Error('No meal id provided');
  const data = await api.get(`/meals/${mealId}/images`);
  return data.data.data;
}

// Add meal image
export async function addMealImage({
  mealId,
  isMain = false,
  name = 'image_name',
  file,
}: // isMain = false,
AddImagesVariables) {
  const formData = new FormData();
  formData.append('mealName', name);
  formData.append('image', file);
  const data = await api.post(`/meals/${mealId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data.data.data;
}

// Patch meal image - isMain
export async function patchMealImageIsMain({
  mealId,
  imageId,
}: MealImageParams) {
  const data = await api.patch(`/meals/${mealId}/images/${imageId}/isMain`);
  return data.data.data;
}

// Delete meal
export async function deleteMealImage({ mealId, imageId }: MealImageParams) {
  const data = await api.delete(`/meals/${mealId}/images/${imageId}`);
  return data.data.data;
}
