import React, { useState } from 'react';

import { DropzoneDialog } from 'react-mui-dropzone';
// import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@mui/material/Button';

interface Props {
  handleAddMealImage: (files: any) => void;
}

const DropZone = ({ handleAddMealImage }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files: any) => {
    handleAddMealImage(files[0]);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Dodaj zdjęcia</Button>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleClose}
        filesLimit={1}
      />
    </div>
  );
};

export default DropZone;
