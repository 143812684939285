import * as z from 'zod';

export const allSeasons = ['wiosna', 'lato', 'jesień', 'zima'] as const;
export const allVersions = ['A', 'B', 'C', 'D'] as const;

const YearSeason = z.enum(allSeasons);
type YearSeason = z.infer<typeof YearSeason>;

export const MenuOverview = z.object({
  season: YearSeason,
  weekNr: z.number().int().min(1).max(6),
  version: z.enum(allVersions),
  dayOfWeek: z.number().int().min(0).max(6),
});
