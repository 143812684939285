import {
  REPORT_DRIVERS_REQUEST,
  REPORT_DRIVERS_SUCCESS,
  REPORT_DRIVERS_FAIL,
  REPORT_SALES_REQUEST,
  REPORT_SALES_SUCCESS,
  REPORT_SALES_FAIL,
  REPORT_PAYMENT_REQUEST,
  REPORT_PAYMENT_SUCCESS,
  REPORT_PAYMENT_FAIL,
  REPORT_CONTACTS_REQUEST,
  REPORT_CONTACTS_SUCCESS,
  REPORT_CONTACTS_FAIL,
} from '../constants/reportConstants';
export const reportReducer = (
  state = {
    drivers: [],
    sales: [],
    payments: [],
    contacts: [],
    loading: false,
    error: null,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case REPORT_DRIVERS_REQUEST:
    case REPORT_SALES_REQUEST:
    case REPORT_CONTACTS_REQUEST:
    case REPORT_PAYMENT_REQUEST:
      return { ...state, loading: true };

    case REPORT_SALES_FAIL:
    case REPORT_DRIVERS_FAIL:
    case REPORT_CONTACTS_FAIL:
    case REPORT_PAYMENT_FAIL:
      return { ...state, loading: false, error: payload };

    case REPORT_DRIVERS_SUCCESS:
      return { ...state, loading: false, drivers: payload };
    case REPORT_SALES_SUCCESS:
      return { ...state, loading: false, sales: payload };
    case REPORT_CONTACTS_SUCCESS:
      return { ...state, loading: false, contacts: payload };
    case REPORT_PAYMENT_SUCCESS:
      return { ...state, loading: false, payments: payload };

    default:
      return state;
  }
};
