import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesReport } from '../../actions/reportActions';
import Loader from '../layout/Loader';
import Message from '../layout/Message';
import getMonth from 'date-fns/getMonth';
import { useTable } from 'react-table';

import CssBaseline from '@mui/material/CssBaseline';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { createStyles, makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow, footerGroups } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}

        {footerGroups.map((group) => (
          <TableRow {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <TableCell {...column.getFooterProps()}>
                {column.render('Footer')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MaUTable>
  );
}

function SalesReport() {
  const dispatch = useDispatch();
  const { sales, loading, error } = useSelector((state) => state.reports);
  const [queryValues, setQueryValues] = useState({
    year: new Date().getFullYear(),
    month: getMonth(new Date()) + 1,
  });
  //create an array of years to selectconst startYear = 2019;
  const startYear = 2019;
  const endYear = new Date().getFullYear(); // Current year
  const yearsToSelect = [];

  for (let year = startYear; year <= endYear; year++) {
    yearsToSelect.push(year);
  }

  // const yearsToSelect = [2019, 2020, 2021, 2022, 2023, 2024];
  const monthsToSelect = Array.from(new Array(12), (x, i) => i + 1);
  useEffect(() => {
    const { year, month } = queryValues;
    dispatch(getSalesReport(year, month));
  }, [queryValues]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Dzień',
        accessor: 'day',
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Opłacone</div>,
        accessor: 'paid',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        width: 50,
        maxWidth: 50,
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.paid + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Nieopłacone</div>,
        accessor: 'unPaid',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.unPaid + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Anulowane</div>,
        accessor: 'cancelled',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),

        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.cancelled + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => (
          <div style={{ textAlign: 'right' }}>
            Nieopłacone <br />i nieanulowane
          </div>
        ),
        accessor: 'unPaidNotCancelled',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),

        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.unPaidNotCancelled + sum,
                0
              ),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Suma</div>,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        accessor: 'total',
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.total + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
      },
    ],
    []
  );

  const MySelect = ({ value, name, label, selectValues }) => {
    const useStyles = makeStyles((theme) =>
      createStyles({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
      })
    );
    const classes = useStyles();
    const handleChange = (e) => {
      setQueryValues({ ...queryValues, [e.target.name]: e.target.value });
    };
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {selectValues.map((v) => (
            <MenuItem value={v}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  if (loading) return <Loader />;
  if (error) return <Message message={error.msg} />;
  return (
    <div>
      <MySelect
        label='Miesiąc'
        name='month'
        value={queryValues.month}
        selectValues={monthsToSelect}
      />
      <MySelect
        label='Rok'
        name='year'
        value={queryValues.year}
        selectValues={yearsToSelect}
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message message={error.msg} />
      ) : (
        <>
          <CssBaseline />
          <Table columns={columns} data={sales} />
        </>
      )}
    </div>
  );
}

export default SalesReport;
