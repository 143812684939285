import React, { useState, useEffect } from 'react';
import {
  CART_DIET_DELIVERY_DAYS_UPDATE,
  CART_DIET_UPDATE_NR_DAYS,
} from '../../constants/cartConstants';
import { updateDailyDiscount } from '../../actions/cartActions';
import { DayPicker, Matcher } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {
  isSameDay,
  isSaturday as isSaturdayDateFns,
  isSunday as isSundayDateFns,
  addDays,
} from 'date-fns';

// import 'moment/locale/pl';
import { pl } from 'date-fns/locale';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';

export default function Schedule() {
  const {
    deliveryDays,
    qty,
    nrDays,
    weekend,
    isSaturday,
    isSunday,
    isTrialDay,
  } = useAppSelector((state: any) => state.cart.currentCartItem);

  const dispatch = useAppDispatch();
  const [disabledDays, setDisabledDays] = useState<any>([
    { before: new Date() },
  ]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    let newDisabledDays: Matcher[] = [{ before: new Date() }];

    if (!isSaturday) newDisabledDays.push({ dayOfWeek: [6] });
    if (!isSunday) newDisabledDays.push({ dayOfWeek: [0] });
    setDisabledDays(newDisabledDays);
    if (isTrialDay) {
      //if trial day leave  first day as selected
      dispatch({
        type: CART_DIET_DELIVERY_DAYS_UPDATE,
        payload: [deliveryDays[0]],
      });
    } else {
      //if no  trial day selected reset days
      dispatch({
        type: CART_DIET_DELIVERY_DAYS_UPDATE,
        payload: [],
      });
    }
  }, [weekend, isSaturday, isSunday, isTrialDay]);

  useEffect(() => {
    dispatch(updateDailyDiscount());
  }, [dispatch, nrDays, qty]);

  const getRange = (startDay: Date, modifiers: any) => {
    let currDate = startDay;
    let dateArr = [];
    for (let i = 0; i < nrDays; i++) {
      //skipping disabbled days
      if (!isSaturday && isSaturdayDateFns(currDate))
        currDate = addDays(currDate, 1);
      if (!isSunday && isSundayDateFns(currDate))
        currDate = addDays(currDate, 1);

      dateArr.push(currDate);
      currDate = addDays(currDate, 1);
    }
    return dateArr;
  };

  const dayClickHandler = (day: any, modifiers: any = {}) => {
    const { selected, disabled } = modifiers;
    let newDeliveryDays = [...deliveryDays];
    //handle  blocked days
    //it doesnt work
    if (disabled) {
      setErrorMessage('Daty zablokowane');
      return;
    } else {
      setErrorMessage('');
    }

    if (deliveryDays.length < 1) {
      //new range
      newDeliveryDays = getRange(day, modifiers);
    } else {
      //modyfing  existing range
      if (selected) {
        const selectedIndex = newDeliveryDays.findIndex((selectedDay) =>
          isSameDay(selectedDay, day)
        );
        newDeliveryDays.splice(selectedIndex, 1);
      } else {
        if (!isTrialDay) {
          newDeliveryDays.push(day);
        } else {
          newDeliveryDays = [day];
        }
      }
    }
    dispatch({
      type: CART_DIET_DELIVERY_DAYS_UPDATE,
      payload: newDeliveryDays,
    });
    dispatch({
      type: CART_DIET_UPDATE_NR_DAYS,
      payload: newDeliveryDays.length,
    });
  };

  return (
    <>
      <DayPicker
        locale={pl}
        className='Range'
        numberOfMonths={2}
        fromMonth={new Date()}
        selected={deliveryDays}
        disabled={disabledDays}
        onDayClick={dayClickHandler}
      />
      <div>
        <div>
          <Typography color='secondary' variant='caption'>
            {errorMessage}
          </Typography>
        </div>
        <Button
          onClick={() =>
            dispatch({
              type: CART_DIET_DELIVERY_DAYS_UPDATE,
              payload: [],
            })
          }
        >
          Wyczyść
        </Button>
      </div>
    </>
  );
}
