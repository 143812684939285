import {
  GET_PRODUCTS_BY_CATEGORY_REQUEST,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY_FAIL,
  RESET_PRODUCT_LIST,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
} from '../constants/productConstants';

export const productListReducer = (
  state = { products: [],  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS_BY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCTS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        products: payload.products,
        loading: false,
        error: null,
      };
    case GET_PRODUCTS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case RESET_PRODUCT_LIST:
      return {
        products: [],
      };

    default:
      return state;
  }
};

export const productDetailsReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        product: payload,
        loading: false,
        error: null,
      };
    case GET_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
