import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import api from '../../utils/api';
import Loader from '../../components/layout/Loader';
import Table from '../reportCommon/Table';
import Message from '../layout/Message';
import formatCurrency from '../../utils/formatCurrency';

import { createStyles, makeStyles, Theme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const formatRight = (value) => (
  <div style={{ textAlign: 'right' }}>{value}</div>
);

const formatRightStrong = (value) => (
  <div style={{ textAlign: 'right' }}>
    <strong>{value}</strong>
  </div>
);

const SalesMonthlyReport = () => {
  const [queryYear, setQueryYear] = useState(2022);
  const yearsToSelect = [2019, 2020, 2021, 2022, 2023, 2024];
  const { isLoading, error, data } = useQuery(
    ['salesMonthly', queryYear],
    async () => {
      const data = await api.get(
        `/orders/reports/monthly-sales?year=${queryYear}`
      );
      return data.data.data;
    }

    // fetch(`/api/v1/orders/reports/monthly-sales`).then((res) => res.json())
  );

  const MySelect = ({ value, name, label, selectValues }) => {
    const useStyles = makeStyles((theme) =>
      createStyles({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
      })
    );
    const classes = useStyles();
    const handleChange = (e) => {
      setQueryYear(e.target.value);
    };
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {selectValues.map((v) => (
            <MenuItem value={v}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Miesiąc',
        accessor: '_id',
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Suma[k PLN]</div>,
        accessor: 'totalOrderValue',
        Cell: ({ value }) => formatRight(formatCurrency(value / 1000)),
        Footer: (info) => {
          // Only calculate total if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.totalOrderValue + sum,
                0
              ),
            [info.rows]
          );
          return formatRightStrong(formatCurrency(total / 1000));
        },
      },
      {
        Header: () => formatRight('Liczba zam.'),
        accessor: 'orderQty',
        Cell: ({ value }) => formatRight(formatCurrency(value)),
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.orderQty + sum, 0),
            [info.rows]
          );
          return formatRightStrong(formatCurrency(total));
        },
      },
      {
        Header: () => formatRight('Śr. cena/ zam.'),
        accessor: 'avgTotalPrice',
        Cell: ({ value }) => formatRight(formatCurrency(value)),

        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.avgTotalPrice + sum, 0),
            [info.rows]
          );
          return formatRightStrong(formatCurrency(total / info.rows.length));
        },
      },
      {
        Header: () => formatRight('L.dni'),
        accessor: 'nrDays',
        Cell: ({ value }) => formatRight(value.toFixed(0)),

        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.nrDays + sum, 0),
            [info.rows]
          );
          return formatRightStrong(total.toFixed(0));
        },
      },
      {
        Header: () => formatRight('Śr. cena/ dzień'),
        accessor: 'avgDayPrice',
        Cell: ({ value }) => formatRight(formatCurrency(value)),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.avgDayPrice + sum, 0),
            [info.rows]
          );
          return formatRightStrong(formatCurrency(total / info.rows.length));
        },
      },
      {
        Header: () => formatRight('Suma rabatu adr. [k PLN]'),
        accessor: 'totalRabateAddress',
        Cell: ({ value }) => formatRight(formatCurrency(value / 1000)),

        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.totalRabateAddress + sum,
                0
              ),
            [info.rows]
          );
          return formatRightStrong(formatCurrency(total / 1000));
        },
      },
      {
        Header: () => formatRightStrong('Suma rabatu inne [k PLN]'),
        Cell: ({ value }) => formatRightStrong(formatCurrency(value / 1000)),
        accessor: 'totalRabateOther',
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.totalRabateOther + sum,
                0
              ),
            [info.rows]
          );
          return formatRightStrong(formatCurrency(total / 1000));
        },
        width: 50,
      },
    ],
    []
  );

  if (isLoading) return <Loader />;
  if (error) return <Message message={error.message} />;

  return (
    <div>
      <MySelect
        label='Rok'
        name='year'
        value={queryYear}
        selectValues={yearsToSelect}
      />
      <Table columns={columns} data={data} />
    </div>
  );
};

export default SalesMonthlyReport;
