import api from '../api';
import {
  MenuMatrixMealBody,
  MenuMatrixMealRequestParams,
  MenuMatrixRequestParams,
  MenuPatchProductionAtRequestParams,
  MenuPatchProductionAtBody,
} from '../../../../FitAPI/src/types/menuType';
import { format } from 'date-fns';

export async function getMenu(menuId: string) {
  if (!menuId) return null;
  // const {dateAt, dietName, mealType, mealSize, mealCount} =
  const data = await api.get(`/menus/${menuId}`);
  return data.data.data;
}

export async function getMenuByDate(date: Date) {
  if (!date) throw new Error('No menu date');
  const data = await api.get(`/menus/day/${date.toISOString().slice(0, 10)}`);
  return data.data.data;
}

export async function getMealName(
  dateAt: string,
  dietName: string,
  mealType: string,
  mealSize: string,
  mealCount: number
) {
  if (!dateAt || !dietName || !mealType) return null;
  // const {dateAt, dietName, mealType, mealSize, mealCount} =
  const data = await api.get(
    `/menus/get-meal?dayAt=${dateAt}&dietName=${dietName}&mealType=${mealType}&mealSize=${mealSize}&mealCount=${mealCount}`
  );
  return data.data.data;
}

// Function to update meal inside the menu
export interface MealMutationVariables {
  menuId: string;
  dietMenuId: string;
  mealMatrixId: string;
  mealId: string;
}
export interface UpdateMealVariables extends MealMutationVariables {
  currentMeal: string; //meal object Id
}

export interface AddMealVariables
  extends Omit<MealMutationVariables, 'mealId'> {
  currentMeal: string; //meal object Id
}

// Function to add meal to menu
export async function addMealToMenu(
  addMealVariables: MenuMatrixRequestParams & MenuMatrixMealBody
) {
  const { menuId, dietMenuId, matrixId, currentMeal } = addMealVariables;

  if (!menuId || !dietMenuId || !matrixId || !currentMeal)
    throw new Error('Check parameters');
  const data = await api.post(
    `/menus/${menuId}/dietMenus/${dietMenuId}/mealMatchMatrix/${matrixId}/meals`,
    { currentMeal }
  );

  return data.data.data;
}

//update existing meal in menu
export async function updateMeal(
  updateMealVariables: MenuMatrixMealRequestParams & MenuMatrixMealBody
) {
  const { menuId, dietMenuId, matrixId, mealId, currentMeal } =
    updateMealVariables;

  if (!menuId || !mealId) throw new Error('No menuId or mealId');
  const data = await api.put(
    `/menus/${menuId}/dietMenus/${dietMenuId}/mealMatchMatrix/${matrixId}/meals/${mealId}`,
    { currentMeal }
  );

  return data.data.data;
}

//delete meal from menu

export async function deleteMeal(
  deleteMealVariables: MenuMatrixMealRequestParams
) {
  const { menuId, dietMenuId, matrixId, mealId } = deleteMealVariables;

  if (!menuId || !dietMenuId || !matrixId || !mealId)
    throw new Error('No menuId or mealId');
  const data = await api.delete(
    `/menus/${menuId}/dietMenus/${dietMenuId}/mealMatchMatrix/${matrixId}/meals/${mealId}`
  );

  return data.data.data;
}

// Function to update production date of menu
export async function updateMenuProductionAt(
  patchMenuProductionAtVariables: MenuPatchProductionAtRequestParams &
    MenuPatchProductionAtBody
) {
  const { menuId, productionAt } = patchMenuProductionAtVariables;
  if (!menuId) throw new Error('No menuId');
  const data = await api.patch(`/menus/${menuId}/productionAt`, {
    productionAt,
  });

  return data.data.data;
}

// function to copy menu from a given day to current day
export async function copyMenu(copyFromAt: Date, copyToAt: Date) {
  if (!copyFromAt || !copyToAt) throw new Error('Check parameters');
  const data = await api.get(
    `/menus/${format(copyFromAt, 'yyyy-MM-dd')}/copy/${format(
      copyToAt,
      'yyyy-MM-dd'
    )}`
  );

  return data.data.data;
}
