import {
  MenuTypeLean,
  MenuTypePopulated,
} from '../../../../FitAPI/src/models/MenuModel';
import { WithId } from '../../types';
import api from '../api';
//update menu

export async function updateMenu(updatedMenu: Partial<WithId<MenuTypeLean>>) {
  if (!updatedMenu) return null;

  const data = await api.put(`/menus/${updatedMenu._id}`, updatedMenu);
  return data.data.data;
}
