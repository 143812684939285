import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  MealMatchMatrixItemTypePopulated,
  MenuType,
} from '../../../../../FitAPI/src/models/MenuModel';
import { NavLink } from 'react-router-dom';
import { Meal } from '../../../../../FitAPI/src/models/MealModel';
import { UseMutationResult } from '@tanstack/react-query';
import {
  MenuMatrixMealBody,
  MenuMatrixMealRequestParams,
  MenuMatrixRequestParams,
} from '../../../../../FitAPI/src/types/menuType';
import { WithId } from '../../../types';

type Props = {
  menuId: string;
  dietMenuId: string;
  mealMatrixItem: MealMatchMatrixItemTypePopulated;
  allMealNames: WithId<Meal>[] | undefined;
  addMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixRequestParams & MenuMatrixMealBody,
    unknown
  >;
  updateMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixMealRequestParams & MenuMatrixMealBody,
    unknown
  >;
};

const StandardDietMeal = ({
  menuId,
  dietMenuId,
  mealMatrixItem,
  allMealNames,
  addMealMutation,
  updateMealMutation,
}: Props) => {
  const [isMealRefreshing, setIsMealRefreshing] = useState(false);
  const [newMealId, setNewMealId] = useState<string | null>(null);
  // console.log(mealMatrixItem.meals[0]?.currentMeal);
  // console.log(isMealRefreshing);

  useEffect(() => {
    if (isMealRefreshing) {
      if (mealMatrixItem.meals[0]?.currentMeal._id === newMealId) {
        setIsMealRefreshing(false);
      }
    }
  }, [mealMatrixItem.meals[0]?.currentMeal._id, newMealId, isMealRefreshing]);

  const handleMealChange = (
    event: React.ChangeEvent<{}>,
    newValue: WithId<Meal> | null
  ) => {
    if (!newValue) return;
    event.stopPropagation();
    if (mealMatrixItem.meals[0]) {
      updateMealMutation.mutate({
        menuId,
        dietMenuId,
        matrixId: mealMatrixItem._id,
        mealId: mealMatrixItem.meals[0]._id,
        currentMeal: newValue._id,
      });
    } else {
      addMealMutation.mutate({
        menuId,
        dietMenuId,
        matrixId: mealMatrixItem._id,
        currentMeal: newValue._id,
      });
    }
    setIsMealRefreshing(true);
    setNewMealId(newValue._id);
  };

  return isMealRefreshing ? (
    // <CircularProgress color='inherit' />
    <Skeleton variant='rectangular' width='100%' height={80} />
  ) : (
    <Accordion sx={{ mb: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ width: '100%' }}>
          <Box mb={1}>
            <Typography variant='body2'>
              <strong>
                {mealMatrixItem?.meals[0]?.mealCode}
                {'. '}
              </strong>
              {mealMatrixItem?.mealType?.label}
            </Typography>
          </Box>
          <Box>
            {/* {mealMatrixItem.meals[0] && (
              <MealAutocompleteField
                menuId={menuId}
                dietMenuId={dietMenuId}
                mealMatrixItemId={mealMatrixItem._id}
                mealId={mealMatrixItem.meals[0]._id}
                selectedMenuItem={mealMatrixItem.meals[0]}
                // mealIdx={0}
                // mealId={m.currentMeal.id}
              />
            )} */}

            {allMealNames && (
              <Autocomplete
                fullWidth
                value={mealMatrixItem.meals[0]?.currentMeal || null}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={handleMealChange}
                onOpen={(e) => e.stopPropagation()}
                id='meal-autocomplete'
                options={allMealNames}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} variant='standard' label='' />
                )}
              />
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {/* rozmiar oraz kalorycznosc dla pojedynczego posilku */}
        <div style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant='caption'>
                <strong>Rozmiar</strong>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='caption'>
                <strong>Kc.</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='caption'>
                <strong>B.[g]</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='caption'>
                <strong>W.[g]</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='caption'>
                <strong>T.[g]</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='caption'>
                <strong>Bł.[g]</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='caption'>
                <strong>S.[mg]</strong>
              </Typography>
            </Grid>
          </Grid>
          {mealMatrixItem.meals[0] &&
            mealMatrixItem.meals[0].currentMeal.nutritions.map((nutr) => (
              <Grid container spacing={2} key={nutr.size}>
                <Grid item xs={3}>
                  <Typography variant='caption'>{nutr.size}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='caption'>
                    {nutr.nutritionValues?.kcal?.toFixed(0)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='caption'>
                    {nutr.nutritionValues?.protein?.toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='caption'>
                    {nutr.nutritionValues?.carbs?.toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='caption'>
                    {nutr.nutritionValues?.fat?.toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='caption'>
                    {nutr.nutritionValues?.carbFiber?.toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant='caption'>
                    {nutr.nutritionValues?.sodium?.toFixed(1)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {mealMatrixItem.meals[0] && (
            <NavLink
              to={`/meals/${mealMatrixItem.meals[0].currentMeal._id}`}
              target='_blank'
            >
              Tacka
            </NavLink>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default StandardDietMeal;
