// Dialog to change payment details of a user

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updatePayment } from '../../actions/orderActions';
import { getPaymentMethodList } from '../../actions/cartActions';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function OrderPay({ isVisible, close, orderId }) {
  const dispatch = useDispatch();
  const {
    isCancelled,
    paymentMethod,
    paidAt,
    isPaid,
    fiscalizedAt,
    invoiceNo,
  } = useSelector((state) => state.order.order);
  const { paymentMethods } = useSelector((state) => state.cartOptions);
  const { user } = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    isCancelled,
    paymentMethod,
    paidAt,
    isPaid,
    fiscalizedAt: fiscalizedAt ? new Date(fiscalizedAt) : undefined,
    isInvoice: invoiceNo ? true : false,
    invoiceNo: invoiceNo,
    // status,
  });

  useEffect(() => {
    dispatch(getPaymentMethodList());
  }, []);

  // const methods = ['Brak', 'P24', 'Przelew', 'Inne'];
  // const status = ['Aktywne', 'Zakończone', 'Anulowane'];

  const handleClose = () => {
    close();
  };
  const handlePaymentMethod = (e, newValue) => {
    if (newValue !== null) {
      setValues({ ...values, paymentMethod: newValue });
    }
  };

  const isPaidChangeHandler = (e) => {
    let paidAt = new Date();
    if (!e.target.checked) paidAt = undefined;
    setValues({ ...values, isPaid: e.target.checked, paidAt: paidAt });
  };

  const handleIsInvoiceCheckBox = (e) => {
    let invoiceNo = values.invoiceNo;

    // clears invoice number if invoice is unchecked
    if (!e.target.checked) invoiceNo = '';
    setValues({ ...values, [e.target.name]: e.target.checked, invoiceNo });
  };

  const handlerInvoiceNoChange = (e) => {
    setValues({ ...values, invoiceNo: e.target.value });
  };

  const handleFiscalization = () => {
    setValues({ ...values, fiscalizedAt: new Date() });
  };

  const submitHandler = () => {
    dispatch(updatePayment(orderId, values));
    handleClose();
  };

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle id='alert-dialog-title'>Płatność</DialogTitle>
      <DialogContent>
        {paymentMethods.length && (
          <>
            <Typography variant='h6' color='textSecondary'>
              Sposób płatności:
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isCancelled}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        isCancelled: e.target.checked,
                        isPaid: false,
                        paidAt: undefined,
                        // status: e.target.checked && 'Anulowane',
                      })
                    }
                    name='isCancelled'
                    color='primary'
                  />
                }
                label='Anulowane'
              />
            </FormGroup>
            {!values.isCancelled && (
              <>
                {user.role === 'admin' ||
                !values.isPaid ||
                values.paymentMethod === 'Przelew' ? (
                  <>
                    <ToggleButtonGroup
                      size='large'
                      color='primary'
                      exclusive
                      value={values.paymentMethod}
                      onChange={handlePaymentMethod}
                    >
                      {paymentMethods.map((pMethod) => (
                        <ToggleButton value={pMethod} key={pMethod}>
                          {pMethod}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isPaid}
                            onChange={isPaidChangeHandler}
                            name='isPaid'
                            color='primary'
                          />
                        }
                        label='Opłacone'
                      />
                    </FormGroup>
                    {values.isPaid && (
                      <DatePicker
                        label='Data płatności'
                        value={new Date(values.paidAt)}
                        onChange={(date) =>
                          setValues({ ...values, paidAt: date })
                        }
                        slotProps={{ textField: { variant: 'outlined' } }}
                      />
                    )}
                  </>
                ) : (
                  ''
                )}

                {/* Invoice section */}
                <Collapse in={values.isPaid && !values.fiscalizedAt} mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isInvoice}
                        onChange={handleIsInvoiceCheckBox}
                        name='isInvoice'
                      />
                    }
                    label='Faktura VAT'
                  />

                  <Collapse in={values.isInvoice}>
                    <TextField
                      id='invoiceNo'
                      // name='invoiceNo'
                      label='Nr faktury'
                      onChange={handlerInvoiceNoChange}
                      value={values.invoiceNo}
                    />
                  </Collapse>
                </Collapse>

                {/* fiscalization section */}
                <Box mt={2}>
                  {values.fiscalizedAt ? (
                    <Typography variant='subtitle1'>
                      Data fiskalizacji:
                      <strong>
                        {format(values.fiscalizedAt, 'dd.MM.yy | HH:mm')}
                      </strong>
                    </Typography>
                  ) : values.isPaid && !values.isInvoice ? (
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={handleFiscalization}
                    >
                      Kasa fiskalna
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleClose}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' onClick={submitHandler}>
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
}
