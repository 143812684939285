// show current day menu for the user
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

import IconButton from '@mui/material/IconButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { deleteMealOrder, getDayMeals } from '../../../utils/api/orderMealApi';
import isAfter from 'date-fns/isAfter';
import AlertDialog from './AlertDialog';
import DayMenuAdd from './DayMenuAdd';
import Loader from '../../layout/Loader';

interface Meal {
  _id: string;
  mealLabel: string;
  mealNameLabel: string;
}
interface PropsType {
  dayAt: string;
  orderId: string;
  orderItemId: string;
  dayId: string;
}

const DayMenu = (props: PropsType) => {
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [showAddMeal, setShowAddMeal] = useState(false);
  const [mealIdToDelete, setMealIdToDelete] = useState('');
  const [mealNameToDelete, setMealNameToDelete] = useState('');
  const { dayAt, orderId, orderItemId, dayId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['getMeals', dayId], () =>
    getDayMeals(orderId, orderItemId, dayId)
  );

  const mutation = useMutation(
    ({ mealId }: { mealId: string }) =>
      deleteMealOrder(orderId, orderItemId, dayId, mealId),
    // ({mealId}) => deleteMealOrder(orderId, orderItemId, dayId, mealIdToDelete),
    {
      onSuccess: (data) => {
        enqueueSnackbar(`${mealNameToDelete} został usunięty `, {
          variant: 'success',
        });
        queryClient.invalidateQueries(['getMeals']);
        setMealNameToDelete('');
      },
    }
  );

  //handle delete click - shows warning window
  const handleDeleteClick = (mealId: string) => {
    setMealIdToDelete(mealId);
    setMealNameToDelete(getMealNameToDelete(mealId));
    setOpenDeleteAlert(true);
  };
  const handleDeleteMeal = () => {
    setOpenDeleteAlert(false);
    mutation.mutate({ mealId: mealIdToDelete });
    setMealIdToDelete('');
  };

  const handleAddMealModalOpen = () => setShowAddMeal(true);
  const handleAddMealModalClose = () => setShowAddMeal(false);

  //shows the name of the meal to be deleted based on state
  const getMealNameToDelete = (mealId: string) =>
    data.data.find((m: Meal) => m._id === mealId)?.mealNameLabel;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {data.data.map((m: Meal) => (
            <Box key={m.mealNameLabel} marginTop={1}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant='body1'>{m.mealLabel}</Typography>
                  <Typography variant='body1' color='textSecondary'>
                    {m.mealNameLabel}
                  </Typography>
                </Grid>
                {isAfter(new Date(dayAt), new Date()) && (
                  <Grid item xs={1}>
                    {/* <IconButton onClick={() => handleDeleteMeal(m._id)}>
                  <DeleteForeverIcon />
                </IconButton> */}
                    <IconButton
                      onClick={() => handleDeleteClick(m._id)}
                      size='large'
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          ))}

          <Box marginTop={2}>
            {showAddMeal ? (
              <DayMenuAdd
                orderId={orderId}
                orderItemId={orderItemId}
                dayId={dayId}
                dayAt={dayAt}
                handleClose={handleAddMealModalClose}
              />
            ) : isAfter(new Date(dayAt), new Date()) ? (
              <IconButton onClick={handleAddMealModalOpen} size='large'>
                <AddIcon />
              </IconButton>
            ) : null}
          </Box>

          <AlertDialog
            open={openDeleteAlert}
            handleAccept={() => handleDeleteMeal()}
            handleCancel={() => setOpenDeleteAlert(false)}
            title={mealNameToDelete}
            description={`Czy jesteś pewny, że chcesz usunąć ten posiłek? UWAGA - nie ma możliwości odzyskania tego posiłku`}
          />
        </>
      )}
    </>
  );
};
export default DayMenu;
