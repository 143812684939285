import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MealImage } from '../../../../../FitAPI/src/api/mealImage/image.model';
import { API } from '../../../config';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Box } from '@mui/system';

interface Props {
  image: MealImage | null;
  handleClose: () => void;
  handleDelete: (image: MealImage) => void;
}
export default function SingleImageView({
  image,
  handleClose,
  handleDelete,
}: Props) {
  if (image === null) return <></>;
  return (
    <div>
      <Dialog
        open={image !== null}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Zdjęcie'}</DialogTitle>

        <DialogContent>
          <img
            style={{ display: 'block', margin: 'auto' }}
            src={`${API}/uploads/meals/${image?.name}?w=164&h=164&fit=crop&auto=format`}
            alt={image?.name}
            width={500}
            loading='lazy'
            crossOrigin='anonymous'
          />

          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <IconButton onClick={() => handleDelete(image)}>
              <Delete />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
