import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_DIET_UPDATE_CALORIES,
  CART_DIET_ADD_QTY,
  CART_DIET_SUBSTRACT_QTY,
  CART_DIET_ADD_DAY,
  CART_DIET_SUBSTRACT_DAY,
  CART_DIET_UPDATE_NR_DAYS,
  CART_DIET_UPDATE_NR_DELIVERIES,
  CART_DIET_TRIAL_ON,
  CART_DIET_TRIAL_OFF,
  CART_DIET_WEEKEND_UPDATE,
  CART_DIET_DELIVERY_DAYS_UPDATE,
  CART_DIET_DAILY_DISCOUNT_UPDATE,
  CART_DIET_PRICE_UPDATE,
  CART_RESET,
  CART_CURRENT_CART_ITEM_RESET,
  CART_ORDER_PRICE_UPDATE,
  CART_ORDER_UPDATE_VALUES,
  CART_OPTIONS_REQUEST,
  CART_OPTIONS_SUCCESS,
  CART_OPTIONS_FAIL,
  CART_UPDATE_PAYMENT_METHOD,
  CART_UPDATE_ORDER_SOURCE,
  CART_UPDATE_ISPAID,
  CART_UPDATE_PAIDAT,
  CART_UPDATE_IS_EMAIL_SKIPPED,
  CART_SET_ADDRESS,
} from '../constants/cartConstants';
import produce from 'immer';
import moment from 'moment';

const initCurrentCartItem = {
  qty: 1,
  nrDays: 10,
  isTrialDay: false,
  dailyRateBase: 0,
  dailyRateDiscount: 0,
  dailyRateDelivery: 0,
  dailyRateAddOn: 0,
  deliveryDays: [],
};

const initState = {
  productPriceBeforeAdjustments: 0,
  priceAdjustment: 0,
  productPrice: 0,
  addOnPrice: 0,
  rebateAddress: 0,
  rebateOther: 0,
  shippingPrice: 0,
  totalPrice: 0,
  paymentMethod: 'P24',
  orderSource: undefined,
  idWooCommerce: undefined,
  paidAt: undefined,
  isEmailSkipped: false,
  orderItems: [],
  address: null,
  currentCartItem: {
    ...initCurrentCartItem,
  },
};

export const cartReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CART_ADD_ITEM:
      return {
        ...state,
        orderItems: [...state.orderItems, action.payload],
      };

    case CART_REMOVE_ITEM: {
      const newCartItems = [...state.orderItems];
      const idx = action.payload;
      newCartItems.splice(idx, 1);
      return {
        ...state,
        orderItems: newCartItems,
      };
    }

    case CART_DIET_UPDATE_CALORIES:
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          calories: payload.newCalories,
          dailyRateBase: payload.newDailyPrice,
          trialPrice: payload.trialPrice,
        },
      };

    case CART_DIET_ADD_QTY:
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          qty: state.currentCartItem.qty + 1,
        },
      };

    case CART_DIET_SUBSTRACT_QTY:
      let newQty = state.currentCartItem.qty - 1;
      if (newQty < 1) newQty = 1;
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          qty: newQty,
        },
      };
    case CART_DIET_ADD_DAY:
      if (state.currentCartItem.isTrialDay) return { ...state };
      // if (state.currentCartItem.deliveryDays.length <1 ) return {
      return produce(state, (draftState) => {
        const { deliveryDays } = state.currentCartItem;
        draftState.currentCartItem.nrDays =
          Number(state.currentCartItem.nrDays) + 1;
        if (deliveryDays.length > 1) {
          const lastDay = deliveryDays[deliveryDays.length - 1];
          const newDay = moment(lastDay).add(1, 'days').toDate();
          draftState.currentCartItem.deliveryDays.push(newDay);
        }
      });

    case CART_DIET_SUBSTRACT_DAY:
      if (state.currentCartItem.isTrialDay) return { ...state };
      let newNrDays = state.currentCartItem.nrDays - 1;
      if (newNrDays < 1) newNrDays = 1;
      let newDeliveryDays = [...state.currentCartItem.deliveryDays];
      newDeliveryDays.pop();
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          nrDays: newNrDays,
          deliveryDays: newDeliveryDays,
        },
      };
    case CART_DIET_UPDATE_NR_DAYS:
      if (state.currentCartItem.isTrialDay) return { ...state };
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          nrDays: payload < 1 ? 1 : payload,
        },
      };

    case CART_DIET_UPDATE_NR_DELIVERIES:
      return produce(state, (draftState) => {
        const { deliveryDays } = state.currentCartItem;
        let nrDeliveries = 0;
        for (let i = 0; i < deliveryDays.length; i++) {
          if (
            i > 1 &&
            deliveryDays[i].getDay() === 0 &&
            deliveryDays[i - 1].getDay() === 6
          ) {
          } else {
            nrDeliveries++;
          }
        }
        draftState.currentCartItem.nrDeliveries = nrDeliveries;
      });

    case CART_DIET_TRIAL_ON:
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          isTrialDay: true,
          nrDays: 1,
        },
      };

    case CART_DIET_TRIAL_OFF:
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          isTrialDay: false,
          nrDays: 10,
        },
      };

    case CART_DIET_WEEKEND_UPDATE:
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          isSaturday: payload.includes('isSaturday'),
          isSunday: payload.includes('isSunday'),
        },
      };

    case CART_DIET_DELIVERY_DAYS_UPDATE:
      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          deliveryDays: [...payload],
        },
      };

    case CART_DIET_DAILY_DISCOUNT_UPDATE:
      return produce(state, (draftState) => {
        const dietDurationDiscount = action.payload;
        const { nrDays } = state.currentCartItem;
        // let durationRabate = 0;

        const durationRabate = dietDurationDiscount.reduce(
          (dailyRabate, discountItem) => {
            let discount = dailyRabate;
            if (
              nrDays >= discountItem.minNrDays &&
              nrDays <= discountItem.maxNrDays
            )
              discount = discountItem.durationDiscount;
            return discount;
          },
          0
        );

        draftState.currentCartItem.dailyRateDiscount = durationRabate;
      });
    case CART_DIET_PRICE_UPDATE:
      const {
        dailyRateBase,
        dailyRateDiscount,
        nrDays,
        qty,
        isTrialDay,
        trialPrice,
      } = state.currentCartItem;
      const currentDailyPrice = isTrialDay
        ? trialPrice
        : dailyRateBase - dailyRateDiscount;

      return {
        ...state,
        currentCartItem: {
          ...state.currentCartItem,
          price: currentDailyPrice * nrDays * qty,
        },
      };

    case CART_RESET: {
      return initState;
    }

    case CART_CURRENT_CART_ITEM_RESET:
      return {
        ...state,
        currentCartItem: {
          ...initCurrentCartItem,
        },
      };
    case CART_ORDER_PRICE_UPDATE:
      return produce(state, (draftState) => {
        const newProductPriceBeforeAdjustments = state.orderItems.reduce(
          (acc, curr) => (acc += curr.price),
          0
        );
        const newProductPrice =
          newProductPriceBeforeAdjustments + state.priceAdjustment;
        const newTotalPrice =
          newProductPrice +
          state.addOnPrice +
          state.shippingPrice -
          state.rebateAddress -
          state.rebateOther;
        draftState.productPriceBeforeAdjustments =
          newProductPriceBeforeAdjustments;
        draftState.productPrice = newProductPrice;
        draftState.totalPrice = newTotalPrice.toFixed(2);
      });

    case CART_ORDER_UPDATE_VALUES:
      return produce(state, (draftState) => {
        const { name, value } = payload;
        let parsedValue = 0;
        // check if proper number is entered - if not leave 0 as the value
        if (!Number.isNaN(Number.parseFloat(value))) {
          parsedValue = parseFloat(value);
        }
        draftState[name] = parsedValue;
      });

    case CART_UPDATE_PAYMENT_METHOD:
      return produce(state, (draftState) => {
        draftState.paymentMethod = payload;
      });
    case CART_UPDATE_ORDER_SOURCE:
      return produce(state, (draftState) => {
        draftState.orderSource = payload;
        if (draftState.orderSource !== 'Strona ')
          draftState.idWooCommerce = undefined;
      });

    case CART_UPDATE_ISPAID:
      return { ...state, isPaid: action.payload, paidAt: new Date() };

    case CART_UPDATE_IS_EMAIL_SKIPPED:
      return { ...state, isEmailSkipped: payload };

    case CART_UPDATE_PAIDAT:
      return produce(state, (draftState) => {
        draftState.paidAt = payload;
      });
    case CART_SET_ADDRESS: {
      return produce(state, (draftState) => {
        draftState.address = payload;
      });
    }
    default:
      return state;
  }
};

export const cartOptionsReducer = (
  state = {
    paymentMethods: [], //all payments metthods available in the system,
    orderSourceList: [], //all possible ways to acquire order
    loading: false,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case CART_OPTIONS_REQUEST:
      return { ...state, loading: true };
    case CART_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: [...action.payload.paymentMethods],
        orderSourceList: [...action.payload.orderSource],
      };
    case CART_OPTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
