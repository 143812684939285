import { GridRenderCellParams, GridValueSetterParams } from '@mui/x-data-grid';
import { MealRecipePopulated } from '../../../../../FitAPI/src/models/MealModel/mealRecipe';
import { Nutrient } from '../../../../../FitAPI/src/models/NutrientsSchema';
import { EnumFromKeys } from '../../../types';

export function createSizeColumn(size: 'S' | 'M' | 'L' | 'XL') {
  return {
    field: size,
    headerName: size,
    valueGetter: (params: GridRenderCellParams<MealRecipePopulated>) =>
      params.row.sizes.find((s) => s.size === size)?.weight,
    valueSetter: (params: GridValueSetterParams<MealRecipePopulated>) => ({
      ...params.row,
      sizes: params.row.sizes.map((s) =>
        s.size === size ? { ...s, weight: params.value } : s
      ),
    }),

    // renderCell: (params: GridRenderCellParams<MealRecipePopulated>) =>
    //   params.row.sizes.find((s) => s.size === 'S')?.weight,
    editable: true,
    type: 'number',
  };
}

export function createNutritionColumns(
  field: EnumFromKeys<Nutrient>,
  headerName: 'kcal' | 'B.' | 'W.' | 'T.' | 'Bł.' | 'Sód'
) {
  return {
    field,
    headerName,
    renderCell: (params: GridRenderCellParams<MealRecipePopulated>) =>
      (params.row.recipe?.nutrition100?.[field] as number)?.toFixed(1),
  };
}
