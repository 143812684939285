import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { updateUser } from '../../actions/userActions';

import { MyTextInput, MyRadioInput } from '../../helpers/CustomFields';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const genderList = [
  { value: 'F', label: 'Kobieta' },
  { value: 'M', label: 'Męzczyzna' },
];

const validationSchema = Yup.object({
  fName: Yup.string().required('Wymagane'),
  sName: Yup.string().required('Wymagane'),
  // gender: Yup.string().required('Wymagane'),
  email: Yup.string()
    .email('Sprawdź czy email jest poprawny')
    .required('Wymagane'),
  phone: Yup.string().matches(
    /^(?:\(?\?)?(?:[-.()\s]*(\d)){9}\)?$/,
    // /^(?:\(?\?)?(?:[-\.\(\)\s]*(\d)){9}\)?$/,
    'Sprawdź czy telefon jest poprawny'
  ),
});

const UserProfileUpdate = ({ isVisible, handleClose }) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userDetails);
  const { user } = userState;

  const initialValues = {
    fName: user.fName,
    sName: user.sName,
    gender: 'M',
    phone: user.phone,
    email: user.email,
    foodExclusions: [],
    otherFoodExclusions: user.otherFoodExclusions,
    comments: user.comments,
  };

  const handleSubmit = (values) => {
    dispatch(updateUser(values));
    handleClose();
  };

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle>Zmiana danych uzytkownika</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form>
          <>
            <DialogContent>
              <MyTextInput
                variant='outlined'
                margin='normal'
                fullWidth
                label='Imię*'
                name='fName'
              />
              <MyTextInput
                variant='outlined'
                margin='normal'
                label='Nazwisko*'
                name='sName'
              />
              <MyTextInput
                variant='outlined'
                margin='normal'
                label='Email*'
                name='email'
              />
              <MyTextInput
                variant='outlined'
                margin='normal'
                label='Telefon'
                name='phone'
              />
              {/* <MyRadioInput
                row
                legend='Płeć*'
                name='gender'
                radioOptions={genderList}
              /> */}

              {/* Wyjątki dla klienta	*/}
              {/* <Autocomplete
                selectOnFocus
                multiple
                id='foodExclusions'
                name='foodExclusions'
                options={foodList}
                onChange={(e, value) =>
                  formik.setFieldValue('foodExclusions', value)
                }
                getOptionLabel={(food) => food.name}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    margin='normal'
                    label='Wyjątki ze składników'
                    name='foodExclusions'
                    {...params}
                  />
                )}
                //   value={formik.values.foodExclusions}
              /> */}
              <MyTextInput
                variant='outlined'
                margin='normal'
                label='Wyjątki - inne'
                name='otherFoodExclusions'
                multiline
                rows={2}
              />
              <MyTextInput
                variant='outlined'
                margin='normal'
                multiline
                rows={4}
                label='Uwagi'
                name='comments'
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => handleClose()}
              >
                Zamknij
              </Button>
              <Button type='submit' variant='contained' color='primary'>
                Zapisz
              </Button>
            </DialogActions>
          </>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UserProfileUpdate;
