import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useSearchParams } from 'react-router-dom';
// import queryString from 'query-string';

import { getUserById } from '../actions/userActions';
import Loader from '../components/layout/Loader';

import Cart from '../components/newOrder/Cart';
import Layout from '../components/layout/Layout';
import ProductSelection from '../components/newOrder/ProductSelection';
import AddDiet from '../components/newOrder/AddDiet';
import { CLEAR_ORDER } from '../constants/orderConstants';
import { CLEAR_USER } from '../constants/userConstants';
import Message from '../components/layout/Message';

export default function NewOrderScreen() {
  const dispatch = useAppDispatch();
  const { user, loading, error } = useAppSelector((state) => state.userDetails);
  // const [selectedProduct, setSelectedProduct] = useState();

  // const [cartItems, setCartItems] = useState([]);
  const [isAddDietVisible, setIsAddDietVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isProductSelectionVisible, setIsProductSelectionVisible] =
    useState(true);
  // Extract user id from url string
  const [searchParam, setSearchParam] = useSearchParams();
  // const query = queryString.parse(location.search);
  // const userId = 'no user';
  // if (query.userId) {
  //   userId = query;
  // }
  // const { userId } = query;
  const userId = searchParam.get('userId');

  // Ten useeffect chyba jest niepotrzebny - user i tak jest juz w state-cie
  useEffect(() => {
    if (userId) {
      dispatch(getUserById(userId));
    } else {
      dispatch({ type: CLEAR_USER });
    }
    dispatch({ type: CLEAR_ORDER });
  }, [dispatch, userId]);

  // Dodanie kolejnej pozycji do koszyka zakupowego
  // const addNewCartItem = (newCartItem) => {
  //   setCartItems([...cartItems, newCartItem]);
  //   setIsCartVisible(true);
  // };

  //Add new product to the cart
  const addNewProduct = () => {
    setIsCartVisible(false);
    setIsAddDietVisible(false);
    setIsProductSelectionVisible(true);
  };

  const startDietConfig = () => {
    setIsProductSelectionVisible(false);
    setIsAddDietVisible(true);
  };

  const showCart = () => {
    setIsProductSelectionVisible(false);
    setIsAddDietVisible(false);
    setIsCartVisible(true);
  };

  const content = () => (
    <>
      {/* Wybór z listy kategorii i produkt */}
      {isProductSelectionVisible && (
        <ProductSelection startDietConfig={startDietConfig} />
      )}
      {isAddDietVisible && !isCartVisible && <AddDiet showCart={showCart} />}

      {/* Sekcja z koszykiem */}
      {isCartVisible && <Cart addNewProduct={addNewProduct} />}
    </>
  );

  const crmView = () => (
    <Layout title={`Nowe zamówienia dla ${user.fName} ${user.sName}`}>
      {content()}
    </Layout>
  );

  return loading ? (
    <Loader />
  ) : error ? (
    <Message message={error.msg} />
  ) : user._id ? (
    crmView()
  ) : (
    content()
  );
}
