import React from 'react';
import { makeStyles } from '@mui/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { Tag, TagProduction, TagUser } from './types';
import { Meal } from '../../../../../FitAPI/src/models/MealModel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 1,
    width: '100%',
    minWidth: 150,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 3,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  type: 'production' | 'user';
  mealId: string;
  label: string;
  inputValue: Tag[];
  tagArr: Tag[];
  handleMealUpdate: (mealId: string, meal: Partial<Meal>) => void;
}

const TagSelect = (props: Props) => {
  const { label, inputValue, tagArr } = props;
  const [tags, setTags] = React.useState<Tag[]>(inputValue);

  const handleChange = (event: SelectChangeEvent<Tag[]>) => {
    if (typeof event.target.value === 'string') {
      setTags([]);
      return;
    }
    setTags(event.target.value);
    if (props.type === 'production') {
      props.handleMealUpdate(props.mealId, {
        tagsProduction: event.target.value as TagProduction[],
      });
    } else if (props.type === 'user') {
      props.handleMealUpdate(props.mealId, {
        tagsUser: event.target.value as TagUser[],
      });
    }
  };

  const classes = useStyles();
  //full list of tags combined old and new - it is possible to edit meals with wrong tags
  // const tagArrFull = Array.from(new Set(tagArr.concat(inputValue)));
  //   const theme = useTheme();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={tags}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {tagArr.map((tag) => (
          <MenuItem key={tag} value={tag}>
            <Checkbox checked={tags.indexOf(tag) > -1} />
            <ListItemText primary={tag} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TagSelect;
