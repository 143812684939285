import {
  Autocomplete,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DietMenuSchemaPopulated,
  MenuType,
} from '../../../../../FitAPI/src/models/MenuModel';
import AddIcon from '@mui/icons-material/Add';

import TrayNutritionSummary from './TrayNutritionSummary';
import { UseMutationResult } from '@tanstack/react-query';
import FlexDietMeal from './FlexDietMeal';
import StandardDietMeal from './StandardDietMeal';
import { Meal } from '../../../../../FitAPI/src/models/MealModel';
import {
  MenuMatrixMealBody,
  MenuMatrixMealRequestParams,
  MenuMatrixRequestParams,
} from '../../../../../FitAPI/src/types/menuType';
import { WithId } from '../../../types';

type Props = {
  menuId: string;
  dietMenu: DietMenuSchemaPopulated;
  allMealNames: WithId<Meal>[] | undefined;
  deleteMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixMealRequestParams,
    unknown
  >;
  addMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixRequestParams & MenuMatrixMealBody,
    unknown
  >;
  updateMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixMealRequestParams & MenuMatrixMealBody,
    unknown
  >;
};

const SingleDietModule = (props: Props) => {
  const {
    menuId,
    dietMenu,
    deleteMealMutation,
    allMealNames,
    addMealMutation,
    updateMealMutation,
  } = props;

  const [isNewMealAdding, setIsNewMealAdding] = useState('');
  const [isNewMealSaving, setIsNewMealSaving] = useState(false);
  // const [nrOfFlexMeals, setNrOfFlexMeals] = useState();
  const handleAddMeal = (
    event: React.ChangeEvent<{}>,
    newValue: WithId<Meal> | null
  ) => {
    if (!newValue) return;
    event.stopPropagation();
    setIsNewMealAdding('');
    setIsNewMealSaving(true);
    addMealMutation.mutate({
      menuId,
      dietMenuId: dietMenu._id,
      matrixId: isNewMealAdding,
      currentMeal: newValue._id,
    });
  };

  useEffect(() => {
    if (addMealMutation.status === 'success') {
      setIsNewMealSaving(false);
    }
  }, [addMealMutation.status]);

  return (
    <div>
      <Paper key={dietMenu._id} style={{ marginBottom: 24, padding: 12 }}>
        <Typography variant='h6'>{dietMenu.dietType}</Typography>

        <Grid container spacing={2}>
          <Grid item lg={12} xl={8}>
            {/* Mapping through each meal item e.g breakfast, ... */}
            {dietMenu.mealMatchMatrix?.map((mealMatrixItem) => (
              // Menu
              <Grid container spacing={2} key={mealMatrixItem._id}>
                {/* <Grid item xs={2}> */}
                {/* <Typography variant='body2'>
                  {mealMatrixItem?.mealType?.label}
                </Typography> */}
                {/* </Grid> */}
                <Grid item xs={12}>
                  {dietMenu.isFlex ? (
                    // Opcja flex
                    <>
                      <Typography variant='body2'>
                        {mealMatrixItem?.mealType?.label}
                      </Typography>
                      {mealMatrixItem.meals.map((m) => (
                        <FlexDietMeal
                          key={m.currentMeal._id}
                          menuId={props.menuId}
                          dietMenuId={dietMenu._id}
                          mealMatrixId={mealMatrixItem._id}
                          meal={m}
                          updateMealMutation={updateMealMutation}
                          deleteMealMutation={deleteMealMutation}
                          allMealNames={allMealNames}
                        />
                      ))}
                      {isNewMealAdding === mealMatrixItem._id && (
                        <Autocomplete
                          fullWidth
                          value={null}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          onChange={handleAddMeal}
                          onOpen={(e) => e.stopPropagation()}
                          id='meal-autocomplete'
                          options={allMealNames || []}
                          getOptionLabel={(option) => option.name}
                          sx={{ my: 2 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label=''
                            />
                          )}
                        />
                      )}
                      {isNewMealSaving && <Skeleton />}
                      <IconButton
                        style={{ float: 'right' }}
                        onClick={() => setIsNewMealAdding(mealMatrixItem._id)}
                      >
                        <AddIcon />
                      </IconButton>
                    </>
                  ) : (
                    <StandardDietMeal
                      menuId={props.menuId}
                      dietMenuId={dietMenu._id}
                      mealMatrixItem={mealMatrixItem}
                      allMealNames={allMealNames}
                      addMealMutation={addMealMutation}
                      updateMealMutation={updateMealMutation}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item lg={12} xl={4}>
            {dietMenu.nutritions.length > 0 ? (
              <TrayNutritionSummary nutritions={dietMenu.nutritions} />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SingleDietModule;
