import { combineReducers } from 'redux';

import auth, { AuthState } from './auth';
import alert from './alert';
// import userReducers from './userReducers';
import { categoryReducer } from './categoryReducer';
import { productListReducer, productDetailsReducer } from './productReducer';
import { userListReducer, userDetailsReducer } from './userReducers';
import { cartReducer } from './cartReducer';
import { cartOptionsReducer } from './cartReducer';
import order, { ordersListReducer } from './orderReducers';
import { userOrdersReducer } from './orderReducers';
import productionReportReducers from './productionReportReducers';
import { deliveryZoneReducer } from './deliveryZoneReducers';
import { recipeDetailsReducer } from './recipeReducers';
import { mealsListReducer } from './mealReducers';
import { mealDetailsReducer } from './mealReducers';
// import { menuDetailsReducer } from './menuReducers';
// import { menuUpdateReducer } from './menuReducers';
import { optionListReducer } from './optionReducers';
import { reportReducer } from './reportsReducer';

export interface State {
  auth: AuthState;
  menuDetails: {};
  productionReport: {};
  productList: {};
}

// export default configureStore({

export default combineReducers({
  alert,
  auth,
  // user: userReducers,
  category: categoryReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  userDetails: userDetailsReducer,
  userList: userListReducer,
  userOrders: userOrdersReducer,
  cart: cartReducer,
  cartOptions: cartOptionsReducer,
  order,
  orderList: ordersListReducer,
  productionReport: productionReportReducers,
  deliveryZone: deliveryZoneReducer,
  recipeDetails: recipeDetailsReducer,
  mealsList: mealsListReducer,
  mealDetails: mealDetailsReducer,
  // menuDetails: menuDetailsReducer,
  // menuUpdate: menuUpdateReducer,
  optionList: optionListReducer,
  reports: reportReducer,
});
