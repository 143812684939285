import React from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from '../../components/layout/Layout';
import {
  Box,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ReportPeriod } from '../../types/reportType';
import PurchaseInvoiceChart from '../../components/home/PurchaseInvoiceChart';
import SalesChart from '../../components/home/SalesChart';
import DeliveryDayChart from '../../components/home/DeliveryDayChart';

type Props = {};

const Index = (props: Props) => {
  const [period, setPeriod] = React.useState<ReportPeriod>('month');

  const [dayFrom, setDayFrom] = React.useState<Date | null>(
    new Date('2023-09-01')
  );
  const [dayTo, setDayTo] = React.useState<Date | null>(new Date());

  const handlePeriod = (
    event: React.MouseEvent<HTMLElement>,
    newPeriod: ReportPeriod
  ) => {
    setPeriod(newPeriod);
  };

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Layout>
        <Container>
          <Typography mb={2} variant='h4'>
            Podsumowanie
          </Typography>
          {/* <DatePicker
            label='Uncontrolled picker'
            defaultValue={new Date('2022-04-17')}
          /> */}
          <Box my={3} sx={{ display: 'flex' }} gap={2}>
            <DatePicker
              label='Od'
              value={dayFrom}
              onChange={(newValue) => setDayFrom(newValue)}
            />
            <DatePicker
              label='Do'
              value={dayTo}
              onChange={(newValue) => setDayTo(newValue)}
            />
            <ToggleButtonGroup
              value={period}
              exclusive
              onChange={handlePeriod}
              aria-label='text alignment'
            >
              <ToggleButton value='year' aria-label='left aligned'>
                Lata
              </ToggleButton>
              <ToggleButton value='month' aria-label='left aligned'>
                Miesiące
              </ToggleButton>
              <ToggleButton value='week' aria-label='centered'>
                Tygodnie
              </ToggleButton>
              <ToggleButton value='day' aria-label='right aligned'>
                Dni
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <PurchaseInvoiceChart
            dayFrom={dayFrom}
            dayTo={dayTo}
            period={period}
          />
          <SalesChart dayFrom={dayFrom} dayTo={dayTo} period={period} />
          <DeliveryDayChart dayFrom={dayFrom} dayTo={dayTo} period={period} />
        </Container>
      </Layout>
    </>
  );
};

export default Index;
