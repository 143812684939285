import React from 'react';
import { Helmet } from 'react-helmet-async';
import Layout from '../../components/layout/Layout';
import { Box, IconButton, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Purchase, PurchaseSchema } from '../../types/purchaseType';
import { DatePicker } from '@mui/x-date-pickers';
import SaveIcon from '@mui/icons-material/Save';

type Props = {};

const PurchaseIngredientsNewScreen = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Purchase>({
    resolver: zodResolver(PurchaseSchema),
  });

  const onSubmit = (data: Purchase) => {
    console.log('send');
    console.log(data);
  };

  const handleOrderDateChange = (date: Date | null) => {
    console.log(date);
  };

  return (
    <>
      <Helmet>
        <title>{`Z | ${''}`}</title>
      </Helmet>
      <Layout>
        <Typography variant='h4'>Nowa Lista Zakupów</Typography>
        <Box sx={{ mb: 3 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='orderDate'
            control={control}
            defaultValue={new Date()}
            render={({ field }) => (
              <DatePicker
                label='Zamówienie na dzień'
                value={field.value}
                onChange={handleOrderDateChange}
                // onChange={(date) =>
                //   field.onChange(parseISO(date) || new Date())
                // }

                // <TextField
                //   sx={{ marginRight: 2, width: 200 }}
                //   select
                //   // disabled={!isEditing}
                //   label='Zamówienie na dzień'
                //   variant='standard'
                //   //   fullWidth
                //   error={!!errors.orderDate}
                //   helperText={errors.orderDate?.message}
                //   {...field}
                // >
                //   {/* {allSeasons.map((season) => (
                //     <MenuItem key={season} value={season}>
                //       {season}
                //     </MenuItem>
                //   ))} */}
                // </TextField>
              />
            )}
          />
          {/* //Field with field  delivery date from*/}
          <Box mt={3} sx={{ display: 'flex', gap: 3 }}>
            <Controller
              name='deliveryDateFrom'
              control={control}
              defaultValue={new Date()}
              render={({ field }) => (
                <DatePicker
                  label='Dostawa od'
                  value={field.value}
                  onChange={handleOrderDateChange}
                />
              )}
            />
            {/* //Field with field  delivery date to*/}
            <Controller
              name='deliveryDateTo'
              control={control}
              defaultValue={new Date()}
              render={({ field }) => (
                <DatePicker
                  label='Dostawa do'
                  value={field.value}
                  onChange={handleOrderDateChange}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton aria-label='save' type='submit'>
              <SaveIcon />
            </IconButton>
          </Box>
        </form>
      </Layout>
    </>
  );
};

export default PurchaseIngredientsNewScreen;
