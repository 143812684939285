import { Box, Typography } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { SummaryData } from '../../../../FitAPI/src/types/reports/summaryReport';

type Props = {
  summaryData: SummaryData;
  type: 'driverReport' | 'productionReport';
};

const SummaryInfo = (props: Props) => {
  const { from, to, boxQty, boxQtyWAW, boxQtyOTHER } = props.summaryData;
  return from && to && boxQty ? (
    <Box mb={2}>
      {/* <Typography variant='subtitle1' align='center'>
        Dni dostawy
      </Typography> */}
      <Typography variant='subtitle1' align='center'>
        <strong>
          {format(new Date(from), 'dd.MM.yyyy (eeee)', { locale: pl })}
        </strong>
        {' - '}
        <strong>
          {format(new Date(to), 'dd.MM.yyyy (eeee)', { locale: pl })}
        </strong>
      </Typography>
      {props.type === 'productionReport' ? (
        <Typography variant='subtitle1' align='center'>
          Liczba zestawów: <strong>{boxQty} </strong>
        </Typography>
      ) : (
        <>
          <Typography variant='subtitle1' align='center'>
            Liczba dostaw: <strong>{boxQtyWAW + boxQtyOTHER} </strong>
          </Typography>
          <Typography variant='subtitle1' align='center'>
            Wwa: <strong>{boxQtyWAW}</strong> | Miasta:{' '}
            <strong>{boxQtyOTHER}</strong>
          </Typography>
        </>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default SummaryInfo;
