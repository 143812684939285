import React from 'react';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import { red } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    // marginRight: theme.spacing(2)
  },

  avatar: {
    backgroundColor: red[500],
  },
}));

export default function MyBox({
  key = '',
  id = '',
  title = '',
  avatar = '',
  children,
}) {
  const classes = useStyles();
  return (
    <div id={id} key={key}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label='recipe' className={classes.avatar}>
              {avatar}
            </Avatar>
          }
          // action={
          // 	<IconButton aria-label="settings">
          // 		<MoreVertIcon />
          // 	</IconButton>
          // }
          title={title}
          // subheader="September 14, 2016"
        />
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
}
