export const REPORT_DRIVERS_REQUEST = 'REPORT_DRIVERS_REQUEST';
export const REPORT_DRIVERS_SUCCESS = 'REPORT_DRIVERS_SUCCESS';
export const REPORT_DRIVERS_FAIL = 'REPORT_DRIVERS_FAIL';

export const REPORT_SALES_REQUEST = 'REPORT_SALES_REQUEST';
export const REPORT_SALES_SUCCESS = 'REPORT_SALES_SUCCESS';
export const REPORT_SALES_FAIL = 'REPORT_SALES_FAIL';

export const REPORT_PAYMENT_REQUEST = 'REPORT_PAYMENT_REQUEST';
export const REPORT_PAYMENT_SUCCESS = 'REPORT_PAYMENT_SUCCESS';
export const REPORT_PAYMENT_FAIL = 'REPORT_PAYMENT_FAIL';

export const REPORT_CONTACTS_REQUEST = 'REPORT_CONTACTS_REQUEST';
export const REPORT_CONTACTS_SUCCESS = 'REPORT_CONTACTS_SUCCESS';
export const REPORT_CONTACTS_FAIL = 'REPORT_CONTACTS_FAIL';
