import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Alert from './components/layout/Alert';

import UserScreen from './screens/UserScreen';
import UserListScreen from './screens/UserListScreen';
import OrderListScreen from './screens/OrderListScreen';

import LoginScreen from './screens/LoginScreen';
import ManagerRoute from './components/routing/ManagerRoute';
import AdminRoute from './components/routing/AdminRoute';
import OrderScreen from './screens/OrderScreen';
import NewOrderScreen from './screens/NewOrderScreen';
import NewUserScreen from './screens/NewUserScreen';
import ForgotPassword from './screens/ForgotPasswordScreen';
import ResetPassword from './screens/ResetPasswordScreen';
import WebscrapScreen from './screens/WebscrapScreen';
import IngredientListScreen from './screens/IngredientListScreen';
import RecipesListScreen from './screens/RecipesListScreen';
import RecipeScreen from './screens/RecipeScreen';
import MealsListScreen from './screens/MealsListScreen';
import MealScreen from './screens/MealScreen';
import MenusListScreen from './screens/MenusListScreen';
// import MenuScreen from './screens/MenuScreen';

import MealSelectionScreen from './screens/MealSelectionScreen';
import UserEndingOrderScreen from './screens/UserEndingOrderScreen';
import ReportAdminScreen from './screens/ReportAdminScreen';
import ReportProductionScreen from './screens/ReportProductionScreen';
import ReportManagerScreen from './screens/ReportManagerScreen';
import ContactsScreen from './screens/ContactsScreen';
import ProtectedRoute from './components/routing/ProtectedRoute';
import { useSelector } from 'react-redux';
import Loader from './components/layout/Loader';

import { State } from './reducers';
import MenuScreen from './screens/MenuScreen';
import PurchaseIngredientsScreen from './screens/purchaseIngredients/PurchaseIngredientsListScreen';
import PurchaseIngredientsNewScreen from './screens/purchaseIngredients/PurchaseIngredientsNewScreen';
import PurchaseInvoiceListScreen from './screens/purchaseInvoice/PurchaseInvoiceListScreen';

import PurchaseInvoiceScreen from './screens/purchaseInvoice/PurchaseInvoiceScreen';
import Home from './screens/Home';

const AppRoutes = () => {
  const authState = useSelector((state: State) => state.auth);
  const { user, isAuthenticated, loading } = authState;
  // console.log(user, isAuthenticated, localStorage.token, loading);
  if (loading) {
    return <Loader />;
  }
  // if (!user || Object.keys(user).length === 0) {
  //   return <div>Loading user data...</div>;
  // }
  return (
    <BrowserRouter>
      <Alert />
      <Routes>
        <Route path='/login' element={<LoginScreen />} />
        {/* <Route exact path='/signup' component={Signup} /> */}
        {/* <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route
        path='/reset-password/:resettoken'
        exact
        component={ResetPassword}
      /> */}
        <Route
          element={
            <ProtectedRoute
              isAllowed={
                user?.role
                  ? !!isAuthenticated &&
                    ['admin', 'manager'].includes(user.role)
                  : false
              }
            />
          }
        >
          {/* <Route path='/' element={<UserListScreen />} /> */}
          <Route path='/' element={<Navigate to='/users' />} />
          <Route path='users' element={<UserListScreen />} />
          <Route path='users/new' element={<NewUserScreen />} />

          {/* <Route path='new' element={<NewUserScreen />} /> */}
          <Route path='users/:id' element={<UserScreen />} />
          <Route path='/orders/new' element={<NewOrderScreen />} />
          <Route path='/orders' element={<OrderListScreen />} />
          <Route path='/orders/:orderId' element={<OrderScreen />} />
          <Route path='/meal-selection' element={<MealSelectionScreen />} />
          <Route
            path='/users-orders-ending'
            element={<UserEndingOrderScreen />}
          />
          <Route path='/users-contacts' element={<ContactsScreen />} />
          <Route path='/ingredients' element={<IngredientListScreen />} />
          <Route path='/recipes' element={<RecipesListScreen />} />
          <Route path='/recipes/:recipeId' element={<RecipeScreen />} />
          <Route path='/webscrap' element={<WebscrapScreen />} />
          <Route path='/meals' element={<MealsListScreen />} />
          <Route path='/meals/:mealId' element={<MealScreen />} />
          <Route path='/menus' element={<MenusListScreen />} />
          <Route path='/menus/:menuId' element={<MenuScreen />} />
          {/* <Route path='/menus/:menuId' element={<MenuScreen />} /> */}

          <Route path='/reports-manager' element={<ReportManagerScreen />} />
          <Route
            path='/reports-production'
            element={<ReportProductionScreen />}
          />
          <Route path='/purchases' element={<PurchaseIngredientsScreen />} />
          <Route
            path='/purchases/new'
            element={<PurchaseIngredientsNewScreen />}
          />
        </Route>
        <Route
          path='/purchase-invoices'
          element={<PurchaseInvoiceListScreen />}
        />
        <Route
          path='/purchase-invoices/:invoiceId'
          element={<PurchaseInvoiceScreen />}
        />

        <Route
          element={
            <ProtectedRoute
              isAllowed={
                user?.role
                  ? !!isAuthenticated && user?.role.includes('admin')
                  : false
              }
            />
          }
        >
          <Route path='/reports-admin' element={<ReportAdminScreen />} />
          <Route path='/home' element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
