import React, { useState } from 'react';
import { Meal } from '../../../../FitAPI/src/models/MealModel';
import { MealTray } from '../../../../FitAPI/src/models/MealModel/mealTray';
import { WithId } from '../../types';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
// import { allTrayTypes } from '../../../../FitAPI/src/models/MealModel';

interface Props {
  mealId: string;
  data: WithId<MealTray>[];
  handleMealUpdate: (mealId: string, meal: Partial<Meal>) => void;
}

const allTrayTypes = [
  'Dwudzielna mała zupka',
  'Zupka niska',
  'Zupka wysoka',
  'Zupka mała przezroczysta 350 ml',
  'Zupka duża przezroczysta 500 ml',
  'Dwudzielna niska',
  'Dwudzielna wysoka',
  'Colt wysoki',
  'Sałata ½  niska',
  'Sałata ½ wysoka',
  'Sałata wysoka',
  'Kwadratowa niska sałatka',
  'Trójdzielna dzielona na prosto',
  'Trójdzielna z pojemnikiem na sos niska',
  'Trójdzielna z pojemnikiem na sos wysoka',
  'Kubek deser 250 ml',
  'Kubek deser 350 ml',
  'Kubek deser 500 ml',
  'Butelka 250 ml',
  'Butelka 330 ml',
  'Butelka 480 ml',
  'Sosjerka 30 ml',
  'Słoiki 500 ml',
  'Słoiki 60 ml',
] as const;

const Trays = (props: Props) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [trays, setTrays] = useState(props.data);

  type AllTrayTypes = (typeof allTrayTypes)[number];
  const handleTrayChange = (
    event: SelectChangeEvent<AllTrayTypes>,
    id: string
  ) => {
    console.log(event.target.value);
    const newTrays = trays.map((tray) => {
      if (tray._id === id) {
        tray.tray = event.target.value as AllTrayTypes;
      }
      return tray;
    });
    setTrays(newTrays);
  };

  const handleSave = () => {
    props.handleMealUpdate(props.mealId, { trayTypes: trays });
    setIsInEditMode(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Rozmiar</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Tacka</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((trayType, index) => (
              <TableRow
                key={trayType._id}
                // sx={{ '&:last-child td, &:last-child th': { border: 5 } }}
              >
                <TableCell>{trayType.size}</TableCell>
                <TableCell>
                  {isInEditMode ? (
                    <FormControl
                      fullWidth
                      sx={{ my: 1 }}
                      //   sx={{ minWidth: 120 }}
                    >
                      <InputLabel id='tray-select-label'>Tacki</InputLabel>
                      <Select
                        labelId='tray-select-label'
                        id='tray-select'
                        label='Tacki'
                        variant='standard'
                        value={trayType.tray}
                        onChange={(event) =>
                          handleTrayChange(event, trayType._id)
                        }
                      >
                        {allTrayTypes.map((key) => (
                          <MenuItem key={key} value={key}>
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    trayType.tray
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                {isInEditMode ? (
                  <IconButton sx={{ float: 'right' }} onClick={handleSave}>
                    <SaveIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ float: 'right' }}
                    aria-label='edit'
                    onClick={() => setIsInEditMode(!isInEditMode)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Trays;
