import React, { useEffect } from 'react';
import AppRoutes from './AppRoutes';

import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Redux
import { LOGOUT } from './actions/types';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/authActions';
import setAuthToken from './utils/setAuthToken';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { createMuiTheme } from '@mui/core/styles';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// for date picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pl } from 'date-fns/locale';
import { plPL as plMaterial } from '@mui/material/locale';
import { plPL as plDataGrid } from '@mui/x-data-grid';
// import { Theme } from '@mui/material/styles';
// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }

const App = () => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  const queryClient = new QueryClient();

  const theme = createTheme(
    {
      palette: {
        // mode: 'dark',
        background: {
          default: '#f3f6f9', // set your desired gray color here
        },
        primary: {
          main: '#3E51B5',
        },
        secondary: {
          main: '#c00044',
        },
      },
    },
    plMaterial,
    plDataGrid
  );

  return (
    <div>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              <QueryClientProvider client={queryClient}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={pl}
                >
                  <AppRoutes />
                </LocalizationProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </div>
  );
};

export default App;
