import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import api from '../utils/api';
import Layout from '../components/layout/Layout';
import { MenuTypeLean } from '../../../FitAPI/src/models/MenuModel';

import MaterialTable, { MTableToolbar, Column } from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { WithId } from '../types';
import getDayOfWeekName, { daysOfWeek } from '../utils/menu/getDayOfWeekName';
import { allSeasons, allVersions } from '../types/menuTypes';
import convertArrayToObject from '../utils/menu/convertArrayToObject';
import queryString from 'query-string';

const MenusListScreen = () => {
  // const queryStr = queryString.parse(match.location.search);
  // const [newSearch, setNewSearch] = useState(queryStr.q || '');
  const [searchParam, setSearchParam] = useSearchParams({ menuAt: '' });

  // let navigate = useNavigate();
  const menusRef = React.createRef<any>();

  useEffect(() => {
    const searchTerm = searchParam.get('menuAt');
    if (!searchTerm || searchTerm.length > 2) menusRef.current.onQueryChange();
  }, [searchParam, menusRef]);

  const structure: Array<Column<WithId<MenuTypeLean>>> = [
    {
      title: 'Dzień',
      field: 'menuAt',
      editable: 'always',
      filtering: false,
      render: (rowData) => (
        <Link to={`/menus/${rowData._id}`}>
          {format(new Date(rowData.menuAt), 'd MMM yy (EEEE)', { locale: pl })}
        </Link>
      ),
    },
    // add columns for season, week number, version, day of week

    {
      title: 'Pora roku',
      field: 'season',

      editable: 'never',
      lookup: convertArrayToObject(Array.from(allSeasons)),
    },
    {
      title: 'Numer tygodnia',
      field: 'weekNr',
      editable: 'never',
      lookup: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
      },
    },
    {
      title: 'Wersja',
      field: 'version',
      editable: 'never',
      lookup: convertArrayToObject(Array.from(allVersions)),
      // lookup: allVersions.reduce((acc, cur) => {
      //   acc[cur] = cur;
      //   return acc;
      // }, {} as { [key: string]: string }),
    },
    {
      title: 'Dzień tygodnia',
      field: 'dayOfWeek',
      render: (rowData) => getDayOfWeekName(rowData.dayOfWeek),
      editable: 'never',
      lookup: [0, 1, 2, 3, 4, 5, 6].reduce((acc, cur) => {
        acc[cur] = getDayOfWeekName(cur);
        return acc;
      }, {} as { [key: string]: string }),
    },
  ];

  return (
    <Layout>
      <MaterialTable
        title='Menu'
        tableRef={menusRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            let sort = '-menuAt';
            if (query.orderBy) {
              sort =
                query.orderDirection === 'asc'
                  ? (query.orderBy.field as string)
                  : (`-${query.orderBy.field}` as string);
            }

            const filtersAll = query.filters.reduce((filterObj, item) => {
              const filterField = item.column.field as string;
              const filterValue = item.value;
              filterObj = { ...filterObj, [filterField]: filterValue };
              return filterObj;
            }, {});

            const queryStrAPI = queryString.stringify(
              {
                select: ['menuAt', 'season', 'weekNr', 'version', 'dayOfWeek'],
                page: query.page + 1,
                pageSize: query.pageSize,
                sort: sort,
                menuAt: searchParam.get('menuAt'), //searchTerm,
                // name: searchParam.get('name'),
                ...filtersAll,
              },
              { arrayFormat: 'comma' }
            );

            const url = `/menus?${queryStrAPI}`;

            api.get(url).then((result) => {
              resolve({
                data: result.data.menus,
                page: result.data.page - 1,
                totalCount: result.data.count,
              });
            });
          })
        }
        columns={structure}
        options={{
          pageSize: 25,
          pageSizeOptions: [15, 25, 50],
          sorting: true,
          search: false,
          filtering: true,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              api.post('/menus/', { ...newData }).then((result) => {
                resolve();
              });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise<void>((resolve, reject) => {
              api
                .put(`/menus/menuAt/${oldData?._id}`, { ...newData })
                .then((result) => {
                  console.log(`Menu zmienione na ${result.data.data}`);
                  resolve();
                });
            }),
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <TextField
                  fullWidth
                  autoFocus
                  // variant='outlined'
                  onChange={(e) => setSearchParam({ menuAt: e.target.value })}
                  value={searchParam.get('menuAt')}
                  placeholder='Wpisz pierwsze 3 litery, aby rozpocząć wyszukiwanie'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton onClick={(e) => setSearchParam({ q: '' })}>
                          <Cancel />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  Szukaj
                </TextField>
              </div>
            </div>
          ),
        }}
      />
    </Layout>
  );
};

export default MenusListScreen;
