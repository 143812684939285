import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentReport } from '../../actions/reportActions';
import Loader from '../layout/Loader';
import Message from '../layout/Message';
import getMonth from 'date-fns/getMonth';
import Table from '../reportCommon/Table';
import MySelect from './MySelect';

import CssBaseline from '@mui/material/CssBaseline';

function PaymentReport() {
  const dispatch = useDispatch();
  const { payments, loading, error } = useSelector((state) => state.reports);
  const [queryValues, setQueryValues] = useState({
    year: 2021,
    month: getMonth(new Date()) + 1,
  });
  const yearsToSelect = [2019, 2020, 2021];
  const monthsToSelect = Array.from(new Array(12), (x, i) => i + 1);
  useEffect(() => {
    const { year, month } = queryValues;
    dispatch(getPaymentReport(year, month));
  }, [queryValues]);

  const handleChangeDuration = (e) => {
    setQueryValues({ ...queryValues, [e.target.name]: e.target.value });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Dzień',
        accessor: 'day',
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Suma</div>,
        accessor: 'totalPaid',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        width: 50,
        maxWidth: 50,
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.totalPaid + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>P24</div>,
        accessor: 'p24',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.p24 + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Liczba P24</div>,
        accessor: 'p24Count',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),

        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.p24Count + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Przelew</div>,
        accessor: 'bankTransfers',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),

        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.bankTransfers + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Liczba przlewów</div>,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        accessor: 'bankTransfersCount',
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.bankTransfersCount + sum,
                0
              ),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Inne</div>,
        accessor: 'others',
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),

        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.others + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
        maxWidth: 50,
      },
      {
        Header: () => <div style={{ textAlign: 'right' }}>Liczba innych</div>,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
        accessor: 'othersCount',
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.othersCount + sum, 0),
            [info.rows]
          );
          return (
            <div style={{ textAlign: 'right' }}>
              <strong>{total.toLocaleString()}</strong>
            </div>
          );
        },
        width: 50,
      },
    ],
    []
  );

  if (loading) return <Loader />;
  if (error) return <Message message={error.msg} />;
  return (
    <div>
      <MySelect
        label='Miesiąc'
        name='month'
        value={queryValues.month}
        selectValues={monthsToSelect}
        handleChange={handleChangeDuration}
      />
      <MySelect
        label='Rok'
        name='year'
        value={queryValues.year}
        selectValues={yearsToSelect}
        handleChange={handleChangeDuration}
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message message={error.msg} />
      ) : (
        <>
          <CssBaseline />
          <Table columns={columns} data={payments} />
        </>
      )}
    </div>
  );
}

export default PaymentReport;
