import {
  MEAL_LIST_REQUEST,
  MEAL_LIST_SUCCESS,
  MEAL_LIST_FAIL,
  MEAL_CLONE_REQUEST,
  MEAL_CLONE_SUCCESS,
  MEAL_CLONE_FAIL,
} from '../constants/mealConstants';

export const mealsListReducer = (state = { meals: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case MEAL_LIST_REQUEST:
      return { loading: true, meals: [] };
    case MEAL_LIST_SUCCESS:
      return {
        loading: false,
        meals: payload,
      };
    case MEAL_LIST_FAIL:
      return {
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const mealDetailsReducer = (
  state = { meal: null, trayTypes: [], loading: false, error: null },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case MEAL_CLONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MEAL_CLONE_SUCCESS:
      return {
        ...state,
        meal: payload.meal,
        trayTypes: payload.trayTypes,
        loading: false,
        error: null,
      };
    case MEAL_CLONE_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
};
