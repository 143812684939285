import React from 'react';
import { useParams } from 'react-router-dom';
import {
  getPurchaseInvoice,
  updatePurchaseInvoice,
} from '../../utils/api/purchase.invoice.api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';

import {
  PurchaseInvoice,
  paymentTypeList,
  purchaseCategoryList,
  purchaseInvoiceTypeList,
  suppliersList,
} from '../../types/purchaseInvoiceType';
import { PurchaseInvoiceSchema } from '../../types/purchaseInvoiceType';
import Layout from '../../components/layout/Layout';
import {
  Box,
  Breadcrumbs,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InvoicePDF from '../../components/purchase-invoices/InvoicePDF';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';

type Props = {};

const PurchaseInvoiceScreen = (props: Props) => {
  const { invoiceId } = useParams();
  const [refreshFlag, setRefreshFlag] = React.useState(false);

  const { data: invoiceData } = useQuery<PurchaseInvoice>({
    queryKey: ['purchaseInvoice', invoiceId],
    queryFn: () => getPurchaseInvoice(invoiceId),
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(updatePurchaseInvoice, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['purchaseInvoice', invoiceId]);
      enqueueSnackbar('Zmiany zapisane', {
        variant: 'success',
      });
      setRefreshFlag(!refreshFlag);
    },
    onError: (error) => {
      enqueueSnackbar('Błąd zapisu', {
        variant: 'error',
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PurchaseInvoice>({
    resolver: zodResolver(PurchaseInvoiceSchema),
    // defaultValues: async () => await getPurchaseInvoice(invoiceId),
  });

  const onSubmit = (data: PurchaseInvoice) => {
    mutation.mutate({
      id: invoiceId,
      data: data,
    });
  };

  return (
    <>
      <Helmet>
        <title>{`Faktura | ${invoiceData?.invoiceNumber || ''}`}</title>
        <meta
          property='og:title'
          content={`F ${invoiceData?.invoiceNumber} | ${invoiceData?.supplier}`}
        />
        <meta
          name='description'
          content={`Faktura ${invoiceData?.invoiceNumber} (${invoiceData?.supplier})`}
        />
      </Helmet>
      <Layout>
        <Breadcrumbs>
          <Link
            color='inherit'
            to='/purchase-invoices'
            component={RouterLink}
            underline='hover'
          >
            Faktury
          </Link>
          <Typography>{`${invoiceData?.invoiceNumber} (${invoiceData?.supplier})`}</Typography>
        </Breadcrumbs>
        <Box sx={{ mb: 3 }} />
        <Typography sx={{ mt: 2 }} variant='h5' align='center'>
          Faktura nr {invoiceData?.invoiceNumber}
        </Typography>
        <Grid container columnSpacing={6} mt={12}>
          <Grid item xs={12} md={3}>
            {invoiceData && (
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <Controller
                    name='invoiceNumber'
                    control={control}
                    defaultValue={invoiceData.invoiceNumber}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Numer faktury'
                        fullWidth
                        error={!!errors.invoiceNumber}
                        helperText={errors.invoiceNumber?.message}
                      />
                    )}
                  />
                  <Controller
                    name='invoiceDate'
                    control={control}
                    defaultValue={new Date(invoiceData.invoiceDate)}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label='Data faktury'
                        format='dd.MM.yyyy' // Adjust the format according to your library's requirements
                        slotProps={{
                          textField: {
                            helperText: 'DD.MM.YYYY',
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name='supplier'
                    control={control}
                    defaultValue={invoiceData.supplier}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id='supplier'>Dostawca</InputLabel>
                        <Select
                          labelId='supplier'
                          id='supplier'
                          label='Dostawca'
                          {...field}
                          error={!!errors.supplier}
                        >
                          {suppliersList.map((supplier) => (
                            <MenuItem key={supplier} value={supplier}>
                              {supplier}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name='type'
                    control={control}
                    defaultValue={invoiceData.type}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id='type'>Typ</InputLabel>
                        <Select
                          labelId='type'
                          id='type'
                          label='Typ'
                          {...field}
                          error={!!errors.type}
                        >
                          {purchaseInvoiceTypeList.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name='category'
                    control={control}
                    defaultValue={invoiceData.category}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id='category'>Kategoria</InputLabel>
                        <Select
                          labelId='category'
                          id='category'
                          label='Kategoria'
                          {...field}
                          error={!!errors.category}
                        >
                          {purchaseCategoryList.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name='netCost'
                    control={control}
                    defaultValue={invoiceData.netCost}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type='number'
                        onChange={(e) => {
                          field.onChange(parseFloat(e.target.value) || 0);
                        }}
                        label='Kwota netto'
                        fullWidth
                        error={!!errors.netCost}
                        helperText={errors.netCost?.message}
                      />
                    )}
                  />
                  <Controller
                    name='vat'
                    control={control}
                    defaultValue={invoiceData.vat}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type='number'
                        onChange={(e) => {
                          field.onChange(parseFloat(e.target.value) || 0);
                        }}
                        label='VAT'
                        fullWidth
                        error={!!errors.vat}
                        helperText={errors.vat?.message}
                      />
                    )}
                  />
                  <Controller
                    name='grossCost'
                    control={control}
                    defaultValue={invoiceData.grossCost}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type='number'
                        onChange={(e) => {
                          field.onChange(parseFloat(e.target.value) || 0);
                        }}
                        label='Kwota brutto'
                        fullWidth
                        error={!!errors.grossCost}
                        helperText={errors.grossCost?.message}
                      />
                    )}
                  />
                  <Controller
                    name='paymentType'
                    control={control}
                    defaultValue={invoiceData.paymentType}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id='paymentType'>
                          Sposób płatności
                        </InputLabel>
                        <Select
                          labelId='paymentType'
                          id='paymentType'
                          label='Sposób płatności'
                          {...field}
                          error={!!errors.paymentType}
                        >
                          {paymentTypeList.map((paymentType) => (
                            <MenuItem key={paymentType} value={paymentType}>
                              {paymentType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name='dueDate'
                    control={control}
                    defaultValue={new Date(invoiceData.dueDate)}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label='Termin płatności faktury'
                        format='dd.MM.yyyy' // Adjust the format according to your library's requirements
                        slotProps={{
                          textField: {
                            helperText: 'DD.MM.YYYY',
                          },
                        }}
                      />
                    )}
                  />
                  {invoiceData.isPaid && invoiceData.paidAt && (
                    <Controller
                      name='paidAt'
                      control={control}
                      defaultValue={new Date(invoiceData.paidAt)}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          disabled
                          label='Data opłacenia faktury'
                          format='dd.MM.yyyy' // Adjust the format according to your library's requirements
                          slotProps={{
                            textField: {
                              helperText: 'DD.MM.YYYY',
                            },
                          }}
                        />
                      )}
                    />
                  )}

                  <Controller
                    name='invoiceScan'
                    control={control}
                    defaultValue={invoiceData.invoiceScan}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Nazwa pliku'
                        disabled
                        fullWidth
                        error={!!errors.invoiceScan}
                        helperText={errors.invoiceScan?.message}
                      />
                    )}
                  />
                  <Controller
                    name='isPaid'
                    control={control}
                    defaultValue={invoiceData.isPaid}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label='Opłacona'
                      />
                    )}
                  />
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <IconButton type='submit'>
                    <Icon>save</Icon>
                  </IconButton>
                </Box>
              </form>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <InvoicePDF invoiceId={invoiceId} />
            </Box>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default PurchaseInvoiceScreen;
