import api from '../utils/api';
import {
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAIL,
} from '../constants/categoryConstants';

// Get all categories
export const getCategoryList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORY_LIST_REQUEST });
    const res = await api.get('/categories');
    dispatch({
      type: GET_CATEGORY_LIST_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CATEGORY_LIST_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
