import api from '../api';
import { format } from 'date-fns';
import {
  DeliveryDayReportQueryParams,
  DriverReportFilters,
  PurchaseInvoiceQueryParams,
} from '../../../../FitAPI/src/types/reports/ReportQueryParams';
import { ReportPeriod } from '../../types/reportType';
import { PurchaseInvoiceTypeList } from '../../../../fitAPI/src/api/purchaseInvoice/supplierList';
import queryString from 'query-string';

export async function getSummayDayReport(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  const res = await api.get(
    `/reports/summary?dateFrom=${format(
      dateFrom as Date,
      'yyyy-MM-dd'
    )}&dateTo=${format(dateTo as Date, 'yyyy-MM-dd')}`
  );

  return res.data.data;
}

export async function getIngredientsReport(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  const res = await api.get(
    `/reports/ingredients?dateFrom=${format(
      dateFrom as Date,
      'yyyy-MM-dd'
    )}&dateTo=${format(dateTo as Date, 'yyyy-MM-dd')}`
  );

  return res.data.data;
}

export async function getRecipeReport(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  const res = await api.get(
    `/reports/recipes?dateFrom=${format(
      dateFrom,
      'yyyy-MM-dd'
    )}&dateTo=${format(dateTo, 'yyyy-MM-dd')}`
  );

  return res.data.data;
}

export async function getMealReport(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  const res = await api.get(
    `/reports/meals?dateFrom=${format(dateFrom, 'yyyy-MM-dd')}&dateTo=${format(
      dateTo,
      'yyyy-MM-dd'
    )}`
  );

  return res.data.data;
}

export async function getDriverReport(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  const res = await api.get(
    `/reports/drivers?dateFrom=${format(
      dateFrom,
      'yyyy-MM-dd'
    )}&dateTo=${format(dateTo, 'yyyy-MM-dd')}`
  );

  return res.data.data;
}

export async function getDriverEmailHistoryQuery() {
  const res = await api.get(
    `/logs?operationType=Email&limit=10&emailTo=zamowienia@pgstrans.pl`
  );

  return res.data.data;
}

export async function getDriverExcel(
  dateFrom: Date | null,
  dateTo: Date | null,
  driverFilter: DriverReportFilters
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  try {
    const res = await api.get(
      `/reports/drivers/excel-waw?dateFrom=${format(
        dateFrom,
        'yyyy-MM-dd'
      )}&dateTo=${format(dateTo, 'yyyy-MM-dd')}&driverFilter=${driverFilter}`,
      { responseType: 'arraybuffer' }
    );
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return blob;
  } catch (error) {
    console.log(error);
  }
}

//send driver report
export async function sendDriverReportMail(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  try {
    const res = await api.get(
      `/reports/drivers/send-mail?dateFrom=${format(
        dateFrom,
        'yyyy-MM-dd'
      )}&dateTo=${format(dateTo, 'yyyy-MM-dd')}`
    );
    return res.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDriverLabels(
  dateFrom: Date | null,
  dateTo: Date | null
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  try {
    const res = await api.get(
      `/reports/labels/drivers?dateFrom=${format(
        dateFrom,
        'yyyy-MM-dd'
      )}&dateTo=${format(dateTo, 'yyyy-MM-dd')}`,
      { responseType: 'arraybuffer' }
    );
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return blob;
  } catch (error) {
    console.log(error);
  }
}

//purchase invoices report
export async function getPurchaseInvoiceReport(
  props: PurchaseInvoiceQueryParams
  // dateFrom: Date | null,
  // dateTo: Date | null,
  // period: ReportPeriod,
  // type: 'all' | PurchaseInvoiceTypeList,
  // isPaid: 'all' | 'true' | 'false'
) {
  if (!props.dateFrom || !props.dateTo)
    throw new Error('Dates are not defined');

  const queryParams = queryString.stringify(props);
  const res = await api.get(`/reports/purchase-invoices?${queryParams}`);
  // const res = await api.get(
  //   `/reports/purchase-invoices?dateFrom=${format(
  //     dateFrom,
  //     'yyyy-MM-dd'
  //   )}&dateTo=${format(
  //     dateTo,
  //     'yyyy-MM-dd'
  //   )}&type=count&period=${period}&type=${type}&isPaid=${isPaid}`
  // );

  return res.data.data;
}

//sales report
export async function getSalesReport(
  dateFrom: Date | null,
  dateTo: Date | null,
  period: ReportPeriod,
  // type: 'all' | PurchaseInvoiceTypeList,
  isPaid: 'all' | 'true' | 'false'
) {
  if (!dateFrom || !dateTo) throw new Error('Dates are not defined');

  const res = await api.get(
    `/reports/sales?dateFrom=${format(dateFrom, 'yyyy-MM-dd')}&dateTo=${format(
      dateTo,
      'yyyy-MM-dd'
    )}&type=count&period=${period}&isPaid=${isPaid}`
  );

  return res.data.data;
}

//delivery day report
export async function getDeliveryDayReport(
  props: DeliveryDayReportQueryParams
) {
  if (!props.dateFrom || !props.dateTo)
    throw new Error('Dates are not defined');

  const queryParams = queryString.stringify(props);
  const res = await api.get(`/reports/delivery-day?${queryParams}`);

  return res.data.data;
}
