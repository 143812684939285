import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = ({ alerts }) => {
  const position = {
    vertical: 'bottom',
    horizontal: 'left',
  };
  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <Snackbar
        open
        autoHideDuration={alert.timeout}
        anchorOrigin={position}
        key={alert.msg}
      >
        <MuiAlert variant='filled' severity={alert.alertType}>
          {alert.msg}
        </MuiAlert>
      </Snackbar>
    ))
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
