import React, { useState } from 'react';
import moment from 'moment';

import MyRow from '../../../helpers/MyRow';
// import Address from '../user/Address';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DayMenu from './DayMenu.tsx';

export default function DayInfo({
  orderId,
  orderItemId,
  day,
  isVisible,
  close,
  openDayChange,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const handleClose = () => {
    close();
  };

  const handleShowMenu = () => {
    setShowMenu(true);
  };
  const handleHideMenu = () => {
    setShowMenu(false);
  };
  // show current day menu for the user
  //  const showCurrentMenu = () => {
  //   const handleDeleteMeal = (mealId) => console.log('delete meal' + mealId);
  //   return (
  //     <Grid container spacing={2}>
  //       {day?.meals.map((m) => (
  //         <Grid>
  //           <Grid item xs={3}>
  //             <Typography variant='body1'>{m.mealLabel}</Typography>
  //           </Grid>
  //           <Grid item xs={8}>
  //             <Typography variant='body1' color='textSecondary'>
  //               {m.mealName}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={1}>
  //             <IconButton onClick={handleDeleteMeal}>
  //               <DeleteForeverIcon />
  //             </IconButton>
  //           </Grid>
  //         </>
  //       ))}
  //     </Grid>
  //   );
  // };

  // <MyRow key={m._id} label={m.mealLabel} data={m.mealName} />

  const showDayInfo = () => {
    const { diet, calories, status, changes, address } = day;
    const { driver, fromTime, toTime, comments, formattedAddress } = address;
    return (
      <>
        <MyRow label='Typ diety' data={diet} />
        <MyRow label='Kaloryczność' data={calories} />
        <MyRow label='Status' data={status} />
        <MyRow label='Zmiany' data={changes ? 'Tak' : 'Nie'} />
        <MyRow
          label='Adres'
          data={formattedAddress}
          // onClick={handleClickAddress}
        />

        <MyRow label='Kierowca' data={driver} />
        {fromTime && <MyRow label='Godziny (od)' data={fromTime} />}
        {toTime && <MyRow label='Godziny (do)' data={toTime} />}
        {comments && <MyRow label='Komentarz(dost.)' data={comments} />}
      </>
    );
  };

  return day ? (
    <div>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {moment(day.deliveredAt).format('DD.MM.YYYY (dddd)')}
        </DialogTitle>
        <div>
          <DialogContent>
            {!showMenu ? (
              showDayInfo()
            ) : (
              <DayMenu
                dayAt={day.deliveredAt}
                orderId={orderId}
                orderItemId={orderItemId}
                dayId={day._id}
                meals={day.meals}
              />
            )}
            {/* {!showMenu ? showDayInfo() : showCurrentMenu()} */}
          </DialogContent>
          <DialogActions>
            {/* Not allowing to change a day that is  already delivered */}
            {day.status !== 'Dostarczony' && (
              <Button onClick={openDayChange} color='primary'>
                Zmień
              </Button>
            )}
            {!showMenu ? (
              <Button onClick={handleShowMenu} color='primary' autoFocus>
                Menu
              </Button>
            ) : (
              <Button onClick={handleHideMenu} color='primary' autoFocus>
                Info
              </Button>
            )}
            <Button onClick={handleClose} color='primary' autoFocus>
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {/* <Address
          address={selectedAddr}
          handleClose={handleCloseAddressView}
          isVisible={isAddressVisible}
        /> */}
    </div>
  ) : (
    'Poczekaj chwile...'
  );
}
