import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueSetterParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowsProp,
  GridRowModesModel,
  GridActionsCellItem,
  GridRowParams,
  GridEventListener,
  GridCellParams,
} from '@mui/x-data-grid';
import { Meal } from '../../../../../FitAPI/src/models/MealModel';
import { MealIngredientPopulated } from '../../../../../FitAPI/src/models/MealModel/mealIngredient';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { MuiEvent } from '@mui/x-data-grid';
import { GridRowModel } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { WithId } from '../../../types';
import { Ingredient } from '../../../../../FitAPI/src/models/IngredientModel';
import IngredientSelectField from './IngredientSelect';
import EditToolbar from './EditToolbar';
import { createNutritionColumns, createSizeColumn } from './helpers';
import { GridCallbackDetails } from '@mui/x-data-grid';

interface Props {
  mealId: string;
  ingredients: GridRowsProp<MealIngredientPopulated & { isNew?: boolean }>;
  allIngrdients: AllOptions[];
  handleMealUpdate: (mealId: string, objectBody: Partial<Meal>) => void;
}

const Ingredients = (props: Props) => {
  const { mealId, ingredients, handleMealUpdate } = props;
  const [rows, setRows] = React.useState(ingredients);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  useEffect(() => {
    setRows(ingredients);
  }, [ingredients]);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const newRows = rows.filter((row) => row._id !== id);
    setRows(newRows);
    handleMealUpdate(mealId, {
      ingredients: newRows.map((row) => ({
        ingredient: row.ingredient._id,
        ingredientName: row.ingredient.name,
        sizes: row.sizes,
      })),
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row._id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel<MealIngredientPopulated>) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) =>
      row._id === newRow._id ? updatedRow : row
    );
    handleMealUpdate(mealId, {
      ingredients: updatedRows.map((row) => ({
        ingredient: row.ingredient._id,
        ingredientName: row.ingredient.name,
        sizes: row.sizes,
      })),
    });
    setRows(updatedRows);
    return updatedRow;
  };

  const handleCellKeyDown = (
    params: GridCellParams,
    event: MuiEvent<React.KeyboardEvent>,
    details: GridCallbackDetails
  ) => {
    if (event.key === 'Enter' && params.field !== 'name') {
      event.preventDefault();
      event.stopPropagation();
      const row = processRowUpdate(params.row);
      setRowModesModel({
        ...rowModesModel,
        [row._id]: { mode: GridRowModes.View },
      });
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nazwa',
      valueGetter: (params: GridRenderCellParams<MealIngredientPopulated>) => ({
        name: params.row.ingredient.name,
        _id: params.row.ingredient._id,
      }),
      valueSetter: (params: GridValueSetterParams<MealIngredientPopulated>) => {
        return {
          ...params.row,
          ingredient: {
            // ...params.row.recipe,
            name: params.value.name,
            _id: params.value._id,
          },
          // recipe: params.value,
        };
      },

      renderCell: (
        params: GridRenderCellParams<{ ingredient: WithId<Ingredient> }>
      ) => (
        // renderCell: (params: GridRenderCellParams<RecipeWithId>) =>
        <Link
          to={`/ingredients?q=${params.row.ingredient.name}`}
          target='_blank'
        >
          {params.row.ingredient.name}
        </Link>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <IngredientSelectField
          {...params}
          allIngredients={props.allIngrdients} //TODO
        />
      ),

      width: 400,
      editable: true,
    },
    {
      field: 'empty', //matching with recipe columns
      headerName: ' ',
      width: 100,
    },

    //sizes
    createSizeColumn('S'),
    createSizeColumn('M'),
    createSizeColumn('L'),
    createSizeColumn('XL'),

    //nutrition
    createNutritionColumns('kcal', 'kcal'),
    createNutritionColumns('protein', 'B.'),
    createNutritionColumns('carbs', 'W.'),
    createNutritionColumns('fat', 'T.'),
    createNutritionColumns('carbFiber', 'Bł.'),
    createNutritionColumns('sodium', 'Sód'),
    {
      field: 'actions',
      headerName: 'Akcje',
      type: 'actions',
      getActions: (params) => {
        const { id } = params;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },

      // renderCell: (params: GridRenderCellParams<MealRecipePopulated>) => (
      //   <RecipeActions {...{ params, rowId, setRowId }} />
      // ),
    },
  ];

  return (
    <Box
      sx={
        {
          // height: 400,
          // width: '100%',
        }
      }
    >
      <Typography
        variant='h6'
        component='h3'
        sx={{ textAlign: 'left', mt: 3, mb: 1 }}
      >
        Składniki
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row._id}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onCellKeyDown={handleCellKeyDown}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        // autoPageSize
        autoHeight
        // sx={{
        //   [`& .${gridClasses.row}`]: {
        //     bgcolor: grey[50],
        //   },
        // }}
        //  rowsPerPageOptions={[5, 10, 15]}
        // onCellClick={(params) => setRowId(params.id)}
      />
    </Box>
  );
};

export default Ingredients;
