import React from 'react';
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { blue, grey } from '@mui/material/colors';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { getSalesReport } from '../../utils/api/reportApi';
import { ReportPeriod } from '../../types/reportType';

type Props = {
  dayFrom: Date | null;
  dayTo: Date | null;
  period: ReportPeriod;
};

const SalesChart = (props: Props) => {
  const { dayFrom, dayTo, period } = props;
  // const [type, setType] = React.useState<'all' | PurchaseInvoiceTypeList>(
  //   'all'
  // );
  const [isPaid, setIsPaid] = React.useState<'all' | 'true' | 'false'>('all');

  const { data, isLoading } = useQuery({
    queryKey: [
      'getSalesReportData',
      dayFrom,
      dayTo,
      period,
      // type,
      isPaid,
    ],
    queryFn: () =>
      getSalesReport(
        dayFrom,
        dayTo,
        // new Date('2023-09-01'),
        // new Date(),
        period,
        // type,
        isPaid
      ),
  });
  // const handleTypeChange = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newType: 'all' | PurchaseInvoiceTypeList
  // ) => {
  //   setType(newType);
  // };

  const handleisPaidChange = (
    event: React.MouseEvent<HTMLElement>,
    newPaidStatus: 'all' | 'true' | 'false'
  ) => {
    setIsPaid(newPaidStatus);
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <section>
      <Typography variant='h5' mt={4} mb={1}>
        Przychody netto (Bez anulowanych)
      </Typography>
      <Box my={3}>
        {/* <ToggleButtonGroup
          sx={{ ml: 2 }}
          value={type}
          exclusive
          onChange={handleTypeChange}
          aria-label='text alignment'
        >
          <ToggleButton value='all' aria-label='all'>
            Wszystko
          </ToggleButton>
          <ToggleButton value='Spożywka' aria-label='food'>
            Spożywka
          </ToggleButton>
          <ToggleButton value='Transport' aria-label='logistics'>
            Transport
          </ToggleButton>
        </ToggleButtonGroup> */}
        <ToggleButtonGroup
          sx={{ ml: 2 }}
          value={isPaid}
          exclusive
          onChange={handleisPaidChange}
          aria-label='paid status'
        >
          <ToggleButton value='all' aria-label='all'>
            Wszystko
          </ToggleButton>
          <ToggleButton value='true' aria-label='paid'>
            Opłacone
          </ToggleButton>
          <ToggleButton value='false' aria-label='unpaid'>
            Nieopłacone
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {data ? (
        <ResponsiveContainer width='100%' height={400}>
          <BarChart
            width={150}
            height={40}
            data={data?.data} //.map((i) => ({ name: i._id }))}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='_id'
              tickFormatter={(val) => format(new Date(val), 'd.MM')}
            />
            <YAxis yAxisId='left' orientation='left' stroke={grey[600]} />

            <Tooltip />
            <Bar
              yAxisId='left'
              dataKey='totalNetAmount'
              fill={blue[400]}
              label
            />
          </BarChart>
        </ResponsiveContainer>
      ) : null}
    </section>
  );
};

export default SalesChart;
