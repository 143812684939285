import React from 'react';
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { blue, grey } from '@mui/material/colors';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { getDeliveryDayReport } from '../../utils/api/reportApi';
import { ReportPeriod } from '../../types/reportType';
import { DeliveryDayReportStatus } from '../../../../FitAPI/src/types/reports/ReportQueryParams';

type Props = {
  dayFrom: Date | null;
  dayTo: Date | null;
  period: ReportPeriod;
};

const DeliveryDayChart = (props: Props) => {
  const { dayFrom, dayTo, period } = props;
  const [status, setStatus] =
    React.useState<DeliveryDayReportStatus['status']>('all');

  const { data, isLoading } = useQuery({
    queryKey: ['getDeliveryDayReportData', dayFrom, dayTo, period, status],
    queryFn: () =>
      getDeliveryDayReport({
        dateFrom: dayFrom ? format(dayFrom, 'yyyy-MM-dd') : undefined!,
        dateTo: dayTo ? format(dayTo, 'yyyy-MM-dd') : undefined!,
        period,
        status,
      }),
  });
  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: DeliveryDayReportStatus['status']
  ) => {
    setStatus(newStatus);
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <section>
      <Typography variant='h5' mt={4} mb={1}>
        Dostawy dni
      </Typography>
      <Box my={3}>
        <ToggleButtonGroup
          sx={{ ml: 2 }}
          value={status}
          exclusive
          onChange={handleStatusChange}
          aria-label='paid status'
        >
          <ToggleButton value='all' aria-label='all'>
            Wszystko
          </ToggleButton>
          <ToggleButton value='Dostarczony' aria-label='delivered'>
            Dostarczone
          </ToggleButton>
          <ToggleButton value='Planowany' aria-label='planned'>
            Planowany
          </ToggleButton>
          <ToggleButton value='Płatność' aria-label='unpaid'>
            Nieopłacone
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {data ? (
        <ResponsiveContainer width='100%' height={400}>
          <BarChart
            width={150}
            height={40}
            data={data?.data} //.map((i) => ({ name: i._id }))}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='_id'
              tickFormatter={(val) => format(new Date(val), 'd.MM')}
            />
            <YAxis yAxisId='left' orientation='left' stroke={grey[600]} />

            <Tooltip
              labelFormatter={(value) => format(new Date(value), 'd.MM.yyyy')}
            />
            <Bar yAxisId='left' dataKey='count' fill={blue[400]} label />
          </BarChart>
        </ResponsiveContainer>
      ) : null}
    </section>
  );
};

export default DeliveryDayChart;
