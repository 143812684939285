import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import api from '../utils/api';
import Layout from '../components/layout/Layout';
import { getMealTags, listOptions } from '../actions/optionActions';
import { cloneMeal } from '../actions/mealActions';

import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { Meal } from '../../../FitAPI/src/models/MealModel';
import { deleteOneMeal } from '../utils/api/mealApi';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { WithId } from '../types';

const RecipesListScreen = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParam, setSearchParam] = useSearchParams({ name: '' });

  // let navigate = useNavigate();
  const mealsRef = React.createRef<any>();

  //get  list of meal types from DB to use as filter
  const dispatch: any = useDispatch();
  const optionState = useSelector((state: any) => state.optionList);
  const loadingMeal = useSelector((state: any) => state.mealDetails.loading);

  let mealOptions = [];
  let lookupMeals = {};
  if (optionState.options.length > 0) {
    mealOptions = optionState.options.find(
      (o: any) => o.name === 'mealOptions'
    ).options;
    //convert arr to obj e.g {'Śniadanie': Śniadanie, ...}
    lookupMeals = mealOptions?.reduce(
      (acc: any, curr: any, i: any) => ({ ...acc, [curr.label]: curr.label }),
      {}
    );
  }

  const { tagsProduction, tagsUser } = useSelector(
    (state: any) => state.optionList
  );
  const lookupTagsProduction = tagsProduction?.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [curr]: curr,
    }),
    {}
  );
  const lookupTagsUser = tagsUser?.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [curr]: curr,
    }),
    {}
  );

  useEffect(() => {
    dispatch(listOptions());
    dispatch(getMealTags());
  }, [dispatch]);

  const deleteMealMutation = useMutation({
    mutationFn: (mealId: string) => deleteOneMeal({ id: mealId }),
    onSuccess: () => {
      mealsRef.current.onQueryChange();
      enqueueSnackbar('Usunięto posiłek', { variant: 'success' });
    },
  });

  if (deleteMealMutation.isError)
    if (deleteMealMutation.error instanceof AxiosError) {
      enqueueSnackbar(deleteMealMutation.error.response?.data.error, {
        variant: 'error',
      });
    }

  useEffect(() => {
    const searchTerm = searchParam.get('name');
    if (!searchTerm || searchTerm.length > 2) mealsRef.current.onQueryChange();
  }, [mealsRef, searchParam]);

  // interface Meal extends MealType {
  //   _id: string;
  // }
  const structure: Array<Column<WithId<Meal>>> = [
    {
      title: 'Nazwa',
      field: 'name',
      editable: 'always',
      render: (rowData) => (
        <Link to={`/meals/${rowData._id}`}>{rowData.name}</Link>
      ),
      filtering: false,
    },
    {
      title: 'Typ posiłku',
      field: 'mealType',
      editable: 'never',
      lookup: lookupMeals,
    },
    {
      title: 'Tagi produkcja',
      field: 'tagsProduction',
      render: (rowData) => rowData.tagsProduction.join(', '),
      editable: 'never',
      lookup: lookupTagsProduction,
    },
    {
      title: 'Tagi użytkownik',
      field: 'tagsUser',
      render: (rowData) => rowData.tagsUser.join(', '),
      editable: 'never',
      lookup: lookupTagsUser,
    },
  ];

  return (
    <Layout>
      <MaterialTable
        title='Tacki'
        tableRef={mealsRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            let sort = 'name';
            if (query.orderBy) {
              sort =
                query.orderDirection === 'asc'
                  ? (query.orderBy.field as string)
                  : (`-${query.orderBy.field}` as string);
            }

            const filtersAll = query.filters.reduce((filterObj, item) => {
              const filterField = item.column.field as string;
              const filterValue = item.value;
              filterObj = { ...filterObj, [filterField]: filterValue };
              return filterObj;
            }, {});

            const queryStrAPI = queryString.stringify(
              {
                select: ['name', 'mealType', 'tagsProduction', 'tagsUser'],
                page: query.page + 1,
                pageSize: query.pageSize,
                sort: sort,
                name: searchParam.get('name'),
                ...filtersAll,
              },
              { arrayFormat: 'comma' }
            );
            let url = `/meals?${queryStrAPI}`;
            api.get(url).then((result) => {
              resolve({
                data: result.data.meals,
                page: result.data.page - 1,
                totalCount: result.data.count,
              });
            });
          })
        }
        columns={structure}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 25, 50],
          sorting: true,
          search: false,
          filtering: true,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              api.post('/meals/', { ...newData }).then((result) => {
                resolve();
              });
            }),
          onRowUpdate: (newData, oldData) => {
            return new Promise<void>((resolve, reject) => {
              if (oldData)
                api
                  .put(`/meals/${oldData._id}`, { ...newData })
                  .then((result) => {
                    resolve();
                  });
            });
          },
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <TextField
                  fullWidth
                  autoFocus
                  // variant='outlined'
                  onChange={(e) => setSearchParam({ name: e.target.value })}
                  value={searchParam.get('name')}
                  placeholder='Wpisz pierwsze 3 litery, aby rozpocząć wyszukiwanie'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          onClick={(e) => setSearchParam({ name: '' })}
                          size='large'
                        >
                          <Cancel />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  Szukaj
                </TextField>
              </div>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'library_add',
            tooltip: 'Klonowanie',
            onClick: (event, rowData) => {
              if (Array.isArray(rowData)) return;
              dispatch(cloneMeal(rowData._id));
              const table = mealsRef.current;
              if (!loadingMeal) table.onQueryChange();
            },
          },
          {
            icon: 'delete',
            tooltip: 'Kasowanie',
            onClick: (event, rowData) => {
              if (Array.isArray(rowData)) return;
              deleteMealMutation.mutate(rowData._id);
            },
          },
        ]}
      />
    </Layout>
  );
};

export default RecipesListScreen;
