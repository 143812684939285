import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../utils/api';
import Layout from '../components/layout/Layout';

import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Ingredient } from '../../../FitAPI/src/models/IngredientModel';
import { WithId } from '../types';

const IngredientListScreen = () => {
  const [newSearch, setNewSearch] = useSearchParams({ q: '' });

  const ingredientsRef = React.createRef<any>();
  // let ingrCategoryTypes: string[];
  let ingrSupplierTypes: string[];

  useEffect(() => {
    // navigate.push(`/ingredients?q=${newSearch}`);
    const searchTerm = newSearch.get('q');
    if (!searchTerm || searchTerm.length > 2)
      ingredientsRef.current.onQueryChange();
  }, [newSearch]);

  // interface Ingredient extends Ingredient {
  //   _id: string;
  // }

  const structure: Array<Column<WithId<Ingredient>>> = [
    {
      title: 'Nazwa',
      field: 'name',
    },
    { title: 'kcal [g]', field: 'kcal' },
    { title: 'Białko [g]', field: 'protein' },
    {
      title: 'Węgle [g]',
      field: 'carbs',
      render: (rowData) => rowData.carbs?.toFixed(1),
    },
    { title: 'Tłuszcze [g]', field: 'fat' },
    {
      title: 'Węgl.przys. [g]',
      field: 'availableCarbs',
    },
    {
      title: 'Błonnik [g]',
      field: 'carbFiber',
    },
    { title: 'Sód [mg]', field: 'sodium' },
    { title: 'Odpad', field: 'waste' },

    // {
    //   title: 'Kategoria',
    //   field: 'category',
    //   editComponent: (props) => (
    //     <FormControl style={{ width: '100%' }}>
    //       <Select
    //         labelId='category-select-label'
    //         id='category-select'
    //         value={props.rowData.category}
    //         onChange={(e) => props.onChange(e.target.value)}
    //       >
    //         {ingrCategoryTypes.map((iCat) => (
    //           <MenuItem value={iCat}>{iCat}</MenuItem>
    //         ))}
    //       </Select>
    //     </FormControl>
    //   ),
    // },
    {
      title: 'Dostawca',
      field: 'supplier',
      editComponent: (props) => (
        <FormControl style={{ width: '100%' }}>
          <Select
            labelId='supplier-select-label'
            id='supplier-select'
            value={props.rowData.supplier}
            onChange={(e) => props.onChange(e.target.value)}
          >
            {ingrSupplierTypes.map((iSup) => (
              <MenuItem value={iSup}>{iSup}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
  ];

  return (
    <Layout>
      <MaterialTable
        title='Składniki'
        tableRef={ingredientsRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            let sort = '-id';
            // let filterQuery = '';
            if (query.orderBy) {
              sort =
                query.orderDirection === 'asc'
                  ? (query.orderBy.field as string)
                  : (`-${query.orderBy.field}` as string);
            }
            // query.filters.forEach((f) => {
            //   filterQuery = filterQuery + `&${f.column.field}=${f.value}`;
            // });

            let url = `/ingredients?page=${query.page + 1}&pageSize=${
              query.pageSize
            }&sort=${sort}&q=${newSearch.get('q')}`;
            api.get(url).then((result) => {
              // ingrCategoryTypes = result.data.ingrCategoryTypes;
              ingrSupplierTypes = result.data.ingrSupplierTypes;
              resolve({
                data: result.data.ingredients,
                page: result.data.page - 1,
                totalCount: result.data.count,
              });
            });
          })
        }
        columns={structure}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 25, 50],
          sorting: true,
          search: false,
          // filtering: true,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              api.post('/ingredients/', { ...newData }).then((result) => {
                resolve();
              });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise<void>((resolve, reject) => {
              api
                .put(`/ingredients/${oldData?._id}`, { ...newData })
                .then((result) => {
                  resolve();
                });
            }),
        }}
        actions={[
          // if there is no idFakturownia then add button is enabled
          (rowData) => {
            return !rowData.idFakturownia
              ? {
                  icon: 'add',
                  tooltip: 'Dodaj do fakturowni',
                  onClick: (event, rowData) => {
                    if (!Array.isArray(rowData)) {
                      new Promise<void>((resolve, reject) => {
                        api
                          .put(`/ingredients/${rowData._id}/warehouse`)
                          .then((result) => {
                            resolve();
                            ingredientsRef.current &&
                              ingredientsRef.current.onQueryChange();
                          });
                      });
                    }
                  },
                }
              : {
                  icon: 'check',
                  tooltip: 'Produkt dodany do fakturowni',
                  disabled: true,
                  onClick(event, data) {
                    return;
                  },
                };
          },
        ]}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <TextField
                  fullWidth
                  autoFocus
                  // variant='outlined'
                  onChange={(e) => setNewSearch({ q: e.target.value })}
                  value={newSearch.get('q')}
                  placeholder='Wpisz pierwsze 3 litery, aby rozpocząć wyszukiwanie'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          onClick={(e) => setNewSearch('')}
                          size='large'
                        >
                          <Cancel />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  Szukaj
                </TextField>
              </div>
            </div>
          ),
        }}
      />
    </Layout>
  );
};

export default IngredientListScreen;
