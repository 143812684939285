import React from 'react';
import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

type Props = {
  dateFrom: Date | null;
  dateTo: Date | null;
  handleDateFromChange: (date: Date | null) => void;
  handleDateToChange: (date: Date | null) => void;
};

const DateSelector = (props: Props) => {
  const { dateFrom, dateTo, handleDateFromChange, handleDateToChange } = props;

  return (
    <div>
      <Typography variant='h5' component='h1' align='left' sx={{ mb: 3 }}>
        Raporty produkcji
      </Typography>
      <Typography variant='body1' component='h2' align='left'>
        Wybierz daty raportu
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 6 }}>
        <DatePicker
          label='Raport od'
          value={dateFrom}
          onChange={handleDateFromChange}
        />

        <DatePicker
          label='Raport do'
          value={dateTo}
          onChange={handleDateToChange}
          // renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </div>
  );
};

export default DateSelector;
