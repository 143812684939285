import React from 'react';
import Layout from '../components/layout/Layout';
import DriversReport from '../components/reportsAdmin/DriversReport';
import DriversShortReport from '../components/reportsAdmin/DriversShortReport';
import SalesReport from '../components/reportsAdmin/SalesReport';
import PaymentsReport from '../components/reportsAdmin/PaymentReport';
import SalesMonthlyReport from '../components/reportsAdmin/SalesMonthlyReport';

import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ReportAdminScreen = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <div className={classes.root}>
        <AppBar position='static'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
          >
            <Tab label='Sprzedaz' {...a11yProps(0)} />
            <Tab label='Sprzedaz Podsumowanie' {...a11yProps(1)} />
            <Tab label='Płatności' {...a11yProps(2)} />
            <Tab label='Kierowcy NEW' {...a11yProps(3)} />
            <Tab label='Kierowcy' {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SalesReport />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SalesMonthlyReport />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PaymentsReport />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DriversShortReport />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <DriversReport />
        </TabPanel>
      </div>
    </Layout>
  );
};

export default ReportAdminScreen;
