//Common UI for Table used with react table

import React from 'react';
import { useTable } from 'react-table';

import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cell: {
    minWidth: 50,
    maxWidth: 50,
    width: 50,
  },
});
const Table = ({ columns, data }) => {
  const classes = useStyles();
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow, footerGroups } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                {...column.getHeaderProps()}
                classes={{ root: classes.cell }}
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}

        {footerGroups.map((group) => (
          <TableRow {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <TableCell {...column.getFooterProps()}>
                {column.render('Footer')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MaUTable>
  );
};

export default Table;
