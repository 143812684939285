import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { SummaryData } from '../../../../../FitAPI/src/types/reports/summaryReport';
import SummaryInfo from '../SummaryInfo';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  handleClose: () => void;
  summaryData: SummaryData;
};

const ConfirmDownloadDialog = (props: Props) => {
  const { isOpen, onConfirm, handleClose, summaryData } = props;
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Potwierdź wysłanie maila</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <Typography variant='body1' align='center'>
              Czy jesteś pewny, że chesz wysłać maila z następującymi danymi
            </Typography>
            <Box mt={2} />
            <SummaryInfo summaryData={summaryData} type='driverReport' />

            {/* <Typography variant='body1'>
              {`${summaryData?.from} - ${summaryData?.to}`}
            </Typography> */}
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            gap={3}
            mt={4}
          >
            <Button variant='contained' onClick={handleConfirm}>
              Wyślij
            </Button>
            <Button onClick={handleClose}>Zaniechaj</Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDownloadDialog;
