import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Row from './Row';
import { DayWithMeals } from '../../../../FitAPI/src/utils/order/getOrderMealsDetails';
import { ProductType } from '../../../../FitAPI/src/models/ProductModel';
import { DietMenuSchemaPopulated } from '../../../../FitAPI/src/models/MenuModel';
import { UseMutationResult } from '@tanstack/react-query';
import { UpdateMealProps } from '../../utils/api/orderMealApi';

const useTableStyles = makeStyles({
  container: {
    maxHeight: '100vh',
  },
  errorText: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
});

interface Props {
  days: DayWithMeals[];
  products: ProductType[];
  dietMenus: DietMenuSchemaPopulated[];
  isLoading: boolean;
  isDayRefetching: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mealMutation: UseMutationResult<void, unknown, UpdateMealProps, unknown>;
}

export default function DayTable({
  days,
  products,
  dietMenus,
  isLoading,
  isDayRefetching,
  mealMutation,
  setIsLoading,
}: Props) {
  const classes = useTableStyles();
  if (isLoading) {
    setIsLoading(false);
    return null;
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align='left'>Nr zam.</TableCell>
            <TableCell align='left'>Imię</TableCell>
            <TableCell align='left'>Nazwisko</TableCell>
            <TableCell align='left'>Dieta</TableCell>
            <TableCell align='left'>Wyjątki</TableCell>
            <TableCell align='left'>Status</TableCell>
            {/* <TableCell align='left'>Płatność</TableCell> */}
            <TableCell align='left'>Mod.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {days.map((row) => (
            <Row
              key={row.dayId}
              row={row}
              products={products}
              dietMenus={dietMenus}
              isDayRefetching={isDayRefetching}
              mealMutation={mealMutation}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
