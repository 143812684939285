import { Box } from '@mui/system';
import React from 'react';
import { Meal } from '../../../../../FitAPI/src/models/MealModel';
import TagSelect from './TagSelect';
import {
  allTagsProduction,
  allTagsUser,
  TagProduction,
  TagUser,
} from './types';

interface Props {
  mealId: string;
  tagNameProduction: TagProduction[];
  tagNameUser: TagUser[];
  handleMealUpdate: (mealId: string, meal: Partial<Meal>) => void;
}

export default function MealTags(props: Props) {
  return (
    <div>
      {/* Tags production */}
      <TagSelect
        type='production'
        mealId={props.mealId}
        label='Tagi produkcja'
        inputValue={props.tagNameProduction}
        tagArr={Array.from(allTagsProduction) as TagProduction[]}
        handleMealUpdate={props.handleMealUpdate}
      />
      {/* Tags user */}
      <Box mt={6} />
      <TagSelect
        type='user'
        mealId={props.mealId}
        label='Tagi użytkownika'
        inputValue={props.tagNameUser}
        tagArr={Array.from(allTagsUser) as TagUser[]}
        handleMealUpdate={props.handleMealUpdate}
      />
    </div>
  );
}
