import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { myDate } from '../helpers/helpers';

import { useNavigate, useParams } from 'react-router-dom';
import {
  getUserById,
  deleteAddress,
  addContact,
  editContact,
} from '../actions/userActions';
import { getUserContactOptions } from '../actions/optionActions';
// import { getOrdersByUserId } from '../actions/orderActions';
import { USER_DETAILS_CLEAR_ERROR } from '../constants/userConstants';
import UserOrders from '../components/user/UserOrders';
import Layout from '../components/layout/Layout';
import Loader from '../components/layout/Loader';
import MyBox from '../helpers/MyBox';
import MyRow from '../helpers/MyRow';
import Address from '../components/user/Address/Address';
import AddressUpdate from '../components/user/Address/AddressUpdate';
import UserProfileUpdate from '../components/user/UserProfileUpdate';
import UserContacts from '../components/user/UserContacts';

import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import EditIcon from '@mui/icons-material/Edit';
import Message from '../components/layout/Message';
import { useAppDispatch, useAppSelector } from '../hooks';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

// const UserScreen = ({ match }) => {
const UserScreen = () => {
  const classes = useStyles();
  // const { id } = match.params;
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const {
    user,
    loading: loadingUser,
    error: errorUser,
  } = useAppSelector((state) => state.userDetails);

  const { userContactOptions } = useAppSelector((state) => state.optionList);

  const [isAddressVisible, setIsAddressVisible] = useState(false);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [selectedAddr, setSelectedAddr] = useState();
  const [isProfileUpdateVisible, setIsProfileUpdateVisible] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserById(id));
    dispatch(getUserContactOptions());
    // dispatch(getOrdersByUserId(id));
  }, [dispatch, id]);

  const handleClickNewOrder = () => {
    navigate(`/orders/new?userId=${id}`);
  };

  // Row data wwith
  const addrRowData = (address) => {
    const handleAddressView = (address) => {
      setSelectedAddr(address);
      setIsAddressVisible(true);
    };
    const handleClickDeleteAddress = (addressId) => {
      dispatch(deleteAddress(addressId));
    };
    const handleEdit = (address) => {
      // dispatch({ type: USER_SELECT_ADDRESS, payload: address });
      // setIsAddAddress(true);
      setSelectedAddr(address);
      setIsAddressUpdate(true);
    };
    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            {' '}
            {address.formattedAddress}
          </Grid>
          <Grid item xs={6}>
            {/* <div style={{ float: 'right' }}> */}
            <IconButton onClick={() => handleAddressView(address)}>
              <MoreHorizIcon />
            </IconButton>
            <IconButton onClick={() => handleEdit(address)}>
              <EditIcon />
            </IconButton>
            {user.addresses.length > 1 && (
              <IconButton onClick={() => handleClickDeleteAddress(address._id)}>
                <DeleteIcon />
              </IconButton>
            )}
            {/* </div> */}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Layout>
      <Container>
        {loadingUser && !user ? (
          <Loader />
        ) : (
          user._id && (
            <div>
              {errorUser && (
                <Message
                  message={`${errorUser.msg} (${errorUser.status})`}
                  handleClose={() =>
                    dispatch({
                      type: USER_DETAILS_CLEAR_ERROR,
                    })
                  }
                />
              )}
              <Typography align='center' className={classes.title} variant='h5'>
                {user.fName} {user.sName}
              </Typography>
              <Typography align='center' variant='h6'>
                ID: {user.no}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {/* User  Profile */}
                  <MyBox title='Dane' avatar={<ContactPhoneIcon />}>
                    <MyRow
                      label='Imię i nazwisko'
                      data={`${user.fName} ${user.sName}`}
                    />
                    <MyRow label='Email' data={user.email} />
                    <MyRow label='Telefon' data={user.phone} />
                    {/* <MyRow
                      label='Płeć'
                      // data={user.gender === 'female' ? 'Kobieta' : 'Męzczyzna'}
                      data={user.gender}
                    /> */}
                    {user.foodExclusions[0] && (
                      <MyRow
                        label='Wyjątki'
                        data={user.foodExclusions.map(
                          (food, i) => `${i > 0 ? ', ' : ''}${food.name}`
                        )}
                      />
                    )}
                    {user.otherFoodExclusions && (
                      <MyRow
                        label='Inne wyjątki'
                        data={user.otherFoodExclusions}
                      />
                    )}
                    {user.comments && (
                      <MyRow label='Uwagi' data={user.comments} />
                    )}
                    <IconButton
                      style={{ float: 'right' }}
                      onClick={() => setIsProfileUpdateVisible(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </MyBox>
                  {/* Dane adresowe */}
                  <MyBox title='Adresy' avatar={<BusinessIcon />}>
                    {user.addresses.map((address, i) => (
                      <MyRow
                        key={address._id}
                        label={`Adres ${i + 1}`}
                        data={addrRowData(address)}
                      />
                    ))}
                    <div className={classes.add_button}>
                      <IconButton
                        style={{ float: 'right' }}
                        onClick={() => setIsAddAddress(true)}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  </MyBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MyBox title='Podsumowanie' avatar={<LabelImportantIcon />}>
                    <MyRow label='Liczba zamówień' data={user.nrOrders} />
                    <MyRow label='Liczba dni' data={user.nrDays} />
                    <MyRow
                      label='Ostatnia dostawa'
                      data={new Date(user.lastDeliveryAt).toLocaleDateString()}
                      // data={myDate(user.lastDeliveryAt)}
                    />
                    <MyRow
                      label='Klient od'
                      data={moment(user.createdAt).fromNow()}
                    />
                    <MyRow label='Dni próbne' data={user.nrTrialDays} />
                    <MyRow
                      label='Data dodania'
                      data={myDate(user.dateCreated)}
                    />
                    <MyRow
                      label='Importowany'
                      data={user.isImported ? 'Tak' : 'Nie'}
                    />
                  </MyBox>
                </Grid>
                <Grid item xs={12}>
                  <UserOrders id={id} />
                  {user.contacts && (
                    <UserContacts
                      contacts={user.contacts}
                      contactOption={userContactOptions}
                      addContact={(newData) => dispatch(addContact(newData))}
                      editContact={(newData, oldData) =>
                        dispatch(editContact(newData, oldData))
                      }
                    />
                  )}
                  <div className={classes.buttons}>
                    {user?.addresses.length > 0 ? (
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={handleClickNewOrder}
                      >
                        Nowe zamówienie
                      </Button>
                    ) : (
                      <Typography color='secondary'>
                        Brak mozliwości dodania nowego zamówienia - najpierw
                        dodaj adres !
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          )
        )}
        {/* AddressUpdate is responsible for adding and updating address */}
        {isAddAddress && (
          <AddressUpdate
            address={{}}
            handleClose={() => setIsAddAddress(false)}
            isVisible={setIsAddAddress}
            isNewAddress
          />
        )}
        {isAddressUpdate && (
          <AddressUpdate
            address={selectedAddr}
            handleClose={() => setIsAddressUpdate(false)}
            isVisible={setIsAddressUpdate}
          />
        )}
        {isAddressVisible && (
          <Address
            address={selectedAddr}
            handleClose={() => setIsAddressVisible(false)}
            isVisible={isAddressVisible}
          />
        )}
        {isProfileUpdateVisible && (
          <UserProfileUpdate
            isVisible={isProfileUpdateVisible}
            handleClose={setIsProfileUpdateVisible}
          />
        )}
      </Container>
    </Layout>
  );
};
export default UserScreen;
