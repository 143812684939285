import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const PaymentStatus = ({ status = false }) => {
  if (status) {
    return <CheckIcon color='primary' />;
  } else {
    return <CancelIcon color='secondary' />;
  }
};

export default PaymentStatus;
