//used in meal day module

import { DeliveryDayMeal } from '../../../../FitAPI/src/api/deliveryDay/deliveryDayMeal/deliveryDayMeal.model';
import api from '../api';

export async function getDayMeals(
  orderId: string,
  orderItemId: string,
  dayId: string
) {
  const data = await api.get(
    `/orders/${orderId}/order-items/${orderItemId}/delivery-days/${dayId}/meals`
  );
  return data.data;
}

// Add meal to given user
export async function addMealOrder(
  orderId: string,
  orderItemId: string,
  dayId: string,
  dietName: string,
  calories: number,
  mealType: string,
  mealCount: number
) {
  await api.post(
    `/orders/${orderId}/order-items/${orderItemId}/delivery-days/${dayId}/meals`,
    {
      dietName,
      calories,
      mealType,
      mealCount,
    }
  );
}

// Update meal of given user on given day

export interface UpdateMealProps {
  orderId: string;
  orderItemId: string;
  dayId: string;
  mealId: string;
  updatedMeal: DeliveryDayMeal;
}

export async function updateMealOrder(props: UpdateMealProps) {
  const { orderId, orderItemId, dayId, mealId, updatedMeal } = props;
  await api.put(
    `/orders/${orderId}/order-items/${orderItemId}/delivery-days/${dayId}/meals/${mealId}`,
    { updatedMeal }
  );
}

export async function deleteMealOrder(
  orderId: string,
  orderItemId: string,
  dayId: string,
  mealId: string
) {
  await api.delete(
    `/orders/${orderId}/order-items/${orderItemId}/delivery-days/${dayId}/meals/${mealId}`
  );
}
