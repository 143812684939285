//function used to check deviation in nutrition info vs norm
//accepts numbers
// returns none | small || large

import { LARGE_ERROR_FROM, SMALL_ERROR_FROM } from '../../config';

const calculateDeviation = (value, norm) => {
  let error = 'none';
  const dev = Math.abs((value - norm) / norm);

  if (dev > SMALL_ERROR_FROM) error = 'small';
  if (dev > LARGE_ERROR_FROM) error = 'large';
  return error;
};

export default calculateDeviation;
