import React from 'react';
import api from '../utils/api';
import PaymentStatus from '../components/layout/PaymentStatus';

import MaterialTable, { Column } from '@material-table/core';
// import moment from 'moment';
import { format, isValid } from 'date-fns';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { OrderType } from '../../../FitAPI/src/models/OrderModel';

const OrderListScreen = () => {
  interface Order extends OrderType {
    _id: string;
  }
  const structure: Array<Column<Order>> = [
    {
      title: 'Nr',
      field: 'no',
      render: (rowData) => <Link to={`${rowData._id}`}>{rowData.no}</Link>,
    },
    // { title: 'nr W', field: 'idWooCommerce', filters: true },
    {
      title: 'Źródło',
      field: 'orderSource',
      // filters: true,
      filtering: true,
      lookup: {
        Strona: 'Strona',
        Email: 'Email',
        Telefon: 'Telefon',
        Inne: 'Inne',
      },
    },

    {
      title: 'Data zam.',
      field: 'createdAt',
      render: (rowData) => {
        const date = rowData.orderDate || rowData.createdAt;
        return format(new Date(date), 'dd.MM.yy HH:mm');
      },
      filtering: false,
      // customFilterAndSearch: (date, rowData) => date == rowData.name.length
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    { title: 'Imię', field: 'user.fName', filtering: false },
    { title: 'Nazwisko', field: 'user.sName', filtering: false },
    { title: 'Kwota', field: 'totalPrice' },
    {
      title: 'Promocja "Adres"',
      field: 'rebateAddress',
      render: (rowData) => (rowData.rebateAddress ? rowData.rebateAddress : ''),
      filtering: false,
    },
    {
      title: 'Inne Rabaty',
      field: 'rebateOther',
      render: (rowData) => (rowData.rebateOther ? rowData.rebateOther : ''),
      filtering: false,
    },

    {
      title: 'Pierwsza dost.',
      field: 'startAt',
      render: (rowData) =>
        rowData.orderItems[0].startAt
          ? format(new Date(rowData.orderItems[0].startAt), 'dd.MM.yy')
          : '',
      filtering: false,
      // customFilterAndSearch: (date, rowData) => date == rowData.name.length
      cellStyle: {
        width: 200,
        // minWidth: 200,
      },
    },
    {
      title: 'Anulowane',
      field: 'isCancelled',
      render: (rowData) => {
        return <PaymentStatus status={!rowData.isCancelled} />;
      },
      filtering: true,
      lookup: {
        true: 'Tak',
        false: 'Nie',
      },
    },
    {
      title: 'Płatność',
      field: 'isPaid',
      render: (rowData) => {
        return <PaymentStatus status={rowData.isPaid} />;
      },
      filtering: true,
      lookup: {
        true: 'Tak',
        false: 'Nie',
      },
    },
    {
      title: 'Data płatn.',
      field: 'paidAt',
      render: (rowData) => {
        const paidAt = rowData.paidAt ? new Date(rowData.paidAt) : new Date();
        return rowData.isPaid && isValid(paidAt)
          ? format(paidAt, 'dd.MM.yy')
          : '';
      },
      filtering: false,
      cellStyle: {
        width: 200,
        // minWidth: 200,
      },
    },
    {
      title: 'Data fiskaliz.',
      field: 'fiscalizedAt',
      render: (rowData) => {
        const fiscalizedAt = new Date(rowData.fiscalizedAt as Date);
        if (!rowData.invoiceNo) {
          return rowData.fiscalizedAt && isValid(fiscalizedAt)
            ? format(fiscalizedAt, 'dd.MM.yy')
            : '';
        } else {
          return 'Faktura';
        }
      },
      filtering: false,
      cellStyle: {
        width: 200,
        // minWidth: 200,
      },
    },

    {
      title: 'Nr faktury',
      field: 'invoiceNo',
      render: (rowData) => rowData.invoiceNo,
      filtering: false,
      cellStyle: {
        width: 200,
        // minWidth: 200,
      },
    },

    // {
    //   title: 'Aktywne',
    //   field: 'isActive',
    //   render: (rowData) => (rowData.isActive ? 'T' : ''),
    //   filtering: false,
    // },
  ];

  return (
    <Layout>
      <MaterialTable
        title='Zamówienia'
        data={(query) =>
          new Promise((resolve) => {
            let sort = '-no';
            let filterQuery = '';
            if (query.orderBy) {
              sort =
                query.orderDirection === 'asc'
                  ? (query.orderBy.field as string)
                  : (`-${query.orderBy.field}` as string);
            }
            query.filters.forEach((f) => {
              //jesli nie ma wartości filtra to tez nie przekazuje nazwy pola do przeszukiwania w przeciwnym przypadku serwer zwraca pusta liste
              if (f.value[0]) {
                filterQuery = filterQuery + `&${f.column.field}=${f.value}`;
              }
            });

            let url = `/orders?page=${query.page + 1}&limit=${
              query.pageSize
            }&sort=${sort}&select=no,orderDate,createdAt,totalPrice,paidAt,isPaid,fiscalizedAt,invoiceNo,orderItems.startAt,isCancelled,isActive,idWooCommerce,rebateAddress,rebateOther,orderSource&${filterQuery}`;
            api.get(url).then((result) => {
              resolve({
                data: result.data.data,
                page: result.data.page - 1,
                totalCount: result.data.total,
              });
            });
          })
        }
        columns={structure}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 25, 50],
          sorting: true,
          search: false,
          filtering: true,
        }}
      />
    </Layout>
  );
};

export default OrderListScreen;
