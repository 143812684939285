import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import InfoBox from '../layout/InfoBox';

const NutrientElement = ({ nutrient, value }) => (
  <>
    <Grid item xs={6}>
      <Typography variant='body1'>
        <strong>{nutrient}</strong>
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant='body1'>{value}</Typography>
    </Grid>
  </>
);

const NutrtionBox = ({ title, nutrition }) => {
  return (
    <div>
      <Paper style={{ padding: '18px' }}>
        <Typography variant='h5'>{title}</Typography>
        {/* First column */}
        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <NutrientElement
                nutrient='Kcal:'
                value={nutrition?.kcal?.toFixed(0)}
              />
              <NutrientElement
                nutrient='Białko [g]:'
                value={nutrition?.protein?.toFixed(1)}
              />
              <NutrientElement
                nutrient='Węgle [g]'
                value={nutrition?.carbs?.toFixed(1)}
              />
              <NutrientElement
                nutrient='Tłuszcze [g]'
                value={nutrition?.fat?.toFixed(1)}
              />
            </Grid>
          </Grid>
          {/* Second column */}
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <NutrientElement
                nutrient='Błonnik [g]'
                value={nutrition?.carbFiber?.toFixed(2)}
              />
              <NutrientElement
                nutrient='Sód [mg]'
                value={nutrition?.sodium?.toFixed(1)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default NutrtionBox;
