// export const GET_ORDERS = 'GET_ORDERS';

export const GET_RECIPE_DETAILS_REQUEST = 'GET_RECIPE_DETAILS_REQUEST,';
export const GET_RECIPE_DETAILS_SUCCESS = 'GET_RECIPE_DETAILS_SUCCESS';
export const GET_RECIPE_DETAILS_FAIL = 'GET_RECIPE_DETAILS_FAIL';

export const RECIPE_DETAILS_UPDATE_INGREDIENT =
  'RECIPE_DETAILS_UPDATE_INGREDIENT';
export const RECIPE_DETAILS_UPDATE_SUCCESS = 'RECIPE_DETAILS_UPDATE_SUCCESS';
export const RECIPE_DETAILS_UPDATE_FAIL = 'RECIPE_DETAILS_UPDATE_FAIL';

export const RECIPE_DETAILS_SAVE_REQUEST = 'RECIPE_DETAILS_SAVE_REQUEST';
export const RECIPE_DETAILS_SAVE_SUCCESS = 'RECIPE_DETAILS_SAVE_SUCCESS';
export const RECIPE_DETAILS_SAVE_FAIL = 'RECIPE_DETAILS_SAVE_FAIL';
// export const RECIPE_DETAILS_CLEAR_ERROR = 'RECIPE_DETAILS_CLEAR_ERROR';

export const RECIPE_CREATE_REQUEST = 'RECIPE_CREATE_REQUEST';
export const RECIPE_CREATE_SUCCESS = 'RECIPE_CREATE_SUCCESS';
export const RECIPE_CREATE_FAIL = 'RECIPE_CREATE_FAIL';

export const RECIPE_CLONE_REQUEST = 'RECIPE_CLONE_REQUEST';
export const RECIPE_CLONE_SUCCESS = 'RECIPE_CLONE_SUCCESS';
export const RECIPE_CLONE_FAIL = 'RECIPE_CLONE_FAIL';
