import React from 'react';
import { MenuTypeLean } from '../../../../FitAPI/src/models/MenuModel';
import { Box, MenuItem, TextField } from '@mui/material';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { MenuOverview, allSeasons, allVersions } from '../../types/menuTypes';
import { addDays, format } from 'date-fns';
import { pl } from 'date-fns/locale';

type MealOverview = Pick<
  MenuTypeLean,
  'season' | 'weekNr' | 'version' | 'dayOfWeek'
>;

type Props = MealOverview & {
  //   menuId: string;
  handleUpdate: (menu: Partial<MenuTypeLean>) => void;
  isLoading: boolean;
};

const MenuSummary = (props: Props) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const handleToggleEditing = () => {
    setIsEditing((prev) => !prev);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MealOverview>({
    resolver: zodResolver(MenuOverview),
  });

  const onSubmit = (data: MealOverview) => {
    props.handleUpdate(data);
    handleToggleEditing();
  };

  return (
    <Box mb={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='season'
          control={control}
          defaultValue={props.season}
          render={({ field }) => (
            <TextField
              sx={{ marginRight: 2, width: 200 }}
              select
              disabled={!isEditing}
              label='Pora roku'
              variant='standard'
              //   fullWidth
              error={!!errors.season}
              helperText={errors.season?.message}
              {...field}
            >
              {allSeasons.map((season) => (
                <MenuItem key={season} value={season}>
                  {season}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {/* Selectable field for nr of week with the possibility to select between 1-6 */}
        <Controller
          name='weekNr'
          control={control}
          defaultValue={props.weekNr}
          render={({ field }) => (
            <TextField
              sx={{ marginRight: 2, width: 200 }}
              select
              disabled={!isEditing}
              label='Numer tygodnia'
              variant='standard'
              //   fullWidth
              error={!!errors.weekNr}
              helperText={errors.weekNr?.message}
              {...field}
            >
              {Array.from({ length: 6 }, (_, index) => index + 1).map(
                (weekNr) => (
                  <MenuItem key={weekNr} value={weekNr}>
                    {weekNr}
                  </MenuItem>
                )
              )}
            </TextField>
          )}
        />
        {/* Selectable field for version of menu with the possibility to select between A-D as in allVersions array */}
        <Controller
          name='version'
          control={control}
          defaultValue={props.version}
          render={({ field }) => (
            <TextField
              sx={{ marginRight: 2, width: 200 }}
              select
              disabled={!isEditing}
              label='Wersja'
              variant='standard'
              //   fullWidth
              error={!!errors.version}
              helperText={errors.version?.message}
              {...field}
            >
              {allVersions.map((version) => (
                <MenuItem key={version} value={version}>
                  {version}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {/* Selectable field for day of week with the possibility to select between 1-7 but in the select field names of the days should be shown to user in Polish language */}
        <Controller
          name='dayOfWeek'
          control={control}
          defaultValue={props.dayOfWeek}
          render={({ field }) => {
            //   random date to get the days of the week starting from Monday
            const days = Array.from({ length: 7 }, (_, index) =>
              addDays(new Date('1970-01-05'), index)
            );

            return (
              <TextField
                sx={{ marginRight: 2, width: 200 }}
                select
                disabled={!isEditing}
                label='Dzień tygodnia'
                variant='standard'
                error={!!errors.dayOfWeek}
                helperText={errors.dayOfWeek?.message}
                {...field}
              >
                {days.map((date) => (
                  <MenuItem key={date.getDay()} value={date.getDay()}>
                    {/* Use format function from date-fns to format the day */}
                    {format(date, 'EEEE', { locale: pl })}{' '}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
        {isEditing && (
          <>
            <IconButton type='submit'>
              <SaveIcon />
            </IconButton>
          </>
        )}
      </form>
      {!isEditing && (
        <>
          <IconButton onClick={handleToggleEditing}>
            <EditIcon />
          </IconButton>
        </>
      )}

      {/* Add icon button to set editing on or off */}

      {/* <Typography>{props.season}</Typography>
      <Typography>Test</Typography> */}
    </Box>
  );
};

export default MenuSummary;
