import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myDate } from '../helpers/helpers';
import { Link, useParams } from 'react-router-dom';

import { getOrderById } from '../actions/orderActions';
import Layout from '../components/layout/Layout';
import OrderPay from '../components/order/OrderPay';
import DietDetails from '../components/order/DietDetails';
import Loader from '../components/layout/Loader';
import Message from '../components/layout/Message';
import useIsUserAdmin from '../utils/useIsUserAdmin.js';
import PaymentStatus from '../components/layout/PaymentStatus';

import { Link as MUILink } from '@mui/material';
import Grid from '@mui/material/Grid';
import MyBox from '../helpers/MyBox';
import MyRow from '../helpers/MyRow';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { Breadcrumbs, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const OrderScreen = () => {
  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.order);
  const { orderId } = useParams();
  // const { orderId } = match.params;
  const [selectedProductId, setSelectedProductId] = useState();
  const [isOrderPayVisible, setIsOrderPayVisible] = useState(false);
  const { order, loading, error } = orderState;
  const isUserAdmin = useIsUserAdmin();

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, [dispatch, orderId]);

  // Payment methods
  const handleOpenPayment = () => {
    setIsOrderPayVisible(true);
  };

  const handleClosePayment = () => {
    setIsOrderPayVisible(false);
  };

  const handleClickProduct = (id) => {
    setSelectedProductId(id);
  };

  return (
    <>
      <Helmet>
        <title>{`Z ${order?.no} | ${order?.user?.fullName || ''}`}</title>
        <meta property='og:title' content={`Zamówienie `} />
      </Helmet>

      <Layout>
        <Typography variant='h5' align='center'>
          Szczegóły zamówienia
        </Typography>
        <Breadcrumbs>
          <MUILink
            color='inherit'
            to='/orders'
            component={Link}
            underline='hover'
          >
            Tacki
          </MUILink>
          <Typography color='textPrimary'>Nr {order?.no}</Typography>
        </Breadcrumbs>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message message={error.msg} />
        ) : (
          order && (
            <div id='Order'>
              <Typography variant='h6' align='center'>
                Nr {order.no} (
                <Link to={`/users/${order.user._id}`}>
                  {order.user.fullName}
                </Link>
                )
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  {/* Lista produktów */}
                  <MyBox title='Produkty' avatar='P'>
                    {order.orderItems.map((p) => (
                      <Grid key={p._id} container alignItems='baseline'>
                        <Grid item xs={3}>
                          <strong>
                            {p.name} {p.calories}
                          </strong>
                        </Grid>
                        <Grid item xs={7}>
                          <strong>
                            {`${new Date(
                              p.startAt
                            ).toLocaleDateString()} - ${new Date(
                              p.endAt
                            ).toLocaleDateString()}`}
                          </strong>
                        </Grid>
                        <Grid item xs={2}>
                          <strong>{p.price}</strong>
                          <IconButton onClick={() => handleClickProduct(p._id)}>
                            <MoreHorizIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </MyBox>

                  {/* Informacja odnośnie płatności */}

                  <MyBox title='Płatność' avatar={<AttachMoneyIcon />}>
                    {order.isCancelled && (
                      <MyRow
                        label='Anulowane'
                        data={order.isCancelled ? 'Tak' : 'Nie'}
                      />
                    )}
                    {!order.isCancelled && (
                      <>
                        <MyRow
                          label='Płatność'
                          data={<PaymentStatus status={order.isPaid} />}
                        />
                        {order.shippingPrice > 0 && (
                          <MyRow label='Transport' data={order.shippingPrice} />
                        )}
                        {order.rebateAddress > 0 && (
                          <MyRow
                            label='Promocja "Adres"'
                            data={order.rebateAddress}
                          />
                        )}
                        {order.rebateOther > 0 && (
                          <MyRow label='Rabaty' data={order.rebateOther} />
                        )}
                        <MyRow label='Kwota' data={order.totalPrice} />
                        {/* <MyRow label="Status płatności" data={order.payment.status} /> */}
                        <MyRow
                          label='Forma płatności'
                          data={order.paymentMethod}
                        />
                        {order.isPaid && (
                          <MyRow
                            label='Data płatności'
                            data={
                              order.paidAt &&
                              new Date(order.paidAt).toLocaleDateString()
                            }
                          />
                        )}
                        {order.fiscalizedAt && (
                          <MyRow
                            label='Data fiskalizacji'
                            data={
                              order.fiscalizedAt &&
                              new Date(order.fiscalizedAt).toLocaleDateString()
                            }
                          />
                        )}
                        {order.invoiceNo && (
                          <MyRow label='Nr faktury' data={order.invoiceNo} />
                        )}
                      </>
                    )}
                    {(order.isActive || isUserAdmin) && (
                      <IconButton
                        style={{ float: 'right' }}
                        onClick={handleOpenPayment}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </MyBox>
                  <OrderPay
                    isVisible={isOrderPayVisible}
                    close={handleClosePayment}
                    orderId={orderId}
                  />

                  {/* Informacje marketingowe */}
                  {(order.idWooCommerce || order.marketingSource) && (
                    <MyBox title='Marketing' avatar='M'>
                      {order.orderSource && (
                        <MyRow label='Źródło zam.' data={order.orderSource} />
                      )}
                      {order.idWooCommerce !== 0 && (
                        <MyRow
                          label='Nr zam. w wooCommerce'
                          data={order.idWooCommerce}
                        />
                      )}
                      {/* <MyRow label='Uwagi' data={order.marketing.other} /> */}
                    </MyBox>
                  )}

                  {/* Informacje  administracyjne */}
                  <MyBox title='Administracja' avatar='A'>
                    <MyRow
                      label='Przygotował'
                      data={order.createdByUser?.fullName}
                    />
                    <MyRow
                      label='Przygotowane'
                      data={myDate(order.createdAt, 't')}
                    />
                    <MyRow
                      label='Edytowane'
                      data={myDate(order.updatedAt, 't')}
                    />
                    <MyRow
                      label='Importowane'
                      data={order.isImported ? 'Tak' : 'Nie'}
                    />
                    <MyRow
                      label='Aktywne'
                      data={order.isActive ? 'Tak' : 'Nie'}
                    />
                    <MyRow
                      label='Zakończenie'
                      data={new Date(order.completedAt).toLocaleDateString()}
                    />
                    <MyRow
                      label='Email wysłany'
                      data={order.isConfirmationEmailSent ? 'Tak' : 'Nie'}
                    />
                  </MyBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  {selectedProductId && (
                    <DietDetails
                      productId={selectedProductId}
                      // addrList={order.user.addresses}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          )
        )}
      </Layout>
    </>
  );
};

export default OrderScreen;
