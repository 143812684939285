import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Loader from '../layout/Loader';
import Message from '../layout/Message';
import {
  CART_RESET,
  CART_ORDER_PRICE_UPDATE,
  CART_ORDER_UPDATE_VALUES,
  CART_UPDATE_ORDER_SOURCE,
  CART_UPDATE_PAYMENT_METHOD,
  CART_UPDATE_ISPAID,
  CART_UPDATE_PAIDAT,
  CART_UPDATE_IS_EMAIL_SKIPPED,
} from '../../constants/cartConstants';
import { getPaymentMethodList } from '../../actions/cartActions';

import { createOrder } from '../../actions/orderActions';
import { makeStyles } from '@mui/styles';
import ProductCard from './ProductCard';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddressSelection from './AddressSelection';

const useStyles = makeStyles((theme) => ({
  productCard: {
    marginBottom: theme.spacing(2),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  toggleButtons: { width: '100%' },
  buttonFromButtonGroup: { width: '33%' },
  summary: {
    width: 345,
  },
  summaryText: {
    padding: theme.spacing(2),
  },
}));
const Cart = ({ addNewProduct }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    orderItems,
    totalPrice,
    rebateAddress,
    rebateOther,
    priceAdjustment,
    shippingPrice,
    paymentMethod,
    orderSource,
    isPaid,
    paidAt,
    isEmailSkipped,
  } = useSelector((state) => state.cart);
  const { order, loading, error } = useSelector((state) => state.order);
  const { paymentMethods, orderSourceList } = useSelector(
    (state) => state.cartOptions
  );

  let navigate = useNavigate();

  // Redirects user after new order is  created to newly created order
  useEffect(() => {
    if (order) navigate(`/orders/${order._id}`);
  }, [order, navigate]);

  //price update on the start (add up order items)
  useEffect(() => {
    dispatch({ type: CART_ORDER_PRICE_UPDATE });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPaymentMethodList());
  }, [dispatch]);

  const classes = useStyles();
  const handleAddNewProduct = () => {
    addNewProduct();
  };

  //changes idWooCommerce, priceRebate and shipping
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: CART_ORDER_UPDATE_VALUES, payload: { name, value } });
    dispatch({ type: CART_ORDER_PRICE_UPDATE });
  };

  const handleSubmit = async () => {
    if (!orderSource) {
      enqueueSnackbar('Wybierz źródło pozyskania zamównia', {
        variant: 'error',
      });
      return;
    }

    // if (orderSource === 'Strona' && !idWooCommerce) {
    //   enqueueSnackbar('Wpisz nr zamówienia ze strony', {
    //     variant: 'error',
    //   });
    //   return;
    // }
    dispatch(createOrder());
    dispatch({ type: CART_RESET });
  };

  const orderSourceSelection = () => {
    const handleOrderSource = (e, newValue) => {
      if (newValue !== null) {
        dispatch({ type: CART_UPDATE_ORDER_SOURCE, payload: newValue });
      }
    };

    return (
      orderSourceList.length && (
        <>
          <div className={classes.buttonGroup}>
            <Typography variant='h6' color='textSecondary'>
              Sposób pozyskania zamówienia:
            </Typography>
            <ToggleButtonGroup
              size='large'
              color='primary'
              exclusive
              value={orderSource}
              onChange={handleOrderSource}
              className={classes.toggleButtons}
            >
              {orderSourceList.map((source) => (
                <ToggleButton
                  className={classes.buttonFromButtonGroup}
                  value={source}
                  key={source}
                >
                  {source}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </>
      )
    );
  };

  const payment = () => {
    const handlePaymentMethod = (e, newValue) => {
      if (newValue !== null) {
        dispatch({ type: CART_UPDATE_PAYMENT_METHOD, payload: newValue });
      }
    };

    return (
      paymentMethods.length && (
        <>
          <div className={classes.buttonGroup}>
            <Typography variant='h6' color='textSecondary'>
              Sposób płatności:
            </Typography>
            <ToggleButtonGroup
              size='large'
              color='primary'
              exclusive
              value={paymentMethod}
              onChange={handlePaymentMethod}
              className={classes.toggleButtons}
            >
              {paymentMethods.map((pMethod) => (
                <ToggleButton
                  className={classes.buttonFromButtonGroup}
                  value={pMethod}
                  key={pMethod}
                >
                  {pMethod}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isPaid}
                  onChange={(e) =>
                    dispatch({
                      type: CART_UPDATE_ISPAID,
                      payload: e.target.checked,
                    })
                  }
                  name='isPaid'
                  color='primary'
                />
              }
              label='Opłacone'
            />
          </FormGroup>
          {isPaid && (
            <div className={classes.buttonGroup}>
              <DatePicker
                label='Data płatności'
                value={paidAt}
                onChange={(date) =>
                  dispatch({ type: CART_UPDATE_PAIDAT, payload: date })
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          )}
        </>
      )
    );
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message message={error.msg} />
  ) : (
    <Container>
      {orderItems.length > 0 ? (
        <Grid container>
          <Grid xs={12} md={6} item>
            {orderItems.map((cartItem, idx) => (
              <div key={idx} className={classes.productCard}>
                <ProductCard cartItem={cartItem} idx={idx} />
              </div>
            ))}
            {orderItems.length > 0 && (
              <div className={classes.summary}>
                <Paper>
                  <div className={classes.summaryText}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='h5'>Suma:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='h5'>{totalPrice} zł</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
            )}
          </Grid>
          <Grid xs={12} md={6} item>
            <Typography variant='h5'>Podsumowanie zamówienia</Typography>
            <AddressSelection />
            {/* Pozyskanie klienta */}
            {orderSourceSelection()}
            {/* Płatnosc */}
            {payment()}
            <Stack spacing={2} mt={3}>
              <TextField
                label='Dopasowanie ceny'
                type='number'
                // InputLabelProps={{
                //   shrink: true,
                // }}
                name='priceAdjustment'
                onChange={handleChange}
                value={priceAdjustment}
                fullWidth
              />
              <TextField
                label='Promocja "Razem zawsze taniej"'
                type='number'
                // InputLabelProps={{
                //   shrink: true,
                // }}
                name='rebateAddress'
                onChange={handleChange}
                value={rebateAddress}
                fullWidth
              />
              <TextField
                label='Rabaty'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                name='rebateOther'
                onChange={handleChange}
                value={rebateOther}
                fullWidth
              />
              <TextField
                label='Transport'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                name='shippingPrice'
                onChange={handleChange}
                value={shippingPrice}
                fullWidth
              />
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEmailSkipped}
                    onChange={(e) =>
                      dispatch({
                        type: CART_UPDATE_IS_EMAIL_SKIPPED,
                        payload: e.target.checked,
                      })
                    }
                    name='isEmailSkipped'
                    color='primary'
                  />
                }
                label='Nie wysyłaj potwierdzenie email'
              />
            </FormGroup>
            <div className={classes.buttonGroup}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Zapisz
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Message message={'Dodaj produkty'} />
      )}
      <Button onClick={handleAddNewProduct}>Dodaj nowy produkt</Button>
    </Container>
  );
};

export default Cart;
