import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import Layout from '../components/layout/Layout';
import Loader from '../components/layout/Loader';
import { Typography, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Overview from '../components/meal/Overview';
import Recipes from '../components/meal/recipes/Recipes';
import Ingredients from '../components/meal/ingredients/Ingredients';
import DropZone from '../components/meal/image/DropZone';
import MealImages from '../components/meal/image/MealImages';
import SingleImageView from '../components/meal/image/SingleImageView';
import {
  addMealImage,
  deleteMealImage,
  getMealImages,
  patchMealImageIsMain,
} from '../utils/api/meal.image.api';
import { getOneMeal, updateOneMeal } from '../utils/api/mealApi';
import { useSnackbar } from 'notistack';
import { getAllRecipes } from '../utils/api/recipe.api';
import { getAllIngredients } from '../utils/api/ingredient.api';
import { Meal, MealPopulated } from '../../../FitAPI/src/models/MealModel';
import { MealRecipePopulated } from '../../../FitAPI/src/models/MealModel/mealRecipe';
import { MealIngredientPopulated } from '../../../FitAPI/src/models/MealModel/mealIngredient';
import { MealTray } from '../../../FitAPI/src/models/MealModel/mealTray';
import { WithId } from '../types';
import { MealImage } from '../../../FitAPI/src/api/mealImage/image.model';
import MealTags from '../components/meal/tags/MealTags';
import Trays from '../components/meal/Trays';
import Nutritions from '../components/meal/Nutritions';

const MealScreen = () => {
  const { mealId } = useParams();
  const [mealImageOpen, setMealImageOpen] = useState<MealImage | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // ---------------------------------------------------
  // Meal
  // ---------------------------------------------------
  // const mealQuery = useQuery<WithId<Meal>>({
  const mealQuery = useQuery<MealPopulated>({
    queryKey: ['meal', mealId],
    queryFn: () => getOneMeal({ id: mealId }),
  });
  const mealData = mealQuery.data;

  const mealUpdateMutation = useMutation(updateOneMeal, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['meal', mealId]);
      enqueueSnackbar('Zmiany zapisane', {
        variant: 'success',
      });
    },
  });

  const handleMealUpdate = (mealId: string, meal: Partial<Meal>) => {
    mealUpdateMutation.mutate({
      mealId,
      meal,
    });
  };

  const allRecipesQuery = useQuery({
    queryKey: ['recipes'],
    queryFn: () => getAllRecipes(),
  });
  const allRecipesData = allRecipesQuery.data;

  const allIngredientsQuery = useQuery({
    queryKey: ['ingredients'],
    queryFn: () => getAllIngredients(),
  });

  // ---------------------------------------------------
  // Meal Images
  // ---------------------------------------------------
  const { data: mealImagesData, isLoading: isMealImagesLoading } = useQuery({
    queryKey: ['mealImages', mealId],
    queryFn: () => getMealImages({ mealId }),
  });

  const addMealImageMutation = useMutation(addMealImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['mealImages', mealId]);
    },
  });

  const isMainMealImageMutation = useMutation(patchMealImageIsMain, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['mealImages', mealId]);
    },
  });

  const deleteMealImageMutation = useMutation(deleteMealImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['mealImages', mealId]);
    },
  });

  const handleMealImageOpen = (image: any) => {
    setMealImageOpen(image);
  };
  const handleMealImageClose = () => {
    setMealImageOpen(null);
  };

  const handleMealImageDelete = (image: any) => {
    deleteMealImageMutation.mutate({ mealId: mealId, imageId: image._id });
    setMealImageOpen(null);
  };

  const handleMealImageAdd = (file: any) => {
    addMealImageMutation.mutate({
      mealId: mealId,
      isMain: false,
      name: mealData?.nameWWW,
      file,
    });
  };
  const handleSetMealImageIsMain = (image: any) => {
    isMainMealImageMutation.mutate({
      mealId: mealId,
      imageId: image._id,
    });
  };

  if (mealQuery.isLoading) return <Loader />;
  // if (mealQuery.isError) return <Message message={mealQuery.error.msg} />;

  return (
    <>
      <Helmet>
        <title>{`T | ${mealData?.name || ''}`}</title>
      </Helmet>
      <Layout>
        {mealData && (
          <div id='Meal'>
            <Breadcrumbs>
              <Link
                color='inherit'
                to='/meals'
                component={RouterLink}
                underline='hover'
              >
                Tacki
              </Link>
              <Typography color='textPrimary'>{mealData.name}</Typography>
            </Breadcrumbs>
            <Typography style={{ marginTop: 18 }} variant='h5' align='center'>
              {mealData.name}
            </Typography>
            <Grid container spacing={2} style={{ marginTop: 18 }}>
              <Grid item xs={12} md={6}>
                <Overview
                  meal={mealData}
                  handleUpdate={handleMealUpdate}
                  isLoading={mealUpdateMutation.isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Nutritions data={mealData.nutritions} />
              </Grid>
              <Grid item xs={12}>
                <Recipes
                  mealId={mealData._id}
                  recipes={mealData.recipes as MealRecipePopulated[]}
                  allRecipes={allRecipesData}
                  handleMealUpdate={handleMealUpdate}
                />
                <Ingredients
                  mealId={mealData._id}
                  ingredients={
                    mealData.ingredients as MealIngredientPopulated[]
                  }
                  allIngrdients={allIngredientsQuery.data}
                  handleMealUpdate={handleMealUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <Trays
                  mealId={mealData._id}
                  data={mealData.trayTypes as WithId<MealTray>[]}
                  handleMealUpdate={handleMealUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <MealTags
                  mealId={mealData._id}
                  tagNameProduction={mealData.tagsProduction}
                  tagNameUser={mealData.tagsUser}
                  handleMealUpdate={handleMealUpdate}
                />

                {/* <DropZone /> */}
                {isMealImagesLoading ? (
                  'Loading'
                ) : (
                  <MealImages
                    images={mealImagesData}
                    handleOpen={handleMealImageOpen}
                    handleSetIsMainChange={handleSetMealImageIsMain}
                  />
                )}
                <DropZone handleAddMealImage={handleMealImageAdd} />
                <SingleImageView
                  image={mealImageOpen}
                  handleClose={handleMealImageClose}
                  handleDelete={handleMealImageDelete}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Layout>
    </>
  );
};

export default MealScreen;
