import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';

import { MySimpleSelect, MyTextInput } from '../../../helpers/CustomFields';
import { updateAddress, addAddress } from '../../../actions/userActions';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Grid } from '@mui/material';
import { getVoivodeships, getDrivers } from '../../../utils/userApi';
import ZoneInfo from './ZoneInfo';

const validationSchema = object({
  street: string().required('Wymagane'),
  buildingNr: string().required('Wymagane'),
  city: string().required('Wymagane'),
  zipCode: string()
    .required('Wymagane')
    .matches(/\d{2}-\d{3}/, { message: 'Sprawdź kod' })
    .length(6, 'Sprawdź kod'),
  driver: string().required('Wymagane'),
  zone: string().required('Wymagane'),
  fromTime: string().matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
    message: 'Sprawdź poprawność godziny',
  }),
  toTime: string().matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
    message: 'Sprawdź poprawność godziny',
  }),
});

// if isNewAddress is true it will add new address to the user
const AddressUpdate = ({ handleClose, isVisible, address, isNewAddress }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    //temp solution backend is not working now
    values.formattedAddress = `${values.street} ${values.city}`;
    if (isNewAddress) {
      dispatch(addAddress(values));
    } else {
      dispatch(updateAddress(values));
    }

    handleClose();
  };

  const voivodeshipQuery = useQuery({
    queryKey: ['voivodeships'],
    queryFn: getVoivodeships,
  });
  const driverQuery = useQuery({
    queryKey: ['drivers'],
    queryFn: getDrivers,
  });

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle>{isNewAddress ? 'Dodaj adres' : 'Zmień adres'}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={address}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MySimpleSelect
                    label='Województwo'
                    name='voivodeship'
                    optionList={voivodeshipQuery.data || []}
                  />
                  <MyTextInput
                    label='Miejscowość'
                    name='city'
                    placeholder='Miejscowość'
                  />

                  <MyTextInput
                    label='Ulica'
                    name='street'
                    placeholder='Ulica i nr domu'
                    required
                  />
                  <MyTextInput
                    label='Nr domu'
                    name='buildingNr'
                    placeholder='Nr domu'
                    required
                  />
                  <MyTextInput
                    label='Nr mieszkania'
                    name='aptNr'
                    placeholder='Wpisz nr mieszkania'
                  />

                  <MyTextInput
                    label='Kod pocztowy'
                    name='zipCode'
                    placeholder='00-000'
                    // onBlur={(e) => fetchDeliveryZonesbyZip(e.target.value)}
                  />

                  <MySimpleSelect
                    label='Kierowca'
                    name='driver'
                    optionList={driverQuery.data || []}
                  />
                  <MySimpleSelect
                    label='Strefa'
                    name='zone'
                    optionList={[0, 1, 2, 3]}
                  />
                  {values.zone > 0 && (
                    <MyTextInput
                      label='Koszt'
                      type='number'
                      name='deliveryPrice'
                      placeholder='Uwagi dot dostawy'
                    />
                  )}
                  <MyTextInput
                    label='Od godz.'
                    name='fromTime'
                    placeholder='Dostawa od godz.'
                  />
                  <MyTextInput
                    label='Do godz.'
                    name='toTime'
                    placeholder='Dostawa do godz.'
                  />

                  <MyTextInput
                    label='Uwagi dot dostawy'
                    multiline
                    rows={3}
                    name='comments'
                    placeholder='Uwagi dot dostawy'
                  />
                </Grid>
                <Grid item xs={6}>
                  <ZoneInfo
                    town={values.city}
                    voivodeship={values.voivodeship}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  type='submit'
                  variant='contained'
                  color='secondary'
                >
                  Porzuć
                </Button>
                <Button type='submit' variant='contained' color='primary'>
                  Zapisz
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddressUpdate;
