// export const GET_ORDERS = 'GET_ORDERS';

export const OPTION_LIST_REQUEST = 'OPTION_LIST_REQUEST';
export const OPTION_LIST_SUCCESS = 'OPTION_LIST_SUCCESS';
export const OPTION_LIST_FAIL = 'OPTION_LIST_FAIL';

export const OPTION_TRAY_REQUEST = 'OPTION_TRAY_REQUEST';
export const OPTION_TRAY_SUCCESS = 'OPTION_TRAY_SUCCESS';
export const OPTION_TRAY_FAIL = 'OPTION_TRAY_FAIL';

export const OPTION_DRIVER_REQUEST = 'OPTION_DRIVER_REQUEST';
export const OPTION_DRIVER_SUCCESS = 'OPTION_DRIVER_SUCCESS';
export const OPTION_DRIVER_FAIL = 'OPTION_DRIVER_FAIL';

export const OPTION_USER_CONTACTS_REQUEST = 'OPTION_USER_CONTACTS_REQUEST';
export const OPTION_USER_CONTACTS_SUCCESS = 'OPTION_USER_CONTACTS_SUCCESS';
export const OPTION_USER_CONTACTS_FAIL = 'OPTION_USER_CONTACTS_FAIL';

export const OPTION_MEAL_TAGS_REQUEST = 'OPTION_MEAL_TAGS_REQUEST';
export const OPTION_MEAL_TAGS_SUCCESS = 'OPTION_MEAL_TAGS_SUCCESS';
export const OPTION_MEAL_TAGS_FAIL = 'OPTION_MEAL_TAGS_FAIL';
