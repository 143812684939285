import z from 'zod';

export const suppliersList = [
  'Skrzynka',
  'Makro',
  'Inter-mlecz',
  'Bruno',
  'AgroFarm',
  'ALDI',
  'GoodSpeed',
  'Karopak',
  'CENSUS',
  'Google',
  'Facebook',
  'Polskie Znaki',
  'Inny',
] as const;
const SupplierListSchema = z.enum(suppliersList);

export const purchaseInvoiceTypeList = [
  'Spożywka',
  'Opakowanie',
  'Transport',
  'Marketing',
  'Inne',
] as const;
const PurchaseInvoiceTypeListSchema = z.enum(purchaseInvoiceTypeList);

export const purchaseCategoryList = [
  'Warzywa',
  'Suche',
  'Mięso',
  'Jaja',
  'Tacki',
  'Dowóz_diet',
  'Transport',
  'Reklama',
  'Content',
  'Inne',
] as const;
const PurchaseCategoryListSchema = z.enum(purchaseCategoryList);

export const paymentTypeList = ['Przelew', 'Karta', 'Gotówka'] as const;
const PaymentTypeListSchema = z.enum(paymentTypeList);

export const PurchaseInvoiceSchema = z.object({
  invoiceDate: z.date(),
  supplier: SupplierListSchema,
  type: PurchaseInvoiceTypeListSchema,
  category: PurchaseCategoryListSchema,
  netCost: z.number(),
  vat: z.number(),
  grossCost: z.number(),
  paymentType: PaymentTypeListSchema,
  dueDate: z.date(), //termin płatności
  paidAt: z.date().optional(), //data płatności
  isPaid: z.boolean(),
  invoiceNumber: z.string(),
  invoiceScan: z.string(),
});

export type PurchaseInvoice = z.infer<typeof PurchaseInvoiceSchema>;
