import api from '../utils/api';
import {
  REPORT_DRIVERS_REQUEST,
  REPORT_DRIVERS_SUCCESS,
  REPORT_DRIVERS_FAIL,
  REPORT_SALES_REQUEST,
  REPORT_SALES_SUCCESS,
  REPORT_SALES_FAIL,
  REPORT_PAYMENT_REQUEST,
  REPORT_PAYMENT_SUCCESS,
  REPORT_PAYMENT_FAIL,
  REPORT_CONTACTS_REQUEST,
  REPORT_CONTACTS_SUCCESS,
  REPORT_CONTACTS_FAIL,
} from '../constants/reportConstants';

export const getDriverReport = (year, week) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_DRIVERS_REQUEST });
    const { data } = await api.get(
      `/orders/reports/drivers?year=${year}&week=${week}`
    );
    dispatch({ type: REPORT_DRIVERS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: REPORT_DRIVERS_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: 'error',
      },
    });
  }
};

export const getSalesReport = (year, month) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_SALES_REQUEST });
    const { data } = await api.get(
      `/orders/reports/sales?year=${year}&month=${month}`
    );
    dispatch({ type: REPORT_SALES_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: REPORT_SALES_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: 'error',
      },
    });
  }
};
export const getPaymentReport = (year, month) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_PAYMENT_REQUEST });
    const { data } = await api.get(
      `/orders/reports/payment?year=${year}&month=${month}`
    );
    dispatch({ type: REPORT_PAYMENT_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: REPORT_PAYMENT_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: 'error',
      },
    });
  }
};
export const getContactsReport = (year, month) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_CONTACTS_REQUEST });
    const { data } = await api.get(`/users/reports/contacts`);
    dispatch({ type: REPORT_CONTACTS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: REPORT_CONTACTS_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: 'error',
      },
    });
  }
};
