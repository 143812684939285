import React from 'react';
import Layout from '../../components/layout/Layout';
import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { MoreHoriz, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PurchaseInvoice } from '../../../../fitAPI/src/api/purchaseInvoice/purchaseInvoice.model';
import {
  addPurchaseInvoiceScan,
  deletePurchaseInvoice,
  getPurchaseInvoices,
  updatePurchaseInvoice,
} from '../../utils/api/purchase.invoice.api';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { WithId } from '../../types';
import { useSnackbar } from 'notistack';
import PurchaseInvoiceDropZone from '../../components/purchase-invoices/PurchaseInvoiceDropZone';
import useIsUserAdmin from '../../utils/useIsUserAdmin';
import PurchaseInvoicesToolBar from '../../components/purchase-invoices/PurchaseInvoicesToolBar';

type Props = {};
const PurchaseInvoiceListScreen = (props: Props) => {
  const [rowSelection, setRowSelection] = React.useState<GridRowSelectionModel>(
    []
  );
  const [invoicesGrossSum, setInvoicesGrossSum] = React.useState<number>(0);
  const isAdmin = useIsUserAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data } = useQuery<WithId<PurchaseInvoice>[]>({
    queryKey: ['purchaseInvoices'],
    queryFn: () => getPurchaseInvoices(),
  });

  const processRowUpdate = async (newRow: GridRowModel) => {
    const response = await updatePurchaseInvoice({
      id: newRow._id,
      data: { isPaid: newRow.isPaid, paidAt: new Date() },
    });
    enqueueSnackbar('Faktura zaktualizowana', {
      variant: 'success',
    });
    return response;
  };

  const handleProcessRowUpdateError = (params: any) => {
    enqueueSnackbar('Nie udało się zaktualizować faktury', {
      variant: 'error',
    });
  };

  const handleRowSelectionChange = (newSelection: GridRowSelectionModel) => {
    setRowSelection(newSelection);

    // Calculating gross sum of selected invoices
    const newInvoicesGrossSum = data
      ?.filter((invoice) => newSelection.includes(invoice._id))
      .reduce((acc, invoice) => acc + invoice.grossCost, 0);
    setInvoicesGrossSum(newInvoicesGrossSum || 0);
  };

  const addPurchaseInvoiceScanMutation = useMutation(addPurchaseInvoiceScan, {
    onSuccess: (data) => {
      navigate(`/purchase-invoices/${data._id}`);
    },
  });

  const deletePurchaseInvoiceMutation = useMutation(deletePurchaseInvoice, {
    onSuccess: (data) => {
      enqueueSnackbar('Faktura usunięta', {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['purchaseInvoices'] });
    },
  });
  const handleDelete = (id: GridRowId) => {
    deletePurchaseInvoiceMutation.mutate(id as string);
  };

  const handleAddScan = (file: any) => {
    addPurchaseInvoiceScanMutation.mutate(file);
  };

  const columns: GridColDef[] = [
    {
      field: 'invoiceDate',
      valueGetter: (params) => new Date(params.row.invoiceDate),
      renderCell: (params) =>
        format(new Date(params.row.invoiceDate), 'dd.MM.yyyy'),
      headerName: 'Data faktury',
      width: 120,
      type: 'date',
    },
    {
      field: 'createdAt',
      valueGetter: (params) => new Date(params.row.createdAt),
      renderCell: (params) =>
        format(new Date(params.row.createdAt), 'dd.MM.yyyy'),
      headerName: 'Data dodania',
      width: 120,
      type: 'date',
    },
    { field: 'invoiceNumber', headerName: 'Nr faktury', width: 200 },
    { field: 'supplier', headerName: 'Dostawca', width: 120 },
    { field: 'type', headerName: 'Typ', width: 120 },
    { field: 'category', headerName: 'Kategoria', width: 120 },
    { field: 'netCost', headerName: 'Netto', width: 100 },
    { field: 'grossCost', headerName: 'Brutto', width: 100 },
    { field: 'paymentType', headerName: 'Forma płatności', width: 100 },
    {
      field: 'dueDate',
      valueGetter: (params) => new Date(params.row.dueDate),
      renderCell: (params) =>
        format(new Date(params.row.dueDate), 'dd.MM.yyyy'),
      headerName: 'Termin płatności',
      width: 100,
      type: 'date',
    },
    {
      field: 'isPaid',
      headerName: 'Opłacona',
      width: 50,
      type: 'boolean',
      editable: true,
    },
    {
      field: 'actions', // Custom field for invoice details link
      type: 'actions',
      width: 120,
      // renderCell: (params) => {
      getActions: ({ id }) => {
        const actions = [
          <GridActionsCellItem
            icon={<MoreHoriz />}
            label='Szczegóły'
            onClick={() => window.open(`/purchase-invoices/${id}`, '_blank')}
            // onClick={() => navigate(`/purchase-invoices/${id}`)}
          />,
        ];
        if (isAdmin) {
          // actions.push(
          //   <GridActionsCellItem
          //     icon={<Delete />}
          //     label='Usuń'
          //     onClick={() => handleDelete(id)}
          //   />
          // );
        }
        return actions;
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`Z | ${'Faktury'}`}</title>
      </Helmet>
      <Layout>
        <Typography variant='h4'>Lista faktur zakupowych</Typography>
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <PurchaseInvoiceDropZone
            handleAddPurchaseInvoicePdf={handleAddScan}
          />
        </Box>
        {data && (
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={data}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'invoiceDate', sort: 'desc' }],
                },
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[20, 50, 100]}
              autoHeight
              checkboxSelection
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              onRowSelectionModelChange={handleRowSelectionChange}
              slots={{
                toolbar: isAdmin
                  ? () =>
                      PurchaseInvoicesToolBar({
                        rowSelection,
                        setRowSelection,
                        invoicesGrossSum,
                      })
                  : undefined,
              }}
            />
          </div>
        )}
      </Layout>
    </>
  );
};

export default PurchaseInvoiceListScreen;
