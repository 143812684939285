import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import MaterialTable, { MTableToolbar } from 'material-table';

import { getDriverReport } from '../../actions/reportActions';
import Loader from '../layout/Loader';
import Message from '../layout/Message';

import { createStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import getISOWeek from 'date-fns/getISOWeek';

const DriversReport = () => {
  const dispatch = useDispatch();
  const { drivers, loading, error } = useSelector((state) => state.reports);
  const [queryValues, setQueryValues] = useState({
    year: 2023,
    week: getISOWeek(new Date()) - 1, //get data for prev week
  });

  const weeksToSelect = Array.from(new Array(52), (x, i) => i + 1);
  const yearsToSelect = [2019, 2020, 2021, 2022, 2023];

  useEffect(() => {
    const { year, week } = queryValues;
    dispatch(getDriverReport(year, week));
  }, [queryValues]);

  const columns = [
    'Data',
    'Box-y',
    'Stop-y',
    'PD-P',
    'KRK-S',
    'KAT-S',
    // 'KAT2-S',
    'WAW-S',
    'WAW1-S',
    'WAW2-S',
    'GDN-S',
    'GDN1-S',
    'POZ-S',
    'POZ1-S',
    'POZ2-S',
    'WRO-S',
    'WRO1-S',
    'TOR/BYD-S',
    'TOR/BYD1-S',
    'RAD/KIE-S',
    'RAD/KIE1-S',
    'LOD-S',
    'LOD1-S',
  ];

  //Informacje o wszystkich paczkach dostarczonych na południe polski (KRK, KAT)
  const findSouthBoxes = (row) => {
    const kat = row.drivers.filter((i) => i.driver === 'KAT');
    const katBoxes = kat.reduce((a, katItem) => a + katItem.boxes, 0);
    const krk = row.drivers.filter((i) => i.driver === 'KRK');
    const krkBoxes = krk.reduce((a, krkItem) => a + krkItem.boxes, 0);
    return katBoxes + krkBoxes;
  };
  const createRow = (row) => {
    const date = new Date(row.transportedAt).toLocaleDateString();
    const totalBoxes = row.totalBoxes;
    const totalStops = row.totalStops;

    const southBoxes = findSouthBoxes(row);
    const krkStops =
      row.drivers.find((i) => i.driver === 'KRK' && i.zone === 0)?.stops || 0;
    const katStops =
      row.drivers.find((i) => i.driver === 'KAT' && i.zone === 0)?.stops || 0;
    const wawStops = row.drivers.find(
      (i) => i.driver === 'WAW' && i.zone === 0
    ).stops;
    const waw1 = row.drivers.find(
      (i) => i.driver === 'WAW' && i.zone === 1
    )?.stops;
    const waw1Stops = waw1 ? waw1 : 0;
    const waw2Stops =
      row.drivers.find((i) => i.driver === 'WAW' && i.zone === 2)?.stops || 0;
    const gdnStops =
      row.drivers.find((i) => i.driver === 'GDN' && i.zone === 0)?.stops || 0;
    const gdn1Stops =
      row.drivers.find((i) => i.driver === 'GDN' && i.zone === 1)?.stops || 0;
    const pozStops =
      row.drivers.find((i) => i.driver === 'POZ' && i.zone === 0)?.stops || 0;
    const poz1Stops =
      row.drivers.find((i) => i.driver === 'POZ' && i.zone === 1)?.stops || 0;
    const poz2Stops =
      row.drivers.find((i) => i.driver === 'POZ' && i.zone === 2)?.stops || 0;
    const wroStops =
      row.drivers.find((i) => i.driver === 'WRO' && i.zone === 0)?.stops || 0;
    const wro1Stops =
      row.drivers.find((i) => i.driver === 'WRO' && i.zone === 1)?.stops || 0;

    // Trasa  TOR/BYD
    const tor = row.drivers.find((i) => i.driver === 'TOR' && i.zone === 0);
    const torStops = tor ? tor.stops : 0;
    const tor1 = row.drivers.find((i) => i.driver === 'TOR' && i.zone === 1);
    const tor1Stops = tor1 ? tor1.stops : 0;
    const byd = row.drivers.find((i) => i.driver === 'BYD' && i.zone === 0);
    const bydStops = byd ? byd.stops : 0;
    const byd1 = row.drivers.find((i) => i.driver === 'BYD' && i.zone === 1);
    const byd1Stops = byd1 ? byd1.stops : 0;

    // Trasa RAD/KIE
    const rad = row.drivers.find((i) => i.driver === 'RAD' && i.zone === 0);
    const radStops = rad ? rad.stops : 0;
    const rad1 = row.drivers.find((i) => i.driver === 'RAD' && i.zone === 1);
    const rad1Stops = rad1 ? rad1.stops : 0;
    const kie = row.drivers.find((i) => i.driver === 'KIE' && i.zone === 0);
    const kieStops = kie ? kie.stops : 0;
    const kie1 = row.drivers.find((i) => i.driver === 'KIE' && i.zone === 1);
    const kie1Stops = kie1 ? kie1.stops : 0;

    //Trasa Lodz
    const lod = row.drivers.find((i) => i.driver === 'LOD' && i.zone === 0);
    const lodStops = lod ? lod.stops : 0;
    const lod1 = row.drivers.find((i) => i.driver === 'LOD' && i.zone === 1);
    const lod1Stops = lod1 ? lod1.stops : 0;

    const currentRow = [
      date,
      totalBoxes,
      totalStops,
      southBoxes,
      krkStops,
      katStops,
      wawStops,
      waw1Stops,
      waw2Stops,
      gdnStops,
      gdn1Stops,
      pozStops,
      poz1Stops,
      poz2Stops,
      wroStops,
      wro1Stops,
      torStops + bydStops,
      tor1Stops + byd1Stops,
      radStops + kieStops,
      rad1Stops + kie1Stops,
      lodStops,
      lod1Stops,
    ];
    return currentRow;
  };
  const rows = drivers.map((d) => createRow(d));

  const createSummaryRow = (rows) => {
    let summaryRow = [];
    for (let i = 0; i < 22; i++) {
      const sum = rows.reduce((acc, row) => {
        if (Number.isInteger(row[i])) {
          return acc + row[i];
        } else {
          return '';
        }
      }, 0);
      summaryRow.push(sum);
    }

    return summaryRow;
  };

  const summaryRow = createSummaryRow(rows);

  const MySelect = ({ value, name, label, selectValues }) => {
    // const useStyles = makeStyles((theme) =>
    //   createStyles({
    //     formControl: {
    //       margin: theme.spacing(1),
    //       minWidth: 120,
    //     },
    //   })
    // );
    // const classes = useStyles();
    const handleChange = (e) => {
      setQueryValues({ ...queryValues, [e.target.name]: e.target.value });
    };
    return (
      <FormControl
        sx={{ m: 1, minWidth: 120 }}
        // className={classes.formControl}
      >
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {selectValues.map((v) => (
            <MenuItem value={v}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <div>
      <MySelect
        label='Tydzień'
        name='week'
        value={queryValues.week}
        selectValues={weeksToSelect}
      />
      <MySelect
        label='Rok'
        name='year'
        value={queryValues.year}
        selectValues={yearsToSelect}
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message message={error.msg} />
      ) : drivers.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((c) => (
                  <TableCell>{c}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={i}>
                  {row.map((r) => (
                    <TableCell align='left' key={r}>
                      {r !== 0 ? r : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {summaryRow.map((sR) => (
                  <TableCell align='left' key={sR}>
                    <Typography variant='body2'>
                      <strong>{sR}</strong>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ''
      )}
    </div>
  );
};

export default DriversReport;
