import React from 'react';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import api from '../utils/api';
import format from 'date-fns/format';
import plLocale from 'date-fns/locale/pl';

const UserEndingOrderScreen = () => {
  const structure = [
    {
      title: 'no',
      field: 'no',
      render: (rowData) => (
        <Link to={`/users/${rowData._id}`}>{rowData.no}</Link>
      ),
    },
    {
      title: 'Imię',
      field: 'fName',
    },
    {
      title: 'Nazwisko',
      field: 'sName',
      customSort: (a, b) => a.sName.length - b.sName.length,
    },
    { title: 'Telefon', field: 'phone' },
    { title: 'Email', field: 'email' },
    {
      title: 'Ostatnia dostawa',
      field: 'lastDeliveryAt',
      render: (rowData) =>
        format(new Date(rowData.lastDeliveryAt), 'dd.MM (eeee)', {
          locale: plLocale,
        }),
      // new Date(rowData.lastDeliveryAt).toLocaleDateString(),
    },
    { title: 'L.zam.', field: 'nrOrders' },
    { title: 'L.dn.', field: 'nrDays' },
    {
      title: 'Ost. kontakt',
      render: (rowData) => {
        const { contacts } = rowData;
        const contactRenewal = contacts.filter(
          (c) => c.contactType === 'Odnowienie' || c.contactType === 'Próbne'
        );
        const contactRenewalLast = contactRenewal.slice(-1)[0];
        return contactRenewalLast
          ? format(new Date(contactRenewalLast.contactDate), 'dd.MM.yy')
          : '';
      },
    },
    {
      title: 'Opis',
      render: (rowData) => {
        const { contacts } = rowData;
        const contactRenewal = contacts.filter(
          (c) => c.contactType === 'Odnowienie' || c.contactType === 'Próbne'
        );
        const contactRenewalLast = contactRenewal.slice(-1)[0];
        return contactRenewalLast ? contactRenewalLast.contactDescription : '';
      },
    },
  ];

  return (
    <Layout>
      <>
        <MaterialTable
          title='Kończące się diety'
          columns={structure}
          options={{
            // pageSize: 15,
            // pageSizeOptions: [15, 25, 50],
            sorting: true,
            search: false,
            paging: false,
          }}
          data={(query) =>
            new Promise((resolve) => {
              const url = `/users/reports/order-end`;
              api.get(url).then((result) => {
                resolve({
                  data: result.data.data,
                  totalCount: result.data.count,
                });
              });
            })
          }
        />
      </>
    </Layout>
  );
};

export default UserEndingOrderScreen;
