import {
  GET_DELIVERY_ZONE_REQUEST,
  GET_DELIVERY_ZONE_SUCCESS,
  GET_DELIVERY_ZONE_FAIL,
  CLEAR_DELIVERY_ZONE,
} from '../constants/deliveryZoneConstants';

export const deliveryZoneReducer = (
  state = { deliveryZone: null, loading: false },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DELIVERY_ZONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_ZONE_SUCCESS:
      return {
        ...state,
        deliveryZone: payload,
        loading: false,
      };
    case GET_DELIVERY_ZONE_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case CLEAR_DELIVERY_ZONE:
      return {
        ...state,
        deliveryZone: null,
      };

    default:
      return state;
  }
};
