import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import plLocale from '@fullcalendar/core/locales/pl';
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import './DietDetails.css';

import { updateDay } from '../../actions/orderActions';
import { setAlert } from '../../actions/alert';
import MyRow from '../../helpers/MyRow';
import DayInfo from './dayInfo';
import { myDate } from '../../helpers/helpers';
import DayChange from './DayChange';
import MyBox from '../../helpers/MyBox';
import isAfter from 'date-fns/isAfter';
import useIsUserAdmin from '../../utils/useIsUserAdmin';

export default function DietDetails({ productId, addrList }) {
  // const [product, setProduct] = useState();
  const [calendarEvents, setCalendarEvents] = useState();
  const [clickedDay, setClickedDay] = useState();
  const [isDayVisible, setIsDayVisible] = useState(false);
  const [isDayChangeVisible, setIsDayChangeVisible] = useState(false);

  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.order);
  const { order } = orderState;

  const product = order.orderItems.find((p) => p._id === productId);
  const isUserAdmin = useIsUserAdmin();

  const convertDeliveryToCalendar = (deliveryDays) => {
    const events = deliveryDays.map((day) => {
      let color;
      let textColor = 'white';
      switch (day.status) {
        case 'Planowany':
          color = 'green';
          break;
        case 'Dostarczony':
          color = 'grey';
          break;
        case 'Zawieszony':
          color = 'orange';
          textColor = 'black';
          break;
        case 'Płatność':
          color = 'yellow';
          textColor = 'black';
          break;
        case 'Anulowany':
          color = 'red';
          break;
        case 'Niedostarczony':
          color = 'brown';
          break;
        default:
          color = 'blue';
          break;
      }

      return {
        title: `${day.changes ? '*' : ''}${day.diet}-${day.calories} ${
          day.address.formattedAddress
        }`,
        start: day.deliveredAt,
        allDay: true,
        color: color,
        textColor: textColor,
        id: day._id,
      };
    });
    return events;
  };

  useEffect(() => {
    const events = convertDeliveryToCalendar(product.deliveryDays);
    setCalendarEvents(events);
  }, [product]);

  const handleClick = (info) => {
    const day = product.deliveryDays.find((d) => d._id === info.event.id);
    setClickedDay(day);
    setIsDayVisible(true);
  };

  const handleDayInfoClose = () => {
    setIsDayVisible(false);
  };

  const handleDayChangeOpen = () => {
    setIsDayChangeVisible(true);
  };

  const handleDayChangeClose = () => {
    setIsDayChangeVisible(false);
    setIsDayVisible(false);
  };

  const handleMove = (info) => {
    const updatedDay = {
      ...product.deliveryDays.find((d) => d._id === info.event.id),
      deliveredAt: myDate(info.event.start, 'db'),
    };

    //only admin can move the day after it is produced
    if (isUserAdmin || isAfter(new Date(updatedDay.deliveredAt), new Date())) {
      dispatch(updateDay(order._id, product._id, updatedDay._id, updatedDay));
    } else {
      info.revert();
      dispatch(setAlert('Nie mozesz przenieść dnia', 'error'));
      return;
    }
  };

  return (
    <MyBox title='Informacje odnośnie diety' avatar='I'>
      {product && (
        <>
          <MyRow
            label='Początek'
            data={`${new Date(product.startAt).toLocaleDateString()}`}
          />
          <MyRow
            label='Koniec'
            data={`${new Date(product.endAt).toLocaleDateString()}`}
          />
          <MyRow label='Liczba dni' data={product.nrDays} />
          <MyRow label='Typ diety' data={product.name} />
          <MyRow label='Kaloryczność' data={product.calories} />
          <MyRow label='Sobota' data={product.isSaturday ? 'Tak' : 'Nie'} />
          <MyRow label='Niedziela' data={product.isSunday ? 'Tak' : 'Nie'} />
          <MyRow label='Cena' data={`${product.price} zł`} />
          <MyRow label='Aktywne' data={product.isActive ? 'Tak' : 'Nie'} />
          {/* <MyRow label='Liczba diet' data={product.count} /> */}
          {/* <MyRow label='Cena za dzien' data={product.price.dailyRateBase} /> */}
          {/* <MyRow label='Cena za całość' data={product.price.totalBasePrice} /> */}
          <FullCalendar
            locale={plLocale}
            initialDate={
              product.deliveryDays[product.deliveryDays.length - 1].deliveredAt
            }
            initialView='dayGridMonth'
            // defaultView='dayGridMonth'
            plugins={[dayGridPlugin, interactionPlugin]}
            editable={true}
            allDay
            events={calendarEvents}
            eventDrop={handleMove}
            eventClick={(info) => handleClick(info)}
          />
          {isDayVisible && (
            <DayInfo
              orderId={order._id}
              orderItemId={productId}
              day={clickedDay}
              close={handleDayInfoClose}
              isVisible={isDayVisible}
              openDayChange={handleDayChangeOpen}
            />
          )}
          {isDayChangeVisible && (
            <DayChange
              day={clickedDay}
              // addrList={addrList}
              close={handleDayChangeClose}
              isVisible={isDayChangeVisible}
              product={product}
            />
          )}
        </>
      )}
    </MyBox>
  );
}
