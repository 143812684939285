import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useIsUserAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user.role === 'admin') setIsAdmin(true);
  }, [user.role]);

  return isAdmin;
};

export default useIsUserAdmin;
