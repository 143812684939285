import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import api from '../../utils/api';
import Loader from '../layout/Loader';
import Message from '../layout/Message';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import Table from '../reportCommon/Table';
import { Cell, UseTableInstanceProps } from 'react-table';
import { pl } from 'date-fns/locale';

import CssBaseline from '@mui/material/CssBaseline';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function DietProductionReport() {
  const [queryValues, setQueryValues] = useState({
    year: getYear(new Date()),
    month: getMonth(new Date()) + 1,
  });
  const yearsToSelect = [2019, 2020, 2021, 2022, 2023];
  const monthsToSelect = Array.from(new Array(12), (x, i) => i + 1);
  const { isLoading, error, data }: any = useQuery(
    ['dietProduction', queryValues.month, queryValues.year],
    async () => {
      const data = await api.get(
        `/orders/reports/production?month=${queryValues.month}&year=${queryValues.year}`
      );
      return data.data.data;
    }
  );

  //func to generate same col for diet prod statistics
  const dietColumnGenerate = (name: string, accessor: string) => ({
    Header: name,
    accessor,
    Cell: ({ value }: Cell) => (value > 0 ? value : '-'),
    Footer: (info: UseTableInstanceProps<Number>) => {
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => row.values[accessor] + sum, 0),
        [info.rows]
      );
      return <strong>{`${total.toLocaleString()}`}</strong>;
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: 'left' }}>Dzień</div>,
        accessor: 'date',
        Cell: ({ value }: Cell) => (
          <div style={{ textAlign: 'left' }}>
            {format(new Date(value), 'dd E', {
              locale: pl,
            })}
          </div>
        ),
        width: 50,
        maxWidth: 50,
      },
      dietColumnGenerate('Slim', 'SlimFit'),
      dietColumnGenerate('Life', 'LifeFit'),
      dietColumnGenerate('Power', 'PowerFit'),
      dietColumnGenerate('VegeF', 'VegeFishFit'),
      dietColumnGenerate('Vege', 'VegeFit'),
      dietColumnGenerate('Off(b. śn.)', 'OfficeFit (bez śniadania)'),
      dietColumnGenerate('Off(b. kl.)', 'OfficeFit (bez kolacji)'),
      dietColumnGenerate('LGNLact', 'LowGluten&NoLactoseFit'),
      dietColumnGenerate('Diabetic', 'DiabeticFit'),
      dietColumnGenerate('Hashi', 'HashiFit'),
      dietColumnGenerate('Zupa', 'FitZupa'),
      dietColumnGenerate('SirtI', 'SirtFit I'),
      dietColumnGenerate('SirtII', 'SirtFit II'),
      dietColumnGenerate('SirtIII', 'SirtFit III'),
      dietColumnGenerate('Dash', 'DashFit'),
      dietColumnGenerate('IF', 'FastingFit'),
      {
        Header: () => <div style={{ textAlign: 'left' }}>Suma</div>,
        accessor: 'totalCount',
        Cell: ({ value }: Cell) => (
          <div style={{ textAlign: 'left' }}>{value}</div>
        ),
        Footer: (info: UseTableInstanceProps<Number>) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.totalCount + sum, 0),
            [info.rows]
          );
          return <strong>{total}</strong>;
        },
        width: 50,
        maxWidth: 50,
      },
    ],
    []
  );

  const MySelect = ({ value, name, label, selectValues }: any) => {
    const handleChange = (e: any) => {
      setQueryValues({ ...queryValues, [e.target.name]: e.target.value });
    };
    return (
      <FormControl sx={{ ml: 1, minWidth: 120 }}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          label={label}
          labelId={label}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {selectValues.map((v: string) => (
            <MenuItem value={v}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  if (isLoading) return <Loader />;
  if (error) return <Message message={error.msg} />;
  return (
    <div>
      <MySelect
        label='Miesiąc'
        name='month'
        value={queryValues.month}
        selectValues={monthsToSelect}
      />
      <MySelect
        label='Rok'
        name='year'
        value={queryValues.year}
        selectValues={yearsToSelect}
      />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message message={error.msg} />
      ) : (
        <>
          <CssBaseline />
          <Table columns={columns} data={data} />
        </>
      )}
    </div>
  );
}

export default DietProductionReport;
