export const daysOfWeek = [
  'poniedziałek',
  'wtorek',
  'środa',
  'czwartek',
  'piątek',
  'sobota',
  'niedziela',
];

function getDayOfWeekName(dayOfWeek: number): string {
  // Adjust dayOfWeek to match array index (0 - Monday, 6 - Sunday)
  const adjustedDayOfWeek = (dayOfWeek + 6) % 7;

  return daysOfWeek[adjustedDayOfWeek];
}

export default getDayOfWeekName;
