import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid';

interface IngredientSelect extends GridRenderEditCellParams {
  allIngredients: AllOptions[];
}

const IngredientSelectField = (props: IngredientSelect) => {
  const apiRef = useGridApiContext();
  if (!props.allIngredients) return null;
  const filterOptions = createFilterOptions({
    limit: 100,
    trim: true,
    ignoreAccents: true,
    stringify: (option: AllOptions) => option.name,
  });
  return (
    <Autocomplete
      value={{ _id: props.row.ingredient._id, name: props.row.ingredient.name }}
      onChange={(e, value) => {
        if (!value) return null;
        apiRef.current.setEditCellValue({
          id: props.id,
          field: props.field,
          value: value,
        });
      }}
      id='ingredient-autocomplete'
      filterOptions={filterOptions}
      sx={{ width: 500 }}
      options={props.allIngredients}
      isOptionEqualToValue={(option: AllOptions, value: AllOptions) => {
        return option._id === value._id;
      }}
      getOptionLabel={(option: AllOptions) => option.name || ''}
      renderInput={(params) => (
        <TextField {...params} label='Składniki' variant='standard' />
      )}
    />
  );
};

export default IngredientSelectField;
