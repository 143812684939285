// export const GET_USERS = 'GET_USERS';
export const USER_ERROR = 'USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const USER_DETAILS_UPDATE_REQUEST = 'USER_DETAILS_UPDATE_REQUEST';
export const USER_DETAILS_UPDATE_SUCCESS = 'USER_DETAILS_UPDATE_SUCCESS';
export const USER_DETAILS_UPDATE_FAIL = 'USER_DETAILS_UPDATE_FAIL';
export const USER_DETAILS_CLEAR_ERROR = 'USER_DETAILS_CLEAR_ERROR';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';

export const USER_ADDRESS_UPDATE_REQUEST = 'USER_ADDRESS_UPDATE_REQUEST';
export const USER_ADDRESS_UPDATE_REQUEST_SUCCESS =
  'USER_ADDRESS_UPDATE_REQUEST_SUCCESS';
export const USER_ADDRESS_UPDATE_FAIL = 'USER_ADDRESS_UPDATE_FAIL';
