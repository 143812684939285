import React, { useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import MyRow from '../../../helpers/MyRow';
import { getDeliveryZoneDetails } from '../../../utils/userApi';

interface Props {
  town: string;
  voivodeship: string;
}

interface deliveryZoneType {
  _id: string;
  voivodeship: string;
  town: string;
  region: string;
  fromTime: string;
  toTime: string;
  price: string;
  zone: string;
}

const ZoneInfo = (props: Props) => {
  const { town, voivodeship } = props;
  const { setFieldValue } = useFormikContext();

  const deliveryZone: UseQueryResult<deliveryZoneType> = useQuery(
    ['deliveyZoneDetails', town, voivodeship],
    () => getDeliveryZoneDetails(town, voivodeship)
  );

  useEffect(() => {
    setFieldValue('deliveryZone', deliveryZone.data?._id);
  }, [deliveryZone.data]);

  if (!deliveryZone.data?.town || !town || !voivodeship)
    return 'Brak miejscowości w bazie!!!';

  return (
    <div>
      <MyRow label='Województwo' data={deliveryZone.data?.voivodeship} />
      <MyRow label='Miejscowość' data={deliveryZone.data?.town} />
      <MyRow label='Kierowca' data={deliveryZone.data?.region} />
      <MyRow label='Godzina od' data={deliveryZone.data?.fromTime} />
      <MyRow label='Godzina do' data={deliveryZone.data?.toTime} />
      <MyRow label='Strefa' data={deliveryZone.data?.zone} />
      <MyRow label='Cena' data={deliveryZone.data?.price} />
    </div>
  );
};

export default ZoneInfo;
