import {
  OPTION_LIST_REQUEST,
  OPTION_LIST_SUCCESS,
  OPTION_LIST_FAIL,
  OPTION_TRAY_REQUEST,
  OPTION_TRAY_SUCCESS,
  OPTION_TRAY_FAIL,
  OPTION_DRIVER_REQUEST,
  OPTION_DRIVER_SUCCESS,
  OPTION_DRIVER_FAIL,
  OPTION_USER_CONTACTS_REQUEST,
  OPTION_USER_CONTACTS_SUCCESS,
  OPTION_USER_CONTACTS_FAIL,
  OPTION_MEAL_TAGS_REQUEST,
  OPTION_MEAL_TAGS_SUCCESS,
  OPTION_MEAL_TAGS_FAIL,
} from '../constants/optionConstants';

export const optionListReducer = (
  state = {
    options: [],
    trayTypes: [],
    driverCodes: [],
    userContactOptions: [],
    tagsProduction: [],
    tagsUser: [],
    mealTags: {},
    loading: false,
    error: null,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case OPTION_LIST_REQUEST:
    case OPTION_TRAY_REQUEST:
    case OPTION_DRIVER_REQUEST:
    case OPTION_USER_CONTACTS_REQUEST:
    case OPTION_MEAL_TAGS_REQUEST:
      return { ...state, loading: true };
    case OPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        options: payload,
      };
    case OPTION_LIST_FAIL:
    case OPTION_TRAY_FAIL:
    case OPTION_DRIVER_FAIL:
    case OPTION_USER_CONTACTS_FAIL:
    case OPTION_MEAL_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case OPTION_TRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        trayTypes: payload,
      };

    case OPTION_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        driverCodes: payload,
      };

    case OPTION_USER_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userContactOptions: payload,
      };
    case OPTION_MEAL_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tagsProduction: payload.tagsProduction,
        tagsUser: payload.tagsUser,
      };

    default:
      return state;
  }
};
