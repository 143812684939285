import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
// import { CSSTransition } from 'react-transition-group';

import { updateCaloriesCart } from '../../actions/cartActions';
import {
  CART_ADD_ITEM,
  CART_DIET_ADD_QTY,
  CART_DIET_SUBSTRACT_QTY,
  CART_DIET_ADD_DAY,
  CART_DIET_SUBSTRACT_DAY,
  CART_DIET_UPDATE_NR_DAYS,
  CART_DIET_UPDATE_NR_DELIVERIES,
  // CART_DIET_TRIAL_TOGGLE,
  CART_DIET_TRIAL_ON,
  CART_DIET_TRIAL_OFF,
  CART_DIET_WEEKEND_UPDATE,
  CART_DIET_PRICE_UPDATE,
  CART_CURRENT_CART_ITEM_RESET,
} from '../../constants/cartConstants';
import Schedule from './Schedule';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../layout/Loader';
import MyRow from '../../helpers/MyRow';
import { IconButton, Paper, TextField } from '@mui/material';
import Fade from '@mui/material/Fade';
import { API } from '../../config';

const useStyles = makeStyles((theme) => ({
  imgDiv: {
    width: '80%',
    margin: 'auto',
  },
  summaryDiv: {
    width: 400,
    margin: 'auto',
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // textAlign: 'center',
  },
  buttonFromButtonGroup: {
    // padding: theme.spacing(2),
  },
  dietCount: {
    margin: theme.spacing(2),
    display: 'flex',
    alignContent: 'center',
  },
  daysCountDiv: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  dietCountNubmer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  daysQty: { width: 70 },
}));

const AddDiet = ({ showCart }) => {
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.productDetails);
  const { user } = useSelector((state) => state.userDetails);
  const { currentCartItem } = useSelector((state) => state.cart);
  const {
    qty,
    nrDays,
    nrDeliveries,
    calories,
    isTrialDay,
    trialPrice,
    // weekend,
    isSaturday,
    isSunday,
    // duration,
    dailyRateBase,
    dailyRateDiscount,
    deliveryDays,
    price,
  } = currentCartItem;
  const classes = useStyles();

  // const [dailyRateDelivery, setDailyRateDelivery] = useState(0);
  // const [dailyRateAddOn, setDailyRateAddOn] = useState(0);
  // const [nrDeliveries, setNrDeliveries] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch({
      type: CART_DIET_UPDATE_NR_DELIVERIES,
    });
  }, [deliveryDays]);

  useEffect(() => {
    // Update price when duration or calories are changing
    const updatePrice = () => {
      // const newPrice = (dailyRateBase - dailyRateDiscount) * nrDays * qty;
      // +dailyRateDelivery * nrDeliveries;
      dispatch({ type: CART_DIET_PRICE_UPDATE });
    };
    updatePrice();
  }, [
    dailyRateBase,
    dailyRateDiscount,
    nrDays,
    qty,
    // dailyRateDelivery,
    nrDeliveries,
  ]);

  //  Liczba diet
  const dietQtyModule = () => {
    const handleAddDiets = () => {
      dispatch({ type: CART_DIET_ADD_QTY });
    };
    const handleRemoveDiets = () => {
      dispatch({ type: CART_DIET_SUBSTRACT_QTY });
    };
    return (
      <div className={classes.dietCount}>
        <div className={classes.dietCountNubmer}>
          <Typography>Liczba diet:</Typography>
        </div>
        <Fab
          size='small'
          color='secondary'
          aria-label='minus'
          className={classes.margin}
          onClick={handleRemoveDiets}
        >
          <RemoveIcon />
        </Fab>

        <div className={classes.dietCountNubmer}>
          <Typography variant='h5'>{qty}</Typography>
        </div>
        <Fab
          size='small'
          color='secondary'
          aria-label='add'
          className={classes.margin}
          onClick={handleAddDiets}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  };

  // Number of days
  const nrDaysModule = () => {
    const nrDaysHandler = (e) => {
      dispatch({ type: CART_DIET_UPDATE_NR_DAYS, payload: e.target.value });
    };
    return (
      <div className={classes.daysCountDiv}>
        <div className={classes.dietCountNubmer}>
          <Typography>Liczba dni:</Typography>
        </div>
        <IconButton
          onClick={() => dispatch({ type: CART_DIET_SUBSTRACT_DAY })}
          size='large'
        >
          <RemoveIcon />
        </IconButton>

        <div className={classes.daysQty}>
          <TextField
            type='number'
            variant='outlined'
            value={nrDays}
            size='small'
            onChange={nrDaysHandler}
          />
        </div>
        <IconButton
          onClick={() => dispatch({ type: CART_DIET_ADD_DAY })}
          size='large'
        >
          <AddIcon />
        </IconButton>
      </div>
    );
  };
  //Calories
  const caloriesModule = () => {
    const handleCalories = (e, newCalories) => {
      dispatch(updateCaloriesCart(newCalories));
    };
    return (
      <div className={classes.buttonGroup}>
        <ToggleButtonGroup
          size='large'
          color='primary'
          exclusive
          value={calories}
          onChange={handleCalories}
        >
          {product.calories.map((c) => (
            <ToggleButton
              className={classes.buttonFromButtonGroup}
              value={c.variant}
              key={c.variant}
            >
              {c.variant} kcal
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    );
  };

  //Trial
  const trialModule = () => {
    const trialHandler = () => {
      if (!isTrialDay) {
        dispatch({ type: CART_DIET_TRIAL_ON });
      } else {
        dispatch({ type: CART_DIET_TRIAL_OFF });
      }
      // () => dispatch({ type: CART_DIET_TRIAL_TOGGLE })
    };
    return (
      <ToggleButton value='check' selected={isTrialDay} onClick={trialHandler}>
        <CheckIcon />
        <Typography>Zestaw próbny</Typography>
      </ToggleButton>
    );
  };

  //  Weekendy
  const weekendModule = () => {
    return (
      <div className={classes.buttonGroup}>
        <ToggleButtonGroup
          size='large'
          color='primary'
          value={[isSaturday && 'isSaturday', isSunday && 'isSunday']}
          onChange={(e, newWeekend) => {
            dispatch({ type: CART_DIET_WEEKEND_UPDATE, payload: newWeekend });
          }}
        >
          <ToggleButton value='isSaturday'>Z sobotą</ToggleButton>
          <ToggleButton value='isSunday'>Z niedzielą</ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  const handleSubmit = () => {
    if (!calories || deliveryDays.length < 1) {
      enqueueSnackbar(`Wybierz wszystkie opcje w formularzu`, {
        variant: 'error',
      });
      return;
    }

    const cartItem = {
      product,
      name: product.name,
      price,
      isTrialDay,
      qty,
      calories,
      isSaturday,
      isSunday,
      nrDays,
      nrDeliveries,
      dailyRateBase,
      // dailyRateDelivery,
      dailyRateDiscount,
      // dailyRateAddOn,
      deliveryDays: deliveryDays,
    };

    dispatch({ type: CART_ADD_ITEM, payload: cartItem });
    dispatch({ type: CART_CURRENT_CART_ITEM_RESET });

    showCart();
  };

  //when calories selected - fade in transition
  const fadeIn = calories ? true : false;
  return product ? (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* <Box mt={2} mb={2}>
            <Typography variant='h5'>{product.name}</Typography>
          </Box> */}
          {/* <Typography variant='body1'>{product.description}</Typography> */}
          {/* <Box mt={3} textAlign='center'> */}
          <Box mb={2}>
            <Paper>
              <Box p={2} textAlign='center'>
                {caloriesModule()}
              </Box>
            </Paper>
          </Box>

          <Fade
            in={fadeIn}
            style={{ transformOrigin: '0 0 0' }}
            {...(fadeIn ? { timeout: 500 } : {})}
          >
            <Paper>
              <Box p={2} textAlign='center'>
                {nrDaysModule()}
                {trialModule()}
                {weekendModule()}
                <Schedule />
              </Box>
            </Paper>
          </Fade>
          {/* )} */}

          {/* </Box> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={2}>
              <Typography variant='h5' align='center'>
                {product.name}
              </Typography>
              <div className={classes.imgDiv}>
                <img
                  alt={product.name}
                  width='100%'
                  src={`/diets/${product.photo}`}
                  // src={`${API}/uploads/${product.photo}`}
                />
              </div>
              {dietQtyModule()}
              {/* Diet summary */}
              {/* {calories && ( */}
              <Fade
                in={fadeIn}
                style={{ transformOrigin: '0 0 0' }}
                {...(fadeIn ? { timeout: 500 } : {})}
              >
                <div className={classes.summaryDiv}>
                  {!isTrialDay ? (
                    <MyRow
                      label='Cena za dzień'
                      data={` ${dailyRateBase} zł - ${dailyRateDiscount} zł = ${
                        dailyRateBase - dailyRateDiscount
                      }zł`}
                    />
                  ) : (
                    <MyRow
                      label='Cena za dzień próbny'
                      data={`${trialPrice} zł`}
                    />
                  )}
                  <MyRow label='Liczba dni' data={`${nrDays}`} />
                  <MyRow label='Liczba dostaw' data={`${nrDeliveries}`} />
                  <MyRow
                    label='Cena za całość'
                    data={`${Number(price).toFixed(2)} zł`}
                  />
                </div>
              </Fade>
              {/* )} */}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.buttons}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Dodaj
        </Button>
      </div>
    </Container>
  ) : (
    <Loader />
  );
};

export default AddDiet;
