import {
  Autocomplete,
  CircularProgress,
  TableCell,
  TableRow,
  TextField,
  Box,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DietMenuSchemaPopulated,
  MenuMealTypePopulated,
} from '../../../../FitAPI/src/models/MenuModel';
import { ProductType } from '../../../../FitAPI/src/models/ProductModel';
import { DayWithMeals } from '../../../../FitAPI/src/utils/order/getOrderMealsDetails';
import { UseMutationResult } from '@tanstack/react-query';
import { UpdateMealProps } from '../../utils/api/orderMealApi';

type Props = {
  meal: DayWithMeals['meals'][0];
  products: ProductType[];
  dietMenus: DietMenuSchemaPopulated[];
  orderId: string;
  orderItemId: string;
  dayId: string;
  mealMutation: UseMutationResult<void, unknown, UpdateMealProps, unknown>;
  isDayRefetching: boolean;
};

const MealRow = ({
  meal,
  products,
  dietMenus,
  orderId,
  orderItemId,
  dayId,
  mealMutation,
  isDayRefetching,
}: Props) => {
  const [isMealUpdating, setIsMealUpdating] = useState(false);

  useEffect(() => {
    if (isMealUpdating && !mealMutation.isLoading && !isDayRefetching) {
      setIsMealUpdating(false);
    }
  }, [mealMutation.isLoading, isDayRefetching]);

  // options for flex diet
  const isFlexDiet = products.find((prod) => prod.name === meal.diet)?.isFlex;
  let mealFlexOptions: MenuMealTypePopulated[] | undefined;
  if (isFlexDiet) {
    const currentFlexDiet = dietMenus.find(
      (dietMenuItem) => dietMenuItem.dietType === meal.diet
    );
    mealFlexOptions = currentFlexDiet?.mealMatchMatrix.find(
      (matrixItem) => matrixItem?.mealType?.name === meal.mealType
    )?.meals;
  }

  const productListNames = products
    .filter((p) => p.active)
    .sort((a, b) => a.dayMenuOrder - b.dayMenuOrder)
    .map((p) => p.name);

  const handleChange = (e: React.SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }
    setIsMealUpdating(true);
    mealMutation.mutate({
      orderId,
      orderItemId,
      dayId,
      mealId: meal._id,
      updatedMeal: {
        mealType: meal.mealType,
        mealLabel: meal.mealLabel,
        diet: value,
        mealCount: isFlexDiet ? meal.mealCount : 0,
        size: meal.size,
        calories: meal.calories,
      },
    });

    // setSelectedMeal(value);
  };

  interface HandleFlexMealChange {
    name: string;
    mealCount: number;
  }

  const handleFlexMealChange = (
    e: React.SyntheticEvent,
    value: HandleFlexMealChange | null
  ) => {
    if (!value) {
      return;
    }
    setIsMealUpdating(true);
    mealMutation.mutate({
      orderId,
      orderItemId,
      dayId,
      mealId: meal._id,
      updatedMeal: {
        mealType: meal.mealType,
        mealLabel: meal.mealLabel,
        diet: meal.diet,
        mealCount: value.mealCount,
        size: meal.size,
        calories: meal.calories,
      },
    });
  };

  // console.log(isLoading, mealMutation.isLoading);
  // const isMealLoading =
  //   (isLoading || mealMutation.isLoading) &&
  //   mealMutation.variables?.mealId === meal._id;

  return isMealUpdating ? (
    <TableRow sx={{ mx: 'auto', width: '100%' }}>
      <TableCell component='th' scope='row' colSpan={9} align='center'>
        <Skeleton sx={{ fontSize: '2.5rem' }} />
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell component='th' scope='row'>
        {meal.mealLabel}
      </TableCell>
      <TableCell>
        {/* Diet type selection */}

        <Autocomplete
          options={productListNames} //change to menu names
          value={meal.diet}
          onChange={(e, value) => handleChange(e, value)}
          getOptionLabel={(option) => option}
          style={{ width: 150 }}
          renderInput={(params) => <TextField {...params} />}
        />
      </TableCell>
      {/* <TableCell>{meal.mealName}</TableCell> */}

      {/* for flex diet possiblity to select meal */}
      <TableCell align='left'>
        {isFlexDiet && mealFlexOptions ? (
          <Autocomplete
            options={mealFlexOptions.map((m, idx) => ({
              name: m.currentMeal.name,
              mealCount: idx,
            }))}
            value={{ name: meal.mealName, mealCount: meal.mealCount }}
            onChange={(e, value) => handleFlexMealChange(e, value)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option.mealCount === value.mealCount
            }
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          meal.mealName
        )}
      </TableCell>
      <TableCell align='left'>{meal.size}</TableCell>
      <TableCell align='left'>
        {meal.kcal ? meal.kcal.toFixed(0) : ''}
      </TableCell>
      <TableCell align='left'>
        {meal.protein ? meal.protein.toFixed(1) : ''}
      </TableCell>
      <TableCell align='left'>
        {meal.carbs ? meal.carbs.toFixed(1) : ''}
      </TableCell>
      <TableCell align='left'>{meal.fat ? meal.fat.toFixed(1) : ''}</TableCell>
      {/* <TableCell>
            {meal.isMealNameEdited ? (
              <WarningIcon
                style={{ color: yellow[900] }}
                color='secondary'
              />
            ) : (
              ''
            )}
          </TableCell> */}
      {/* Edycja nazw  posiłków */}
      <TableCell align='left'>
        {/* <IconButton
              onClick={() => {
                setShowMealLabelEdit(true);
                setSelectedMeal(meal);
                // console.log(meal);
              }}
              size='large'
            >
              <EditIcon />
            </IconButton> */}
      </TableCell>
    </TableRow>
  );
};

export default MealRow;
