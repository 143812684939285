// function to format currency used in reports table

const formatCurrency = (value) => {
  return value.toLocaleString('pl', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
};

export default formatCurrency;
