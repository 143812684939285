import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import api from '../../utils/api';
import Loader from '../layout/Loader';
import Message from '../layout/Message';
import getISOWeek from 'date-fns/getISOWeek';
import Table from '../reportCommon/Table';
import { Cell, UseTableInstanceProps } from 'react-table';
import { pl } from 'date-fns/locale';

import CssBaseline from '@mui/material/CssBaseline';

import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SalesReport() {
  const [queryValues, setQueryValues] = useState({
    year: new Date().getFullYear(),
    week: getISOWeek(new Date()) - 1,
  });
  const yearsToSelect = [2019, 2020, 2021, 2022, 2023];
  const weeksToSelect = Array.from(new Array(52), (x, i) => i + 1);
  const { isLoading, error, data }: any = useQuery(
    ['driversShortReport', queryValues.week, queryValues.year],
    async () => {
      const data = await api.get(
        `/orders/reports/driver-short?week=${queryValues.week}&year=${queryValues.year}`
      );
      return data.data.data;
    }
  );

  //func to generate same col for diet prod statistics
  const standardColumnGenerate = (name: string, accessor: string) => ({
    Header: name,
    accessor,
    Cell: ({ value }: Cell) => (value > 0 ? value : '-'),
    Footer: (info: UseTableInstanceProps<Number>) => {
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => row.values[accessor] + sum, 0),
        [info.rows]
      );
      return <strong>{`${total.toLocaleString()}`}</strong>;
    },
    width: 50,
    maxWidth: 50,
  });

  const avgColumnGenerate = (name: string, accessor: string) => ({
    Header: name,
    accessor,
    Cell: ({ value }: Cell) => (value > 0 ? value.toFixed(1) : '-'),
    Footer: (info: UseTableInstanceProps<Number>) => {
      const total = React.useMemo(() => {
        const columnToSum =
          accessor === 'costWAW_per_box' ? 'costWAW' : 'totalDailyCost';
        const divider =
          accessor === 'costWAW_per_box' ? 'wawBoxes' : 'totalBoxes';
        return (
          info.rows.reduce((sum, row) => row.values[columnToSum] + sum, 0) /
          info.rows.reduce((sum, row) => row.values[divider] + sum, 0)
        ).toFixed(1);
      }, [info.rows]);
      return <strong>{`${total.toLocaleString()}`}</strong>;
    },
    width: 50,
    maxWidth: 50,
  });
  const columns = React.useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: 'left' }}>Dzień</div>,
        accessor: 'date',
        Cell: ({ value }: Cell) => (
          <div style={{ textAlign: 'left' }}>
            {format(new Date(value), 'dd E', {
              locale: pl,
            })}
          </div>
        ),
        width: 50,
        maxWidth: 50,
      },
      standardColumnGenerate('Pudełka', 'totalBoxes'),
      standardColumnGenerate('Pud. Miasta', 'citiesBoxes'),
      standardColumnGenerate('Pud. WAW', 'wawBoxes'),
      standardColumnGenerate('WAW Stopy 0', 'wawStops0'),
      standardColumnGenerate('WAW Stopy 1', 'wawStops1'),
      standardColumnGenerate('WAW Stopy 2', 'wawStops2'),
      standardColumnGenerate('WAW Stopy Suma', 'wawStopsTotal'),
      standardColumnGenerate('Koszt miasta', 'costCities'),
      standardColumnGenerate('Koszt WAW', 'costWAW'),
      standardColumnGenerate('Koszt suma', 'totalDailyCost'),
      avgColumnGenerate('Śr. koszt WAW', 'costWAW_per_box'),
      avgColumnGenerate('Śr. koszt', 'avgCost_per_box'),
    ],
    []
  );

  const MySelect = ({ value, name, label, selectValues }: any) => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
      })
    );
    const classes = useStyles();
    const handleChange = (e: any) => {
      setQueryValues({ ...queryValues, [e.target.name]: e.target.value });
    };
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.formControl}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          label={label}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {selectValues.map((v: string) => (
            <MenuItem value={v}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  if (isLoading) return <Loader />;
  if (error) return <Message message={error.msg} />;
  return (
    <div>
      <MySelect
        label='Tydzien'
        name='week'
        value={queryValues.week}
        selectValues={weeksToSelect}
      />
      <MySelect
        label='Rok'
        name='year'
        value={queryValues.year}
        selectValues={yearsToSelect}
      />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message message={error.msg} />
      ) : (
        <>
          <CssBaseline />
          <Table columns={columns} data={data} />
        </>
      )}
    </div>
  );
}

export default SalesReport;
