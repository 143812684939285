import api from '../api';

export async function getMealVariants(mealVariant: string, calories: number) {
  if (mealVariant === '' || calories === 0) return null;
  const data = await api.get(
    `/options/meals?mealVariant=${mealVariant}&calories=${calories}`
  );

  return data.data.menuMatrixOptions;
}
