import {
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_FAIL,
  GET_ORDERS_BY_USER_REQUEST,
  GET_ORDERS_BY_USER_SUCCESS,
  GET_ORDERS_BY_USER_FAIL,
  GET_ORDER,
  ORDER_ERROR,
  CLEAR_ORDER,
  // SET_CURRENT_DIET,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_START_REQUEST,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DAY_UPDATE_REQUEST,
  ORDER_DAY_UPDATE_SUCCESS,
  ORDER_DAY_UPDATE_FAIL,
  ORDER_MEAL_UPDATE_REQUEST,
  ORDER_MEAL_UPDATE_SUCCESS,
  ORDER_MEAL_UPDATE_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
} from '../constants/orderConstants';

const initialState = {
  order: null,
  // orders: [],
  // diet: null,
  // loading: true,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case GET_ORDERS:
    //   return {
    //     ...state,
    //     orders: payload,
    //     loading: false,
    //   };
    case GET_ORDER:
      return {
        ...state,
        order: payload,
        loading: false,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        order: null,
      };
    case ORDER_CREATE_REQUEST:
    case ORDER_UPDATE_REQUEST:
    case ORDER_START_REQUEST:
    case ORDER_DAY_UPDATE_REQUEST:
    case ORDER_MEAL_UPDATE_REQUEST:
    case ORDER_PAY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_UPDATE_SUCCESS:
    case ORDER_CREATE_SUCCESS:
    case ORDER_PAY_SUCCESS:
    case ORDER_DAY_UPDATE_SUCCESS:
    case ORDER_MEAL_UPDATE_SUCCESS:
      return {
        ...state,
        order: payload,
        loading: false,
      };
    case ORDER_UPDATE_FAIL:
    case ORDER_ERROR:
    case ORDER_CREATE_FAIL:
    case ORDER_PAY_FAIL:
    case ORDER_DAY_UPDATE_FAIL:
    case ORDER_MEAL_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}

export const ordersListReducer = (state = { orders: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        orders: payload,
        loading: false,
        error: null,
      };
    case GET_ORDER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const userOrdersReducer = (
  state = { orders: [], loading: true },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDERS_BY_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ORDERS_BY_USER_SUCCESS:
      return {
        ...state,
        orders: payload,
        loading: false,
      };
    case GET_ORDERS_BY_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
