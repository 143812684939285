import React from 'react';

import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    // alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  rowLabel: {
    minWidth: (noMinWidth) => (noMinWidth ? 0 : 120),
    width: 150,
    marginRight: theme.spacing(2),
  },
}));

//Wyświetlanie danych w roznych modulach
const MyRow = ({ label, data, noMinWidth = false, actions = undefined }) => {
  const classes = useStyles(noMinWidth);
  return (
    <div className={classes.row}>
      <div className={classes.rowLabel}>
        <Typography align='right' variant='body1'>
          <strong>{label}:</strong>
        </Typography>
      </div>
      <div>
        <Typography variant='body1'>{data}</Typography>
      </div>
      {actions && <div>{actions} AA</div>}
    </div>
  );
};

export default MyRow;
