import {
  CART_DIET_UPDATE_CALORIES,
  CART_DIET_DAILY_DISCOUNT_UPDATE,
  CART_OPTIONS_REQUEST,
  CART_OPTIONS_SUCCESS,
  CART_OPTIONS_FAIL,
} from '../constants/cartConstants';
import { listOptions } from './optionActions';
import api from '../utils/api';
//Update calories in diet config

export const updateCaloriesCart = (newCalories) => (dispatch, getState) => {
  const {
    productDetails: { product },
  } = getState();
  if (newCalories !== null) {
    // Find right daily price
    const productCalories = product.calories.find(
      (c) => c.variant === newCalories
    );
    const newDailyPrice = productCalories.price;
    const trialPrice = productCalories.trialPrice
      ? productCalories.trialPrice
      : newDailyPrice;

    dispatch({
      type: CART_DIET_UPDATE_CALORIES,
      payload: { newCalories, newDailyPrice, trialPrice },
    });
  }
};
export const updateDailyDiscount = () => async (dispatch, getState) => {
  const {
    optionList: { options },
  } = getState();

  //At first render no options are in the  store
  if (options.length === 0) {
    await dispatch(listOptions());
  } else {
    const dietDurationDiscount = options.find(
      (o) => o.name === 'dietDurationDiscount'
    ).options;
    dispatch({
      type: CART_DIET_DAILY_DISCOUNT_UPDATE,
      payload: dietDurationDiscount,
    });
  }
};

// Get all payment  methods
export const getPaymentMethodList = () => async (dispatch) => {
  try {
    dispatch({ type: CART_OPTIONS_REQUEST });
    const res = await api.get(`/orders/cart-options`);
    dispatch({
      type: CART_OPTIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: CART_OPTIONS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// export const updateNrDeliveries = () => (dispatch, getState) => {
//   const {
//     productDetails: { product },
//   } = getState();
//   if (newCalories !== null) {
//     // Find right daily price
//     const newDailyPrice = product.calories.find(
//       (c) => c.variant === newCalories
//     ).price;
//     dispatch({
//       type: CART_DIET_UPDATE_CALORIES,
//       payload: { newCalories, newDailyPrice },
//     });
//   }
// };
