import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../utils/api';
import Layout from '../components/layout/Layout';
import { cloneRecipe } from '../actions/recipeActions';

import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { RecipeType } from '../../../FitAPI/src/models/RecipeModel';
const RecipesListScreen = () => {
  // const queryStr = queryString.parse(match.location.search);
  const dispatch: any = useDispatch();
  const loadingRecipe = useSelector(
    (state: any) => state.recipeDetails.loading
  );
  const [searchParam, setSearchParam] = useSearchParams({ q: '' });
  const searchTerm = searchParam.get('q');

  const recipesRef = React.createRef<any>();

  useEffect(() => {
    if (!searchTerm || searchTerm.length > 2)
      recipesRef.current.onQueryChange();
  }, [searchTerm, recipesRef]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam({ q: e.target.value });
  };

  const handleCancelSearch = () => {
    setSearchParam({ q: '' });
  };

  interface Recipe extends RecipeType {
    _id: string;
  }
  const structure: Array<Column<Recipe>> = [
    {
      title: 'Nazwa',
      field: 'name',
      render: (rowData) => (
        <Link to={`/recipes/${rowData._id}`}>{`${rowData.name} ${
          rowData.isPortion ? `(${rowData.portionWeightPrepared}g)` : ''
        }`}</Link>
      ),
    },
    {
      title: 'kcal',
      field: 'nutrition100.kcal',
      type: 'numeric',
      render: (rowData) => (rowData.nutrition100?.kcal || 0).toFixed(0),
      // parseFloat(rowData.nutrition100?.kcal || 0).toFixed(0),
      editable: 'never',
    },
    {
      title: 'Białko',
      field: 'nutrition100.protein',
      render: (rowData) => (rowData.nutrition100?.protein || 0).toFixed(1),
      // parseFloat(rowData.nutrition100?.protein || 0).toFixed(1),
    },
    {
      title: 'Węgle',
      field: 'nutrition100.carbs',
      render: (rowData) => (rowData.nutrition100?.carbs || 0).toFixed(1),
      // parseFloat(rowData.nutrition100?.carbs || 0).toFixed(1),
    },
    {
      title: 'Tłuszcze',
      field: 'nutrition100.fat',
      render: (rowData) => (rowData.nutrition100?.fat || 0).toFixed(1),
      // parseFloat(rowData.nutrition100?.fat || 0).toFixed(1),
    },
    {
      title: 'Sód',
      field: 'nutrition100.sodium',
      render: (rowData) => (rowData.nutrition100?.sodium || 0).toFixed(1),
      // parseFloat(rowData.nutrition100?.sodium || 0).toFixed(1),
    },
    {
      title: 'Błonnik',
      field: 'nutrition100.carbFiber',
      render: (rowData) => (rowData.nutrition100?.carbFiber || 0).toFixed(1),
      // parseFloat(rowData.nutrition100?.carbFiber || 0).toFixed(1),
    },
  ];

  return (
    <Layout>
      <MaterialTable
        title='Receptury'
        tableRef={recipesRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            let sort = 'name';
            // let filterQuery = '';
            if (query.orderBy) {
              sort =
                query.orderDirection === 'asc'
                  ? (query.orderBy.field as string)
                  : (`-${query.orderBy.field}` as string);
            }
            // query.filters.forEach((f) => {
            //   filterQuery = filterQuery + `&${f.column.field}=${f.value}`;
            // });

            let url = `/recipes?select=name,nutrition100,isPortion,portionWeightPrepared&page=${
              query.page + 1
            }&pageSize=${query.pageSize}&sort=${sort}&q=${searchTerm}`;
            api
              .get(url)
              // .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.recipes,
                  page: result.data.page - 1,
                  totalCount: result.data.count,
                });
              });
          })
        }
        columns={structure}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 25, 50],
          sorting: true,
          search: false,
          // filtering: true,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              api.post('/recipes/', { ...newData }).then((result) => {
                resolve();
              });
            }),
          onRowDelete: (oldData) =>
            new Promise<void>((resolve, reject) => {
              api.delete(`/recipes/${oldData._id}`).then((result) => {
                if (result.data.success) {
                  resolve();
                } else {
                  console.log('Błąd kasowania');
                  reject();
                }
              });
            }),
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ marginLeft: 24, marginRight: 24 }}>
                <TextField
                  fullWidth
                  autoFocus
                  // variant='outlined'
                  onChange={handleSearchChange}
                  value={searchTerm}
                  placeholder='Wpisz pierwsze 3 litery, aby rozpocząć wyszukiwanie'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton onClick={handleCancelSearch}>
                          <Cancel />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  Szukaj
                </TextField>
              </div>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'library_add',
            tooltip: 'Klonowanie',
            onClick: async (event, rowData) => {
              if (Array.isArray(rowData)) return;
              try {
                await dispatch(cloneRecipe(rowData._id));
                const table = recipesRef.current;
                if (!loadingRecipe) table.onQueryChange();
              } catch (error) {
                console.log(error);
              }
            },
          },
        ]}
      />
    </Layout>
  );
};

export default RecipesListScreen;
