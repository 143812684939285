// Flex diet meal component

import {
  Autocomplete,
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  MenuMealTypePopulated,
  MenuType,
} from '../../../../../FitAPI/src/models/MenuModel';
import { UseMutationResult } from '@tanstack/react-query';
import {
  MenuMatrixMealBody,
  MenuMatrixMealRequestParams,
} from '../../../../../FitAPI/src/types/menuType';
import { Meal } from '../../../../../FitAPI/src/models/MealModel';
import { WithId } from '../../../types';

interface Props {
  menuId: string;
  dietMenuId: string;
  mealMatrixId: string;
  meal: MenuMealTypePopulated;
  updateMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixMealRequestParams & MenuMatrixMealBody,
    unknown
  >;
  deleteMealMutation: UseMutationResult<
    MenuType,
    unknown,
    MenuMatrixMealRequestParams,
    unknown
  >;
  allMealNames: WithId<Meal>[] | undefined;
}

const FlexDietMeal = ({
  menuId,
  dietMenuId,
  mealMatrixId,
  meal,
  updateMealMutation,
  deleteMealMutation,
  allMealNames,
}: Props) => {
  const [deletedMealIds, setDeletedMealIds] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleMealChange = (
    event: React.ChangeEvent<{}>,
    newValue: WithId<Meal> | null
  ) => {
    event.stopPropagation();
    setIsEditing(false);
    if (!newValue) return;
    setIsUpdating(true);
    updateMealMutation.mutate({
      menuId,
      dietMenuId,
      matrixId: mealMatrixId,
      mealId: meal._id,
      currentMeal: newValue._id,
    });
  };

  useEffect(() => {
    if (updateMealMutation.isSuccess) {
      setIsUpdating(false);
    }
  }, [updateMealMutation.isSuccess]);

  return (
    <Fade key={meal._id} in={!deletedMealIds.includes(meal._id)}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 0,
        }}
      >
        {isEditing && allMealNames ? (
          <ClickAwayListener onClickAway={() => setIsEditing(false)}>
            <Autocomplete
              fullWidth
              value={meal.currentMeal || null}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={handleMealChange}
              onOpen={(e) => e.stopPropagation()}
              id='meal-autocomplete'
              options={allMealNames}
              getOptionLabel={(option) => option.name}
              sx={{ my: 2 }}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' label='' />
              )}
            />
          </ClickAwayListener>
        ) : isUpdating ? (
          <Skeleton width='100%' />
        ) : (
          <Typography
            variant='body1'
            sx={{ mr: 2 }}
            onClick={() => setIsEditing(true)}
          >
            <strong>{meal.mealCode}.</strong> {meal.currentMeal.name}
          </Typography>
        )}

        <IconButton
          onClick={() => {
            const win = window.open(`/meals/${meal.currentMeal._id}`, '_blank');
            win?.focus();
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            deleteMealMutation.mutate({
              menuId,
              dietMenuId,
              matrixId: mealMatrixId,
              mealId: meal._id,
            });
            setDeletedMealIds((prev) => [...prev, meal._id]);
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    </Fade>
  );
};

export default FlexDietMeal;
