import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MySelect = ({ value, name, label, selectValues, handleChange }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    })
  );
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
      >
        {selectValues.map((v) => (
          <MenuItem value={v}>{v}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MySelect;
