import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  DialogActions,
  TextField,
  Skeleton,
} from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { pl } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import format from 'date-fns/format';

interface Props {
  productionAt: string | undefined;
  handleDateChange: (date: Date | null) => void;
  isUpdating: boolean;
}

const ProductionDate = ({
  productionAt,
  handleDateChange,
  isUpdating,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleEditOpen = () => setIsOpen(true);
  const handleEditClose = () => setIsOpen(false);
  const handleDateChangeAndClose = (date: Date | null) => {
    handleDateChange(date);
    handleEditClose();
  };

  return (
    <>
      {isUpdating ? (
        <Skeleton width={250} height={50} />
      ) : (
        <Box mb={3} flex={1} display='flex' alignItems='center'>
          <Typography variant='subtitle1'>
            Produkcja:{' '}
            <span>
              <strong>
                {productionAt
                  ? format(new Date(productionAt), 'dd.MM.yy (eeee)', {
                      locale: pl,
                    })
                  : ''}
              </strong>
            </span>
          </Typography>

          <IconButton size='large' onClick={handleEditOpen}>
            <Edit />
          </IconButton>
        </Box>
      )}
      <Dialog open={isOpen}>
        <DialogTitle>Zmiana daty produkcji</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tutaj możesz zmodyfikować datę produkcji jadłospisu <br />
            Będzie to miało wpływ na generowane raporty
          </DialogContentText>
          <Box sx={{ marginTop: 5 }}>
            <DatePicker
              label='Wybierz nową datę produkcji'
              value={productionAt}
              onChange={handleDateChangeAndClose}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color='secondary'>
            Zaniechaj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductionDate;
