import api from '../utils/api';
import { setAlert } from './alert';

import {
  GET_RECIPE_DETAILS_REQUEST,
  GET_RECIPE_DETAILS_SUCCESS,
  GET_RECIPE_DETAILS_FAIL,
  RECIPE_DETAILS_SAVE_REQUEST,
  RECIPE_DETAILS_SAVE_SUCCESS,
  RECIPE_DETAILS_SAVE_FAIL,
  RECIPE_CLONE_REQUEST,
  RECIPE_CLONE_SUCCESS,
  RECIPE_CLONE_FAIL,
  // RECIPE_CREATE_REQUEST,
  // RECIPE_CREATE_SUCCESS,
  // RECIPE_CREATE_FAIL,
} from '../constants/recipeConstants';

// Get recipe by ID
export const getRecipeById = (recipeId) => async (dispatch) => {
  try {
    dispatch({ type: GET_RECIPE_DETAILS_REQUEST });
    const res = await api.get(`/recipes/${recipeId}`);

    dispatch({
      type: GET_RECIPE_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);

    dispatch({
      type: GET_RECIPE_DETAILS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Update recipe
export const updateRecipe = (updatedRecipe) => async (dispatch, getState) => {
  const {
    recipeDetails: { recipe },
  } = getState();
  try {
    dispatch({ type: RECIPE_DETAILS_SAVE_REQUEST });
    const { data } = await api.put(`/recipes/${recipe._id}`, updatedRecipe);
    dispatch({ type: RECIPE_DETAILS_SAVE_SUCCESS, payload: data.data });
    dispatch(setAlert('Receptura zmieniona', 'success'));
  } catch (err) {
    console.log(err);
    const error = err.response.data.error;
    dispatch(setAlert(error, 'error'));

    dispatch({
      type: RECIPE_DETAILS_SAVE_FAIL,
      payload: {
        msg: `${err.response.statusText} - ${error}`,
        status: err.response.status,
      },
    });

    // const message =
    //   error.response && error.response.data.message
    //     ? error.response.data.message
    //     : error.message;
    // dispatch({ type: USER_DETAILS_UPDATE_FAIL, payload: {msg: message, status:  });
    // dispatch(setAlert(message, 'error'));
  }
};

//Clone recipe
export const cloneRecipe = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RECIPE_CLONE_REQUEST });
    const { data } = await api.post(`/recipes/${id}`);
    dispatch({ type: RECIPE_CLONE_SUCCESS, payload: data.data });
    dispatch(setAlert('Receptura skopiowana', 'success'));
  } catch (err) {
    console.log(err);
    const error = err.response.data.error;
    dispatch(setAlert(error, 'error'));

    dispatch({
      type: RECIPE_CLONE_FAIL,
      payload: {
        msg: `${err.response.statusText} - ${error}`,
        status: err.response.status,
      },
    });
  }
};
