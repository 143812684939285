import React from 'react';
import NavBar from './NavBar';

import Typography from '@mui/material/Typography';

const Layout = ({ title = '', className = '', children }) => (
  <div>
    <NavBar>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: 12,
        }}
      > */}
      <Typography component='h1' variant='h5'>
        {title}
      </Typography>
      {/* </Box> */}
      <div className={className}>{children}</div>
    </NavBar>
  </div>
);

export default Layout;
