import produce from 'immer';
import api from '../utils/api';
import { setAlert } from './alert';

import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  // GET_USERS,
  // USER_ERROR,
  CLEAR_USER,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  USER_DETAILS_UPDATE_REQUEST,
  USER_DETAILS_UPDATE_SUCCESS,
  USER_DETAILS_UPDATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_ADDRESS_UPDATE_REQUEST,
  USER_ADDRESS_UPDATE_REQUEST_SUCCESS,
  USER_ADDRESS_UPDATE_FAIL,
  // USER_ADDRESS_DELETE_REQUEST,
  // USER_ADDRESS_DELETE_SUCCESS,
  // USER_ADDRESS_DELETE_FAIL,
} from '../constants/userConstants';

// Get current users profile
// export const getCurrentUser = () => async dispatch => {
//   try {
//     const res = await api.get('/profile/me');

//     dispatch({
//       type: GET_PROFILE,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// Get list of all users
export const getUsers = ({ page = 1, limit = 30, select = '' }) => async (
  dispatch
) => {
  dispatch({ type: CLEAR_USER });

  try {
    dispatch({ type: GET_USER_LIST_REQUEST });
    const { data } = await api.get(
      `/users?select=${select}&page=${page}&limit=${limit}`
    );

    dispatch({
      type: GET_USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_LIST_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get user by ID
export const getUserById = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_DETAILS_REQUEST });
    const res = await api.get(`/users/${userId}`);

    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);

    dispatch({
      type: GET_USER_DETAILS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Create User
export const createUser = (newUser) => async (dispatch) => {
  let newUserID;
  try {
    dispatch({ type: USER_CREATE_REQUEST });
    const { data } = await api.post(`/users/`, newUser);
    dispatch({ type: USER_CREATE_SUCCESS, payload: data.data });
    dispatch(setAlert('Uzytkownik dodany', 'success'));
    newUserID = data.data._id;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_CREATE_FAIL });
    dispatch(setAlert(message, 'error'));
  }
  return newUserID;
};

//Update user
export const updateUser = (updatedUser) => async (dispatch, getState) => {
  const {
    userDetails: { user },
  } = getState();
  try {
    dispatch({ type: USER_DETAILS_UPDATE_REQUEST });
    const { data } = await api.put(`/users/${user._id}`, updatedUser);
    dispatch({ type: USER_DETAILS_UPDATE_SUCCESS, payload: data.data });
    dispatch(setAlert('Dane klienta zmienione', 'success'));
  } catch (err) {
    console.log(err);
    const error = err?.response?.data.error;
    dispatch(setAlert(error, 'error'));

    dispatch({
      type: USER_DETAILS_UPDATE_FAIL,
      payload: {
        msg: `${err.response?.statusText || err.message}`,
        status: err.response?.status || 'error',
      },
    });

    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // dispatch({ type: USER_DETAILS_UPDATE_FAIL, payload: {msg: message, status: 'error' });
    dispatch(setAlert(message, 'error'));
  }
};

export const addAddress = (newAddress) => (dispatch, getState) => {
  const {
    userDetails: { user },
  } = getState();

  const updatedUser = {
    ...user,
    addresses: [...user.addresses, newAddress],
  };

  dispatch(updateUser(updatedUser));
};

export const updateAddress = (updatedAddress) => async (dispatch, getState) => {
  const {
    userDetails: { user },
  } = getState();
  try {
    dispatch({ type: USER_ADDRESS_UPDATE_REQUEST });
    const { data } = await api.put(
      `/users/${user._id}/addresses/${updatedAddress._id}`,
      updatedAddress
    );
    dispatch({ type: USER_ADDRESS_UPDATE_REQUEST_SUCCESS, payload: data.data });
    dispatch(setAlert('Adres zmieniony', 'success'));
  } catch (err) {
    console.log(err);
    const error = err?.response.statusText;
    dispatch(setAlert(error, 'error'));

    dispatch({
      type: USER_ADDRESS_UPDATE_FAIL,
      payload: {
        msg: `${err.message}`,
        status: err.response?.status,
      },
    });

    // const message =
    //   error.response && error.response.data.message
    //     ? error.response.data.message
    //     : error.message;
    // dispatch({ type: USER_DETAILS_UPDATE_FAIL, payload: {msg: message, status: 'error' });
    // dispatch(setAlert(message, 'error'));
  }
};

// export const updateAddress = (updatedAddress) => (dispatch, getState) => {
//   const {
//     userDetails: { user },
//   } = getState();

//   // const updatedUser = produce(user, (draftUser) => {
//   //   const idx = draftUser.addresses.findIndex(
//   //     (a) => a._id === updatedAddress._id
//   //   );
//   //   draftUser.addresses[idx] = { ...updatedAddress };
//   // });

//   // dispatch(updateUser(updatedUser));
//   dispatch(updateUser(user));
// };

export const deleteAddress = (addressId) => (dispatch, getState) => {
  const {
    userDetails: { user },
  } = getState();

  const updatedUser = {
    ...user,
    addresses: user.addresses.filter((a) => a._id !== addressId),
  };

  // const updatedUser = produce(user, (draftUser) => {
  //   draftUser.addresses.push(newAddress)
  // });

  dispatch(updateUser(updatedUser));
};

export const addContact = (newContact) => (dispatch, getState) => {
  const {
    userDetails: { user },
    auth,
  } = getState();

  const updatedUser = {
    ...user,
    contacts: [
      ...user.contacts,
      {
        ...newContact,
        contactDate: new Date(),
        createdBy: {
          user: auth.user._id,
          fName: auth.user.fName,
          sName: auth.user.sName,
        },
      },
    ],
  };

  dispatch(updateUser(updatedUser));
};

export const editContact = (newContact, oldContact) => (dispatch, getState) => {
  const {
    userDetails: { user },
  } = getState();

  const idx = user.contacts.findIndex((c) => c._id === oldContact._id);
  const updatedUser = produce(user, (draftUser) => {
    draftUser.contacts[idx] = {
      ...newContact,
    };
  });

  dispatch(updateUser(updatedUser));
};
