import api from '../../utils/api';
import React, { useEffect, useState, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export default function AutoCompleteField({
  onAutoCompleteChange,
  currentValue,
  url,
  getOptionLabel,
  dataSource,
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data } = await api.get(url);
      if (active) {
        setOptions([...data[dataSource]]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      fullWidth
      autoComplete={true}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value) => {
        onAutoCompleteChange(e, value);
      }}
      // getOptionSelected={(option, value) =>
      //   // JSON.stringify(option) === JSON.stringify(value)
      // }
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      value={currentValue}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
