//plik z pomocnymi funkcjami
import axios from 'axios';
import moment from 'moment';

export const API_URL = 'http://localhost:8000/api';
// // export const API_URL = 'http://192.168.0.2:3001/api';

// //update customer data - zmienic na asynchroniczna?
// export async function updateDB(customerData) {
// 	const url = `${API_URL}/customers`;
// 	let message = await axios.put(url, {
// 		customerId: customerData._id,
// 		customer: customerData
// 	});

// 	// .then((response) => {
// 	// 	message = response;
// 	// 	console.log('Data submitted successfully', response);
// 	// })
// 	// .catch((error) => {
// 	// 	message = error;
// 	// 	console.log('got errr while posting data', error);
// 	// });
// 	console.log(message);
// 	return message;
// }

// export async function addOrder(customerId, newOrder, newItemsDetails) {
// 	const url = `${API_URL}/customers`;
// 	let message = await axios.put(url, {
// 		customerId: customerId,
// 		newOrder: newOrder,
// 		newItemsDetails: newItemsDetails
// 	});
// 	return message;
// }

// //updates diet details for single order
// export async function updateDiet(dietId, newDietDetails) {
// 	console.log(newDietDetails);
// 	const url = `${API_URL}/customers`;
// 	let message = await axios.put(url, {
// 		dietId: dietId,
// 		newDietDetails: newDietDetails
// 	});
// 	return message;
// }

// export function updateDB(customerData) {
// 	const url = `${API_URL}/customers`;
// 	let message = '';
// 	axios
// 		.put(url, {
// 			customer: customerData
// 		})
// 		.then((response) => {
// 			message = response;
// 			console.log('Data submitted successfully', response);
// 		})
// 		.catch((error) => {
// 			message = error;
// 			console.log('got errr while posting data', error);
// 		});
// 	console.log(message);
// 	return message;
// }

export function convertPaymentStatus(status) {
  switch (status) {
    case 'paid':
      return 'Zapłacone';
    case 'unpaid':
      return 'Niezapłacone';
    case 'partialPayment':
      return 'Częściowa';
    default:
      return 'Błąd';
  }
}

export const findAdrString = (customerAddr, OrderAddressId) => {
  let address = customerAddr.find((a) => a._id === OrderAddressId);
  let addrString = `${address.town}, ${address.street} ${address.houseNr}`;

  return addrString;
};

//Zapisuje do bazy zmiany w bazie danych z klientami
export async function createLogEntry(logEntry) {
  const url = `${API_URL}/navigate`;
  await axios.post(url, {
    ...logEntry,
  });
  console.log(logEntry);
}

//formatowanie dat
export function myDate(date, variation) {
  switch (variation) {
    case 't':
      return moment(date).format('lll');
    //Format w bazie danych
    case 'db':
      return moment(date).format('YYYY-MM-DD');
    default:
      return moment(date).format('l');
  }
}
