import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Layout from '../components/layout/Layout';
import IngredientsReport from '../components/reportsProduction/IngredientsReport';
import RecipesReport from '../components/reportsProduction/RecipesReport';
import MealsReport from '../components/reportsProduction/MealsReport';
import DateSelector from '../components/reportsProduction/DateSelector';
import { addDays, isAfter, differenceInDays } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import IngredientReport from '../../../FitAPI/src/types/reports/IngredientReport';
import {
  getSummayDayReport,
  getIngredientsReport,
  getMealReport,
  getRecipeReport,
  getDriverReport,
  getDriverExcel,
  getDriverLabels,
  sendDriverReportMail,
  getDriverEmailHistoryQuery,
} from '../utils/api/reportApi';
import { RecipeReport } from '../../../FitAPI/src/types/reports/RecipeReport';
import { MealReport } from '../../../FitAPI/src/types/reports/MealReport';
import { DriverReport } from '../../../FitAPI/src/types/reports/DriverReport';
import DriversReport from '../components/reportsProduction/DriversReport';
import ReportDownloads from '../components/reportsProduction/ReportDownloads';
import { DriverReportFilters } from '../../../FitAPI/src/types/reports/ReportQueryParams';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import { EmailHistory } from '../types/emailHistory';
import { SummaryData } from '../../../FitAPI/src/types/reports/summaryReport';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ReportProductionScreen() {
  const [value, setValue] = React.useState(0);
  const tomorrow = addDays(new Date(), 1);
  const [dateFrom, setdateFrom] = React.useState<Date | null>(tomorrow);
  const [dateTo, setdateTo] = React.useState<Date | null>(tomorrow);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDateFromChange = (date: Date | null) => {
    if (!date || (date && dateTo && isAfter(date, dateTo))) return dateFrom;
    setdateFrom(date);
  };
  const handleDateToChange = (date: Date | null) => {
    if (!date || (date && dateFrom && differenceInDays(date, dateFrom) < 0))
      return dateTo;
    setdateTo(date);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Summary report quieries for day
  const summaryDayQuery = useQuery<SummaryData>(
    ['summaryProductionDayReport', dateFrom, dateTo],
    () => getSummayDayReport(dateFrom, dateTo)
  );

  const ingredientQuery = useQuery<IngredientReport[]>(
    ['ingredientsReportProduction', dateFrom, dateTo],
    () => getIngredientsReport(dateFrom, dateTo)
  );

  const recipeQuery = useQuery<RecipeReport[]>(
    ['recipesReportProduction', dateFrom, dateTo],
    () => getRecipeReport(dateFrom, dateTo)
  );

  const mealQuery = useQuery<MealReport[]>(
    ['mealsReportProduction', dateFrom, dateTo],
    () => getMealReport(dateFrom, dateTo)
  );

  const driverQuery = useQuery<DriverReport[]>(
    ['driversReportProduction', dateFrom, dateTo],
    () => getDriverReport(dateFrom, dateTo)
  );

  const driveryEmailHistoryQuery = useQuery<EmailHistory[]>(
    ['driversEmailHistoryReportProduction'],
    getDriverEmailHistoryQuery
  );

  const handleExcelDownload = async (filter: DriverReportFilters) => {
    try {
      if (!dateFrom || !dateTo) return;
      setIsDownloading(true);
      const blob = await getDriverExcel(dateFrom, dateTo, filter);
      setIsDownloading(false);
      if (!blob) return;
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${format(
        dateFrom,
        'yyyy-MM-dd'
      )}_${filter}_kierowcy.xlsx`;
      link.click();
    } catch (error) {
      console.error(error);
    }
  };
  const handleLabelDriversDownload = async () => {
    try {
      if (!dateFrom || !dateTo) return;
      setIsDownloading(true);
      const blob = await getDriverLabels(dateFrom, dateTo);
      setIsDownloading(false);
      if (!blob) return;
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${format(dateFrom, 'yyyy-MM-dd')}_etykiety.pdf`;
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendDriverReport = async () => {
    try {
      if (!dateFrom || !dateTo) return;
      setIsDownloading(true);
      await sendDriverReportMail(dateFrom, dateTo);
      setIsDownloading(false);
      enqueueSnackbar('Email wysłany', { variant: 'success' });
      driveryEmailHistoryQuery.refetch();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Błąd wysyłania emaila', { variant: 'error' });
    }
  };

  return (
    <Layout>
      <DateSelector
        dateFrom={dateFrom}
        dateTo={dateTo}
        handleDateFromChange={handleDateFromChange}
        handleDateToChange={handleDateToChange}
      />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Składniki' {...a11yProps(0)} />
            <Tab label='Posiłki' {...a11yProps(1)} />
            <Tab label='Tacki' {...a11yProps(2)} />
            <Tab label='Kurierzy' {...a11yProps(3)} />
            <Tab label='Pliki' {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <IngredientsReport
            query={ingredientQuery}
            summaryData={summaryDayQuery?.data}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RecipesReport
            query={recipeQuery}
            summaryData={summaryDayQuery?.data}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MealsReport query={mealQuery} summaryData={summaryDayQuery?.data} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DriversReport
            query={driverQuery}
            summaryData={summaryDayQuery?.data}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ReportDownloads
            handleDownloadDriverExcel={handleExcelDownload}
            handleDownloadLabelDrivers={handleLabelDriversDownload}
            handleSendDriverReport={handleSendDriverReport}
            isDownloading={isDownloading}
            emailHistory={driveryEmailHistoryQuery.data}
            summaryData={summaryDayQuery?.data}
          />
        </TabPanel>
      </Box>
    </Layout>
  );
}
