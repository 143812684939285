import api from './api';
// ADDRESS
// Get all voivodeships

export const getVoivodeships = async () => {
  const data = await api.get('/deliveryZones/voivodeship');
  return data.data.data;
};

export const getDrivers = async () => {
  const data = await api.get('/deliveryZones/drivers');
  return data.data.data;
};

export const getDeliveryZoneDetails = async (
  town: string,
  voivodeship: string
) => {
  if (!voivodeship || !town) return {};
  const data = await api.get(
    `deliveryZones/by-location/${town}/${voivodeship}`
  );
  return data.data.data;
};

export const getCities = async (citySearch: string) => {
  if (citySearch === '') return [];
  const data = await api.get(`deliveryZones/cities/${citySearch}`);
  return data.data.data;
};
