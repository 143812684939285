import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

import MaterialTable, { Column, MTableToolbar } from '@material-table/core';

import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import api from '../utils/api';
import { TextField, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

const UserListScreen = () => {
  const [newSearch, setNewSearch] = useSearchParams({ q: '' });
  const userRef = React.createRef<any>();

  useEffect(() => {
    const searchTerm = newSearch.get('q');
    if (!searchTerm || searchTerm.length > 2) userRef.current.onQueryChange();
  }, [newSearch]);

  interface User {
    _id: string;
    no: number;
    fName: string;
    sName: string;
    phone: string;
    email: string;
    lastDeliveryAt: string;
    nrOrders: number;
    nrDays: number;
    isActive: boolean;
    addresses: Array<{
      city: string;
      driver: string;
    }>;
  }

  const structure: Array<Column<User>> = [
    {
      title: 'no',
      field: 'no',
      render: (rowData) => <Link to={`${rowData._id}`}>{rowData.no}</Link>,
    },
    {
      title: 'Imię',
      field: 'fName',
    },
    {
      title: 'Nazwisko',
      field: 'sName',
      customSort: (a, b) => a.sName.length - b.sName.length,
    },
    { title: 'Telefon', field: 'phone' },
    { title: 'Email', field: 'email' },
    {
      title: 'Ostatnia dostawa',
      field: 'lastDeliveryAt',
      render: (rowData) =>
        rowData.lastDeliveryAt
          ? moment(rowData.lastDeliveryAt).format('l')
          : '',
    },
    { title: 'L.zam.', field: 'nrOrders' },
    { title: 'L.dn.', field: 'nrDays' },
    {
      title: 'Aktywne',
      field: 'isActive',
      render: (rowData) => (rowData.isActive ? 'Aktywny' : ''),
    },
    {
      title: 'Miasto',
      render: (rowData) => rowData.addresses[0]?.city,
    },
    { title: 'Kierowca', field: 'addresses[0].driver' },
  ];

  return (
    <Layout>
      <Box m={1}>
        <MaterialTable
          title='Użytkownicy'
          tableRef={userRef}
          columns={structure}
          options={{
            pageSize: 15,
            pageSizeOptions: [15, 25, 50],
            // sorting: true,
            // maxColumnSort: 'all_columns', //Nie
            search: false,
          }}
          data={(query) =>
            new Promise((resolve) => {
              let sort = '-no';
              if (query.orderBy) {
                sort =
                  query.orderDirection === 'asc'
                    ? (query.orderBy.field as string)
                    : (`-${String(query.orderBy.field)}` as string);
              }
              const url = `/users?page=${query.page + 1}&pageSize=${
                query.pageSize
              }&sort=${sort}&keyword=${newSearch.get('q') || ''}`;
              api.get(url).then((result) => {
                resolve({
                  data: result.data.users,
                  page: result.data.page - 1,
                  totalCount: result.data.count,
                });
              });
            })
          }
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ marginLeft: 24, marginRight: 24 }}>
                  <TextField
                    fullWidth
                    autoFocus
                    // variant='outlined'
                    onChange={(e) => setNewSearch({ q: e.target.value })}
                    // onChange={(e) => setNewSearch(e.target.value)}
                    value={newSearch.get('q')}
                    placeholder='Wpisz pierwsze 3 litery, aby rozpocząć wyszukiwanie'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='start'>
                          <IconButton onClick={() => setNewSearch({ q: '' })}>
                            <Cancel />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    Szukaj
                  </TextField>
                </div>
              </div>
            ),
          }}
          localization={{
            toolbar: {
              searchPlaceholder: 'Szukaj',
            },
          }}
        />
        <Link to='/users/new'>Nowy Klient</Link>
      </Box>
    </Layout>
  );
};

export default UserListScreen;
