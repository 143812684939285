import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { CART_SET_ADDRESS } from '../../constants/cartConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const AddressSelection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.cart);
  const { addresses } = useSelector((state) => state.userDetails.user);

  useEffect(() => {
    if (!address) {
      dispatch({ type: CART_SET_ADDRESS, payload: { ...addresses[0] } });
    }
  }, []);

  const handleChange = (e) => {
    const selectedAddress = addresses.find((a) => a._id === e.target.value);
    dispatch({ type: CART_SET_ADDRESS, payload: selectedAddress });
  };

  if (!address) return 'Brak adresu';

  return (
    <div className={classes.root}>
      {/* <div className={classes.title}>
        <Typography variant='h5'>Twój adres</Typography>
      </div> */}
      <FormControl
        fullWidth
        //className={classes.formControl}
      >
        <InputLabel id='addess-label'>Wybierz adres</InputLabel>
        <Select
          labelId='address-label'
          label='Wybierz adres'
          value={address._id}
          onChange={handleChange}
          name='address'
          color='secondary'
          // variant='outlined'
        >
          {addresses.map((a) => {
            return (
              <MenuItem key={a._id} value={a._id}>
                {a.formattedAddress}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default AddressSelection;
