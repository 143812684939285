import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  USER_DETAILS_UPDATE_REQUEST,
  USER_DETAILS_UPDATE_SUCCESS,
  USER_DETAILS_UPDATE_FAIL,
  USER_DETAILS_CLEAR_ERROR,
  CLEAR_USER,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_ADDRESS_UPDATE_REQUEST,
  USER_ADDRESS_UPDATE_REQUEST_SUCCESS,
  USER_ADDRESS_UPDATE_FAIL,
} from '../constants/userConstants';

import produce from 'immer';

// const initialState = {
//   user: null,
//   users: [],
//   loading: true,
//   error: {},
// };

// export default function (state = initialState, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case USER_CREATE_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case USER_CREATE_SUCCESS:
//       return {
//         ...state,
//         user: payload,
//         loading: false,
//       };
//     case GET_USERS:
//       return {
//         ...state,
//         users: payload,
//         loading: false,
//       };
//     case USER_ERROR:
//     case USER_CREATE_FAIL:
//       return {
//         ...state,
//         error: payload,
//         loading: false,
//         user: null,
//       };

//     case CLEAR_USER:
//       return {
//         ...state,
//         user: null,
//       };
//     default:
//       return state;
//   }
// }ss

export const userListReducer = (state = { users: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        users: payload.data,
        pagination: payload.pagination,
        loading: false,
        error: null,
      };
    case GET_USER_LIST_FAIL:
      return {
        ...state,
        users: [],
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const userDetailsReducer = (
  state = { user: undefined, loading: true, error: null },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_DETAILS_REQUEST:
    case USER_CREATE_REQUEST:
    case USER_DETAILS_UPDATE_REQUEST:
    case USER_ADDRESS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_DETAILS_SUCCESS:
    case USER_CREATE_SUCCESS:
    case USER_DETAILS_UPDATE_SUCCESS:
    case USER_ADDRESS_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
        error: null,
      };
    case GET_USER_DETAILS_FAIL:
    case USER_CREATE_FAIL:
    case USER_DETAILS_UPDATE_FAIL:
    case USER_ADDRESS_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case USER_DETAILS_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case CLEAR_USER:
      return {
        // ...state,
        user: undefined,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
