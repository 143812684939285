import api from '../utils/api';
import { setAlert } from './alert';

import {
  MEAL_CLONE_REQUEST,
  MEAL_CLONE_SUCCESS,
  MEAL_CLONE_FAIL,
} from '../constants/mealConstants';

//Clone meal
export const cloneMeal = (id) => async (dispatch, getState) => {
  // const {
  //   mealDetails: { meal },
  // } = getState();
  try {
    dispatch({ type: MEAL_CLONE_REQUEST });
    const { data } = await api.post(`/meals/${id}`);
    dispatch({ type: MEAL_CLONE_SUCCESS, payload: data.data });
    dispatch(setAlert('Tacka skopiowana', 'success'));
  } catch (err) {
    console.log(err);
    const error = err.response.data.error;
    dispatch(setAlert(error, 'error'));

    dispatch({
      type: MEAL_CLONE_FAIL,
      payload: {
        msg: `${err.response.statusText} - ${error}`,
        status: err.response.status,
      },
    });
  }
};
