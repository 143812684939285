import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';

interface RecipeSelect extends GridRenderEditCellParams {
  allRecipes: AllRecipes[];
}

const RecipeSelectField = (props: RecipeSelect) => {
  const apiRef = useGridApiContext();
  if (!props.allRecipes) return null;
  const filterOptions = createFilterOptions({
    limit: 100,
    trim: true,
    ignoreAccents: true,
    stringify: (option: AllRecipes) => option.name,
  });
  return (
    <Autocomplete
      value={{ _id: props.row.recipe._id, name: props.row.recipe.name }}
      onChange={(e, value) => {
        if (!value) return null;
        apiRef.current.setEditCellValue({
          id: props.id,
          field: props.field,
          value: value,
        });
      }}
      id='recipe-autocomplete'
      filterOptions={filterOptions}
      sx={{ width: 500 }}
      options={props.allRecipes}
      isOptionEqualToValue={(option: AllRecipes, value: AllRecipes) => {
        return option._id === value._id;
      }}
      getOptionLabel={(option: AllRecipes) => option.name || ''}
      renderInput={(params) => (
        <TextField {...params} label='Receptury' variant='standard' />
      )}
    />
  );
};

export default RecipeSelectField;
