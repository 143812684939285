import api from '../utils/api';
import {
  GET_PRODUCTS_BY_CATEGORY_REQUEST,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY_FAIL,
  GET_PRODUCT_DETAILS_REQUEST,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
} from '../constants/productConstants';

// Get all categories
export const getProductList =
  ({ filter = '', select, sort = '' }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCTS_BY_CATEGORY_REQUEST });
      const res = await api.get(
        `/products?${filter}&select=${select}&sort=${sort}`
      );
      dispatch({
        type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_PRODUCTS_BY_CATEGORY_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Get product by ID
export const getProductById = (productId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_DETAILS_REQUEST });
    const res = await api.get(`/products/${productId}`);
    dispatch({
      type: GET_PRODUCT_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: GET_PRODUCT_DETAILS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get product by slug
export const getProductBySlug = (productSlug) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCT_DETAILS_REQUEST });
    const res = await api.get(`/products/slug/${productSlug}`);
    dispatch({
      type: GET_PRODUCT_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: GET_PRODUCT_DETAILS_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
