export const allTagsUser = [
  'bez glutenu',
  'bez laktozy',
  'bez cukru',
  'bez soli',
  'bez pszenicy',
  'wege',
  'wegańskie',
  'ryba',
  'niski IG',
  'azjatyckie',
  'włoskie',
  'tradycyjne',
] as const;

export type TagUser = (typeof allTagsUser)[number];

export const allTagsProduction = [
  'główna',
  'wyjątek',
  'do zmiany',
  'szybkie',
  'pracochłonne',
  'bez soli',
  'bez pszenicy',
] as const;
export type TagProduction = (typeof allTagsProduction)[number];

export type Tag = TagUser | TagProduction;
