import api from '../utils/api';
import { setAlert } from './alert';

import {
  OPTION_LIST_REQUEST,
  OPTION_LIST_SUCCESS,
  OPTION_LIST_FAIL,
  OPTION_TRAY_REQUEST,
  OPTION_TRAY_SUCCESS,
  OPTION_TRAY_FAIL,
  OPTION_DRIVER_REQUEST,
  OPTION_DRIVER_SUCCESS,
  OPTION_DRIVER_FAIL,
  OPTION_USER_CONTACTS_REQUEST,
  OPTION_USER_CONTACTS_SUCCESS,
  OPTION_USER_CONTACTS_FAIL,
  OPTION_MEAL_TAGS_REQUEST,
  OPTION_MEAL_TAGS_SUCCESS,
  OPTION_MEAL_TAGS_FAIL,
} from '../constants/optionConstants';

export const listOptions = () => async (dispatch) => {
  try {
    dispatch({ type: OPTION_LIST_REQUEST });
    const { data } = await api.get(`/options`);
    dispatch({
      type: OPTION_LIST_SUCCESS,
      payload: data.options,
    });
  } catch (error) {
    dispatch({
      type: OPTION_LIST_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      },
    });
  }
};
export const getTrayTypes = () => async (dispatch) => {
  try {
    dispatch({ type: OPTION_TRAY_REQUEST });
    const { data } = await api.get(`/options/tray-types`);
    dispatch({
      type: OPTION_TRAY_SUCCESS,
      payload: data.trayTypes,
    });
  } catch (error) {
    dispatch({
      type: OPTION_TRAY_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      },
    });
  }
};
export const getDriverCodes = () => async (dispatch) => {
  try {
    dispatch({ type: OPTION_DRIVER_REQUEST });
    const { data } = await api.get(`/options/driver-codes`);
    dispatch({
      type: OPTION_DRIVER_SUCCESS,
      payload: data.driverCodes,
    });
  } catch (error) {
    dispatch({
      type: OPTION_DRIVER_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      },
    });
  }
};
export const getUserContactOptions = () => async (dispatch) => {
  try {
    dispatch({ type: OPTION_USER_CONTACTS_REQUEST });
    const { data } = await api.get(`/options/user-contacts`);
    dispatch({
      type: OPTION_USER_CONTACTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPTION_USER_CONTACTS_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      },
    });
  }
};
export const getMealTags = () => async (dispatch) => {
  try {
    dispatch({ type: OPTION_MEAL_TAGS_REQUEST });
    const { data } = await api.get(`/options/meal-tags`);
    dispatch({
      type: OPTION_MEAL_TAGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPTION_MEAL_TAGS_FAIL,
      payload: {
        msg:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      },
    });
  }
};
