import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import api from '../../utils/api';
import Loader from '../layout/Loader';
import Message from '../layout/Message';
import isValid from 'date-fns/isValid';
import Table from '../reportCommon/Table';
import { Cell } from 'react-table';

import CssBaseline from '@mui/material/CssBaseline';

type UserData = {
  _id: string;
  no: string;
  fName: string;
  sName: string;
  phone: string;
  nrDays: string;
  lastDeliveryAt: string;
  lastContactDate: string;
  lastContactDescription: string;
};

function UserNonActiveReport() {
  const { isLoading, error, data }: any = useQuery(
    ['userNonActive'],
    async () => {
      const data = await api.get(`/users/reports/unactive`);
      return data.data.data;
    }
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Lp',
        accessor: 'no',
        Cell: (props: Cell<UserData>) => {
          const { index } = props.row;

          return index + 1;
        },
      },
      {
        Header: 'Nr',
        accessor: '_id',
        Cell: (props: Cell<UserData>) => {
          const { value, row } = props;
          const { no } = row.original;
          return (
            <NavLink to={`users/${value}`} target='_blank'>
              {no}
            </NavLink>
          );
        },
      },
      {
        Header: 'Imię',
        accessor: 'fName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'sName',
      },
      {
        Header: 'Telefon',
        accessor: 'phone',
      },
      {
        Header: 'L. dni',
        accessor: 'nrDays',
      },
      {
        Header: 'Ost. dost.',
        accessor: 'lastDeliveryAt',
        Cell: ({ value }: Cell) => format(new Date(value), 'dd.MM.yyyy', {}),
      },
      {
        Header: 'Ost. kontakt.',
        accessor: 'lastContactDate',
        Cell: ({ value }: Cell) => {
          const date = new Date(value);
          return isValid(date) ? format(date, 'dd.MM.yyyy', {}) : '';
        },
      },
      {
        Header: 'Ost. kontakt.',
        accessor: 'lastContactDescription',
      },
    ],
    []
  );

  if (isLoading) return <Loader />;
  if (error) return <Message message={error.msg} />;
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message message={error.msg} />
      ) : (
        <>
          <CssBaseline />
          <Table columns={columns} data={data} />
        </>
      )}
    </div>
  );
}

export default UserNonActiveReport;
