import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Layout from '../components/layout/Layout';

import { listOptions } from '../actions/optionActions';
import { getMenuByDate } from '../utils/api/menu.meal.api';
import Loader from '../components/layout/Loader';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Message from '../components/layout/Message';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import StatusChip from '../components/mealSelection/StatusBadges';

import DayTable from '../components/mealSelection/DayTable';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { MenuTypePopulated } from '../../../FitAPI/src/models/MenuModel';
import { useAppDispatch } from '../hooks';
import { DayWithMeals } from '../../../FitAPI/src/utils/order/getOrderMealsDetails';
import { getDays } from '../utils/api/orderDayApi';
import { getProducts } from '../utils/api/productApi';
import { updateMealOrder } from '../utils/api/orderMealApi';

// export default function MealSelectionScreen({ location }) {
export default function MealSelectionScreen() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    q: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  });

  const q = searchParams.get('q');
  const currentDate = q !== null ? new Date(q) : addDays(new Date(), 1);

  const menuQuery = useQuery<MenuTypePopulated>(
    ['menuByDate', currentDate],
    () => getMenuByDate(currentDate)
  );
  const dayQuery = useQuery<DayWithMeals[]>({
    queryKey: ['deliveryDays', currentDate],
    queryFn: () => getDays(currentDate),
  });
  const productQuery = useQuery({
    queryKey: ['productsShort'],
    queryFn: () => getProducts({ select: 'name,isFlex, active, dayMenuOrder' }),
  });

  const queryClient = useQueryClient();
  const mealMutation = useMutation({
    mutationFn: updateMealOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deliveryDays'] });
    },
  });

  const deliveryDays = dayQuery.data;
  const { dietMenus } = menuQuery.data || {};
  const { products } = productQuery.data || {};
  // const {
  //   deliveryDays,
  //   loading: loadingDays,
  //   error: errorDays,
  // } = useSelector((state: RootState) => state.productionReport);
  // const typedDeliveryDays: DayWithMeals[] | undefined = dayQuery.data;
  // const typedDeliveryDays = deliveryDays as DayWithMeals[];
  // const { products } = useSelector((state: RootState) => state.productList);

  // const queryStr = queryString.parse(location.search);
  // const currentDate = queryStr.q
  //   ? new Date(queryStr.q)
  //   : addDays(new Date(), 1);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [dayStatusFilter, setDayStatusFilter] = useState('Planowany');
  const [filteredDays, setFilteredDays] = useState<DayWithMeals[]>([]);
  const [isFilterChanged, setIsFiterChanged] = useState(false);
  const formatedDate = selectedDate.toISOString().split('T')[0];

  useEffect(() => {
    navigate(`/meal-selection?q=${formatedDate}`);
  }, [formatedDate, navigate]);

  useEffect(() => {
    dispatch(listOptions());
    // dispatch(getProductionByDay(formatedDate));
    // dispatch(getMenuByDate(formatedDate));
    // dispatch(getProductList({ select: 'name,isFlex, active, dayMenuOrder' }));
  }, [dispatch, formatedDate]);

  useEffect(() => {
    if (deliveryDays) {
      const newFiltedDays = deliveryDays.filter(
        (d) => d.status === dayStatusFilter
      );
      setFilteredDays(newFiltedDays);
    }
  }, [dayStatusFilter, deliveryDays]);

  const handleDateChange = (date: Date | null) => {
    if (!date) return;
    setSelectedDate(date);
  };

  const handleStatusChange = (status: string) => {
    if (!deliveryDays) return;
    const newFiltedDays = deliveryDays.filter((d) => d.status === status);
    setFilteredDays(newFiltedDays);
    setDayStatusFilter(status);
    setIsFiterChanged(true);
  };

  return dayQuery.isLoading ? (
    <Loader />
  ) : dayQuery.isError ? (
    <Message />
  ) : (
    <Layout>
      {/* <div className={classes.errorText}> */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <DatePicker
          label='Wybierz datę dostawy'
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <StatusChip
          deliveryDays={deliveryDays}
          handleClick={handleStatusChange}
          currentStatus={dayStatusFilter}
        />
      </Box>

      {dietMenus && products ? (
        <>
          <DayTable
            days={filteredDays}
            products={products}
            dietMenus={dietMenus}
            isLoading={isFilterChanged}
            setIsLoading={setIsFiterChanged}
            isDayRefetching={dayQuery.isRefetching}
            mealMutation={mealMutation}
          />
        </>
      ) : (
        <Typography color='error' variant='subtitle2'>
          Brak menu na ten dzień!
        </Typography>

        // {/* <Typography>{`Suma: ${deliveryDays.length}`}</Typography> */}

        // </div>
      )}
    </Layout>
  );
}
