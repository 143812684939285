import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { API } from '../../../config';
import { MealImage } from '../../../../../FitAPI/src/api/mealImage/image.model';
import { IconButton, ImageListItemBar } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';
import { indigo } from '@mui/material/colors';

interface Props {
  // images: MealImageDocument[];
  images: MealImage[];
  handleOpen: (image: MealImage) => void;
  handleSetIsMainChange: (image: MealImage) => void;
}

export default function MealImages({
  images,
  handleOpen,
  handleSetIsMainChange,
}: Props) {
  return (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
      {images.map((item) => (
        <ImageListItem key={item.name}>
          <img
            // src={`http://localhost:8000/${}${item}?w=164&h=164&fit=crop&auto=format`}
            src={`${API}/uploads/meals/${item.name}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${API}/uploads/meals/${item.name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.name}
            loading='lazy'
            crossOrigin='anonymous'
            onClick={() => handleOpen(item)}
          />
          <ImageListItemBar
            sx={
              {
                // background: 'rgba(0, 0, 0, 0.5)',
              }
            }
            // title={item.name}
            // subtitle={format(new Date(item.createdAt), 'dd.MM.yyyy')}
            position='below'
            // actionPosition='left'
            actionIcon={
              item.isMain ? (
                <IconButton
                  // sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  sx={{ color: indigo[400] }}
                  aria-label={`info about ${item.name}`}
                >
                  {/* <InfoIcon /> */}
                  <StarRateIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ color: indigo[100] }}
                  onClick={() => handleSetIsMainChange(item)}
                  aria-label={`info about ${item.name}`}
                >
                  <StarOutlineIcon />
                </IconButton>
              )
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
