import React from 'react';
import Layout from '../../components/layout/Layout';
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type Props = {};

const PurchaseIngredientsScreen = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>{`Z | ${''}`}</title>
      </Helmet>
      <Layout>
        <Typography variant='h4'>Listy zakupów</Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Link to='/purchases/new'>
            <Button variant='contained' color='primary' startIcon={<Add />}>
              Dodaj listę zakupów
            </Button>
          </Link>
        </Box>
      </Layout>
    </>
  );
};

export default PurchaseIngredientsScreen;
