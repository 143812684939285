import React, { useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { DriverReport } from '../../../../FitAPI/src/types/reports/DriverReport';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { useReactToPrint } from 'react-to-print';
import Loader from '../layout/Loader';
import { Link } from 'react-router-dom';
import { SummaryData } from '../../../../FitAPI/src/types/reports/summaryReport';
import SummaryInfo from './SummaryInfo';

type Props = {
  query: UseQueryResult<DriverReport[], unknown>;
  summaryData?: SummaryData;
};

const DriversReport = (props: Props) => {
  const { query, summaryData } = props;
  const { isLoading, isError, data } = query;

  const ref = useRef(null);
  const printIngredientsHandler = useReactToPrint({
    content: () => ref.current,
  });

  const theme = useTheme();

  return (
    <div>
      <Box
      //    align='right'
      >
        <Button onClick={printIngredientsHandler}>Drukuj</Button>
      </Box>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Typography variant='h5' sx={{ color: theme.palette.secondary.main }}>
          Błąd - Sprawdź czy jest zdefiniowane menu na ten okres
        </Typography>
      ) : (
        <div ref={ref}>
          <Box my={8}>
            <Typography variant='h5' component='h2' align='center'>
              Dostawy
            </Typography>
            {summaryData && (
              <SummaryInfo summaryData={summaryData} type='driverReport' />
            )}
          </Box>
          <TableContainer component={Paper}>
            <Table
              // className={classes.table}
              padding='none'
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Miasto</TableCell>
                  <TableCell>Ulica</TableCell>
                  <TableCell sx={{ width: 60 }}>Nr bud.</TableCell>
                  <TableCell sx={{ width: 60 }}>Nr lok.</TableCell>
                  <TableCell sx={{ width: 60 }}>G.do</TableCell>
                  <TableCell>Dieta</TableCell>
                  <TableCell sx={{ px: 1 }}>Kcal</TableCell>
                  <TableCell>Nr klienta</TableCell>
                  <TableCell>Imię</TableCell>
                  <TableCell sx={{ px: 1, width: 160 }}>Nazwisko</TableCell>
                  <TableCell>Tel.</TableCell>
                  <TableCell>Strefa</TableCell>
                  <TableCell>Uwagi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row.city}</TableCell>
                      <TableCell sx={{ px: 0, width: 180 }}>
                        {row.street}
                      </TableCell>
                      <TableCell sx={{ px: 0, width: 60 }}>
                        {row.buildingNr}
                      </TableCell>
                      <TableCell sx={{ px: 0, width: 60 }}>
                        {row.aptNr}
                      </TableCell>
                      <TableCell>{row.toTime}</TableCell>
                      <TableCell>{row.dietName}</TableCell>
                      <TableCell>{row.calories}</TableCell>
                      <TableCell>
                        <Link to={`/users/${row.userId}`}>{row.userNo}</Link>
                      </TableCell>
                      <TableCell>{row.fName}</TableCell>
                      <TableCell>{row.sName}</TableCell>
                      <TableCell sx={{ pr: 1 }}>{row.phone}</TableCell>
                      <TableCell sx={{ pr: 1, width: 60 }}>
                        {row.driver}
                      </TableCell>
                      <TableCell>{row.comments}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default DriversReport;
