import z from 'zod';

export const PurchaseSchema = z.object({
  orderDate: z.date(),
  deliveryDateFrom: z.date(),
  deliveryDateTo: z.date(),
  // items: z.array(PurchaseItem),
});

export type Purchase = z.infer<typeof PurchaseSchema>;
