//Moduł z polami do wykorzystania w formularzu

import React from 'react';
import { useField } from 'formik';

import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// import Form from 'react-bootstrap/Form';

const MySelect = ({ label, optionList, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <FormControl sx={{ minWidth: 150, width: '100%', textAlign: 'left' }}>
        <InputLabel>{label}</InputLabel>
        <Select label={label} {...field} {...props}>
          {optionList.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText error={true}>
          {meta.touched && meta.error ? meta.error : null}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export const MySimpleSelect = ({ label, optionList, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl sx={{ minWidth: 150, width: '100%', marginTop: 1 }}>
      <InputLabel id={`label-${label}`}>{label}</InputLabel>
      <Select {...field} {...props} labelId={`label-${label}`} label={label}>
        {optionList.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error>
        {meta.touched && meta.error ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

const MyCheckBox = ({ label, ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  return <FormControlLabel control={<Checkbox {...field} />} label={label} />;
};

const CheckBoxGroup = ({ legend, checkGroupOptions, ...props }) => {
  // const [field, meta] = useField({ ...props, type: 'checkbox' });
  // <Checkbox {...field} {...props} />;

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{legend}</FormLabel>
      <FormGroup>
        {checkGroupOptions.map((option) => (
          <MyCheckBox name={option.name} label={option.label} />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      sx={{ width: '100%', marginTop: 1 }}
      label={label}
      error={meta.touched && meta.error ? 'true' : null}
      helperText={meta.error}
      {...field}
      {...props}
    />
  );
};

const MySwitch = ({ label, ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  return (
    <FormControlLabel
      control={<Switch {...field} {...props} />}
      label={label}
    />
  );
};

const MyRadioInput = ({ name, legend, radioOptions, ...props }) => {
  const [field, meta] = useField({ name, type: 'radio' });

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{legend}</FormLabel>
      <RadioGroup name={name} value={meta.value} {...field} {...props}>
        {radioOptions.map((o) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio />}
            label={o.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={true}>
        {meta.touched && meta.error ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

// const MyTextArea = ({ divClass, rows, label, ...props }) => {
// 	const [ field, meta ] = useField(props);
// 	return (
// 		<div className={'form-group ' + divClass}>
// 			<label htmlFor={props.id || props.name}>{label}</label>
// 			<textarea className="form-control" rows={rows} {...field} {...props} />
// 		</div>
// 	);
// };

export {
  MySelect,
  MyCheckBox,
  CheckBoxGroup,
  MyTextInput,
  MySwitch,
  MyRadioInput,
};
