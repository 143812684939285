import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserContactOptions } from '../actions/optionActions';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import format from 'date-fns/format';

import { getContactsReport } from '../actions/reportActions';
import Layout from '../components/layout/Layout';

const ContactsScreen = () => {
  const dispatch = useDispatch();
  const { userContactOptions } = useSelector((state) => state.optionList);
  const contacts = useSelector((state) => state.reports.contacts);
  const {
    contactChannel,
    contactStatus,
    contactTypes,
    problemSource,
  } = userContactOptions;

  useEffect(() => {
    dispatch(getContactsReport());
    dispatch(getUserContactOptions());
  }, []);

  const contactChannelOptions = contactChannel?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );
  const contactStatusOptions = contactStatus?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );
  const contactTypesOptions = contactTypes?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );
  const problemSourceOptions = problemSource?.reduce(
    (obj, curr) => ({ ...obj, [curr]: curr }),
    {}
  );
  const structure = [
    {
      title: 'Data',
      field: 'contactDate',
      render: (rowData) => (
        <Link to={`/users/${rowData.userId}`}>
          {format(new Date(rowData.contactDate), 'd.M.yy (H:mm)')}
        </Link>
      ),
      width: '10%',
      filtering: false,
    },
    {
      title: 'Imię',
      field: 'fName',
    },
    {
      title: 'Nazwisko',
      field: 'sName',
    },
    {
      title: 'Typ',
      field: 'contactType',
      lookup: contactTypesOptions,
    },
    {
      title: 'Kanał',
      field: 'contactChannel',
      lookup: contactChannelOptions,
    },
    {
      title: 'Status',
      field: 'contactStatus',
      lookup: contactStatusOptions,
    },
    {
      title: 'Odpowiedzialność',
      field: 'problemSource',
      lookup: problemSourceOptions,
    },
    {
      title: 'Opis',
      field: 'contactDescription',
      filtering: false,
      width: '50%',
    },
    {
      title: 'Wprowadzone',
      render: (rowData) => rowData?.createdBy?.fName,
    },
  ];

  return (
    <Layout>
      <MaterialTable
        title='Kontakty z użytkownikami'
        data={contacts}
        // data={contacts.filter((c) => c.createdBy?.fName !== 'System')}
        columns={structure}
        options={{
          pageSize: 15,
          pageSizeOptions: [15, 25, 50],
          sorting: true,
          search: false,
          filtering: true,
        }}
      />
    </Layout>
  );
};

export default ContactsScreen;
