import React, { useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import IngredientReport from '../../../../FitAPI/src/types/reports/IngredientReport';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { useReactToPrint } from 'react-to-print';
import Loader from '../layout/Loader';
import SummaryInfo from './SummaryInfo';
import { Link } from 'react-router-dom';
import { SummaryData } from '../../../../FitAPI/src/types/reports/summaryReport';

type Props = {
  query: UseQueryResult<IngredientReport[], unknown>;
  summaryData?: SummaryData;
};

const IngredientsReport = (props: Props) => {
  const { query, summaryData } = props;
  const { isLoading, isError, data } = query;

  const ingredientsRef = useRef(null);
  const printIngredientsHandler = useReactToPrint({
    content: () => ingredientsRef.current,
  });

  const theme = useTheme();

  return (
    <div>
      <Box textAlign='right'>
        <Button onClick={printIngredientsHandler}>Drukuj</Button>
      </Box>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Typography variant='h5' sx={{ color: theme.palette.secondary.main }}>
          Błąd - Sprawdź czy jest zdefiniowane menu na ten okres
        </Typography>
      ) : (
        <div ref={ingredientsRef}>
          <Box my={8}>
            <Typography variant='h5' component='h2' align='center'>
              Lista składników
            </Typography>
            {summaryData && (
              <SummaryInfo summaryData={summaryData} type='productionReport' />
            )}
          </Box>
          <TableContainer component={Paper}>
            <Table
              // className={classes.table}
              padding='none'
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nazwa</TableCell>
                  <TableCell align='right'>Waga[g]</TableCell>
                  <TableCell width={80} align='right'>
                    Odpad[%]
                  </TableCell>
                  <TableCell
                    style={{ paddingRight: 12 }}
                    width={120}
                    align='right'
                  >
                    Waga brutto[g]
                  </TableCell>
                  <TableCell>Dostawca</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align='left'>
                        <Link
                          to={`/ingredients?q=${row.name}`}
                          style={{ textDecoration: 'none' }}
                          target='_blank'
                        >
                          <Typography variant='body1'>{row.name}</Typography>
                        </Link>
                        {/* {row.name} */}
                      </TableCell>
                      <TableCell style={{ paddingRight: 12 }} align='right'>
                        {`${row.weight?.toFixed(0)}`}
                      </TableCell>
                      <TableCell style={{ paddingRight: 12 }} align='right'>
                        <Typography color='textSecondary' variant='body2'>
                          {row.waste ? `${(row.waste * 100).toFixed(0)} %` : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          // marginLeft: 12,
                          // paddingLeft: 12,
                          paddingRight: 12,
                        }}
                        align='right'
                      >
                        <strong>
                          {row.grossWeight
                            ? `${row.grossWeight.toFixed(0)}`
                            : ''}
                        </strong>
                      </TableCell>
                      <TableCell align='left'>{row.supplier}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default IngredientsReport;
