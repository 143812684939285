import React from 'react';
import { Download, Payment } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { GridRowSelectionModel, GridToolbarContainer } from '@mui/x-data-grid';
import {
  getPaymentFile,
  getPurchaseInvoices,
  updateMultipleInvoices,
} from '../../utils/api/purchase.invoice.api';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { WithId } from '../../types';
import { PurchaseInvoice } from '../../../../fitAPI/src/api/purchaseInvoice/purchaseInvoice.model';
import { grey } from '@mui/material/colors';

type Props = {
  rowSelection: GridRowSelectionModel;
  setRowSelection: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  invoicesGrossSum: number;
};

const PurchaseInvoicesToolBar = ({
  rowSelection,
  setRowSelection,
  invoicesGrossSum,
}: Props) => {
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data } = useQuery<WithId<PurchaseInvoice>[]>({
    queryKey: ['purchaseInvoices'],
    queryFn: () => getPurchaseInvoices(),
  });

  const isPaidMutation = useMutation({
    mutationFn: updateMultipleInvoices,
    onSuccess: () => {
      enqueueSnackbar('Faktury oznaczone jako opłacone', {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['purchaseInvoices'] });
      setRowSelection([]);
    },
  });

  const handleSetToPaid = async () => {
    isPaidMutation.mutate({
      invoiceIds: rowSelection as string[],
      fieldsToUpdate: { isPaid: true, paidAt: new Date() },
    });
  };

  const handlePaymentFileDownload = async () => {
    const selectedRows = rowSelection.join(',');
    const blob = await getPaymentFile({ invoices: selectedRows });
    if (!blob) return;
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Przelewy_${format(new Date(), 'yyyy-MM-dd')}.csv`;
    link.click();
  };

  return (
    <GridToolbarContainer>
      <Button
        color='primary'
        startIcon={<Download />}
        onClick={handlePaymentFileDownload}
      >
        Przelewy
      </Button>
      <Button
        startIcon={<Payment />}
        onClick={() => setOpenPaymentDialog(true)}
      >
        Oznacz jako opłacone
      </Button>
      <Dialog open={openPaymentDialog} draggable>
        <DialogTitle>
          Czy chcesz oznaczyć wybrane faktury jako opłacone?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Czy następujące faktury mają zostać oznaczone jako opłacone?
            <ul>
              {data
                ?.filter((invoice) => rowSelection.includes(invoice._id))
                .map((invoice) => (
                  <li key={invoice._id}>
                    {invoice.supplier} {invoice.invoiceNumber} -{' '}
                    {invoice.grossCost.toFixed(2)} zł
                  </li>
                ))}
            </ul>
            Liczba faktur: {rowSelection.length} <br />
            Suma brutto {invoicesGrossSum.toFixed(2)} zł
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setOpenPaymentDialog(false)}>Nie</Button>
            <Button onClick={handleSetToPaid} autoFocus>
              Tak
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Typography variant='button' sx={{ ml: 20, color: grey[700] }}>
        {/* {rowSelection.length} wybranych */}
        {invoicesGrossSum
          ? `Suma brutto: ${invoicesGrossSum.toFixed(2)} zł`
          : ''}
      </Typography>
    </GridToolbarContainer>
  );
};

export default PurchaseInvoicesToolBar;
