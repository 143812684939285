import api from '../api';
import { Meal } from '../../../../FitAPI/src/models/MealModel';

//Get all the meals names to use in the menu autocomplete field
export async function getAllMeals() {
  const data = await api.get('/meals?select=name&pageSize=15000');
  return data.data.meals;
}

// Get one meal by id
export async function getOneMeal({ id }: { id: string | undefined }) {
  if (!id) throw new Error('No id provided');
  const data = await api.get(`/meals/${id}`);
  return data.data.data.meal;
}

// Update one meal by id
export async function updateOneMeal({
  mealId,
  meal,
}: {
  mealId: string;
  meal: Partial<Meal>;
  // meal: MealOverview;
}) {
  if (!mealId) throw new Error('No id provided');
  const data = await api.put(`/meals/${mealId}`, meal);
  return data.data.data;
}

export async function deleteOneMeal({ id }: { id: string }) {
  const data = await api.delete(`/meals/${id}`);
  return data.data.data;
}

export async function getAllIngredients() {
  const data = await api.get('/ingredients?select=name&pageSize=10000');
  return data.data.ingredients;
}
