import React from 'react';
import MaterialTable from '@material-table/core';
import { MealNutrient } from '../../../../FitAPI/src/models/MealModel/mealNutrition';
import { Column } from '@material-table/core';

const nutritionStructure: Array<Column<MealNutrient>> = [
  {
    title: 'Rozmiar',
    field: 'size',
  },
  {
    title: 'kcal',
    field: 'kcal',
    render: (rowData) => rowData.nutritionValues.kcal?.toFixed(0),
  },
  {
    title: 'B.',
    field: 'protein',
    render: (rowData) => rowData.nutritionValues.protein?.toFixed(1),
  },
  {
    title: 'W.',
    field: 'carbs',
    render: (rowData) => rowData.nutritionValues.carbs?.toFixed(1),
  },
  {
    title: 'T.',
    field: 'fat',
    render: (rowData) => rowData.nutritionValues.fat?.toFixed(1),
  },
  {
    title: 'Bł.',
    field: 'carbFiber',
    render: (rowData) => rowData.nutritionValues.carbFiber?.toFixed(2),
  },
  {
    title: 'Sód',
    field: 'sodium',
    render: (rowData) => rowData.nutritionValues.sodium?.toFixed(1),
  },
];
interface Props {
  data: MealNutrient[];
}

const Nutritions = (props: Props) => {
  const { data } = props;

  return (
    <div>
      <MaterialTable
        title='Wartości odżywcze'
        data={data}
        columns={nutritionStructure}
        options={{
          sorting: false,
          search: false,
          paging: false,
        }}
      />
    </div>
  );
};

export default Nutritions;
