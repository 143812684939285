import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDay } from '../../actions/orderActions';
import moment from 'moment';

import { getProductList } from '../../actions/productActions';

// import { getProducts } from '../manager/apiManager';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { format } from 'date-fns';

//przy powtornej zmianie dnia   sie cos zacina

export default function DayChange({
  day,
  // addrList,
  close,
  isVisible,
  product,
  // updateDay,
}) {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);
  const { addresses } = useSelector((state) => state.order.order.user);
  const [newDay, setNewDay] = useState({ ...day });
  // const [dietTypesList, setDietTypesList] = useState([]);
  const { products } = useSelector((state) => state.productList);
  const [caloriesList, setCaloriesList] = useState([]);

  //if different product is selected - calories list is adjusted in list
  useEffect(() => {
    if (products.length) {
      const selectedDiet = products.find((p) => p._id === newDay.product);
      setCaloriesList(selectedDiet.calories.map((c) => c.variant));
    }
  }, [newDay.product, products]);

  useEffect(() => {
    dispatch(getProductList({ select: 'name, calories', sort: 'menuOrder' }));
  }, [dispatch]);

  // Status fields
  const statusField = () => {
    const handleChange = (e) => {
      setNewDay({ ...newDay, [e.target.name]: e.target.value });
    };
    return (
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel>Status</InputLabel>
        <Select
          label='Status'
          value={newDay.status}
          onChange={handleChange}
          name='status'
        >
          {['Planowany', 'Zawieszony', 'Niedostarczony', ''].map((c) => {
            return (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  // Diet selection
  const dietField = () => {
    const handleChangeDiet = (e) => {
      const selectedDiet = products.find((p) => p._id === e.target.value);
      setNewDay({
        ...newDay,
        product: selectedDiet._id,
        diet: selectedDiet.name,
      });
    };
    return (
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel>Dieta</InputLabel>
        <Select
          label='Dieta'
          value={newDay.product}
          onChange={handleChangeDiet}
          name='diet'
        >
          {products.map((p) => {
            return (
              <MenuItem key={p._id} value={p._id}>
                {p.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  // Calories fields
  const caloriesField = () => {
    const handleChangeCalories = (e) => {
      setNewDay({ ...newDay, calories: e.target.value });
    };
    return (
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel>Kaloryczność</InputLabel>
        <Select
          label='Kaloryczność'
          value={newDay.calories}
          onChange={handleChangeCalories}
          name='calories'
        >
          {caloriesList.map((c) => {
            return (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  //  Adres field
  const addressField = () => {
    //if any of the value in the address is different the it is possible to reselected and reassign
    const handleChangeAddress = (e) => {
      const newAddress = addresses.find(
        (a) => JSON.stringify(a) === e.target.value
      );
      setNewDay({ ...newDay, address: newAddress });
    };
    return (
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel>Adres</InputLabel>
        <Select
          label='Adres'
          value={JSON.stringify(newDay.address)}
          onChange={handleChangeAddress}
          name='address'
        >
          {addresses.map((a) => {
            return (
              <MenuItem key={a._id} value={JSON.stringify(a)}>
                {a.formattedAddress}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  //  Comments field
  const commentsField = () => {
    const handleChangeComments = (e) => {
      setNewDay({
        ...newDay,
        address: { ...newDay.address, comments: e.target.value },
      });
    };
    return (
      <TextField
        fullWidth
        sx={{ my: 1 }}
        value={newDay.address.comments}
        label='Komentarz do dostawy'
        name='deliveryComments'
        multiline
        onChange={handleChangeComments}
      />
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateDay(order._id, product._id, day._id, newDay));
    close();
  };
  return (
    <form onSubmit={onSubmit}>
      <Dialog open={isVisible} onClose={close}>
        <DialogTitle id='alert-dialog-title'>
          Szczegóły: {format(new Date(day.deliveredAt), 'dd.MM.yyyy (EEEE)')}
        </DialogTitle>
        <DialogContent>
          {statusField()}
          {dietField()}
          {caloriesField()}
          {addressField()}
          {commentsField()}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color='primary' autoFocus>
            Zamknij
          </Button>
          <Button onClick={onSubmit} color='secondary'>
            Zmień
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
