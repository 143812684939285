import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import format from 'date-fns/format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { copyMenu, getMenuByDate } from '../../utils/api/menu.meal.api';
import { MenuTypePopulated } from '../../../../FitAPI/src/models/MenuModel';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { WithId } from '../../types';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle='#copy-menu-day-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  currentMenuId: string;
  menuAt: Date;
}

export default function CopyDay({
  handleClose,
  isOpen,
  menuAt,
  currentMenuId,
}: Props) {
  const [menuToCopyFromAt, setMenuToCopyFromAt] = useState(new Date());
  const queryClient = useQueryClient();
  // get menu to copy from
  const { data: menuToCopyFrom, isLoading } = useQuery<
    WithId<MenuTypePopulated>
  >(['getMenu', menuToCopyFromAt], () => getMenuByDate(menuToCopyFromAt));

  const handleCopy = async () => {
    const res = await copyMenu(menuToCopyFromAt, new Date(menuAt));
    queryClient.setQueryData(['getMenu', currentMenuId], res);
    handleClose();
  };

  // const dispatch = useDispatch();

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby='copy-menu-day-title'
      >
        <DialogTitle style={{ cursor: 'move' }} id='copy-menu-day-title'>
          Kopiowanie jadłospisu na dzień 
          {format(new Date(menuAt), 'dd.MM.yyyy')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tutaj możesz nadpisać obecny jadłospis daniami z innego dnia. <br />
            UWAGA - obecne dania zostaną usunięte z obecnego jadłospisu.
          </DialogContentText>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <DatePicker
              label='Kopiowanie z dnia'
              value={menuToCopyFromAt}
              onChange={(date) => setMenuToCopyFromAt(date)}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </Box>
          <Box>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div>
                <h3>
                  Menu do skopiowania z dnia{' '}
                  {`${format(menuToCopyFromAt, 'dd.MM.yy')}`}
                </h3>
                <ul>
                  {menuToCopyFrom?.dietMenus.map((dietMenu) => {
                    // check if every mealMatch has no meals in it - if so, don't display it
                    if (
                      dietMenu.mealMatchMatrix.every(
                        (mealMatch) => mealMatch.meals.length === 0
                      )
                    )
                      return null;
                    return (
                      <li key={dietMenu._id}>
                        {dietMenu.dietType}
                        <ul>
                          {dietMenu.mealMatchMatrix.map((matrix) => (
                            <li key={matrix._id}>
                              {matrix.meals[0]?.currentMeal.name}
                            </li>
                          ))}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Zamknij
          </Button>
          <Button onClick={handleCopy} color='primary'>
            Kopiuj
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
