import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import Layout from '../components/layout/Layout';
import CircularProgress from '@mui/material/CircularProgress';

const WebScrap = () => {
  const [menu, setMenu] = useState();
  const [dietTypes, setDietTypes] = useState();

  const loadMenu = async () => {
    // let fetchedMenu;
    try {
      const fetchedMenu = await axios.get(
        'https://crm.fitdiet.eu/webscrap/api/v1'
      );
      if (fetchedMenu.data.success) {
        setMenu(fetchedMenu.data.data);
      }
    } catch (error) {
      console.log(error);
    }

    // console.log(fetchedMenu);
  };

  useEffect(() => {
    loadMenu();
  }, []);

  useEffect(() => {
    if (menu) {
      const allTypes = menu.map((item) => item.dietType);
      const allTypesUnique = Array.from(new Set(allTypes));
      const allTypesObject = allTypesUnique.reduce((curr, item) => {
        return {
          ...curr,
          [item]: item,
        };
      }, {});
      console.log(allTypesObject);
      setDietTypes(allTypesObject);
    }
  }, [menu]);

  const structure = [
    { title: 'Dzień', field: 'day', defaultSort: 'desc' },
    {
      title: 'Typ',
      field: 'dietType',
      lookup: { ...dietTypes },
    },
    { title: 'Sniadanie', field: 'breakfast' },
    { title: 'II Śniadanie', field: 'lunch' },
    { title: 'Obiad', field: 'dinner' },
    { title: 'Podwieczorek', field: 'eveningSnack' },
    { title: 'Kolacja', field: 'supper' },
  ];

  return (
    <Layout>
      {menu && dietTypes ? (
        <MaterialTable
          title='Menu Macz'
          columns={structure}
          data={menu}
          options={{ pageSize: 30, filtering: true, exportButton: true }}
        />
      ) : (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Layout>
  );
};

export default WebScrap;
