import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import React, { useState } from 'react';
import InfoBox from '../layout/InfoBox';
import NutrtionBox from './NutrtionBox';

const Portion = ({
  isPortion,
  handleToggle,
  portionWeightPrepared,
  portionWeightRaw,
  handlePortionSize,
  nutrition,
}) => {
  //   const [test, setTest] = useState(isPortion || false);
  return (
    <div>
      <>
        <FormControlLabel
          control={
            <Switch
              //   checked={true}
              checked={isPortion}
              //   onChange={() => setTest((prevState) => !prevState)}
              onChange={() => handleToggle(isPortion)}
              name='isPortion'
            />
          }
          label='Na porcje'
        />
      </>

      {/* {isPortion && ( */}
      <Collapse in={isPortion}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title='Porcja'></CardHeader>
              <CardContent>
                <Typography variant='body1'>Wielkość porcji</Typography>
                <TextField
                  label='Porcja gotowa (g)'
                  value={portionWeightPrepared || ''}
                  onChange={handlePortionSize}
                />
                {portionWeightRaw && (
                  <Typography
                    style={{ marginTop: 12 }}
                    variant='body1'
                  >{`Porcja (składniki): ${portionWeightRaw?.toFixed(
                    0
                  )}g.`}</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            {portionWeightPrepared && (
              <NutrtionBox
                title={`Wartości odż. na porcję  (${portionWeightPrepared?.toFixed(
                  0
                )} g.)`}
                nutrition={{
                  kcal: nutrition?.kcal,
                  protein: nutrition?.protein,
                  carbs: nutrition?.carbs,
                  fat: nutrition?.fat,
                  sodium: nutrition?.sodium,
                  carbFiber: nutrition?.carbFiber,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Collapse>
      {/* )} */}
    </div>
  );
};

export default Portion;
